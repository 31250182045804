import React, { ReactNode } from "react";
import Flex from "../../core/Flex";
import { screenerStyles } from "../careScreenerStyles";
import { css } from "aphrodite";
import { insuranceStyles } from "./insuranceStyles";

type Props = {
  progressComponent: ReactNode;
  continueButton: ReactNode;
};

export default function InsuranceUnknownCoverage({ progressComponent, continueButton }: Props) {
  return (
    <Flex className={css(screenerStyles.whiteContainerRoundedEdges, screenerStyles.padding, screenerStyles.container)}>
      {progressComponent}
      <Flex flexGrow center>
        <div className={css(insuranceStyles.verticalSpacer)} />
        <p className={css(insuranceStyles.title)}>You're likely covered, but we need to check manually!</p>
        <div className={css(insuranceStyles.verticalSpacer)} />
        <p className={css(insuranceStyles.bodyText)}>
          Our billing team will reach out within 24 hrs with your insurance coverage. <br />
          <br /> In the meantime, go ahead and book your session! <br />
          <br />
        </p>
        {continueButton}
      </Flex>
    </Flex>
  );
}
