export default [
  {
    id: 1,
    objective: "Manage social situations",
    theme: "brightSky",
    animal: "lemur",
    title: "Look people in their eyes when speaking to them",
    expiration: 90,
    frequency: "daily",
    points: 1
  },
  {
    id: 2,
    objective: "Manage social situations",
    theme: "brightSky",
    animal: "dog",
    title: "Adjust the volume of your voice (practice conversational volume)",
    expiration: 30,
    frequency: "daily",
    points: 2
  },
  {
    id: 3,
    objective: "Manage social situations",
    theme: "brightSky",
    animal: "sheep",
    title: "Start a conversation with someone",
    expiration: 90,
    frequency: "daily",
    points: 3
  },
  {
    id: 4,
    objective: "Manage social situations",
    theme: "brightSky",
    animal: "panda",
    title: "Spend 1 hr with your family and play games",
    expiration: 30,
    frequency: "daily",
    points: 3
  },
  {
    id: 5,
    objective: "Self regulation",
    theme: "nightSky",
    animal: "owl",
    title: "Write in your journal",
    expiration: 30,
    frequency: "daily",
    points: 1
  },
  {
    id: 6,
    objective: "Self regulation",
    theme: "nightSky",
    animal: "pufferfish",
    title: "Take a 30 second time out when you feel angry",
    expiration: 60,
    frequency: "daily",
    points: 2
  },
  {
    id: 7,
    objective: "Self regulation",
    theme: "nightSky",
    animal: "octopus",
    title: "Take a shower when you feel angry",
    expiration: 60,
    frequency: "daily",
    points: 2
  },
  {
    id: 8,
    objective: "Self regulation",
    theme: "nightSky",
    animal: "whale",
    title: "Practice your breathing exercises",
    expiration: 60,
    frequency: "daily",
    points: 2
  },
  {
    id: 9,
    objective: "Self regulation",
    theme: "nightSky",
    animal: "owl",
    title: "Label your feelings",
    description: "Label your feelings as they happen for example: 'I am happy'",
    expiration: 30,
    frequency: "daily",
    points: 1
  },
  {
    id: 10,
    objective: "Communication skills",
    theme: "pink",
    animal: "lemur",
    title: "Look people in their eyes when speaking to them",
    expiration: 30,
    frequency: "daily",
    points: 1
  },
  {
    id: 11,
    objective: "Communication skills",
    theme: "pink",
    animal: "leopard",
    title: "Respond to questions",
    expiration: 30,
    frequency: "daily",
    points: 2
  },
  {
    id: 12,
    objective: "Communication skills",
    theme: "pink",
    animal: "panda",
    title: "Spend 1 hr with your family and play games",
    expiration: 30,
    frequency: "daily",
    points: 3
  },

  {
    id: 13,
    objective: "Self esteem & confidence",
    theme: "lemon",
    animal: "lion",
    title: "Write down 1 thing you like about yourself",
    expiration: 30,
    frequency: "daily",
    points: 1
  },
  {
    id: 14,
    objective: "Self esteem & confidence",
    theme: "lemon",
    animal: "owl",
    title: "Learn something new (it can be anything!)",
    expiration: 60,
    frequency: "daily",
    points: 3
  },
  {
    id: 15,
    objective: "Self esteem & confidence",
    theme: "lemon",
    animal: "fox",
    title: "Give yourself a compliment",
    expiration: 30,
    frequency: "daily",
    points: 2
  },
  {
    id: 16,
    objective: "Self esteem & confidence",
    theme: "lemon",
    animal: "penguin",
    title: "Spend 30 min on something you are really good at",
    expiration: 30,
    frequency: "daily",
    points: 1
  },
  {
    id: 17,
    objective: "Manage anxiety",
    theme: "orange",
    animal: "whale",
    title: "Practice your breathing exercises",
    expiration: 90,
    frequency: "daily",
    points: 2
  },
  {
    id: 18,
    objective: "Manage anxiety",
    theme: "orange",
    animal: "crab",
    title: "Talk to someone about what worries you",
    expiration: 90,
    frequency: "daily",
    points: 3
  },
  {
    id: 19,
    objective: "Manage anxiety",
    theme: "orange",
    animal: "turtle",
    title: "Write down what you feel in your journal",
    expiration: 30,
    frequency: "daily",
    points: 1
  },
  {
    id: 20,
    objective: "Manage depression",
    theme: "bloodOrange",
    animal: "jellyfish",
    title: "Write down 1 thing I like about myself",
    expiration: 30,
    frequency: "daily",
    points: 2
  },
  {
    id: 21,
    objective: "Manage depression",
    theme: "bloodOrange",
    animal: "hippo",
    title: "Write in your journal",
    expiration: 30,
    frequency: "daily",
    points: 1
  },
  {
    id: 22,
    objective: "Manage depression",
    theme: "bloodOrange",
    animal: "fish",
    title: "Play outside for at least 1 hour",
    expiration: 30,
    frequency: "daily",
    points: 2
  },
  {
    id: 23,
    objective: "Manage depression",
    theme: "bloodOrange",
    animal: "monkey",
    title: "Stick to your daily schedule",
    expiration: 90,
    frequency: "daily",
    points: 3
  },
  {
    id: 24,
    objective: "Manage depression",
    theme: "bloodOrange",
    animal: "giraffe",
    title: "Eat 1 piece of fruit and 1 vegetable",
    expiration: 90,
    frequency: "daily",
    points: 1
  },
  {
    id: 25,
    objective: "Day to day tasks",
    theme: "brightSky",
    animal: "beaver",
    title: "Brush teeth",
    expiration: 60,
    frequency: "daily",
    points: 1
  },
  {
    id: 26,
    objective: "Day to day tasks",
    theme: "brightSky",
    animal: "cat",
    title: "Finish plate every meal",
    expiration: 60,
    frequency: "daily",
    points: 1
  },
  {
    id: 27,
    objective: "Day to day tasks",
    theme: "brightSky",
    animal: "dolphin",
    title: "Get dressed for school",
    expiration: 60,
    frequency: "daily",
    points: 1
  },
  {
    id: 28,
    objective: "Manage physical health",
    theme: "lemon",
    animal: "lion",
    title: "Shower",
    expiration: 60,
    frequency: "daily",
    points: 1
  },
  {
    id: 29,
    objective: "Manage physical health",
    theme: "lemon",
    animal: "monkey",
    title: "Eat 1 piece of fruit and 1 vegetable",
    expiration: 60,
    frequency: "daily",
    points: 1
  },
  {
    id: 30,
    objective: "Manage physical health",
    theme: "lemon",
    animal: "owl",
    title: "Practise body focus / scanning excercises",
    expiration: 60,
    frequency: "daily",
    points: 2
  },
  {
    id: 31,
    objective: "Manage physical health",
    theme: "lemon",
    animal: "koala",
    title: "Practise your physiotherapy exercises",
    expiration: 90,
    frequency: "daily",
    points: 2
  },
  {
    id: 32,
    objective: "Manage physical health",
    theme: "lemon",
    animal: "leopard",
    title: "Exercise (go for a walk or run)",
    expiration: 90,
    frequency: "daily",
    points: 2
  }
];
