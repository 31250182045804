import React, { useEffect, useMemo, useState } from "react";
import { CREATE_EHR_API_KEYS, GET_FORMS } from "../../queries";
import { useMutation, useQuery } from "@apollo/client";
import ManateeLoading from "../ManateeLoading";
import "./CompleteForms.css";
import _ from "lodash";
import { reportError } from "../../utils/errorUtils";
import CompleteEhrForm from "./CompleteEhrForm";
import { useActions } from "tiny-atom/react/hooks";
import { useSearchParams } from "react-router-dom";

type Props = {
  userIds?: number[];
  formIds?: string[];
};

type EhrApiKey = {
  apiKey: string;
  userId: number;
  ehrUserId: string;
};

export default function CompleteForms({ userIds, formIds }: Props) {
  const { displayToast } = useActions();
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [apiKeys, setApiKeys] = useState<EhrApiKey[]>([]);
  const [index, setIndex] = useState(0);
  const [, setSearchParams] = useSearchParams();

  const [createEhrApiKeysMutation] = useMutation(CREATE_EHR_API_KEYS);

  const {
    data,
    loading: formsLoading,
    error: formsError
  } = useQuery(GET_FORMS, {
    variables: {
      userIds: userIds ? userIds : null,
      ids: formIds ? formIds : null,
      status: "Incomplete"
    },
    fetchPolicy: "no-cache"
  });

  const forms = useMemo(() => data?.forms || [], [data]);

  useEffect(() => {
    const formUserIds = _.uniq(forms.map(f => f.user?.id).filter(id => !!id) || []);

    if (formUserIds?.length) {
      setLoading(true);
      createEhrApiKeysMutation({
        variables: {
          userIds: formUserIds
        },
        onError: error => {
          reportError(`createEhrApiKeysMutation -- ${formUserIds}`, error);
          setShowError(true);
          setLoading(false);
        },
        onCompleted: data => {
          setApiKeys(data.createEhrApiKeys);
          setLoading(false);
        }
      });
    }
  }, [createEhrApiKeysMutation, forms]);

  const currentFormProps = useMemo(() => {
    if (forms.length && apiKeys?.length && index < forms.length) {
      const form = forms[index];
      const apiKey = apiKeys.find(key => key.userId === form.user.id);

      if (apiKey) {
        return {
          ehrApiKey: apiKey?.apiKey,
          ehrFormId: form.formTemplate.id,
          ehrUserId: apiKey?.ehrUserId,
          form
        };
      } else {
        setShowError(true);
      }
    }
    return undefined;
  }, [apiKeys, forms, index]);

  if (loading || formsLoading) {
    return (
      <div className="column-centered">
        <div className="loading-container">
          <ManateeLoading disableShadow />
        </div>
      </div>
    );
  }

  if (showError || formsError) {
    return (
      <div className="column-centered">
        <div className="content-container">
          <p className="message-title">Something went wrong... please go back and try again</p>
        </div>
      </div>
    );
  }

  if (data?.forms?.length === 0) {
    return (
      <div className="column-centered">
        <div className="content-container">
          <p className="message-title">Hmmm... no incomplete forms were found</p>
        </div>
      </div>
    );
  }

  if (currentFormProps) {
    return (
      <>
        <div className="header">
          {!!data?.forms && <p>{`Document ${index + 1} of ${forms.length}`}</p>}
          <>
            <p className="form-name">{currentFormProps.form.formTemplate.name}</p>
            <p className="form-user">{"For " + currentFormProps.form.user.firstName}</p>
          </>
        </div>
        <CompleteEhrForm
          ehrApiKey={currentFormProps.ehrApiKey}
          ehrFormId={currentFormProps.ehrFormId}
          ehrUserId={currentFormProps.ehrUserId}
          onSubmitted={() => {
            displayToast({
              text: "Document completed!",
              size: "small",
              onComplete: () => {
                if (index < forms.length - 1) {
                  setIndex(index + 1);
                } else {
                  setSearchParams({ completed: "true" });
                }
              }
            });
          }}
          index={index}
        />
      </>
    );
  }

  return <></>;
}
