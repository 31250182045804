import React, { useState, useContext } from "react";
import { BlockStack, TextField, Button, Select } from "@shopify/polaris";
import { Formik } from "formik";
import "./AddConnection.css";
import { Mutation } from "@apollo/client/react/components";
import { GET_CHILDREN, THERAPIST_ADD_CONNECTION } from "../queries";
import { logEvent } from "../actions";
import Modal from "../components/Modal";
import { useActions } from "tiny-atom/react/hooks";
import FormErrorMessage from "../components/FormErrorMessage";
import { graphQLError } from "../utils/errorUtils";
import { AppContext } from "../context/AppContext";
import { View } from "../components/react-native";
import { MANATEE_CARE_PROVIDER } from "../utils/constants";
import useCareTeam from "../hooks/useCareTeam";

export default function AddConnection() {
  const { setCurrentScreen, displayToast } = useActions();
  const [connectUserEmail, setConnectUserEmail] = useState("");
  const [connectToCareMemberUserId, setConnectToCareMemberUserId] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState(undefined);
  const { data: appContextData } = useContext(AppContext);
  const isManateeClinicalTherapist = appContextData?.me?.providerName === MANATEE_CARE_PROVIDER;
  const { error: errorCareTeam, members } = useCareTeam();
  if (errorCareTeam) {
    graphQLError();
  }

  const careTeam = members.map(ctm => {
    return {
      label: `${ctm.firstName} ${ctm.lastName}`,
      value: ctm.id
    };
  });

  setCurrentScreen("AddConnectionScreen");

  function onSuccess() {
    logEvent("addConnectionSuccess");
    setConnectUserEmail("");
    setConnectToCareMemberUserId(undefined);
    setLoading(false);
    displayToast({ text: "Done!" });
  }

  function onError(error) {
    setModalMessage(error?.message || "Something went wrong. Please try again later.");
    logEvent("addConnectionFailed");
  }

  function handleCareTeamSelectChange(userId) {
    setConnectToCareMemberUserId(parseInt(userId, 10));
  }

  return (
    <div>
      <div>
        <Formik
          initialValues={{ connectUserEmail: "", connectToCareMemberUserId: "" }}
          validate={() => {
            let errors = {};
            if (!connectUserEmail) {
              errors.connectUserEmail = "Required";
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(connectUserEmail)) {
              errors.connectUserEmail = "Invalid email address";
            }
            if (!connectToCareMemberUserId) {
              errors.connectToCareMemberUserId = "Required";
            }
            return errors;
          }}
          onSubmit={() => {
            setTimeout(() => {}, 400);
          }}
        >
          {({ handleSubmit, validateForm }) => (
            <div className="Connection">
              <form className="Connection-form" onSubmit={handleSubmit}>
                <div className="Connection-header">Connect Family</div>
                <div className="Connection-subtitle">
                  Connect family of an invited or registered user with an additional therapist, coach or care
                  coordinator
                </div>
                <BlockStack gap={"200"}>
                  <a href="/connection"></a>
                  <div className="connection-parent-email Connection-label ">Parent&apos;s email</div>
                  <TextField
                    placeholder="person@mail.net"
                    onChange={email => {
                      setConnectUserEmail(email);
                    }}
                    type="email"
                    name="connectUserEmail"
                    value={connectUserEmail}
                  />
                  <FormErrorMessage name="connectUserEmail" component="div" />
                  {isManateeClinicalTherapist && (
                    <View>
                      <div className="Connection-label"></div>
                      <Select
                        options={careTeam}
                        placeholder="choose care team member"
                        onChange={handleCareTeamSelectChange}
                        value={connectToCareMemberUserId}
                        labelInline={true}
                      />
                      <FormErrorMessage name="connectToCareMemberUserId" component="div" />
                    </View>
                  )}
                  <Mutation
                    mutation={THERAPIST_ADD_CONNECTION}
                    onCompleted={data => {
                      if (data?.addConnectionToCareTeamMember?.message) {
                        setModalMessage(data.addConnectionToCareTeamMember.message);
                      }
                      onSuccess();
                    }}
                    onError={error => onError(error)}
                    refetchQueries={[{ query: GET_CHILDREN }]}
                  >
                    {mutation => (
                      <Button
                        id="Connection-submit"
                        fullWidth
                        variant="primary"
                        submit
                        disabled={loading}
                        onClick={async () => {
                          const validationErrors = await validateForm();
                          if (!validationErrors.connectUserEmail && !validationErrors.connectToCareMemberUserId) {
                            mutation({
                              variables: {
                                connectUserEmail: connectUserEmail,
                                connectToCareMemberUserId: connectToCareMemberUserId
                              }
                            });
                          } else {
                            handleSubmit();
                          }
                        }}
                      >
                        Connect
                      </Button>
                    )}
                  </Mutation>
                </BlockStack>
              </form>
              <Modal
                title={modalMessage}
                hidden={!modalMessage}
                onButton1Press={() => {
                  setModalMessage(undefined);
                }}
                fullscreen={true}
              />
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
}
