export enum AccountType {
  PARENT = "PARENT",
  THERAPIST = "THERAPIST",
  PARENT_COACH = "PARENT_COACH",
  CARE_COORDINATOR = "CARE_COORDINATOR",
  CHILD = "CHILD",
  STANDALONE_CLIENT = "STANDALONE_CLIENT"
}

export const CARE_PROVIDER_ACCOUNT_TYPES: Set<string> = new Set([AccountType.THERAPIST, AccountType.PARENT_COACH]);
export const CARE_TEAM_ACCOUNT_TYPES: Set<string> = new Set([
  ...CARE_PROVIDER_ACCOUNT_TYPES,
  AccountType.CARE_COORDINATOR
]);

export const CARE_TEAM_MEMBER_ACCOUNT_TYPES_ARRAY: AccountType[] = [
  AccountType.THERAPIST,
  AccountType.PARENT_COACH,
  AccountType.CARE_COORDINATOR
];

export const ADULT_ACCOUNT_TYPES = new Set<string>([AccountType.PARENT, ...CARE_TEAM_ACCOUNT_TYPES]);
export const CLIENT_ACCOUNT_TYPES: Set<string> = new Set([AccountType.CHILD, AccountType.STANDALONE_CLIENT]);

export namespace AccountType {
  export function isClient(accountType: AccountType | string): boolean {
    return CLIENT_ACCOUNT_TYPES.has(accountType);
  }
  export function isAdult(accountType: AccountType | string): boolean {
    return ADULT_ACCOUNT_TYPES.has(accountType);
  }
  export function isCareTeamMember(accountType: AccountType | string): boolean {
    return CARE_TEAM_ACCOUNT_TYPES.has(accountType);
  }
  export function isCareProvider(accountType: AccountType | string): boolean {
    return CARE_PROVIDER_ACCOUNT_TYPES.has(accountType);
  }
}

export enum Theme {
  CHILD = "CHILD",
  TEEN = "TEEN"
}

export type User = {
  accountType: AccountType;
  id: number;
  familyId: number;
  firstName: string;
  lastName: string;
};

export type UserConversation = {
  familyId?: number;
  id: number;
  messages: Message[];
  pendingActions: { id: number; reason: string }[];
  permissions: string[];
  read: boolean;
  recipientReadAt: string;
  type: string;
  users: User[];
  hasUnread: boolean;
  lastDate: number;
  lastMessage: string;
  linkedClient: boolean;
  mentioned: boolean;
  name: string;
  showDoneButtons: boolean;
};

type Message = {
  id: number;
  from: Pick<User, "id" | "firstName" | "lastName">;
  message: string;
  date: string;
  automated?: boolean;
  hasAttachments?: boolean;
};
