import React, { useState, useEffect, useCallback } from "react";
import "./GoalManagement.css";
import Button from "./Button";
import SegmentedInput from "./SegmentedInput";
import { TextField } from "@shopify/polaris";
import Label from "./Label";
import ButtonStack from "./ButtonStack";
import moment from "moment";
import { useActions } from "tiny-atom/react/hooks";
import Modal from "./Modal";
import { getLocalTime, getGMT } from "../utils/dateUtils";
import { deleteParentGoal, updateParentGoal, adjustTimezoneServerToClient } from "../utils/goalUtils";
import { useMemo } from "react";
import { isAllWhitespace } from "../utils/formUtils";
import { TouchableOpacity } from "./react-native";
import { Icon } from "@shopify/polaris";
import { DeleteIcon, EditIcon } from "@shopify/polaris-icons";
import _ from "lodash";
import useParentGoalActions from "../hooks/useParentGoalActions";

const Modes = {
  create: "create",
  view: "view",
  edit: "edit",
  assign: "assign",
  unassign: "unassign"
};

const reminderTimeOptions = [
  { label: "morning 7 am", value: "07:00 AM" },
  { label: "afternoon 3 pm", value: "03:00 PM" },
  { label: "evening 6 pm", value: "06:00 PM" },
  { label: "night 8 pm", value: "08:00 PM" }
];

export default function ParentGoal({ mode: inputMode = undefined, parent, goal: inputGoal = {}, onLeave }) {
  const [goal] = useState(inputGoal);
  const initMode = useCallback(() => {
    if (inputMode) {
      return inputMode;
    }
    if (goal == {}) {
      return Modes.create;
    }
    if (goal.assignedAt) {
      return Modes.unassign;
    }
    if (goal.matchesAssignedGoal) {
      return Modes.view;
    }
    return Modes.assign;
  }, [goal, inputMode]);

  const [mode, setMode] = useState(initMode);

  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [reminderTimeSelections, setReminderTimeSelections] = useState([]);
  const [confirmAction, setConfirmAction] = useState(false);
  const [processing, setProcessing] = useState(false);
  const parentTimeZone = parent?.timeZone;

  const goalLocalReminderTimes = useMemo(() => {
    const reminderTimes = [];
    if (goal.reminderTime1) {
      const adjustedTime = goal.assignedAt
        ? adjustTimezoneServerToClient(goal.reminderTime1, parentTimeZone)
        : getLocalTime(goal.reminderTime1);
      const reminderTime1 = moment(adjustedTime).format("hh:mm A");
      reminderTimes.push(reminderTime1);
    }
    if (goal.reminderTime2) {
      const adjustedTime = goal.assignedAt
        ? adjustTimezoneServerToClient(goal.reminderTime2, parentTimeZone)
        : getLocalTime(goal.reminderTime2);
      const reminderTime2 = moment(adjustedTime).format("hh:mm A");
      reminderTimes.push(reminderTime2);
    }
    return reminderTimes;
  }, [goal.assignedAt, goal.reminderTime1, goal.reminderTime2, parentTimeZone]);

  useEffect(() => {
    setName(goal.name);
    setDescription(goal.description);
    if (goal.reminderTime1) {
      setReminderTimeSelections([...goalLocalReminderTimes]);
    }
  }, [goalLocalReminderTimes, goal, mode]);

  const { parentActions } = useParentGoalActions();
  const { displayToast } = useActions();

  const getMutationPayload = useCallback(() => {
    const getReminderTimeinUtc = reminderTimeIdx => {
      const reminderTime = reminderTimeSelections[reminderTimeIdx];
      const reminderDate = new Date("2000/01/01 " + reminderTime);
      return getGMT(reminderDate);
    };
    const firstReminderUtc = getReminderTimeinUtc(0);
    const secondReminderUtc = reminderTimeSelections.length > 1 ? getReminderTimeinUtc(1) : undefined;

    return {
      name: name,
      description: description,
      dueAt: "59 59 23 * * *",
      expiresAt: moment().add(30, "day").format(),
      value: 1,
      imageId: 1,
      reminderTime1: firstReminderUtc,
      reminderTime2: secondReminderUtc,
      dailyFrequency: 1
    };
  }, [description, name, reminderTimeSelections]);

  const getUpdateMutationPayload = useCallback(
    () => ({
      ...getMutationPayload(),
      expiresAt: undefined
    }),
    [getMutationPayload]
  );

  const Actions = useMemo(
    () => ({
      create: {
        toast: "Goal assigned!",
        title: "Assign",
        perform: () => parentActions.createAndAssign(parent, getMutationPayload(goal))
      },
      assign: {
        toast: "Goal assigned!",
        title: "Assign",
        perform: () => parentActions.assign(parent, getMutationPayload())
      },
      unassign: {
        toast: "Goal unassigned!",
        title: "Unassign",
        perform: () => parentActions.delete(parent, goal)
      },
      edit: {
        toast: "Goal saved!",
        title: "Save",
        perform: () => {
          if (goal.assignedAt) {
            return parentActions.update(parent, goal, getUpdateMutationPayload());
          }
          return updateParentGoal({ ...getUpdateMutationPayload(), id: goal.id }, parentTimeZone, "unassigned");
        },
        showInActionBar: ![Modes.create, Modes.edit].includes(mode) && !goal.isDefault,
        onCancel: () => setMode(initMode)
      },
      delete: {
        toast: "Goal deleted!",
        perform: () => deleteParentGoal(goal.id),
        confirmTitle: "Are you sure you want to delete this goal?",
        showInActionBar: [Modes.view, Modes.assign].includes(mode) && !goal.isDefault
      }
    }),
    [parentActions, getMutationPayload, getUpdateMutationPayload, goal, initMode, mode, parentTimeZone, parent]
  );

  let cta = useMemo(() => {
    if (mode) {
      return Actions[mode];
    }
    return null;
  }, [Actions, mode]);

  const ctaEnabled = useMemo(() => {
    if (processing) {
      return false;
    }
    if ([Modes.create, Modes.edit].includes(mode)) {
      if (!name || isAllWhitespace(name)) {
        return false;
      }
    }
    if ([Modes.create, Modes.edit, Modes.assign].includes(mode)) {
      if (reminderTimeSelections.length < 1 || reminderTimeSelections.length > 2) {
        return false;
      }
    }

    if (Modes.edit === mode) {
      const hasChanges =
        goal.name !== name ||
        goal.description !== description ||
        !_.isEqual(goalLocalReminderTimes, reminderTimeSelections);
      return hasChanges;
    }

    return true;
  }, [
    description,
    goalLocalReminderTimes,
    goal.description,
    goal.name,
    mode,
    name,
    processing,
    reminderTimeSelections
  ]);

  const performAction = async action => {
    setProcessing(true);
    await action.perform();
    displayToast({
      text: action.toast,
      onComplete: () => {
        setProcessing(false);
        if (action.onComplete) {
          action.onComplete();
        } else {
          onLeave();
        }
      }
    });
  };

  const editable = [Modes.create, Modes.edit].includes(mode);
  const remindersEditable = [Modes.create, Modes.edit, Modes.assign].includes(mode);

  return (
    <div style={styles.goalManagement}>
      <div style={styles.goalFormContainer}>
        <div style={styles.actionBar}>
          {Actions.edit.showInActionBar && (
            <TouchableOpacity style={styles.editButton} onPress={() => setMode(Modes.edit)}>
              <Icon color="#2F7AC8" source={EditIcon} />
            </TouchableOpacity>
          )}
          {Actions.delete.showInActionBar && (
            <TouchableOpacity style={styles.deleteButton} onPress={() => setConfirmAction(Actions.delete)}>
              <Icon color="#FF2132" source={DeleteIcon} />
            </TouchableOpacity>
          )}
        </div>
        <Label first style={styles.label}>
          Name
        </Label>
        <TextField
          name="name"
          type="TextInput"
          placeholder="Goal Name"
          value={name}
          disabled={!editable}
          style={styles.textField}
          onChange={setName}
        />
        <Label>Why is this important?</Label>
        <TextField
          name="description"
          type="TextInput"
          placeholder="Description"
          value={description}
          multiline={3}
          disabled={!editable}
          style={styles.textField}
          onChange={setDescription}
        />
        <div style={styles.labelContainer}>
          <Label>Time(s) of reminder</Label>
          {remindersEditable && <div style={styles.subLabel}>(select 1 or 2)</div>}
        </div>
        {!remindersEditable && (
          <div>
            {goalLocalReminderTimes.map(reminderTime => {
              return <div key={reminderTime}>{reminderTime}</div>;
            })}
          </div>
        )}
        {remindersEditable && (
          <SegmentedInput
            options={reminderTimeOptions}
            defaultValue={reminderTimeSelections || []}
            type="SegmentedInput"
            multiselect="true"
            name="segInput"
            value={reminderTimeSelections}
            onChange={reminderTimes => {
              if (reminderTimes.length > 2) {
                const length = reminderTimes.length;
                setReminderTimeSelections([reminderTimes[length - 2], reminderTimes[length - 1]]);
              } else {
                setReminderTimeSelections([...reminderTimes]);
              }
            }}
            containerStyle={styles.segmentedInputContainer}
            optionStyle={styles.segmentedInputOption}
          />
        )}
      </div>
      {cta && (
        <ButtonStack horizontal fullWidth bottomSticky>
          {cta.onCancel && <Button title={"Cancel"} theme="gray" onPress={cta.onCancel} />}
          <Button disabled={!ctaEnabled} title={cta.title} theme="manaBlue" onPress={() => performAction(cta)} />
        </ButtonStack>
      )}
      <Modal
        title={confirmAction?.confirmTitle}
        hidden={!confirmAction}
        button1Label="Yes"
        onButton1Press={() => {
          setConfirmAction(undefined);
          performAction(confirmAction);
        }}
        button2Label="No"
        onButton2Press={() => {
          setConfirmAction(undefined);
        }}
      />
    </div>
  );
}

const styles = {
  goalManagement: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: "space-between"
  },
  goalFormContainer: {
    padding: "20px"
  },
  labelContainer: { display: "flex", alignItems: "baseline" },
  label: {
    fontSize: "30px",
    fontWeight: "bold",
    margin: "20px 0px 12px 0px"
  },
  nameTextInput: {
    height: "60px",
    backgroundColor: "rgb(244, 246, 248)",
    borderRadius: "20px",
    marginBottom: "40px",
    alignItems: "center",
    padding: "20px"
  },
  descriptionInput: {
    height: "120px",
    backgroundColor: "rgb(244, 246, 248)",
    borderRadius: "20px",
    marginBottom: "40px",
    alignItems: "center",
    padding: "20px"
  },
  subLabel: {
    marginLeft: "10px"
  },
  textField: {
    height: 40,
    paddingLeft: 15,
    paddingRight: 15,
    borderRadius: 12,
    fontSize: 16,
    backgroundColor: "rgb(244, 246, 248)"
  },
  segmentedInputContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    flex: "0 0 auto"
  },
  segmentedInputOption: {
    flex: "2 2 40%",
    marginRight: "20px",
    marginBottom: "20px"
  },
  editButton: {
    width: 35,
    height: 35,
    padding: 5,
    marginRight: 10,
    backgroundColor: "#BBD2FF",
    borderRadius: 5
  },
  deleteButton: {
    width: 35,
    height: 35,
    padding: 5,
    marginRight: 10,
    backgroundColor: "#FAC9CD",
    borderRadius: 5
  },
  actionBar: {
    height: 20,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end"
  }
};
