import React, { useState } from "react";
import { BlockStack, Button } from "@shopify/polaris";
import { TouchableOpacity } from "../components/react-native";
import { useActions } from "tiny-atom/react/hooks";
import "./Invite.css";
import familyWhiteImage from "../assets/familyWhite.png";
import familyGrayImage from "../assets/familyGray.png";
import standaloneWhiteImage from "../assets/standaloneWhite.png";
import standaloneGrayImage from "../assets/standaloneGray.png";

export default function Invite() {
  const { continueInvite, setCurrentScreen } = useActions();
  const [inviteAccountType, setSelection] = useState(0);
  if (!inviteAccountType) {
    setSelection("PARENT");
  }
  setCurrentScreen("inviteScreen");

  return (
    <div className="Invite">
      <form className="Invite-form">
        <BlockStack gap={"200"}>
          <div className="Invite-header">Invite</div>
          <div className="Invite-label">Choose account type</div>
          <TouchableOpacity
            onPress={() => {
              setSelection("PARENT");
            }}
          >
            <div
              className={
                inviteAccountType === "PARENT" ? "Account-type-label-selected" : "Account-type-label-unselected"
              }
            >
              <div className="Account-type-text">
                <img alt="parent" src={inviteAccountType === "PARENT" ? familyWhiteImage : familyGrayImage} />
                <div className="Account-text">
                  <p id="Weight-bold">Family Account</p>
                  <p id="Weight">
                    Client/patient and caregiver both will be registered. Caregiver can set and approve goals, provide
                    rewards and track progress.
                  </p>
                </div>
              </div>
            </div>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              setSelection("STANDALONE_CLIENT");
            }}
          >
            <div
              className={
                inviteAccountType === "STANDALONE_CLIENT"
                  ? "Account-type-label-selected"
                  : "Account-type-label-unselected"
              }
            >
              <div className="Account-type-text">
                <img
                  alt="standalone"
                  src={inviteAccountType === "STANDALONE_CLIENT" ? standaloneWhiteImage : standaloneGrayImage}
                />
                <div className="Account-text">
                  <p id="Weight-bold">Standalone Account</p>
                  <p id="Weight">Only client/patient is enrolled. Caregiver does not have a Manatee account.</p>
                </div>
              </div>
            </div>
          </TouchableOpacity>
          <Button
            id="Invite-submit"
            fullWidth
            variant="primary"
            submit
            onClick={() => {
              continueInvite({ pathname: "/inviteDetails", params: { inviteAccountType: inviteAccountType } });
            }}
          >
            Continue
          </Button>
        </BlockStack>
      </form>
    </div>
  );
}
