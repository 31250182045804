import { StyleSheet } from "aphrodite";

export const screenerStyles = StyleSheet.create({
  container: {
    minWidth: "60vw",
    minHeight: "60vh",
    maxWidth: 900
  },
  whiteContainerRoundedEdges: {
    backgroundColor: "#FFFFFF",
    borderRadius: 25
  },
  columnCentered: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  fullHeight: {
    height: "100%"
  },
  padding: {
    padding: 30
  },
  flex: {
    flex: 1
  },
  link: {
    color: "#2562D8",
    fontSize: 18,
    marginTop: 10,
    marginBottom: 10
  }
});
