import React from "react";
import { wrapAsImageLoader } from "../ImageLoader";
import { GET_GOAL_ACHIEVEMENT_IMAGE } from "../../queries";
import ImageViewer from "../ImageViewer";

function findAchievementsWithImagesOrderByDateDesc(goal) {
  if (goal && goal.achievements) {
    return goal.achievements.filter(a => a.image).sort((a, b) => b.achievedAt.localeCompare(a.achievedAt));
  } else {
    return [];
  }
}

export default function GoalAchievementImageViewer({ isVisible, hide, userId, goal }) {
  const achievements = findAchievementsWithImagesOrderByDateDesc(goal);

  if (!achievements.length) {
    return <></>;
  }

  const imageLoaders = achievements.map(achievement => {
    return wrapAsImageLoader({
      query: GET_GOAL_ACHIEVEMENT_IMAGE,
      variables: {
        assigneeId: userId,
        goalId: goal.id,
        achievementId: achievement.id
      },
      imageDate: achievement.achievedAt,
      dataImageExtractor: data => data.achievementImage.image
    });
  });

  return <ImageViewer isVisible={isVisible} hide={hide} imageLoaders={imageLoaders} />;
}
