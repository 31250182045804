import { StyleSheet, css } from "aphrodite";

export const insuranceStyles = StyleSheet.create({
  blueText: {
    color: "#2562D8",
    fontSize: 32,
    lineHeight: 1.2,
    fontWeight: 400,
    padding: 20,
    paddingBottom: 30,
    textAlign: "center"
  },
  title: {
    color: "#1A2579",
    fontSize: 36,
    lineHeight: 1.1,
    fontWeight: 700,
    textAlign: "center"
  },
  bodyText: {
    color: "#636C8C",
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 29 / 24,
    padding: 20,
    textAlign: "center"
  },
  smallText: {
    fontSize: 16,
    lineHeight: 1.3
  },
  grayRoundedCorners: {
    backgroundColor: "#F2F2F2",
    borderRadius: 20,
    padding: 30,
    paddingBottom: 20,
    margin: 20,
    marginTop: 5
  },
  sessionCostText: {
    color: "#1A2579",
    fontSize: 24,
    fontWeight: 700,
    lineHeight: 29 / 24,
    textAlign: "center",
    paddingBottom: 15
  },
  sessionPrice: {
    color: "#1A2579",
    fontSize: 40,
    fontWeight: 700,
    lineHeight: 48 / 40,
    textAlign: "center"
  },
  verticalSpacer: {
    height: 20
  },
  bold: {
    fontWeight: 700
  },
  darkBlue: {
    color: "#1A2579"
  }
});
