import React, { useEffect, useMemo } from "react";
import { ChoiceList } from "@shopify/polaris";

export default function useUsersFilter({ users, selectable, selected, setSelected }) {
  const choices = useMemo(
    () => users.map(u => ({ label: u.firstName, value: u, key: u.id, disabled: !selectable.some(s => s.id === u.id) })),
    [selectable, users]
  );

  useEffect(() => {
    if (selected.some(s => !selectable.some(u => u.id === s.id))) {
      setSelected(selected => selected.filter(s => selectable.some(u => u.id === s.id)));
    }
  }, [selected, setSelected, selectable]);

  const filter = {
    key: "users",
    label: "Reported By",
    filter: (
      <ChoiceList
        title="Reported By"
        key="users"
        choices={choices}
        selected={selected || []}
        onChange={values => {
          setSelected(values);
        }}
        allowMultiple
      />
    ),
    hideClearButton: false,
    shortcut: true,
    onRemove: () => {
      setSelected([]);
    }
  };

  return filter;
}
