import React, { useState } from "react";
import { BlockStack, TextField, Button } from "@shopify/polaris";
import { Formik } from "formik";
import AuthService from "../services/AuthService";
import { useAtom, useActions } from "tiny-atom/react/hooks";
import Modal from "../components/Modal";
import "./Register.css";
import FormErrorMessage from "../components/FormErrorMessage";
import { useBeforeunload } from "react-beforeunload";

export default function ForgotPassword() {
  const [email, setEmail] = useState();
  const { navigate, logEvent, setCurrentScreen, showModal, hideModal } = useActions();
  const error = useAtom(state => state.error);
  let loading = useAtom(state => state.loading);
  const isErrorModalVisible = useAtom(state => state.isErrorModalVisible);
  const consultCallback = useAtom(state => state.consultCallback);

  useBeforeunload(consultCallback ? event => event.preventDefault() : null);

  async function handleSubmit() {
    try {
      const result = await AuthService.forgotPassword({
        email: email
      });
      if (result && result.message) {
        showModal(result.message);
        logEvent("webForgotPasswordFailed");
      } else {
        showModal("Please check your email and enter the password reset code on this page.");
        logEvent("webForgotPasswordSucccess");
        navigate({ pathname: "/passwordReset" });
      }
    } catch (e) {
      logEvent("webForgotPasswordFailed");
    }
  }

  setCurrentScreen("WebForgotPasswordScreen");
  return (
    <div className="Register">
      <div
        style={{
          pointerEvents: isErrorModalVisible ? "none" : undefined
        }}
      >
        <Formik
          initialValues={{
            email: ""
          }}
          validate={() => {
            let errors = {};
            if (!email) {
              errors.email = "Required";
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
              errors.email = "Invalid email address";
            }
            return errors;
          }}
          onSubmit={email => {
            setTimeout(() => {
              handleSubmit(email);
            }, 400);
          }}
        >
          {({ handleSubmit }) => (
            <form className="Main-form" onSubmit={handleSubmit}>
              <div className="Manatee-logo" />
              <BlockStack gap={"300"}>
                <TextField placeholder="Email" onChange={setEmail} type="email" name="email" value={email} />
                <FormErrorMessage name="email" component="div" />
                <Button fullWidth variant="primary" submit disabled={loading} onClick={handleSubmit}>
                  Request Password Reset
                </Button>
                <Button
                  fullWidth
                  variant="primary"
                  submit
                  disabled={loading}
                  onClick={() => navigate({ pathname: "/passwordReset" })}
                >
                  Enter Password Reset Code
                </Button>
                <Button fullWidth onClick={() => navigate({ pathname: consultCallback ? "/consult" : "/login" })}>
                  Cancel
                </Button>
                <div className="Register-forgot">
                  Having trouble? Drop us a note at <a href="mailto:support@getmanatee.com">support@getmanatee.com</a>
                </div>
              </BlockStack>
            </form>
          )}
        </Formik>
      </div>
      <Modal
        title={error ? error.message : ""}
        hidden={!isErrorModalVisible}
        onButton1Press={hideModal}
        fullscreen={true}
      />
    </div>
  );
}
