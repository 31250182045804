import React, { Component } from "react";
import ButtonStack from "./ButtonStack";
import Button from "./Button";
import ExitButton from "./ExitButton";
import { sideBarWidthRef } from "../context/SideBarContext";

export default class ManateeModal extends Component {
  narrowScreen = this.props.narrowScreen;
  button2Label = this.props.button2Label;
  horizontalButtons = this.props.horizontalButtons === true;
  state = {
    visibleModalId: null,
    isVisible: false
  };

  handleOnScroll = event => {
    this.setState({
      scrollOffset: event.nativeEvent.contentOffset.y
    });
  };

  handleScrollTo = p => {
    if (this.scrollViewRef) {
      this.scrollViewRef.scrollTo(p);
    }
  };

  render() {
    let button1Label = this.props.button1Label;
    if (!button1Label) {
      button1Label = this.props.onButton1Press ? "OK" : undefined;
    }
    let divStyle = divStyles;
    if (this.props.centeredAbsolute) {
      divStyle = centeredAbsolute;
    } else if (this.props.centeredHorizontallyHigh) {
      divStyle = centeredHorizontallyHigh;
    } else if (this.props.centeredMainPanel) {
      divStyle = this.narrowScreen ? centeredMainPanelNarrow : centeredMainPanel;
    } else if (this.props.center) {
      divStyle = centered(sideBarWidthRef.current);
    } else if (this.props.button2Label) {
      divStyle = divStyles2Buttons;
    } else if (this.props.fullscreen) {
      divStyle = divStylesFullscreen;
    }
    return (
      <div
        className={this.props.className}
        animationtype="fade"
        transparent="true"
        hidden={this.props.hidden ? true : false}
        style={!this.props.className ? divStyle : undefined}
      >
        {this.props.onPressExit && <ExitButton onPress={this.props.onPressExit} />}
        <div style={claimConfirmationContainer}>
          <div>
            <p style={this.props.titleStyle ? this.props.titleStyle : claimConfirmationText}>{this.props.title}</p>
            {this.props.subtitle && <p style={this.props.subtitleStyle || {}}>{this.props.subtitle}</p>}
            {this.props.content}
            {this.props.children}

            <ButtonStack fullWidth horizontal={this.horizontalButtons}>
              {button1Label && (
                <div style={claimConfirmationButton}>
                  <Button
                    theme={this.props.button1Theme || "green"}
                    title={button1Label}
                    disabled={this.props.button1Disabled}
                    fullWidth
                    onPress={() => this.props.onButton1Press()}
                  />
                </div>
              )}
              {this.props.button2Label && (
                <Button
                  title={this.props.button2Label}
                  theme={this.props.button2Theme || "red"}
                  disabled={this.props.button2Disabled}
                  fullWidth
                  onPress={() => this.props.onButton2Press()}
                />
              )}
              {this.props.button3Label && (
                <Button
                  title={this.props.button3Label}
                  theme={this.props.button3Theme || "green"}
                  disabled={this.props.button3Disabled}
                  fullWidth
                  onPress={() => this.props.onButton3Press()}
                />
              )}
              {this.props.button4Label && (
                <Button
                  title={this.props.button4Label}
                  theme={this.props.button4Theme || "red"}
                  disabled={this.props.button4Disabled}
                  fullWidth
                  onPress={() => this.props.onButton4Press()}
                />
              )}
              {this.props.button5Label && (
                <Button
                  title={this.props.button5Label}
                  theme={this.props.button5Theme || "red"}
                  disabled={this.props.button5Disabled}
                  fullWidth
                  onPress={() => this.props.onButton5Press()}
                />
              )}
              {this.props.button6Label && (
                <Button
                  title={this.props.button6Label}
                  theme={this.props.button6Theme || "red"}
                  disabled={this.props.button6Disabled}
                  fullWidth
                  onPress={() => this.props.onButton6Press()}
                />
              )}
              {this.props.button7Label && (
                <Button
                  title={this.props.button7Label}
                  theme={this.props.button7Theme || "red"}
                  disabled={this.props.button7Disabled}
                  fullWidth
                  onPress={() => this.props.onButton7Press()}
                />
              )}
              {this.props.button8Label && (
                <Button
                  title={this.props.button8Label}
                  theme={this.props.button8Theme || "red"}
                  disabled={this.props.button8Disabled}
                  fullWidth
                  onPress={() => this.props.onButton8Press()}
                />
              )}
            </ButtonStack>
          </div>
        </div>
      </div>
    );
  }
}

const claimConfirmationContainer = {
  margin: 0,
  justifyContent: "flex-end",
  alignItems: "center",
  flex: 1
};
const claimConfirmationText = {
  fontSize: 21,
  whiteSpace: "pre-wrap"
};
const claimConfirmationButton = {
  marginTop: 20
};
const centered = sideBarWidth => ({
  position: "absolute",
  top: "25%",
  zIndex: 100,
  padding: "40px",
  background: "#FFFFFF",
  marginLeft: `calc(50vw - 213px - ${sideBarWidth > 1 ? sideBarWidth / 2 : 0}px)`,
  boxShadow: "4px 4px 4px 4px rgba(0, 0, 0, 0.2)",
  width: 426
});
const centeredAbsolute = {
  position: "fixed",
  left: "35%",
  right: "35%",
  top: "25%",
  zIndex: 100,
  padding: "40px",
  background: "white",
  boxShadow: "4px 4px 4px 4px rgba(0, 0, 0, 0.2)",
  width: 425
};
const centeredMainPanel = {
  position: "fixed",
  left: "35%",
  right: "35%",
  top: "25%",
  zIndex: 100,
  padding: "40px",
  background: "white",
  boxShadow: "4px 4px 4px 4px rgba(0, 0, 0, 0.2)",
  width: 425,
  marginLeft: "215px"
};
const centeredMainPanelNarrow = {
  position: "fixed",
  left: "10%",
  right: "35%",
  top: "25%",
  zIndex: 100,
  padding: "40px",
  background: "white",
  boxShadow: "4px 4px 4px 4px rgba(0, 0, 0, 0.2)",
  width: 300
};

const centeredHorizontallyHigh = {
  position: "fixed",
  left: "35%",
  right: "35%",
  top: "5%",
  zIndex: 100,
  padding: "40px",
  background: "white",
  boxShadow: "4px 4px 4px 4px rgba(0, 0, 0, 0.2)",
  width: 425
};

const divStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: 100,
  padding: "40px",
  background: "white",
  boxShadow: "4px 4px 4px 4px rgba(0, 0, 0, 0.2)"
};

const divStylesFullscreen = {
  position: "absolute",
  zIndex: 100,
  padding: "40px",
  background: "white",
  boxShadow: "4px 4px 4px 4px rgba(0, 0, 0, 0.2)"
};

const divStyles2Buttons = {
  position: "absolute",
  left: "25%",
  right: "25%",
  top: "40%",
  zIndex: 100,
  padding: "40px",
  background: "white",
  boxShadow: "4px 4px 4px 4px rgba(0, 0, 0, 0.2)"
};
