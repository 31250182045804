export async function toAttachment(file) {
  const dataUrl = await readAsBase64DataUrl(file);
  const base64 = dataUrl.split(",")[1];

  return {
    name: file.name,
    mimeType: file.type,
    base64
  };
}

export function toDataUrl(attachment) {
  return `data:${attachment.mimeType};base64,${attachment.base64}`;
}

function readAsBase64DataUrl(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
