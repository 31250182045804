const levelUpYourSocialSkills = {
  title: "Level up your social skills and build your friendships",
  goals: [
    {
      id: 1,
      imageId: 101,
      name: "Start a conversation with someone - anyone!",
      description: `💪️  Why is this important?
Building your confidence, so you can talk to people more comfortably!
    
📋️ Step by step
• Start by practicing in the mirror. Think of a time when you really wanted to talk to someone...What did you would want to say?
• Once you're comfortable, practice with a family member or friend! Try this about 3 times.
• It may not be easy, but once you've practiced, you're prepared to start a conversation!
• Maybe go back to the person you wanted to talk to in step 1, and give it a shot again!
    
💡️ Tip
Try to look for things you have in common. It's always easier to talk to someone we can relate to :)`,
      value: 2
    },
    {
      id: 2,
      imageId: 102,
      name: "Take turns sharing something about your day with a friend",
      description: `💪️  Why is this important?
Strengthening your friendship and connection with your friend
    
📋️ Step by step
• Think of something interesting that happened in your day. It can be anything! 
• Share this with your friend.
• Try to get them to share something too, and keep the conversation going! Try asking, "What about you? How was your day?"
    
💡️ Tip
Feel free to share anything about your day. Did something funny happen in class? Or is there something you're looking forward to that day?`,
      value: 2
    },
    {
      id: 3,
      imageId: 103,
      name: "Take the lead to make plans with a friend",
      description: `💪️  Why is this important?
Having something to look forward to with your friend

📋️ Step by step
• Try to think of a fun activity or place you want to go to with your friend. Maybe ask them if they have any ideas!
• Find a time that would work best for you and your friend. Don't forget to let your parents know, too.
• Get excited and think about what you're going to wear when you hang out!

💡️ Tip
Taking the lead on planning also lets your friend know that you value their time together! Think about how nice it feels when they ask if you want to hang out, too!`,
      value: 3
    },
    {
      id: 4,
      imageId: 104,
      name: "Introduce yourself to someone new at school",
      description: `💪️  Why is this important?
Practicing your social skills and maybe making a new friend!

📋️ Step by step
• Start by practicing in the mirror. Think about someone at school that you'd like to meet. Practice saying things like, "Hey, my name is [your name]! What's yours?" or think about something you've noticed about them that you can start a conversation about, like "Hey, I like the keychains on your backpack."
• Practice this 3 times. Now you're prepared to introduce yourself.
• Next time you see this person at school, let's say what we've practiced in the mirror!

💡️ Tip
Try to look for things you have in common. It's always easier to talk to someone we can relate to :)`,
      value: 3
    },
    {
      id: 5,
      imageId: 105,
      name: "Practice telling a friend they've upset you",
      description: `💪️  Why is this important?
Becoming more comfortable with expressing negative emotions to friends that you trust

📋️ Step by step
•  Think about what your friend did or said that upset you. What about it was upsetting? How did it make you feel? 
•  Share this with your friend, directly but kindly.
•  Try saying something like, "What you [said/did], that upset me and made me feel like [how you felt]." Don't be afraid to ask them to be more mindful of what they say, moving forward!

💡️ Tip
Try hugging it out afterwards!`,
      value: 2
    },
    {
      id: 6,
      imageId: 106,
      name: "Connect with loved ones through a video or phone call",
      description: `💪️  Why is this important?
Feel close and connected to your friends

📋️ Step by step
•  Think about who you want to speak to. Maybe you can even do a group video call and get multiple people involved!
•  Ask if they'd like to hop onto a call, and go for it!
•  You can also text, but seeing someone's face or hearing someone's voice will often make you feel more connected

💡️ Tip
Sometimes it's hard to get time together. In this case it might be helpful to schedule a set time. For example, you will call each other every morning after breakfast.`,
      value: 2
    }
  ]
};

const conquerYourBigFeelings = {
  title: "Conquer your big feelings",
  goals: [
    {
      id: 7,
      imageId: 107,
      name: "Meet with a therapist or counselor",
      description: `💪️  Why is this important?
Checking in on your mental health

📋️ Step by step
• Schedule a session with your therapist or counselor
• Before you meet with them, think about what you'd like to talk to them about

💡️ Tip
You could also think about what you talked about in your last session and how things have been since then`,
      value: 3
    },
    {
      id: 8,
      imageId: 108,
      name: "Write in your journal",
      description: `💪️  Why is this important?
Noticing your feelings

📋️ Step by step
• Find a quiet spot to sit down and think about how you're feeling right now. Start with that!
• What else would you like to write about? How did your day go - what went well, what didn't? How did it make you feel?

💡️ Tip
If you find it hard to put something in words, feel free to draw a picture!`,
      value: 3
    },
    {
      id: 9,
      imageId: 109,
      name: "Take 10-15min of “self-care” time as a break in the day",
      description: `💪️  Why is this important?
Staying in charge of your self

📋️ Step by step
• Put a label to how you're feeling right now. Do you feel frustrated, tired, or stressed? 
• Move to a quiet place or a place that you find calming 
• Choose a 10-15 minute activity that will help you take care of yourself. Try watching a funny video, having a snack, or taking a relaxing shower

💡️ Tip
Try checking out our self-care section in the app if you need ideas on what to do!`,
      value: 2
    },
    {
      id: 10,
      imageId: 110,
      name: "Practice your breathing exercises",
      description: `💪️  Why is this important?
Helping you relax and feel good

📋️ Step by step
• Begin by sitting up tall and placing your hands on your lap
• Start breathing in and out
• Next, start counting your breath silently in your mind.  
• Start counting to 2 as you breath in, and then count to 4 when you breath out  
• So breathe in1, 2 and breathe out 3, 4 and again, breathe in 1, 2 and breathe out 3, 4. 
• Keep breathing this way for a few breaths

💡️ Tip
Try checking out our self-care section in the app if you need ideas on what to do!`,
      value: 2
    },
    {
      id: 11,
      imageId: 111,
      name: "Listen to music",
      description: `💪️  Why is this important?
Helping you take a break

📋️ Step by step
• Pull up your favorite music. Especially the kind that helps you feel calm!
• You can also try finding a song that describes how you're feeling. Bonus points if you share it with someone!

💡️ Tip
Make a playlist ahead of time that is ready to go if you are feeling angry or frustrated.`,
      value: 1
    },
    {
      id: 12,
      imageId: 112,
      name: "Listen to or watch a meditation video",
      description: `💪️  Why is this important?
Helping you be mindful of your thoughts and feelings

📋️ Step by step
• Find a meditation that you can listen to or watch.
• Find a quiet space where you can sit by yourself
• Hit play and go through your meditation exercise. Take note of how you feel as you go through it.

💡️ Tip
Meditation can help you become more aware and accepting of how you're feeling.`,
      value: 2
    },
    {
      id: 13,
      imageId: 113,
      name: "Exercise or move your body",
      description: `💪️  Why is this important?
Feeling good from being active

📋️ Step by step
• Think of something you like to do that is either active or outside. For example; biking, planting seeds, playing soccer or watching birds. 
• Can you do it right now? If not, pick out a good time to do it - maybe after school? 
• Try to spend at least 30 minutes on this activity 
• Once you're done, take a minute to notice how you feel. Do you feel happy? Energized? Proud?

💡️ Tip
Sometimes the hardest part is to get going, but once you start you can't stop! It's the same thing with being active. Even if sometimes you don't feel like it, do it anyway! I promise you'll feel great afterwards!`,
      value: 2
    },
    {
      id: 14,
      imageId: 114,
      name: "Blast some music and dance",
      description: `💪️  Why is this important?
Letting loose and vibing with yourself

📋️ Step by step
• Pick out a playlist or album that makes you feel like dancing
• Turn up the volume and shake everything out with a dance!

💡️ Tip
Try doing a silly or quirky dance in front of the mirror. Let loose!`,
      value: 1
    },
    {
      id: 15,
      imageId: 115,
      name: "Spend 20 min doing something that makes you smile",
      description: `💪️  Why is this important?
Experiencing joy in something that you love doing

📋️ Step by step
• Choose an activity that you enjoy or makes you smile
• Set a timer and spend 20 minutes on this activity
• Take note of what you feel and think when you're doing this activity

💡️ Tip
The activity can be anything! Running, drawing, cooking, laying down. It just has to be something you like doing!`,
      value: 2
    },
    {
      id: 16,
      imageId: 116,
      name: "Squeeze your fists then relax them 3 times",
      description: `💪️  Why is this important?
Learning to notice tension and relaxing your body

📋️ Step by step
• Fold each hand into a tight fist for 5 seconds
• Then, relax and open your hands flat and hold for another 5 seconds
• Do this at least 3x in a row (or more!) to help you relax

💡️ Tip
This is a great exercise to remember for when you're feeling angry!`,
      value: 1
    },
    {
      id: 17,
      imageId: 117,
      name: "Build or create something to help you relax",
      description: `💪️  Why is this important?
Learning to express your emotions in different ways

📋️ Step by step
• What are your creative outlets? Do you like to build legos, work on puzzles, or create art?
• Go to a space where you can feel relaxed and let your creativity flow!
• Try building or making something that expresses how you're feeling in the moment.

💡️ Tip
You don't have to be artistic to be creative! This is all about how you're feeling.`,
      value: 2
    }
  ]
};

const connectWithFamilyAndFriends = {
  title: "Connect with family & friends",
  goals: [
    {
      id: 18,
      imageId: 118,
      name: "Spend at least 20 minutes hanging out with your family",
      description: `💪️  Why is this important?
Spending quality time together and connecting with your family

📋️ Step by step
• Decide what you want to do together. It can be anything that is fun for you! 
• Pick out a good time to do this; maybe before dinner? Or on the weekend?
• Pick out a spot; where would be a good place to do it? 

💡️ Tip
Focus on how it feels like to be together with the people you like.`,
      value: 3
    },
    {
      id: 19,
      imageId: 119,
      name: "Share with your parent(s) 1 thing you learned in therapy",
      description: `💪️  Why is this important?
Strengthen your connection with your family through therapy

📋️ Step by step
• Take a moment to reflect on your last session. What's something that you learned about yourself or your family?
• Share this with your parent(s)

💡️ Tip
Remember that you and your parent(s) are working together to strengthen your family.`,
      value: 2
    },
    {
      id: 20,
      imageId: 120,
      name: "Ask 1 question to someone in your family",
      description: `💪️  Why is this important?
Connecting with your family by learning something new about them!

📋️ Step by step
• Think about something you want to learn about someone in your family. Have you ever wondered what your parent was like at your age?
• Find a good moment to ask this question, like during dinner or before bedtime.
• Take note of how it feels to learn something about your family!

💡️ Tip
Use the Family Question feature on Manatee for ideas!`,
      value: 2
    },
    {
      id: 21,
      imageId: 121,
      name: "Take turns sharing something about your day with family",
      description: `💪️  Why is this important?
Strengthening your connection with your family

📋️ Step by step
• Think of something interesting that happened in your day. It can be anything! 
• Share this with your family. Dinner would be a great time to take turns sharing
• Make sure they share something in return!

💡️ Tip
Feel free to share anything about your day. Did you wake up on time? Did something funny happen in class? Or how did you feel about something that somebody said?`,
      value: 3
    },
    {
      id: 22,
      imageId: 122,
      name: "Write a letter or a postcard to someone you care about",
      description: `💪️  Why is this important?
Strengthening your connection with your family

📋️ Step by step
• Think of someone that you care about, maybe somebody that you haven't talked to in a while
• Pick out a postcard or get some nice paper so you can write them a note or a letter

💡️ Tip
You don't actually have to send the letter! It might be nice, but sometimes it's also nice to just get things out on paper!`,
      value: 2
    },
    {
      id: 23,
      imageId: 123,
      name: "Do a fun activity with your sibling(s)",
      description: `💪️  Why is this important?
Feeling closer with your sibling(s)

📋️ Step by step
• Think of an activity that you and your sibling(s) can do together and enjoy. Try asking another family member if you need ideas!
• Ask your sibling nicely if they woud like to do this activity with you

💡️ Tip
Spending time with your sibling(s) one-on-one can be fun and is just as important as spending time as a whole family! Try to remember what they do and what kinds of things they like.`,
      value: 3
    },
    {
      id: 24,
      imageId: 124,
      name: "Talk to someone about what worries you",
      description: `💪️  Why is this important?
Learning how to share feelings and ask for help 

📋️ Step by step
• Think about what is worrying you or what makes you feel uncomfortable, it's okay when it's hard to put into words 
• Choose a person you feel safe with, and tell them: "I'd like to talk because I am upset about something."
• Try to explain how you feel and what makes you feel this way

💡️ Tip
Remember that everybody hurts sometimes. If it's hard to talk about what worries you, then you can explain that and say, "It's hard for me to explain how I feel." The other person will understand that for sure.`,
      value: 2
    },
    {
      id: 25,
      imageId: 125,
      name: "Connect with loved ones through a video or phone call",
      description: `💪️  Why is this important?
Feel close and connected to your friends

📋️ Step by step
•  Think about who you want to speak to. Maybe you can even do a group video call and get multiple people involved!
•  Ask if they'd like to hop onto a call, and go for it!
•  You can also text, but seeing someone's face or hearing someone's voice will often make you feel more connected

💡️ Tip
Sometimes it's hard to get time together. In this case it might be helpful to schedule a set time. For example, you will call each other every morning after breakfast.`,
      value: 2
    },
    {
      id: 26,
      imageId: 126,
      name: "Go around the table and share what you’re grateful for",
      description: `💪️  Why is this important?
Remembering happy things with your family

📋️ Step by step
•  Before dinner time, think about something that you're thankful for. It can be something about yourself, or something that happened today, or even something about your family
•  At dinner time, start off by saying, "Today I'm grateful for..."
•  Make sure that everyone takes a turn to share!

💡️ Tip
Sometimes it's hard think of something, and that's okay! You can always be grateful that you're here with your family. We know we are!`,
      value: 3
    },
    {
      id: 27,
      imageId: 127,
      name: "Find something helpful to do around the house",
      description: `💪️  Why is this important?
Being a team player in your family by being helpful at home

📋️ Step by step
• Take a moment to look around your home and see if there's anything that needs to be picked up or cleaned
• Spend a few minutes taking care of this task!

💡️ Tip
Remember that everyone in the family has a role to play and things on their plate! Sometimes just taking care of something little means a world of difference.`,
      value: 2
    }
  ]
};

const buildSelfEsteemAndConfidence = {
  title: "Build self-esteem and confidence",
  goals: [
    {
      id: 28,
      imageId: 107,
      name: "Meet with a therapist or counselor",
      description: `💪️  Why is this important?
Checking in on your mental health

📋️ Step by step
• Schedule a session with your therapist or counselor
• Before you meet with them, think about what you'd like to talk to them about

💡️ Tip
You could also think about what you talked about in your last session and how things have been since then`,
      value: 3
    },

    {
      id: 29,
      imageId: 129,
      name: "Write down 1 thing you like about yourself",
      description: `💪️  Why is this important?
Feeling good about yourself, and less sad

📋️ Step by step
• Think about what you like about yourself. What are your favorite things about yourself?
• Try to write 3 more of these things!

💡️ Tip
If thinking about your positive qualities is hard, think about a time when someone said osmething nice about your or made you feel good about yourself!`,
      value: 2
    },
    {
      id: 30,
      imageId: 130,
      name: "Give yourself a shoutout",
      description: `💪️  Why is this important?
Learning to talk about yourself positively and appreciating yourself

📋️ Step by step
• Think about something you did well at lately, or something that you liked about yourself.
• Look at yourself in the mirror and give yourself a shoutout for it! Say, "Hey, good job answering questions in class today!" or whatever strikes you.

💡️ Tip
It's important to value yourself. If this feels difficult, start with small things and continue practicing. It gets easier!`,
      value: 2
    },
    {
      id: 31,
      imageId: 108,
      name: "Write in your journal",
      description: `💪️  Why is this important?
Noticing your feelings

📋️ Step by step
• Find a quiet spot to sit down and think about how you're feeling right now. Start with that!
• What else would you like to write about? How did your day go - what went well, what didn't? How did it make you feel?

💡️ Tip
If you find it hard to put something in words, feel free to draw a picture!`,
      value: 3
    },
    {
      id: 32,
      imageId: 115,
      name: "Spend 20 min doing something that makes you smile",
      description: `💪️  Why is this important?
Experiencing joy in something that you love doing

📋️ Step by step
• Choose an activity that you enjoy or makes you smile
• Set a timer and spend 20 minutes on this activity
• Take note of what you feel and think when you're doing this activity

💡️ Tip
The activity can be anything! Running, drawing, cooking, laying down. It just has to be something you like doing!`,
      value: 2
    },
    {
      id: 33,
      imageId: 133,
      name: "Write yourself a letter like you're your best friend",
      description: `💪️  Why is this important?
Learning how to be your own biggest fan

📋️ Step by step
• On a piece of paper, start writing a letter to yourself. If you were your own best friend, what would you say in this letter?
• Think about all the nice and supportive things that you would say to your best friend, or that your best friend would say to you.
• When you're finished writing, read the letter again!

💡️ Tip
You can do whatever you'd like with the letter afterwards. You can get rid of it, or you could try keeping it for you to read again whenever you're feeling down!`,
      value: 3
    },
    {
      id: 34,
      imageId: 134,
      name: "Do your 'Power Pose' and hold it for 5 seconds!",
      description: `💪️  Why is this important?
Feeling powerful and brave and confident!

📋️ Step by step
• Close your eyes and imagine that you’re confident, kind, and kicking butt
• Stand up with your back straight, chin-up, chest up and out, shoulder back, and feet spread shoulder-width apart
• Try doing this in front of the mirror, and give yourself a shoutout while you're at it!

💡️ Tip
Try doing this whenever you're feeling overwhelmed. It might help you feel like you can take on the world!`,
      value: 1
    },
    {
      id: 35,
      imageId: 135,
      name: "Try something outside of your comfort zone",
      description: `💪️  Why is this important?
Experiencing that you can achieve what you want to 

📋️ Step by step
• Pick something that you would like to learn, haven't done before, or have always wanted to try but didn't!
• Write down 3 steps you need to accomplish this.
• Set a timer and spend 10 minutes doing this activity. Once you're done, take a moment to think about how it felt.

💡️ Tip
If you're having trouble thinking of something, try asking advice from a parent or friend! Also ask yourself, what do you like to do? If you like music, you can choose to learn a song on a instrument. If you like games, you can learn how to make a game yourself. By learning things we like we grow.`,
      value: 4
    }
  ]
};

const masterYourRoutine = {
  title: "Master your routine",
  goals: [
    {
      id: 36,
      imageId: 136,
      name: "Set time to plan for the week",
      description: `💪️  Why is this important?
Setting a structure to your week helps you stay on top of everything

📋️ Step by step
• Find a quiet spot and write down a schedule for each day of the week, starting from when you wake up to when you go to sleep
• Don't forget to take note of important days, like when you have projects or tests at school, or when you're seeing your friends
• Think of your schedule as big or small blocks of time for activities - don't forget to block off time to relax and recharge too!

💡️ Tip
Planning and knowing what is happening next and gives you time to prepare and reduces stress and negative feelings. It’s not always easy to do things that aren’t fun, but getting them done with the help of a schedule can make you feel great!`,
      value: 2
    },
    {
      id: 37,
      imageId: 137,
      name: "Go to bed early and get 8+ hours of sleep",
      description: `💪️  Why is this important?
Staying strong and healthy

📋️ Step by step
• Getting between 8-11 hours of sleep is very important to staying healthy!
• Make sure you're ready for what's coming tomorrow
• Follow your relaxing bedtime routine to wind down before going to bed

💡️ Tip
Try going to bed and waking up at the same time every day so your body gets used to your schedule. If you have trouble falling asleep after 30 minutes, try reading something, then try to sleep again after 10 minutes.`,
      value: 2
    },
    {
      id: 38,
      imageId: 138,
      name: "Follow a relaxing bedtime routine",
      description: `💪️  Why is this important?
Preparing to have a good night's sleep

📋️ Step by step
• Pick out some relaxing activities to do to wind down before bed
• This can include: not using your phone 2 hours before going to bed, taking a shower or bath, writing in your journal or reading a book.

💡️ Tip
Try to avoid using your phone while you're getting ready for bed or trying to sleep`,
      value: 2
    },
    {
      id: 39,
      imageId: 139,
      name: "Brush your teeth",
      description: `💪️  Why is this important?
Taking care of yourself

📋️ Step by step
• Pick out your favorite toothbrush. What color is it? What are the bristles like?
• Get your toothpaste. What flavor do you like the most? Minty? Fruity?
• Now you have everything set up. Make sure you brush the outside and inside of your teeth, and the top and bottom!

💡️ Tip
Set a timer for 2 minutes to brush your teeth. If that feels very long, start with 1 minute and try to extend the timer by 10 sec. every time you brush!`,
      value: 2
    },
    {
      id: 40,
      imageId: 140,
      name: "Wash your hands",
      description: `💪️  Why is this important?
Taking care of yourself

📋️ Step by step
• Wet your hands with clean, running water, put soap on your hands and rub them together to make foam
• Rub the backs of your hands, between your fingers, and under your nails for at least 20 seconds
• Rinse your hands well and dry them using a clean towel or air dry them

💡️ Tip
Sing the “Happy Birthday” song from beginning to end twice. Tired of this song? Take a new song everyday, or even make up your own song`,
      value: 2
    },
    {
      id: 41,
      imageId: 141,
      name: "Take a shower/bath",
      description: `💪️  Why is this important?
Taking care of yourself and practicing good hygiene

📋️ Step by step
• Would you like a bath or a shower? When do you like to take one - do you prefer getting clean in the evening or morning?
• Make sure you have everything you need for your bath or shower, especially your towel!
• When you're in there, make sure you wash and rinse everywhere

💡️ Tip
There are many ways to personalize bath or shower time and make it comfy, so play around with it!`,
      value: 2
    },
    {
      id: 42,
      imageId: 142,
      name: "Spend some time outside",
      description: `💪️  Why is this important?
Getting fresh air makes you feel good 

📋️ Step by step
• Think of something you like to do that is outside. Do you like watching birds, going on a bike ride, or taking a walk?
• Pick out a good time to do it - maybe after school? 
• Spend at least 10-20 minutes outside. How do you feel while you're outside?

💡️ Tip
If this feels like a lot, try asking somebody to go outside with you! Take a walk with them and take turns sharing something about your day.`,
      value: 2
    },
    {
      id: 43,
      imageId: 113,
      name: "Exercise or move your body",
      description: `💪️  Why is this important?
Feeling good from being active

📋️ Step by step
• Think of something you like to do that is either active or outside. For example; biking, planting seeds, playing soccer or watching birds. 
• Can you do it right now? If not, pick out a good time to do it - maybe after school? 
• Try to spend at least 30 minutes on this activity 
• Once you're done, take a minute to notice how you feel. Do you feel happy? Energized? Proud?

💡️ Tip
Sometimes the hardest part is to get going, but once you start you can't stop! It's the same thing with being active. Even if sometimes you don't feel like it, do it anyway! I promise you'll feel great afterwards!`,
      value: 2
    },
    {
      id: 44,
      imageId: 144,
      name: "Finish your homework",
      description: `💪️  Why is this important?
Staying on top of school work is important

📋️ Step by step
• Take a look at your weekly plan and assignments. Do you have any work that's due tomorrow or in the next few days?
• Sit down somewhere and make it a space where you can feel focused. Does putting music on help?
• Try breaking down your homework into smaller chunks and tasks so it feels less scary!

💡️ Tip
If you finish quickly, think about work that's due later in the week. Is there anything you can get a head start on?`,
      value: 2
    },
    {
      id: 45,
      imageId: 145,
      name: "Get ready for school tomorrow",
      description: `💪️  Why is this important?
Learning skills for being independent and feeling prepared for the next day

📋️ Step by step
•  Take a look at your weekly plan; is there anything happening tomorrow that you need to bring anything special for?
•  Check for tomorrow's weather and pick out an outfit in advance!
•  Make sure you have everything you might need for school in your backpack

💡️ Tip
Did you know preparation is usually the key to success? Usually before you go to bed is a good time to do this :)`,
      value: 2
    },
    {
      id: 46,
      imageId: 146,
      name: "Read for at least 20 minutes",
      description: `💪️  Why is this important?
Reading can be a great way to relax and exercise your imagination

📋️ Step by step
• Pick up a book that you're reading. It can be for fun, or for school. It could even be a magazine or something else!
• Settle in somewhere comfy and quiet. Make sure you have plenty of light for reading in!
• Set a timer and read for at least 20 minutes. You can read for even longer if you want!

💡️ Tip
What kinds of stories or books do you like to read? What about these do you like a lot? You can learn a lot about yourself by reading!`,
      value: 2
    },
    {
      id: 47,
      imageId: 147,
      name: "Clean up your bedroom",
      description: `💪️  Why is this important?
Being a team player in your family by being helpful at home

📋️ Step by step
• Take a moment to look around your bedroom. Is there anything that needs to be picked up, cleaned, or oragnized?
• Some things you could do are to put away your laundry, clean your desk, pick up toys or things up off the floor, and make your bed.
• Spend at least 10 minutes or more doing something to help clean up your bedroom.

💡️ Tip
Having a clean bedroom space can do wonders for how you're feeling!`,
      value: 2
    },
    {
      id: 48,
      imageId: 148,
      name: "Finish your meal",
      description: `💪️  Why is this important?
You need food to keep going through the day so you can be your best self!

📋️ Step by step
• Finish your meal taking one bite at a time, and make sure you chew before you swallow

💡️ Tip
Remember that even if things like vegetables aren't your favorite, that they're very good for you and will help you grow up strong and healthy!`,
      value: 2
    },
    {
      id: 49,
      imageId: 149,
      name: "Do your weekly chores",
      description: `💪️  Why is this important?
Being a team player in your family by being helpful at home

📋️ Step by step
• Make a list or reminder of the chores you have to do around the house for this week
• Plan some time to take care of these chores throughout the week
• Spend a few minutes taking care of these tasks

💡️ Tip
Remember that everyone in the family has a role to play and things on their plate! Sometimes just taking care of something little means a world of difference.`,
      value: 2
    },
    {
      id: 50,
      imageId: 150,
      name: "Drink water!",
      description: `💪️  Why is this important?
Taking care of yourself and staying hydrated

📋️ Step by step
• Grab your favorite glass or water bottle and fill it up!
• Try to have around 5-8 glasses of water a day.

💡️ Tip
If drinking water is "boring" for you, try something like sparkling water, or use a fun straw or glass!`,
      value: 2
    }
  ]
};

export default [
  levelUpYourSocialSkills,
  conquerYourBigFeelings,
  connectWithFamilyAndFriends,
  buildSelfEsteemAndConfidence,
  masterYourRoutine
];
