import React, { useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import ErrorBoundary from "./components/ErrorBoundary";
import { ApolloProvider } from "@apollo/client";
import { AppProvider } from "@shopify/polaris";
import client from "./apollo/client";
import createAtom from "tiny-atom";
import actions, { setNavigateRef } from "./actions";
import { GlobalComponentContextProvider } from "./context/GlobalComponentsContext";
import AppRouter from "./routing/AppRouter";
import { UserContextProvider } from "./context/UserContext";
import { RoutingContextProvider } from "./context/RoutingContext";
import { Provider as AtomProvider } from "tiny-atom/react";

const atom = createAtom({}, actions());

atom.dispatch("initialize");

export default function App() {
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (navigate) {
      setNavigateRef(navigate);
    }
  }, [navigate]);

  return (
    <ErrorBoundary>
      <ApolloProvider client={client}>
        <AtomProvider atom={atom}>
          <AppProvider i18n={{}}>
            <GlobalComponentContextProvider>
              <UserContextProvider>
                <RoutingContextProvider>
                  <AppRouter />
                </RoutingContextProvider>
              </UserContextProvider>
            </GlobalComponentContextProvider>
          </AppProvider>
        </AtomProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
}
