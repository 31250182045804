import React from "react";
import { Card, Scrollable } from "@shopify/polaris";
import GoalReflectionCard from "./GoalReflectionCard";
import GoalCalendarCard from "./GoalCalendarCard";
import "./GoalCard.css";

export default function ClientGoalCard({ patient, achievements }) {
  return (
    <div className="GoalCard-content">
      <div className="GoalCard-goalsCard">
        <GoalCalendarCard familyUser={patient} />
      </div>

      <div className="GoalCard-goalReflections">
        <Card>
          <p className="GoalCard-goalReflections-title">Goal reflections</p>
          <Scrollable shadow style={{ maxHeight: "800px", minHeight: "120px" }}>
            <GoalReflectionCard achievements={achievements} firstName={patient.firstName} />
          </Scrollable>
        </Card>
      </div>
    </div>
  );
}
