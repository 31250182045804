import React, { useMemo } from "react";
import { HOME, GET_CHILDREN } from "../queries";
import { useQuery } from "@apollo/client";
import { useActions } from "tiny-atom/react/hooks";

export const AppContext = React.createContext();

export function AppContextProvider({ children }) {
  const { loading, error, data } = useQuery(HOME);
  const { data: childrenQueryData, refetch: refetchChildren } = useQuery(GET_CHILDREN, {
    fetchPolicy: "cache-and-network"
  });
  const { setUserId, setUser, setProvider, setUserRole, setChildren, setAssessments } = useActions();

  const { canReceiveMessages } = useMemo(() => {
    if (data) {
      setUserId(data.me.id);
      setUser(data.me);
      setProvider(data.me.providerName);
      setUserRole("THERAPIST");
      setAssessments({ assessments: data.assessments });
      sessionStorage.setItem("user", data.me.firstName);
      sessionStorage.setItem("providerName", data.me.providerName);
      let twoWayMessagingEnabled =
        data.canReceiveMessages && data.canReceiveMessages.length && data.canReceiveMessages[0].canReceiveMessages;
      return { canReceiveMessages: twoWayMessagingEnabled };
    }
    return {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const { childData } = useMemo(() => {
    if (childrenQueryData) {
      const children = childrenQueryData.me.children || [];
      setChildren({ children });
      return { childData: children };
    }
    return { childData: [] };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childrenQueryData]);

  const messageTemplates = useMemo(() => data?.messageTemplates || [], [data]);

  return (
    <AppContext.Provider
      value={{
        loading,
        error,
        user: data?.me,
        children: childData,
        refetchChildren,
        canReceiveMessages,
        messageTemplates,
        data
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
