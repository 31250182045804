import { useLocation, useNavigate } from "react-router-dom";
import { RoutingContext } from "../context/RoutingContext";
import AuthRoutes from "./AuthRoutes";
import React, { useContext, useEffect, useRef } from "react";
import { UserContext } from "../context/UserContext";
import CaregiverRoutes from "./CaregiverApp";
import ScreenerRoutes from "./ScreenerRoutes";
import ProviderApp from "./ProviderApp";
import ChildRoutes from "./ChildRoutes";
import AuthService from "../services/AuthService";

export default function AppRouter() {
  const { authenticated, user, loading, loginInProgress } = useContext(UserContext) || {};
  const { getRedirect, setRedirect, routeRequiresAuth, routeSupportsAccountType } = useContext(RoutingContext);
  const navigate = useNavigate();
  const location = useLocation();
  const attemptedRedirectUrl = useRef<string>();

  useEffect(() => {
    if (!loading && !loginInProgress) {
      if (authenticated && user) {
        if (
          location.pathname !== "/" &&
          routeRequiresAuth(location.pathname) &&
          !routeSupportsAccountType(location.pathname, user.accountType)
        ) {
          if (location.pathname !== "/login") {
            const redirectUrl = `${location.pathname}${location.search}`;

            if (attemptedRedirectUrl.current === redirectUrl) {
              navigate("/");
              setRedirect(undefined);
              return;
            } else {
              attemptedRedirectUrl.current = redirectUrl;
              setRedirect(redirectUrl);
            }
          }
          AuthService.logout();
          navigate("/login");
          return;
        }

        const redirect = getRedirect();

        if (redirect) {
          setRedirect(undefined);
          navigate(redirect);
        } else if (location.pathname === "/" || location.pathname === "/login") {
          navigate("/");
        }
      } else if (!authenticated) {
        if (routeRequiresAuth(location.pathname) && location.pathname !== "/" && location.pathname !== "/login") {
          const redirectUrl = `${location.pathname}${location.search}`;
          attemptedRedirectUrl.current = redirectUrl;

          setRedirect(redirectUrl);
          navigate("/login");
        }
      }
    }
  }, [
    authenticated,
    getRedirect,
    loading,
    location.pathname,
    location.search,
    loginInProgress,
    navigate,
    routeRequiresAuth,
    routeSupportsAccountType,
    setRedirect,
    user
  ]);

  return (
    <>
      <CaregiverRoutes />
      <ChildRoutes />
      <ProviderApp />
      <ScreenerRoutes />
      <AuthRoutes />
    </>
  );
}
