import React, { useState } from "react";
import Button from "./Button";
import { Check, Plus } from "react-feather";
import Modal from "../components/Modal";
import { logEvent } from "../actions";
import useParentGoalActions from "../hooks/useParentGoalActions";

export default function ParentGoalItem({ parent, goal, showParentGoal }) {
  const [isUnassignModalVisible, setIsUnassignModalVisible] = useState(false);
  const { parentActions } = useParentGoalActions();

  return (
    <div key={goal.id} style={styles.goalCard}>
      <div
        onClick={() => {
          showParentGoal(goal);
        }}
        style={styles.goalCardContent}
      >
        {goal.name}
      </div>
      <div style={styles.goalCardContent}>
        <Button
          theme={goal.assignedAt ? "green" : "gray"}
          icon={goal.assignedAt ? Check : Plus}
          disabled={goal.matchesAssignedGoal}
          small
          feather
          onPress={() => {
            if (goal.assignedAt) {
              setIsUnassignModalVisible(true);
            } else {
              showParentGoal(goal);
            }
          }}
        />
        <Modal
          title={"Are you sure you want to unassign this goal?"}
          hidden={!isUnassignModalVisible}
          button1Label="Yes"
          onButton1Press={async () => {
            await parentActions.delete(parent, goal);
            setIsUnassignModalVisible(false);
            logEvent("therapistWebUnassignGoal");
          }}
          button2Label="No"
          onButton2Press={() => {
            setIsUnassignModalVisible(false);
          }}
        />
      </div>
    </div>
  );
}

const styles = {
  goalCard: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid rgba(145, 158, 171, 0.25)",
    borderRadius: "20px",
    marginBottom: "12px"
  },
  goalCardContent: {
    padding: "20px"
  }
};
