import React from "react";
import { View, Text, TouchableOpacity } from "./react-native";

const Button = ({ title, style, onPress }) => (
  <TouchableOpacity
    activeOpacity={0.4}
    style={[
      {
        justifyContent: "center",
        alignItems: "center",
        height: 40,
        width: 40,
        borderRadius: 12,
        backgroundColor: "rgba(145, 158, 171, 0.25)"
      },
      style
    ]}
    onPress={onPress}
  >
    <Text
      style={[
        {
          marginTop: -4,
          marginLeft: 2,
          fontSize: 30,
          color: "rgb(145, 158, 171)"
        }
      ]}
    >
      {title}
    </Text>
  </TouchableOpacity>
);

class RangeInput extends React.Component {
  render() {
    const value = this.props.value || this.props.defaultValue;
    return (
      <View style={{ flexDirection: "row" }}>
        {!this.props.disabled && <Button title="-" style={{ marginRight: 12 }} onPress={() => this.minus()} />}
        <View
          style={{
            width: 40,
            height: 40,
            borderRadius: 12,
            backgroundColor: "rgb(244, 246, 248)",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Text style={{ fontSize: 16, color: "rgb(69, 79, 91)" }}>{value}</Text>
        </View>
        {!this.props.disabled && <Button title="+" style={{ marginLeft: 12 }} onPress={() => this.plus()} />}
      </View>
    );
  }

  minus() {
    const { min, step } = this.props;
    const value = this.props.value || this.props.defaultValue;
    this.props.onChange && this.props.onChange(Math.max(min, value - step));
  }

  plus() {
    const { max, step } = this.props;
    const value = this.props.value || this.props.defaultValue;
    this.props.onChange && this.props.onChange(Math.min(max, value + step));
  }
}

RangeInput.defaultProps = {
  step: 1
};

export default RangeInput;
