import React, { useState } from "react";
import { Query, Mutation } from "@apollo/client/react/components";
import "./PendingInvites.css";
import "../components/DataTable.css";
import DataTable from "react-data-table-component";
import { THERAPIST_GET_INVITES, THERAPIST_RESEND_INVITE, THERAPIST_DELETE_INVITE } from "../queries";
import Spinner from "../components/Spinner";
import { useActions } from "tiny-atom/react/hooks";
import { Mail, Trash2 } from "react-feather";
import { graphQLError } from "../utils/errorUtils";
import Modal from "../components/Modal";
import { TextField } from "@shopify/polaris";
import standaloneGrayImage from "../assets/standaloneGray.png";
import familyGrayImage from "../assets/familyGray.png";
import { logEvent } from "../actions";

export default function PendingInvites() {
  const { navigate, setCurrentScreen } = useActions();
  setCurrentScreen("PendingInvites");

  const [modal, setModal] = useState(null);
  const [inviteNote, setInviteNote] = useState(null);
  const [selectedInviteId, setSelectedInviteId] = useState(null);

  const formatInviteData = data => {
    const formattedInvites = data.therapistInvites.map(therapistInvite => {
      let formattedInvite = { ...therapistInvite };
      formattedInvite.image = handleAccountTypeImage(formattedInvite.accountType);
      formattedInvite.accountType = handleAccountTypeStyle(formattedInvite.accountType);
      return formattedInvite;
    });
    return formattedInvites;
  };

  const ResendButton = ({ row }) => {
    return (
      <div
        className="invitesButton resendButton"
        onClick={() => {
          setSelectedInviteId(row.inviteId);
          setModal("resend-invite-modal");
        }}
      >
        resend invite
        <Mail size={16} />
      </div>
    );
  };

  const DeleteButton = ({ row }) => {
    return (
      <div
        className="invitesButton deleteButton"
        onClick={() => {
          setSelectedInviteId(row.inviteId);
          setModal("delete-invite-modal");
        }}
      >
        delete
        <Trash2 size={16} />
      </div>
    );
  };

  const EmailField = ({ row }) => {
    return (
      <>
        {row.email}
        {row.email && row.accountType === "Family" && <span className="accTypeLabel">&nbsp;(caregiver)</span>}
      </>
    );
  };

  const inviteNoteInput = () => {
    return (
      <TextField
        name="Add a note (optional)"
        type="TextInput"
        placeholder="Hey checkout Manatee!"
        value={inviteNote}
        onChange={setInviteNote}
        multiline={3}
        maxLength={800}
      />
    );
  };

  const AccountTypeImage = ({ row }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <img alt="account type" style={accountTypeStyle} src={row.image} />
      <div style={{ paddingLeft: "10px" }}>{row.accountType}</div>
    </div>
  );

  const handleAccountTypeStyle = accType => {
    if (accType === "STANDALONE_CLIENT") {
      return "Standalone Client";
    } else {
      return "Family";
    }
  };

  function handleAccountTypeImage(accountType) {
    if (accountType === "STANDALONE_CLIENT") {
      return standaloneGrayImage;
    } else {
      return familyGrayImage;
    }
  }

  const titleStyle = {
    color: "#000000",
    fontFamily: "Avenir Next",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "40px",
    lineHeight: "27px",
    textAlign: "center",
    marginBottom: "50px"
  };

  const subtitleStyle = {
    color: "#000000",
    fontFamily: "Avenir Next",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "27px",
    textAlign: "center",
    marginBottom: "15px"
  };

  const deleteTitleStyle = {
    color: "#000000",
    fontFamily: "Avenir Next",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "30px",
    lineHeight: "40px",
    textAlign: "center",
    marginBottom: "15px"
  };

  const columns = [
    {
      name: "",
      selector: row => row.spacer,
      width: "4%"
    },
    {
      name: "",
      selector: row => row.resend,
      maxWidth: "20%",
      // eslint-disable-next-line react/display-name
      cell: row => <ResendButton row={row} />
    },
    {
      name: "CLIENT'S NAME",
      selector: row => row.clientName,
      sortable: true
    },
    {
      name: "EMAIL",
      selector: row => row.email,
      sortable: true,
      minWidth: "30%",
      // eslint-disable-next-line react/display-name
      cell: row => <EmailField row={row} />
    },
    {
      name: "ACCOUNT TYPE",
      selector: row => row.accountType,
      sortable: true,
      maxWidth: "18%",
      // eslint-disable-next-line react/display-name
      cell: row => <AccountTypeImage row={row} />
    },
    {
      name: "",
      selector: row => row.delete,
      maxWidth: "14%",
      // eslint-disable-next-line react/display-name
      cell: row => <DeleteButton row={row} />
    }
  ];

  const accountTypeStyle = {
    height: "30px",
    width: "30px"
  };

  return (
    <>
      <div className="data-table-header">
        <div className="data-table-title">Pending Invites</div>
      </div>
      <div className="data-table-content">
        <Query query={THERAPIST_GET_INVITES}>
          {({ loading, error, data }) => {
            if (loading) return <Spinner></Spinner>;
            if (error) return <p></p>;
            if (data.therapistInvites.length === 0) {
              navigate({ pathname: "/" });
            }
            const pendingInviteData = formatInviteData(data);
            return <DataTable columns={columns} data={pendingInviteData} highlightOnHover noHeader />;
          }}
        </Query>
      </div>
      {modal && (
        <div>
          {modal === "resend-invite-modal" && (
            <Mutation mutation={THERAPIST_RESEND_INVITE} onError={error => graphQLError(error)}>
              {resendInvite => {
                return (
                  <>
                    <div className="Modal-overlay" />
                    <Modal
                      title="Resend Invite"
                      subtitle="Add a note (optional)"
                      centeredMainPanel
                      titleStyle={titleStyle}
                      subtitleStyle={subtitleStyle}
                      content={inviteNoteInput()}
                      button1Label="Send"
                      button1Theme="lightBlue"
                      onButton1Press={() => {
                        inviteNote
                          ? resendInvite({ variables: { inviteId: selectedInviteId, note: inviteNote } })
                          : resendInvite({ variables: { inviteId: selectedInviteId } });
                        setModal(null);
                        setInviteNote(null);
                        logEvent("therapistWebInviteResend");
                      }}
                      button2Label="Cancel"
                      button2Theme="gray"
                      onButton2Press={() => {
                        setModal(null);
                      }}
                    />
                  </>
                );
              }}
            </Mutation>
          )}

          {modal === "delete-invite-modal" && (
            <Mutation
              mutation={THERAPIST_DELETE_INVITE}
              onError={error => graphQLError(error)}
              refetchQueries={[{ query: THERAPIST_GET_INVITES }]}
            >
              {deleteInvite => {
                return (
                  <>
                    <div className="Modal-overlay" />
                    <Modal
                      title="Are you sure you want to delete this invite?"
                      titleStyle={deleteTitleStyle}
                      centeredMainPanel
                      button1Label="Delete"
                      button1Theme="lightBlue"
                      onButton1Press={() => {
                        deleteInvite({ variables: { inviteId: selectedInviteId } });
                        setModal(null);
                        logEvent("therapistWebInviteDelete");
                      }}
                      button2Label="Cancel"
                      button2Theme="gray"
                      onButton2Press={() => {
                        setModal(null);
                      }}
                    />
                  </>
                );
              }}
            </Mutation>
          )}
        </div>
      )}
    </>
  );
}
