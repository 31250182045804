import React from "react";
import { View, Text, TouchableOpacity } from "./react-native";

export default class SegmentedInput extends React.Component {
  render() {
    const { options } = this.props;
    const value = this.props.value || this.props.defaultValue;
    return (
      <View style={[{ flexDirection: "row" }, this.props.containerStyle]}>
        {options.map((o, i) => (
          <TouchableOpacity
            key={o.value}
            activeOpacity={0.4}
            style={[
              {
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                height: 40,
                borderRadius: 20,
                backgroundColor: "rgb(244, 246, 248)",
                marginRight: i !== options.length - 1 ? 10 : 0
              },
              this.props.optionStyle,
              this.props.multiselect
                ? value.includes(o.value) && {
                    backgroundColor: "rgb(145, 158, 171)"
                  }
                : value === o.value && { backgroundColor: "rgb(145, 158, 171)" }
            ]}
            onPress={() => this.onPress(o.value)}
          >
            <Text
              style={[
                { fontSize: 16, color: "rgb(69, 79, 91)" },
                value === o.value || value.includes(o.value) ? { color: "white" } : { color: "rgb(69, 79, 91)" }
              ]}
            >
              {o.label}
            </Text>
          </TouchableOpacity>
        ))}
      </View>
    );
  }

  onPress(newlySelected) {
    if (!this.props.disabled) {
      if (this.props.multiselect) {
        let endValues = this.props.value || this.props.defaultValue;
        endValues.includes(newlySelected)
          ? endValues.splice(endValues.indexOf(newlySelected), 1)
          : endValues.push(newlySelected);
        this.props.onChange && this.props.onChange(endValues);
      } else {
        this.props.onChange && this.props.onChange(newlySelected);
      }
    }
  }
}
