import React, { useMemo } from "react";
import { useMutation } from "@apollo/client";
import { LEAVE_CONVERSATION } from "../../../queries";
import { LogOut } from "react-feather";
import clientIcon from "../../../assets/client-icon-manablue.png";
import parentChildIcon from "../../../assets/parent-child-icon-manablue.png";
import "./ConversationHeader.css";
import { useActions } from "tiny-atom/react/hooks";
import { UserConversation } from "../../../model/userModels";

type Props = {
  conversation: UserConversation;
  label: string;
  typeLabel: string;
  clientsLabel: string;
  parentsLabel: string;
  onExit: () => void;
};

export default function ConversationHeader({
  conversation,
  label,
  typeLabel,
  clientsLabel,
  parentsLabel,
  onExit
}: Props) {
  const hasLeavePermission = conversation?.permissions?.includes("LEAVE");
  const { logEvent } = useActions();

  const [leaveConversation] = useMutation(LEAVE_CONVERSATION);

  const conversationPostfix = useMemo(
    () => (conversation?.type === "CARE_TEAM_INTERNAL" ? "-purple" : "-manablue"),
    [conversation]
  );

  if (!conversation) {
    return <></>;
  }

  const breakLabels = (clientsLabel?.length || 0) + (parentsLabel?.length || 0) > 60;

  return (
    <>
      <div className={`conversation-header conversation-header${conversationPostfix}`}>
        {hasLeavePermission && (
          <div
            className="conversation-header-leave-button"
            onClick={async () => {
              logEvent("leaveConversation");
              await leaveConversation({ variables: { conversationId: conversation.id } });
              onExit();
            }}
          >
            <LogOut size={24} color="#ffffff" />
          </div>
        )}
        <div className="conversation-header-text">
          <div className="conversation-title">{label}</div>
          <div className="conversation-subtitle">{typeLabel}</div>
        </div>
      </div>
      <div className="conversation-family-header" style={breakLabels ? styles.familyHeaderTall : styles.familyHeader}>
        <div className="family-text">
          {breakLabels
            ? VerticalFamilyLabels(parentsLabel, clientsLabel)
            : HorizontalFamilyLabels(parentsLabel, clientsLabel)}
        </div>
      </div>
    </>
  );

  function HorizontalFamilyLabels(parentsLabel: string, clientsLabel: string) {
    return (
      <div style={styles.familyNamesRow}>
        <div style={styles.familyLabel}>Family:</div>
        <div style={styles.familyNames}>
          <img alt="account type" height="15px" width="15px" src={clientIcon} style={styles.clientIcon} />
          {clientsLabel}
        </div>
        {parentsLabel && (
          <div style={styles.familyNames}>
            <img alt="account type" height="15px" width="20.7px" src={parentChildIcon} style={styles.parentChildIcon} />
            {parentsLabel}
          </div>
        )}
      </div>
    );
  }

  function VerticalFamilyLabels(parentsLabel: string, clientsLabel: string) {
    return (
      <div style={styles.familyNamesRow}>
        <div>
          <div style={styles.familyLabel}>Family:</div>
        </div>
        <div style={styles.familyNamesColumn}>
          <div style={styles.familyNames}>
            <img alt="account type" height="15px" width="15px" src={clientIcon} style={styles.clientIcon} />
            {clientsLabel}
          </div>
          {parentsLabel && (
            <div style={styles.familyNames}>
              <img
                alt="account type"
                height="15px"
                width="20.7px"
                src={parentChildIcon}
                style={styles.parentChildIcon}
              />
              {parentsLabel}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const styles = {
  familyNames: {
    display: "flex",
    padding: "2px 6px"
  },
  familyNamesColumn: {
    display: "flex",
    flexDirection: "column" as const, // bug in flexDirection css type declarations
    justifyItems: "left",
    padding: "2px 0"
  },
  familyNamesRow: {
    padding: "0 20px",
    display: "flex",
    flexDirection: "row" as const, // bug in flexDirection css type declarations
    alignItems: "center"
  },
  familyLabel: { marginRight: "12px", fontFamily: "Helvetica, sans-serif", fontWeight: 700 },
  clientIcon: {
    marginRight: 6
  },
  parentChildIcon: {
    marginRight: 6
  },
  familyHeader: { minHeight: "36px", flexShrink: 0 },
  familyHeaderTall: { minHeight: "7px", flexShrink: 0 }
};
