import { useEffect, useState } from "react";

export default function useIsComponentOnScreen(ref) {
  const [isComponentOnScreen, setIsComponentOnScreen] = useState(true);

  useEffect(() => {
    if (ref?.current) {
      const refCurrent = ref.current;
      const observer = new IntersectionObserver(
        entries => {
          if (entries.length) {
            const entry = entries[0];
            setIsComponentOnScreen(entry.isIntersecting);
          }
        },
        {
          threshold: 0
        }
      );

      observer.observe(refCurrent);
      return () => {
        observer.unobserve(refCurrent);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, ref?.current]);
  return isComponentOnScreen;
}
