import React, { useContext, useRef } from "react";
import moment from "moment";
import { StyleSheet, css } from "aphrodite";
import { CartesianGrid, XAxis, YAxis, ResponsiveContainer, ScatterChart, Scatter, LabelList } from "recharts";
import { Box } from "@shopify/polaris";
import { FamilyAssessmentContext } from "../../context/FamilyAssessmentContext";
import _ from "lodash";
import useFamilyMembers from "../../hooks/useFamilyMembers";

export function isChartableClinicalAssessment(assessment) {
  return assessment?.categoryCriteria?.length && (!assessment?.type || assessment?.type === "clinical");
}

export default function ClinicalAssessmentChart({
  assessment: clinicalAssessment,
  selectedUsers,
  onCompletionSelected
}) {
  const chartRef = useRef(null);

  const { familyMembers } = useFamilyMembers();
  const familyMembersById = {};
  const parentLabelPositionById = {};

  familyMembers.forEach(fm => (familyMembersById[fm.id] = fm));
  familyMembers
    .filter(fm => fm.accountType === "PARENT")
    .forEach(
      (parent, i) =>
        (parentLabelPositionById[parent.id] =
          i % 2 === 0 ? { dy: -10, position: "right" } : { dy: 10, position: "left" })
    );
  const { completions: allCompletions, colorByFmId } = useContext(FamilyAssessmentContext);

  let familyCompletions = allCompletions.filter(f => f.assessmentIdentifier === clinicalAssessment.identifier);
  if (selectedUsers?.length) {
    familyCompletions = familyCompletions.filter(c => selectedUsers.some(u => u.id === c.clientId));
  }

  let scorePossible = 0;
  let dataPoints = [];
  let hasCompletions = !!familyCompletions?.length;

  const getCategoryCriteriaForScore = score =>
    clinicalAssessment.categoryCriteria.find(c => score >= c.minScore && score <= c.maxScore);

  function CustomizedDot(props) {
    const { color, cx, cy, payload } = props;
    return (
      <svg height={chartRef.current.height} width={chartRef.current.width} cursor="pointer">
        <circle cx={cx} cy={cy} r={8} strokeWidth={1.5} fill={color || payload.color} />
      </svg>
    );
  }

  if (familyCompletions && familyCompletions.length > 0) {
    familyCompletions.forEach(a => {
      if (a.scorePossible > scorePossible) {
        scorePossible = a.scorePossible;
      }
    });

    dataPoints = familyCompletions.map(a => {
      const categoryCriteria = getCategoryCriteriaForScore(a.score);
      return {
        userId: a.clientId,
        category: categoryCriteria.clinicalCategory || categoryCriteria.category,
        color: categoryCriteria.color,
        score: a.score,
        completedAt: a.completedAt,
        timeMillis: moment(a.completedAt).hour(0).minute(0).second(0).millisecond(0).valueOf(),
        userName: familyMembersById[a.clientId]?.firstName,
        label: familyMembersById[a.clientId]?.firstName + ":\u00A0" + a.score,
        userColor: colorByFmId[a.clientId],
        completionId: a.id
      };
    });
  }
  const dataPointsByFmId = _.groupBy(dataPoints, "userId");

  const height = 310;
  const ticks = [0].concat(clinicalAssessment.categoryCriteria.map(c => c.maxScore));

  const fmIds = [...new Set(familyCompletions.map(c => c.clientId))];

  const uniqueDates = _.uniq(dataPoints.map(r => moment(r.completedAt).format("MM/DD/YY")));
  const uniqueDateMillis = uniqueDates.map(date =>
    moment(date, "MM/DD/YY").hour(0).minute(0).second(0).millisecond(0).valueOf()
  );

  if (!clinicalAssessment.subAssessments || !clinicalAssessment.subAssessments.length) {
    return (
      <Box>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className={css(styles.name)}>{clinicalAssessment.name}</div>
        </div>
        {clinicalAssessment.finePrint && (
          <div>
            <span className={css(styles.finePrint)}>{clinicalAssessment.finePrint}</span>
          </div>
        )}
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ flexDirection: "column", width: "100%" }}>
            <ResponsiveContainer width={"100%"} height={height} ref={chartRef}>
              <ScatterChart margin={{ top: 25, left: 20, bottom: 40 }}>
                <CartesianGrid strokeDasharray="5 1" horizontal={true} vertical={false} />
                <XAxis
                  padding={{ left: 70, right: 70 }}
                  interval={0}
                  type="number"
                  scale="time"
                  domain={["dataMin", "dataMax"]}
                  name="completion"
                  dataKey="timeMillis"
                  ticks={uniqueDateMillis}
                  tickFormatter={timeMillis => moment(timeMillis).format("MMM DD 'YY")}
                  tickSize={12}
                  angle={-45}
                  dx={-20}
                  dy={20}
                />
                <YAxis
                  name="score"
                  dataKey="score"
                  ticks={ticks}
                  interval={0}
                  domain={[0, scorePossible]}
                  dx={5}
                  orientation="right"
                />
                {fmIds.map(fmId => {
                  return (
                    <React.Fragment key={fmId}>
                      <Scatter
                        key={fmId}
                        type="linear"
                        name={familyMembersById[fmId].firstName}
                        data={dataPointsByFmId[fmId]}
                        dataKey={"score"}
                        fill={colorByFmId[fmId]}
                        line={{ strokeWidth: 1.5 }}
                        onClick={({ payload }) => {
                          onCompletionSelected({
                            completedAt: payload.completedAt,
                            userId: payload.userId,
                            assessmentIdentifier: clinicalAssessment.identifier
                          });
                        }}
                        shape={<CustomizedDot />}
                      >
                        <LabelList
                          style={{ fontWeight: 600 }}
                          dataKey="label"
                          dy={(fmIds?.length > 1 && parentLabelPositionById[fmId]?.dy) || 0}
                          position={(fmIds?.length > 1 && parentLabelPositionById[fmId]?.position) || "top"}
                        />
                      </Scatter>
                    </React.Fragment>
                  );
                })}
              </ScatterChart>
            </ResponsiveContainer>
            {!hasCompletions && <div className={css(styles.noAssessments)}>NO ASSESSMENTS COMPLETED!</div>}
          </div>
          <div style={{ position: "relative", left: -20 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: 110,
                height: height - 100,
                marginTop: 25,
                marginBottom: 100
              }}
            >
              {clinicalAssessment.categoryCriteria
                .map(a => a)
                .reverse()
                .map((criteria, i) => {
                  return (
                    <div
                      className={css([
                        styles.category,
                        StyleSheet.create({
                          style: {
                            flex:
                              criteria.minScore === 0
                                ? criteria.maxScore - criteria.minScore
                                : criteria.maxScore - criteria.minScore + 1,
                            color: criteria.color
                          }
                        }).style
                      ])}
                      key={i}
                    >
                      {criteria.category && criteria.clinicalCategory ? (
                        <>
                          <p className={css(styles.category)}>{criteria.clinicalCategory}</p>
                          <p className={css(styles.category)}>({criteria.category})</p>
                        </>
                      ) : (
                        <p className={css(styles.category)}>{criteria.category}</p>
                      )}
                      <div className={css(styles.category)}>{`(${criteria.minScore} - ${criteria.maxScore})`}</div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Box>
    );
  }
}

const styles = StyleSheet.create({
  finePrint: {
    fontFamily: "Helvetica",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "15px",
    color: "#919191"
  },
  name: {
    maxWidth: 600,
    flex: 1,
    marginRight: 10,
    fontFamily: "Helvetica",
    fontStyle: "normal",
    fontWeight: 800,
    fontSize: "21px",
    lineHeight: "30px",
    color: "black"
  },
  category: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    fontFamily: "Helvetica",
    fontWeight: 800,
    fontSize: "13px",
    lineHeight: "16px",
    marginBottom: 1,
    marginTop: 1
  },
  noAssessments: {
    position: "relative",
    top: -150,
    width: "100%",
    textAlign: "center",
    fontWeight: "bold"
  }
});
