function getWindowPropertyIfPresent(propertyName) {
  if (window && window.env && Object.prototype.hasOwnProperty.call(window.env, propertyName)) {
    return window.env[propertyName];
  }
  return null;
}

function loadValue(propertyName) {
  return getWindowPropertyIfPresent(propertyName) || process.env[propertyName];
}

const isOnAndroidEmulator = window.location.href.includes("10.0.2.2");

const apiUrl = isOnAndroidEmulator ? "http://10.0.2.2:8000" : loadValue("REACT_APP_API");
const graphqlUrl = isOnAndroidEmulator ? "http://10.0.2.2:8000/graphql" : loadValue("REACT_APP_GRAPHQL");

const config = {
  env: {
    REACT_APP_ENV: loadValue("REACT_APP_ENV"),
    REACT_APP_API: apiUrl,
    REACT_APP_GRAPHQL: graphqlUrl,
    REACT_APP_CONSULT_APPT_TYPE_ID: loadValue("REACT_APP_CONSULT_APPT_TYPE_ID"),
    REACT_APP_INTAKE_APPT_TYPE_ID: loadValue("REACT_APP_INTAKE_APPT_TYPE_ID"),
    REACT_APP_GCP_PROJECT_ID: loadValue("REACT_APP_GCP_PROJECT_ID"),
    REACT_APP_GCP_ERROR_REPORTING_API_KEY: loadValue("REACT_APP_GCP_ERROR_REPORTING_API_KEY"),
    REACT_APP_EHR_GRAPHQL: loadValue("REACT_APP_EHR_GRAPHQL"),
    REACT_APP_EHR_UI_URL: loadValue("REACT_APP_EHR_UI_URL")
  }
};

export default config;
