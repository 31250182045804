import React, { useState } from "react";
import OverlayCenteredView from "./OverlayCenteredView";
import { ArrowLeft, ArrowRight, X, Download } from "react-feather";
import { TouchableOpacity } from "./react-native";
import useItemIterator from "../hooks/useItemIterator";
import moment from "moment";

export default function ImageViewer({ isVisible, hide, imageLoaders }) {
  const iterator = useItemIterator(imageLoaders || []);
  const [downloadSrc, setDownloadSrc] = useState();

  const date = iterator.getCurrent()?.metadata?.imageDate;
  const downloadFileName = date ? `goalAchievement-${moment(date).format("MM-DD-YYYY")}` : "goalAchievement";

  const HeaderActions = () => (
    <div style={styles.headerContainer}>
      <div style={styles.row}>
        <a download={downloadFileName} href={downloadSrc} style={styles.download}>
          <Download color="#FFFFFF" />
        </a>

        <TouchableOpacity style={styles.cancel} onPress={hide}>
          <X color="#FFFFFF" />
        </TouchableOpacity>
      </div>
    </div>
  );

  const PrevButton = () => (
    <div style={styles.nextButtonContainer}>
      {iterator.hasPrev() && (
        <TouchableOpacity
          onPress={() => {
            iterator.prev();
            setDownloadSrc(undefined);
          }}
        >
          <ArrowLeft style={styles.nextButton} color="#FFFFFF" size={30} />
        </TouchableOpacity>
      )}
    </div>
  );

  const NextButton = () => (
    <div style={styles.nextButtonContainer}>
      {iterator.hasNext() && (
        <TouchableOpacity
          onPress={() => {
            iterator.next();
            setDownloadSrc(undefined);
          }}
        >
          <ArrowRight style={styles.nextButton} color="#FFFFFF" size={30} />
        </TouchableOpacity>
      )}
    </div>
  );

  const Image = () => (
    <div style={styles.imageContainer}>
      {imageLoaders.map(({ component: ImageLoader }, i) => {
        const displayedIndex = iterator.getIndex();
        const load = i === displayedIndex || i === displayedIndex - 1 || i === displayedIndex + 1;

        const handleOnRender = src => {
          if (iterator.getIndex() === i && src) setDownloadSrc(src);
        };
        return (
          <ImageLoader
            key={i + 2}
            load={load}
            style={styles.imageLoader}
            isVisible={iterator.getIndex() === i}
            onRenderImage={handleOnRender}
          />
        );
      })}
      {date && <div style={styles.date}>{moment(date).format("MM/DD/YYYY")}</div>}
    </div>
  );

  return (
    <>
      {isVisible && (
        <OverlayCenteredView>
          <HeaderActions />
          <div style={styles.viewerContainer}>
            <PrevButton />
            <Image />
            <NextButton />
          </div>
        </OverlayCenteredView>
      )}
    </>
  );
}

const styles = {
  row: {
    display: "flex",
    flexDirection: "row"
  },
  column: {
    display: "flex",
    flexDirection: "column"
  },
  viewerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    justifySelf: "center",
    height: "100%"
  },
  imageContainer: {
    height: "100%",

    justifyContent: "center",
    alignItems: "center",
    userSelect: "none",
    display: "flex",
    flexDirection: "column"
  },
  imageLoader: {
    height: "min(90vh, 600px)",
    width: "min(50vw, 550px)"
  },
  date: {
    paddingLeft: 76,
    alignSelf: "flex-start",
    color: "#FFFFFF"
  },
  nextButton: {
    margin: "auto"
  },
  nextButtonContainer: {
    width: 50
  },
  headerContainer: {
    position: "fixed",
    top: 20,
    right: 20
  },
  download: {
    marginLeft: 10,
    marginRight: 10
  },
  cancel: {
    marginLeft: 30,
    marginRight: 10
  }
};
