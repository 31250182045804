import React from "react";
import { TouchableOpacity } from "../../react-native";
import AddTemplateIcon from "../../../assets/icons/list-plus-icon.png";

export default function MessageTemplateButton({ onPress, ...props }) {
  return (
    <TouchableOpacity onPress={onPress} {...props}>
      <img src={AddTemplateIcon} style={styles.templateIcon} />
    </TouchableOpacity>
  );
}

const styles = {
  templateIcon: {
    height: 30,
    width: 30,
    resize: "contain"
  }
};
