import React, { useState } from "react";
import { GoalItemCheckButton } from "../GoalItem";
import "./GoalAssignmentList.css";
import GoalItemSeparator from "../GoalItemSeparator";
import { GoalItemIcon, GoalItemText, GoalItemPlusButton } from "../GoalItem";
import Button from "../Button";
import { THERAPIST_GET_CUSTOM_GOALS } from "../../queries";
import Spinner from "../Spinner";
import { useQuery } from "@apollo/client";
import Modal from "../Modal";
import { useGoalSelector } from "./useGoalSelector";
import { createSortedGoalGroup, DEFAULT_GOAL_GROUPS, GOAL_TYPES } from "../../utils/goalUtils";

export default function GoalList({ onCancel, onSubmit }) {
  const { loading, error, data } = useQuery(THERAPIST_GET_CUSTOM_GOALS);
  const [pending, setPending] = useState(false);
  const [hideErrorModal, setHideErrorModal] = useState(false);

  const { selectGoal, unselectGoal, isGoalSelected, isGoalUnselectable, getSelectedGoals } = useGoalSelector();

  function submit() {
    if (pending) {
      return;
    }
    setPending(true);
    const goals = getSelectedGoals();
    onSubmit(goals);
  }

  const seletedGoals = getSelectedGoals();
  const customGoals = data && data.therapistCustomGoals ? data.therapistCustomGoals : [];
  const customGoalGroup = createSortedGoalGroup("Custom", customGoals, GOAL_TYPES.custom);
  const allGroups = [customGoalGroup, ...DEFAULT_GOAL_GROUPS];
  const assignDisabled = pending || !seletedGoals || seletedGoals.length === 0;

  return (
    <div className="GoalAssignmentContainer">
      <div className="GoalAssignment-title">Assign Goals</div>
      <div className="GoalAssignment-subtitle">
        <div>Help your client get a head start by assigning a few goals now!</div>
        <div>(Once they register, you can edit or add more goals)</div>
      </div>
      <div className="GoalAssignment-goalContainer">
        {allGroups.map(group => {
          return (
            <div key={group.objective}>
              <GoalItemSeparator key={group.objective} label={group.objective} />
              {group.objective === "Custom" && loading && <Spinner></Spinner>}
              {group.goals &&
                group.goals.map(goal => {
                  const goalButton = isGoalSelected(goal) ? (
                    <GoalItemCheckButton onPress={() => unselectGoal(goal)} />
                  ) : (
                    <GoalItemPlusButton disabled={isGoalUnselectable(goal)} onPress={() => selectGoal(goal)} />
                  );
                  return (
                    <div key={`${group.objective}${goal.id}.${goal.goalType}`} className="GoalAssignment-goalItem">
                      <GoalItemIcon item={goal} />
                      <GoalItemText item={goal} />
                      {goalButton}
                    </div>
                  );
                })}
            </div>
          );
        })}
      </div>
      <div className="GoalAssignment-buttonContainer">
        <Button
          style={styles.button}
          fullWidth
          theme="lightBlue"
          title="Assign Goals"
          disabled={assignDisabled}
          onPress={() => {
            if (!assignDisabled) {
              submit();
            }
          }}
        />
        <Button style={styles.button} fullWidth theme="gray" title="I'll do this later" onPress={onCancel} />
      </div>
      {error && !hideErrorModal && (
        <Modal
          title={error ? error.message : "Unable to get goals"}
          onButton1Press={() => setHideErrorModal(true)}
          fullscreen={true}
        />
      )}
    </div>
  );
}

const styles = {
  button: {
    marginTop: 10,
    width: "80%",
    alignSelf: "center"
  }
};
