import React, { useMemo, useState } from "react";
import { MentionsInput, Mention } from "react-mentions";
import MessageActionBar from "../actions/MessageActionBar";
import { MENTION_ID_REGEX, MENTION_REGEX } from "../../../utils/messagesUtils";
import { useMutation } from "@apollo/client";
import { DELETE_PENDING_ACTIONS, SEND_MESSAGE } from "../../../queries";
import useCareTeam from "../../../hooks/useCareTeam";
import { useActions } from "tiny-atom/react/hooks";
import Button from "../../Button";
import MessageTemplateModal from "../MessageTemplateModal";
import { TextField } from "@shopify/polaris";
import { toAttachment } from "../../../utils/fileUtils";
import { Check } from "react-feather";
import { isAllWhitespace } from "../../../utils/formUtils";
import _ from "lodash";
import { reportError } from "../../../utils/errorUtils";

export default function ConversationActionBar({ conversation, userId, asUserId, refetch, onExit, setShowErrorModal }) {
  const [message, setMessage] = useState("");
  const [templateKey, setTemplateKey] = useState();
  const [fileAttachment, setFileAttachment] = useState();
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const { logEvent } = useActions();
  const { members: careTeamMembers } = useCareTeam();
  const [processing, setProcessing] = useState(false);

  const [sendMessage] = useMutation(SEND_MESSAGE);
  const [deletePendingActions] = useMutation(DELETE_PENDING_ACTIONS);

  const mentionables = useMemo(
    () => careTeamMembers.map(member => ({ id: member.id, display: member.firstName + " " + member.lastName })),
    [careTeamMembers]
  );

  const handleSendMessage = async () => {
    setProcessing(true);
    const mentions = _.uniq(message.split(MENTION_REGEX).filter(m => MENTION_REGEX.test(m)));
    const mentionedIds = mentions.map(m => parseInt(MENTION_ID_REGEX.exec(m)[1]));

    try {
      const options = {
        message: message || "",
        templateKey: templateKey,
        conversationId: conversation ? conversation.id : undefined,
        mentionedUserIds: mentionedIds
      };
      if (fileAttachment) {
        const attachment = await toAttachment(fileAttachment);
        options.attachments = [attachment];
      }
      await sendMessage({
        variables: {
          options
        }
      });
      refetch();
      logEvent("webSentMessage");
      setMessage("");
      setFileAttachment(undefined);
    } catch (error) {
      reportError(`ConversationActionBar -- webSendMessageError -- ${error}`);
      logEvent("webSendMessageError");
      setShowErrorModal(true);
    } finally {
      setProcessing(false);
    }
  };

  const handleDone = async () => {
    try {
      await deletePendingActions({ variables: { conversationIds: [conversation.id] } });
      await refetch();
      logEvent("deletePendingActions");
      if (onExit) {
        onExit();
      }
    } catch (error) {
      reportError(`ConversationActionBar -- deletePendingActionsError -- ${error}`);
      logEvent("deletePendingActionsError");
      setShowErrorModal(true);
    }
  };

  const sendButtonTheme = conversation?.type === "CARE_TEAM_INTERNAL" ? "purpleCoral" : "manaBlue";

  return (
    <>
      <div className="send-message-container">
        {conversation?.type === "CARE_TEAM_INTERNAL" ? (
          <div className="mentions-input-container">
            <div className="send-message-input">
              <MentionsInput
                id="conversationTextInputId"
                className="conversation-input"
                value={message}
                onChange={e => setMessage(e.target.value)}
                allowSuggestionsAboveCursor={true}
                allowSpaceInQuery={true}
                placeholder="Write your message here"
                maxLength={3500}
              >
                <Mention
                  trigger="@"
                  data={query => {
                    if (!query) {
                      return mentionables;
                    }
                    return mentionables.filter(tm => tm.display.toLowerCase().includes(query.toLowerCase()));
                  }}
                  markup="@{{__display__}}[[__id__]]"
                  displayTransform={(_, display) => "@" + display}
                  appendSpaceOnAdd={true}
                  style={{
                    backgroundColor: "#cee4e5"
                  }}
                />
              </MentionsInput>
            </div>
          </div>
        ) : (
          <div className="send-message-input">
            <div className="conversation-polaris-input">
              <TextField
                id={"conversationTextInputId"}
                name="message"
                type="TextInput"
                placeholder="Write your message here"
                value={message}
                onChange={setMessage}
                editable
                multiline
                maxHeight="30vh"
                maxLength={3500}
              />
            </div>
          </div>
        )}
        <div style={styles.actionBar}>
          <MessageActionBar
            textInputId={"conversationTextInputId"}
            message={message}
            setMessage={setMessage}
            setShowTemplateModal={setShowTemplateModal}
            setFileAttachment={setFileAttachment}
            fileAttachment={fileAttachment}
          />
          <div style={styles.buttonRow}>
            {conversation &&
              conversation.pendingActions &&
              conversation.pendingActions.length > 0 &&
              asUserId === userId && (
                <Button
                  theme="manaYellow"
                  iconLeft={Check}
                  iconSize={25}
                  style={styles.button}
                  disabled={!isAllWhitespace(message) || fileAttachment}
                  onPress={async () => {
                    await handleDone();
                  }}
                  title="DONE"
                />
              )}
            <Button
              theme={sendButtonTheme}
              style={styles.button}
              disabled={processing || (isAllWhitespace(message) && !fileAttachment)}
              onPress={async () => {
                await handleSendMessage();
              }}
              title="SEND"
            />
          </div>
        </div>
      </div>
      <MessageTemplateModal
        hidden={!showTemplateModal}
        setHidden={hidden => setShowTemplateModal(!hidden)}
        onSubmit={(template, key) => {
          setMessage(message => (message ? message + "\n" + template : template));
          setTemplateKey(key);
        }}
      />
    </>
  );
}

const styles = {
  actionBar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 10,
    marginTop: 10,
    width: "100%"
  },
  buttonRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    flexGrow: 1
  },
  button: {
    width: 120,
    height: 36,
    marginLeft: 10
  }
};
