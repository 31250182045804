import React from "react";
import CareScreenerProgress from "../components/care-screener/CareScreenerProgress";
import ScheduleAppointment from "./ScheduleAppointment";

export default function ScheduleIntake() {
  return (
    <ScheduleAppointment
      availabilityRecurringCount={3}
      availabilityRecurringInterval="Weekly"
      header={<CareScreenerProgress currentView="scheduleConsult" />}
    />
  );
}
