import {
  CSSProperties,
  StyleDeclaration,
  StyleDeclarationMap,
  StyleDeclarationValue,
  StyleSheet,
  css
} from "aphrodite";
import React, { ReactNode } from "react";
import { ThemeColor, themeColors } from "../../theme/colors";
import { TouchableOpacity } from "../react-native";

export type TextProps = {
  title?: boolean;
  subtitle?: boolean;
  body?: boolean;
  center?: boolean;
  left?: boolean;
  theme?: ThemeColor;
  color?: string;
  weight?: number;
  size?: number;
  link?: {
    onPress: () => void;
  };
  fontFamily?: string;
  style?: (CSSProperties | StyleDeclarationMap) | StyleDeclarationValue;
  tag?: "p" | "span";
  children?: ReactNode;
};

export default function Text({
  title,
  subtitle,
  body,
  center,
  left,
  theme,
  color,
  weight,
  size,
  fontFamily,
  style,
  tag,
  link,
  children
}: TextProps) {
  const textStyles: StyleDeclarationValue[] = [];

  textStyles.push(styles.default);

  if (title) {
    textStyles.push(styles.title);
  } else if (subtitle) {
    textStyles.push(styles.subtitle);
  } else if (body) {
    textStyles.push(styles.body);
  }
  if (link) {
    textStyles.push(styles.link);
  }
  if (theme && themeColors[theme]) {
    textStyles.push(toSheetStyle({ color: themeColors[theme] }));
  }
  if (color) {
    textStyles.push(toSheetStyle({ color }));
  }
  if (center) {
    textStyles.push(styles.center);
  } else if (left) {
    textStyles.push(styles.left);
  }
  if (weight !== undefined) {
    textStyles.push(toSheetStyle({ fontWeight: weight }));
  }
  if (size !== undefined) {
    textStyles.push(toSheetStyle({ fontSize: size }));
  }
  if (fontFamily) {
    textStyles.push(toSheetStyle({ fontFamily }));
  }
  if (style) {
    if (isValidAphroditeStyle(style)) {
      textStyles.push(style);
    } else {
      textStyles.push(toSheetStyle(style));
    }
  }

  if (tag === "span") {
    return (
      <TextContainer link={link}>
        <span className={css(textStyles)}>{children}</span>
      </TextContainer>
    );
  }
  return (
    <TextContainer link={link}>
      <p className={css(textStyles)}>{children}</p>
    </TextContainer>
  );
}

function TextContainer({
  children,
  link
}: {
  children: ReactNode;
  link?: {
    onPress: () => void;
  };
}) {
  if (link && link.onPress != undefined) {
    return (
      <TouchableOpacity
        style={{
          cursor: "pointer"
        }}
        onPress={link.onPress}
      >
        {children}
      </TouchableOpacity>
    );
  }

  return <>{children}</>;
}

function isValidAphroditeStyle(style: object) {
  return "_definition" in style && "_name" in style && "_len" in style;
}

function toSheetStyle(style: StyleDeclaration): StyleDeclarationValue {
  const sheet = StyleSheet.create({
    style: style
  });
  return sheet.style;
}

const styles = StyleSheet.create({
  default: {
    fontFamily: "Helvetica Neue",
    color: "#636C8C",
    fontWeight: 500,
    fontSize: 16,
    lineHeight: 1.3
  },
  title: {
    color: themeColors.manaBlueDark,
    fontSize: 32,
    lineHeight: 1.1,
    fontWeight: 700
  },
  subtitle: {
    color: themeColors.manaBlue,
    fontSize: 28,
    lineHeight: 1.1,
    fontWeight: 500
  },
  body: {
    color: "#636C8C",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.3
  },
  center: {
    textAlign: "center"
  },
  left: {
    textAlign: "left"
  },
  link: {
    textDecoration: "underline",
    cursor: "pointer",
    color: "#2562D8"
  }
});
