import { useQuery } from "@apollo/client";
import { useEffect, useMemo } from "react";
import { useGlobalContext } from "../../context/GlobalComponentsContext";
import { GET_APPOINTMENT_TYPES } from "../../queries";

export default function useAppointmentType(appointmentId) {
  const { data, loading, error } = useQuery(GET_APPOINTMENT_TYPES);
  const { showError } = useGlobalContext();

  const appointmentType = useMemo(() => {
    const apptType = (data?.appointmentTypes || []).find(a => a.id === appointmentId);

    if (apptType) {
      return apptType;
    } else if (data) {
      showError(`Appointment type '${appointmentId}' not found. Please contact support@getmanatee.com`);
    }
  }, [appointmentId, data, showError]);

  useEffect(() => {
    if (error) {
      showError();
    }
  }, [error, showError]);

  return { appointmentType, loading, error };
}
