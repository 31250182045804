import React from "react";
import GoalAssignmentList from "../components/invite/GoalAssignmentList";
import "./InviteeGoals.css";
import { useMutation } from "@apollo/client";
import { STORE_PENDING_INVITE_GOALS } from "../queries";
import { logEvent } from "../actions";
import { useActions, useAtom } from "tiny-atom/react/hooks";
import moment from "moment";
import Modal from "../components/Modal";

export default function InviteeGoals({ inviteId, next }) {
  const { displayToast, showModal, hideModal } = useActions();
  const [addGoalList] = useMutation(STORE_PENDING_INVITE_GOALS);

  const error = useAtom(state => state.error);
  const isErrorModalVisible = useAtom(state => state.isErrorModalVisible);

  return (
    <div className="Invitee-Goals-Page">
      <GoalAssignmentList
        onSubmit={goals => {
          if (goals && goals.length > 0) {
            const goalRefs = goals.map(g => {
              return { goalId: g.id, goalType: g.goalType };
            });
            addGoalList({
              variables: {
                inviteId: inviteId,
                goals: goalRefs,
                timeZoneOffset: moment().format("Z")
              }
            })
              .then(() => {
                logEvent("therapistWebInviteGoalsAdded");
                displayToast({ text: "Goals assigned!" });
                next();
              })
              .catch(error => {
                logEvent("therapistWebInviteGoalsAddError");
                showModal(error);
              });
          }
        }}
        onCancel={() => {
          logEvent("therapistWebInviteGoalsNotAdded");
          next();
        }}
      />
      <Modal
        title={error && error.message ? `${error.message}` : "Unable to assign goals"}
        hidden={!isErrorModalVisible}
        onButton1Press={() => {
          hideModal();
          next();
        }}
        fullscreen={true}
      />
    </div>
  );
}
