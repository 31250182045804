export const MANA_BLUE_LIGHT = "#BBD2FF";
export const MANA_BLUE = "#2562D8";
export const MANA_BLUE_DARK = "#1A2579";

const Colors = {
  MANA_BLUE_LIGHT,
  MANA_BLUE,
  MANA_BLUE_DARK
};

export default Colors;

export type ThemeColor = "manaBlueDark" | "manaBlue" | "manaBlueLight";

export const themeColors: { [k in ThemeColor]: string } = {
  manaBlueDark: "#1A2579",
  manaBlue: "#2562D8",
  manaBlueLight: "#BBD2FF"
};
