import React from "react";
import { TouchableOpacity, Text, View, Image } from "./react-native";
import { Image as ImageIcon, Check } from "react-feather";
import Button from "./Button";
import { getImage } from "../data/defaultRewardImages";

class RewardItem extends React.Component {
  render() {
    const { item } = this.props;
    const image = getImage(item.imageId);

    if (item.type === "separator") {
      return (
        <View style={[styles.container, styles.separator]}>
          <Text style={styles.separatorTitle}>{item.title}</Text>
          <View style={styles.separatorLine} />
        </View>
      );
    }

    return (
      <View style={styles.container}>
        <TouchableOpacity activeOpacity={0.4} style={styles.clickable} onPress={() => this.openViewRewardScreen(item)}>
          {!item.type && (
            <View style={styles.icon}>
              <Image source={image} style={[styles.icon, { overflow: "hidden" }]} />
            </View>
          )}
          {item.type === "new" && (
            <View style={styles.createIcon}>
              <ImageIcon size={32} color="rgb(0, 122, 206)" />
            </View>
          )}
          <View style={styles.body}>
            <Text style={styles.title}>{item.name}</Text>
            {!item.type && (
              <Text style={styles.points}>
                Costs {item.cost} point{item.cost === 1 ? "" : "s"}
              </Text>
            )}
          </View>
        </TouchableOpacity>
        <View style={styles.action}>{item.assignedTo && <Button theme="green" icon={Check} small feather />}</View>
      </View>
    );
  }

  openViewRewardScreen(item) {
    this.props.rewardSelected(item);
    return this.props.openDefaultRewardEditScreen({
      reward: item,
      mode: "view",
      refetch: this.props.refetch
    });
  }
}

const styles = {
  container: {
    display: "flex",
    marginTop: 10,
    marginBottom: 10,
    padding: 0,
    flexDirection: "row"
  },
  clickable: {
    display: "flex",
    flexDirection: "row",
    flex: 1
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  body: {
    flex: 1,
    paddingRight: 20
  },
  title: {
    marginBottom: 4
  },
  points: {
    fontSize: 13,
    color: "rgb(145, 158, 171)"
  },
  separator: {
    alignItems: "center",
    marginTop: 20,
    marginBottom: 20
  },
  separatorTitle: {
    fontSize: 16,
    fontWeight: "600",
    color: "rgb(0, 0, 0)"
  },
  separatorLine: {
    flex: 1,
    borderBottom: "1px solid rgba(145, 158, 171, 0.25)",
    borderBottomWidth: 1,
    marginLeft: 32
  },
  icon: {
    borderRadius: 12,
    width: 64,
    height: 64,
    marginRight: 10,
    padding: 5
  },
  createIcon: {
    borderRadius: 12,
    width: 64,
    height: 64,
    marginRight: 10,
    paddingTop: 4,
    backgroundColor: "rgba(0, 122, 206, 0.25)",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0 2px 10px 4px rgba(0, 0, 0, 0.05)"
  }
};

export default RewardItem;
