import React, { useEffect, useState } from "react";
import { User } from "../model/userModels";
import AuthService from "../services/AuthService";
import useQueryUser from "../hooks/useQueryUser";
import { addEventListener } from "../events/Events";

type UserContext = {
  authenticated: boolean;
  user: User;
  loading: boolean;
  loginInProgress: boolean;
  setLoginInProgress: (loginInProgress: boolean) => void;
};

export const UserContext = React.createContext<UserContext | undefined>(undefined);

export function UserContextProvider({ children }) {
  const [authenticated, setAuthenticated] = useState(AuthService.isAuthenticated());
  const [loginInProgress, setLoginInProgress] = useState<boolean>(false);

  useEffect(() => {
    const subscriptions = [
      addEventListener("authTokenSet", () => {
        setAuthenticated(true);
      }),
      addEventListener("authTokenRemoval", () => {
        setAuthenticated(false);
      }),
      addEventListener("loginBegin", () => {
        setLoginInProgress(true);
      })
    ];

    return () => subscriptions.forEach(subscription => subscription.remove());
  }, []);

  const { user, loading } = useQueryUser({ skip: !authenticated });

  useEffect(() => {
    if (authenticated && user && loginInProgress) {
      setLoginInProgress(false);
    }
  }, [authenticated, user, loginInProgress]);

  return (
    <UserContext.Provider value={{ user, loading, authenticated, loginInProgress, setLoginInProgress }}>
      {children}
    </UserContext.Provider>
  );
}
