import "@shopify/polaris/build/esm/styles.css";
import "./pages/styles/main.css";
import "./pages/styles/polaris-tweaks.css";
import ReactGA from "react-ga4";
import * as ReactDOM from "react-dom/client";

import React from "react";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

const root = document.getElementById("root");

if (process.env.NODE_ENV === "production") {
  ReactGA.initialize("G-5922V7R1YM", {
    titleCase: false
  });
}

ReactDOM.createRoot(root).render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
