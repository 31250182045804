import { Search } from "react-feather";
import React, { useContext, useEffect, useState } from "react";
import { Button } from "@shopify/polaris";
import DataTable from "react-data-table-component";
import Spinner from "../components/Spinner";
import "./Directory.css";
import "../components/DataTable.css";
import { useActions, useAtom } from "tiny-atom/react/hooks";
import { TextField } from "@shopify/polaris";
import moment from "moment";
import standaloneGrayImage from "../assets/standaloneGray.png";
import familyGrayImage from "../assets/familyGray.png";
import envelopeImage from "../assets/envelope-icon.png";
import { AppContext } from "../context/AppContext";
import BatchMessageModal from "../components/BatchMessageModal";
import useConversations from "../hooks/useConversations";

export default function Directory() {
  const { setPatient, setFilter, setCurrentScreen } = useActions();
  const { filter } = useAtom(state => ({ filter: state.filter }));
  const { loading, error } = useContext(AppContext);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showBatchMessageModal, setShowBatchMessageModal] = useState(false);
  const { refetchChildren, children } = useContext(AppContext);

  const { conversations } = useConversations();

  useEffect(() => {
    const currentTime = moment().valueOf();
    const lastRefetchTime = sessionStorage.getItem("lastChildRefetch");

    if (!lastRefetchTime) {
      sessionStorage.setItem("lastChildRefetch", currentTime);
    } else {
      const lastRefetchInMillis = parseInt(lastRefetchTime);

      if (Number.isInteger(lastRefetchInMillis)) {
        const nextRefetchTime = moment(lastRefetchInMillis).add(60, "seconds");
        if (moment().isAfter(nextRefetchTime)) {
          refetchChildren();
          sessionStorage.setItem("lastChildRefetch", currentTime);
        }
      }
    }
  }, [refetchChildren]);

  setCurrentScreen("therapistDirectoryScreen");

  function goToPatient(patient) {
    patient = children.find(p => p.user.id === patient.id).user;
    setPatient({ pathname: "/patient", params: { patient } });
  }

  function formatChildren(children) {
    let filteredData = [];
    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      let user = child.user;
      filteredData.push({
        id: user.id,
        name: `${user.firstName} ${user.lastName ? user.lastName : ""}`,
        accountType: getAccountTypeName(user.accountType),
        image: getAccountTypeImage(user.accountType),
        caregivers: listParentNames(user),
        lastActiveAt: user.lastActiveAt,
        unreadMessages: getHasUnreadMesages(user),
        createdAt: user.createdAt
      });
    }
    return filteredData;
  }

  function listParentNames(user) {
    const parents = (user.parents || []).map(p => p.user);
    const includeLastNames = !user.lastName || parents.some(p => p.lastName && p.lastName !== user.lastName);

    return parents
      .map(p => {
        if (includeLastNames && p.lastName) {
          return `${p.firstName} ${p.lastName}`;
        }
        return p.firstName;
      })
      .join(", ");
  }

  function filterChildren(data) {
    let filteredChildren = [];
    for (const child of data) {
      let childName = `${child.user.firstName} ${child.user.lastName}`;
      if (childName.toLowerCase().includes(filter.toLowerCase())) {
        filteredChildren.push(child);
      } else if (
        (child.user.parents || []).some(p =>
          `${p.user.firstName} ${p.user.lastName}`.toLowerCase().includes(filter.toLowerCase())
        )
      ) {
        filteredChildren.push(child);
      }
    }
    return filteredChildren;
  }

  function getAccountTypeName(accountType) {
    if (accountType === "STANDALONE_CLIENT") {
      return "Standalone";
    } else {
      return "Family";
    }
  }

  function getAccountTypeImage(accountType) {
    if (accountType === "STANDALONE_CLIENT") {
      return standaloneGrayImage;
    } else {
      return familyGrayImage;
    }
  }

  function getHasUnreadMesages(client) {
    if (!conversations) {
      return false;
    }
    const familyMemberIds = [client.id, ...(client.parents || []).map(parent => parent.user.id)];

    return conversations
      .filter(
        conversation =>
          (conversation.familyId && conversation.familyId === client.familyId) ||
          conversation.users.some(u => familyMemberIds.includes(u.id))
      )
      .some(conversation => !conversation.read);
  }

  const AccountTypeImage = ({ row }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ paddingLeft: "10px" }}>{row.accountType}</div>
      <img alt="account type" height="30px" width="30px" src={row.image} />
    </div>
  );

  const UnreadMessagesImage = ({ row }) => (
    <div
      style={{
        display: `${row.unreadMessages ? "flex" : "none"}`,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "19px",
        backgroundColor: "#2F7AC8",
        width: "37px",
        height: "22px"
      }}
    >
      <img alt="unread messages" height="11px" width="14px" src={envelopeImage} style={{ color: "#ffffff" }} />
    </div>
  );
  function changeFilter(filter) {
    setFilter({ filter });
  }

  const columns = [
    {
      name: "ACCOUNT TYPE",
      selector: row => row.accountType,
      sortable: true,
      // eslint-disable-next-line react/display-name
      cell: row => <AccountTypeImage row={row} />,
      right: true
    },
    {
      name: "LAST ACTIVE",
      selector: row => row.lastActiveAt,
      format: row => {
        const lastActiveAt = moment(row.lastActiveAt);
        if (lastActiveAt.isSame(moment(), "day")) {
          return "Today";
        } else if (lastActiveAt.isSame(moment().subtract(1, "day"), "day")) {
          return "Yesterday";
        } else if (!row.lastActiveAt) {
          return "";
        } else {
          return lastActiveAt.format("M/DD/YY");
        }
      },
      sortable: true,
      sortFunction: (rowA, rowB) => {
        if (!rowA.lastActiveAt && !rowB.lastActiveAt) {
          return moment(rowA.createdAt).diff(moment(rowB.createdAt));
        }
        return moment(rowA.lastActiveAt || 0).diff(moment(rowB.lastActiveAt || 0));
      },
      right: true
    },
    {
      name: "CAREGIVERS",
      selector: row => row.caregivers,
      sortable: true,
      right: true,
      width: "300px"
    },
    {
      name: "CHILD",
      selector: row => row.name,
      sortable: true,
      compact: true,
      right: true
    },
    {
      name: "",
      selector: row => row.unreadMessages,
      cell: row => <UnreadMessagesImage row={row} />,
      center: true,
      compact: true,
      width: "80px"
    }
  ];

  if (loading) return <Spinner></Spinner>;
  if (error) return <p></p>;

  const childrenData = filter ? formatChildren(filterChildren(children)) : formatChildren(children);

  const handleChange = ({ selectedRows: rows }) => {
    setSelectedRows(rows);
  };

  return (
    <div className="directory-data-table">
      <div className="data-table-header">
        <div className="data-table-title">Clients</div>
        <TextField
          prefix={<Search style={{ marginTop: "8px" }} size={16} />}
          placeholder="Search"
          value={filter}
          type="search"
          onChange={changeFilter}
          clearButton
          onClearButtonClick={() => {
            setFilter({ filter: "" });
          }}
        />
      </div>
      <div className="data-table-actions">
        {selectedRows.length > 0 && (
          <div className="data-table-actions-content">
            <span className="selected-count">{selectedRows.length}</span>{" "}
            <span className="selected-label">Client{selectedRows.length > 1 ? "s" : ""} selected</span>
            <Button
              className="selected-button"
              variant="primary"
              disabled={false}
              onClick={() => setShowBatchMessageModal(true)}
            >
              message
            </Button>
          </div>
        )}
      </div>
      <div className="data-table-content">
        <DataTable
          columns={columns}
          data={childrenData}
          onRowClicked={goToPatient}
          direction="rtl"
          noHeader
          highlightOnHover
          selectableRows
          onSelectedRowsChange={handleChange}
          customStyles={{
            rows: {
              style: {
                paddingRight: "40px"
              }
            }
          }}
        />
      </div>
      {showBatchMessageModal && (
        <BatchMessageModal clients={selectedRows} onClose={() => setShowBatchMessageModal(false)} />
      )}
    </div>
  );
}
