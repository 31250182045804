import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { THERAPIST_GET_CARE_TEAM } from "../queries";
import { AccountType } from "../model/userModels";

export default function useCareTeam(props = { skip: false }) {
  const { data, error } = useQuery(THERAPIST_GET_CARE_TEAM, { fetchPolicy: "cache-and-network", skip: props?.skip });

  const therapists = useMemo(
    () =>
      data?.careTeam?.users?.length > 0 ? data.careTeam.users.filter(u => u.accountType === AccountType.THERAPIST) : [],
    [data]
  );
  const parentCoaches = useMemo(
    () =>
      data?.careTeam?.users?.length > 0
        ? data.careTeam.users.filter(u => u.accountType === AccountType.PARENT_COACH)
        : [],
    [data]
  );
  const careCoordinators = useMemo(
    () =>
      data?.careTeam?.users?.length > 0
        ? data.careTeam.users.filter(u => u.accountType === AccountType.CARE_COORDINATOR)
        : [],
    [data]
  );

  const members = useMemo(() => data?.careTeam?.users || [], [data?.careTeam?.users]);

  const careTeam = useMemo(
    () => ({
      error,
      careCoordinationEnabled: data?.careTeam?.careCoordinationEnabled,
      members,
      therapists,
      careCoordinators,
      parentCoaches
    }),
    [data, error, members, therapists, careCoordinators, parentCoaches]
  );

  return careTeam;
}
