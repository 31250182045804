import React, { useState } from "react";
import { TouchableOpacity } from "../../react-native";
import EmojiPicker from "../EmojiPicker";
import EmojiIcon from "../../../assets/icons/smile-icon.png";

export default function EmojiPickerButton({ textInputId, message, setMessage, pickerStyle }) {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  return (
    <>
      <TouchableOpacity onPress={() => setShowEmojiPicker(prev => !prev)}>
        <img src={EmojiIcon} style={styles.icon} />
      </TouchableOpacity>
      {showEmojiPicker && (
        <div style={styles.pickerContainer}>
          <EmojiPicker
            textInputId={textInputId}
            message={message}
            setMessage={setMessage}
            setShowEmojiPicker={setShowEmojiPicker}
            style={pickerStyle || styles.pickerAbsolute}
          />
        </div>
      )}
    </>
  );
}

const styles = {
  icon: {
    height: 20,
    width: 20,
    resize: "contain"
  },
  pickerContainer: {
    position: "relative"
  },
  pickerAbsolute: {
    position: "absolute",
    bottom: 60,
    left: -25,
    zIndex: 1000
  }
};
