import React from "react";
import Modal from "./Modal";

export default function ErrorLandingView() {
  return (
    <Modal
      title="An error has occurred."
      subtitle={`The error has been reported. Please refresh your browser or go to ${process.env.REACT_APP_BASE_URL}`}
      subtitleStyle={modalSubtitleStyle}
      titleStyle={modalTitleStyle}
    />
  );
}

const modalTitleStyle = {
  color: "#000000",
  fontFamily: "Avenir Next",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "30px",
  lineHeight: "40px",
  textAlign: "center",
  marginBottom: "15px"
};

const modalSubtitleStyle = {
  color: "#000000",
  fontFamily: "Avenir Next",
  fontStyle: "normal",
  textAlign: "center",
  marginBottom: "15px"
};
