import { useQuery } from "@apollo/client";

export default function useImageLoader(query, variables, dataImageExtractor) {
  const { loading, data, error } = useQuery(query, {
    variables
  });
  const image = data ? dataImageExtractor(data) : undefined;

  return { loading, error, image };
}
