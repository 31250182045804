import React from "react";
import Picker, { SkinTones, EmojiStyle } from "emoji-picker-react";
import "./EmojiPicker.scss";

export default function EmojiPicker({ textInputId, message, setMessage, setShowEmojiPicker, style }) {
  const emojiSkinTone = localStorage.getItem("manateeEmojiSkinTone") as SkinTones;

  const onEmojiClick = (data, _event) => {
    if (data.activeSkinTone) {
      localStorage.setItem("manateeEmojiSkinTone", data.activeSkinTone);
    }
    const insertAtCursor = () => {
      if (textInputId) {
        const element = document.getElementById(textInputId) as HTMLInputElement;
        if (element) {
          const cursorPosition = findCursorPosition(message, element.selectionStart);
          if (message && message.length >= 0 && cursorPosition >= 0 && cursorPosition < message.length) {
            setMessage(message.slice(0, cursorPosition) + data.emoji + message.slice(cursorPosition));
            return true;
          }
        }
      }
      return false;
    };

    if (data.emoji) {
      if (!insertAtCursor()) {
        setMessage(message => message + data.emoji);
      }
    }
    setShowEmojiPicker(false);
  };

  return (
    <div style={style}>
      <Picker
        onEmojiClick={onEmojiClick}
        defaultSkinTone={emojiSkinTone || undefined}
        emojiStyle={EmojiStyle.NATIVE}
        previewConfig={{ showPreview: false }}
        width={"300px"}
      />
    </div>
  );
}

function findCursorPosition(message: string, cursorPosition: number | null): number {
  if (!cursorPosition) {
    return 0;
  }
  const mentionRegex = /@\{\{(.+?)\}\}\[\[(\d+?)\]\]/g;
  const allMentions = [...message.matchAll(mentionRegex)].map(match => ({
    name: match[1],
    id: match[2],
    index: match.index
  }));

  let cleanMessageBeforeCursor = message.replace(mentionRegex, "@$1").slice(0, cursorPosition);

  const filteredMentions = allMentions
    .map(element => {
      const mentionString = `@${element.name}`;
      if (cleanMessageBeforeCursor.includes(mentionString)) {
        cleanMessageBeforeCursor = cleanMessageBeforeCursor.replace(mentionString, "");
        return element;
      }
    })
    .filter(Boolean);

  return cursorPosition + 8 * filteredMentions.length + filteredMentions.reduce((a, e) => a + (e?.id?.length || 0), 0);
}
