import _ from "lodash";
import React from "react";
import defaultGoalImages from "../../data/defaultGoalImages";
import AnimalCard from "../AnimalCard";
import "./GoalIcon.css";

export function randomImageId() {
  return _.sample(defaultGoalImages.custom);
}

export default function GoalIcon({ imageId, small, children, theme, borderRadius = 12, padding = 8 }) {
  if (imageId < 100) {
    return (
      <AnimalCard imageId={imageId} small={small} theme={theme} borderRadius={borderRadius}>
        {children}
      </AnimalCard>
    );
  }
  if (theme === "TEEN") {
    theme = "manaBlue";
  } else {
    theme = "default";
  }
  const colors = defaultGoalImages[imageId][theme].colors;
  const imageSource = small ? defaultGoalImages[imageId][theme].icon : defaultGoalImages[imageId][theme].cover;

  return (
    <div
      className="container"
      style={{ backgroundImage: `linear-gradient(to bottom, ${colors[0]}, ${colors[1]})`, borderRadius }}
    >
      <div className={`imageContainer ${small ? "imageContainerSmall" : "imageContainerLarge"}`} style={{ padding }}>
        <img alt="" src={imageSource} className="image" />
      </div>
      {children}
    </div>
  );
}
