import client from "../apollo/client";
import Cookies from "universal-cookie";
import config from "../config/env";
import { fireEvent } from "../events/Events";

const cookies = new Cookies();

const AuthService = {
  isAuthenticated: () => {
    return !!cookies.get("manateeAuthToken");
  },
  role: () => {
    return sessionStorage.getItem("role") || false;
  },
  login: async account => {
    try {
      if (cookies.get("manateeAuthToken")) {
        logout();
      }
      fireEvent("loginBegin");

      const twoFAtoken = cookies.get("manatee2FA");
      const response = await fetch(`${config.env.REACT_APP_API}/login`, {
        body: JSON.stringify({
          ...account
        }),
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-2FA-token": twoFAtoken ? `${twoFAtoken}` : ""
        }
      });
      if (!response.ok) {
        const e = await response.json();
        throw Error(e.error);
      }
      const user = await response.json();
      sessionStorage.setItem("userId", user.id);
      sessionStorage.setItem("authyStatus", user.authyStatus);
      sessionStorage.setItem("role", user.accountType);
      sessionStorage.setItem("user", user.firstName);
      sessionStorage.setItem("phone", user.phone);
      sessionStorage.setItem("familyId", user.familyId);
      sessionStorage.setItem("email", account.username);
      if (user.token) {
        setAuthTokenCookie(user.token);
        fireEvent("loginComplete");
      }
      return user;
    } catch (e) {
      throw Error(e);
    }
  },
  submitPhone: async account => {
    try {
      const response = await fetch(`${config.env.REACT_APP_API}/submitPhone`, {
        body: JSON.stringify({
          ...account
        }),
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        return {
          success: false,
          message: "Error submitting phone number. Please contact support@getmanatee.com"
        };
      }
      const data = await response.json();
      if (!data.success) {
        return {
          success: false,
          message: "Error submitting phone number. Please contact support@getmanatee.com"
        };
      }
      sessionStorage.setItem("phone", data.phone);
      return {
        success: true
      };
    } catch (e) {
      throw Error(e);
    }
  },
  requestSMS: async account => {
    try {
      const response = await fetch(`${config.env.REACT_APP_API}/requestSMS`, {
        body: JSON.stringify({
          ...account
        }),
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        return {
          success: false,
          message: "Error requesting code. Please contact support@getmanatee.com"
        };
      }
      const data = await response.json();
      if (data.success) {
        return {
          success: true,
          message: "A new verification code was sent to phone number: " + data.phone
        };
      } else {
        return {
          success: false,
          message: data.message
        };
      }
    } catch (e) {
      throw Error(e);
    }
  },
  verifyTOTP: async account => {
    try {
      const response = await fetch(`${config.env.REACT_APP_API}/verifyTOTP`, {
        body: JSON.stringify({
          ...account
        }),
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      const result = await response.json();
      if (!response.ok || result === "invalid") {
        return {
          success: false,
          message: "Invalid code. Please try receiving a new code. Contact support@getmanatee.com if problem persists."
        };
      }
      if (result.message) {
        return {
          success: false,
          message: result.message
        };
      } else {
        sessionStorage.setItem("userId", result.id);
        sessionStorage.setItem("role", result.accountType);
        sessionStorage.setItem("user", result.firstName);
        if (result.token) {
          setAuthTokenCookie(result.token);
        }
        result.success = true;
        return result;
      }
    } catch (e) {
      throw Error(e);
    }
  },
  register: async data => {
    try {
      const response = await fetch(`${config.env.REACT_APP_API}/register`, {
        body: JSON.stringify({
          ...data
        }),
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      const result = await response.json();
      if (result.message) {
        return result;
      }
    } catch (e) {
      throw Error("Error calling User API");
    }
  },
  getEmailRegistrationOptions: async email => {
    try {
      const data = JSON.stringify({
        email
      });
      const response = await fetch(`${config.env.REACT_APP_API}/registration/isCareCoordinationEnabled`, {
        body: data,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      const result = await response.json();
      return result;
    } catch (e) {
      throw Error("Error calling isCareCoordinationEnabled");
    }
  },
  forgotPassword: async data => {
    try {
      const response = await fetch(`${config.env.REACT_APP_API}/forgotPassword`, {
        body: JSON.stringify({
          ...data
        }),
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        const result = await response.json();
        return {
          success: false,
          message: result.message
        };
      }
      const result = await response.json();
      if (result.message) {
        return result;
      }
    } catch (e) {
      throw Error("Error calling forgotPassword");
    }
  },
  passwordReset: async data => {
    try {
      const response = await fetch(`${config.env.REACT_APP_API}/parentPasswordReset`, {
        body: JSON.stringify({
          ...data
        }),
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        const result = await response.json();
        return {
          success: false,
          message: result.message
        };
      }
      const result = await response.json();
      if (result.message) {
        return result;
      }
    } catch (e) {
      throw Error("Error calling password reset");
    }
  },
  logout: logout,
  setAuthToken(token, options) {
    setAuthTokenCookie(token, options);
  },
  removeAuthToken: removeAuthToken
};

function logout() {
  const authToken = cookies.get("manateeAuthToken");
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("role");

  fireEvent("logoutBegin");

  client.clearStore();

  if (authToken) {
    try {
      fetch(`${config.env.REACT_APP_API}/logout`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": `${authToken}`
        }
      });
    } catch (error) {
      if (process.env.NODE_ENV === "development") {
        console.error("Error logging out", error);
      }
    }
  }
  removeAuthToken();
}

function removeAuthToken() {
  fireEvent("authTokenRemoval");
  cookies.remove("manateeAuthToken", { path: "/" });
}

export function setAuthTokenCookie(authToken, options) {
  const prodOrStaging = config.env.REACT_APP_ENV === "production" || config.env.REACT_APP_ENV === "staging";

  fireEvent("authTokenSet");

  cookies.set("manateeAuthToken", authToken, {
    path: "/",
    secure: prodOrStaging,
    sameSite: true,
    expires: new Date(Date.now() + (options?.expiresInMillis || 25920000000))
  });
}

export default AuthService;
