import React, { useContext, useState, useEffect } from "react";
import cx from "classnames";
import { useActions } from "tiny-atom/react/hooks";
import { Clock, Power, BookOpen, Link, Mail, UserPlus } from "react-feather";
import "./Sidebar.css";
import { Query } from "@apollo/client/react/components";
import { THERAPIST_GET_INVITES } from "../queries";
import { MANATEE_CARE_PROVIDER } from "../utils/constants";
import { MessagingContext } from "../context/MessagingContext";
import useHasNarrowScreen from "../hooks/useHasNarrowScreen";
import Modal from "./Modal";
import { useLocation, useNavigate } from "react-router-dom";

const isDevEnv = process.env.NODE_ENV === "development";

const inviteEnabled = isDevEnv;
const connectEnabled = isDevEnv;

/* eslint-disable no-unused-vars */
const Sidebar = React.forwardRef((props, ref) => {
  /* eslint-enable no-unused-vars */

  const { pathname } = useLocation();

  const { logout } = useActions();
  const [isCollapsed, setCollapsed] = useState();
  const isActive = p => pathname === p;

  const { unreadCount } = useContext(MessagingContext);
  const hasNarrowScreen = useHasNarrowScreen();
  const [modal, setModal] = useState(null);
  const navigate = useNavigate();

  localStorage.setItem("scrollTop", 0);

  useEffect(() => {
    const navExpandedPreference = localStorage.getItem("navExpansion");
    if (navExpandedPreference === "expanded") {
      setCollapsed(false);
    } else if (navExpandedPreference === "collapsed") {
      setCollapsed(true);
    } else {
      setCollapsed(hasNarrowScreen);
    }
  }, [hasNarrowScreen]);

  const manateeCareUser = sessionStorage.getItem("providerName") === MANATEE_CARE_PROVIDER;

  const promptLogout = () => {
    setModal("logout-modal");
  };

  const modalTitleStyle = {
    color: "#000000",
    fontFamily: "Avenir Next",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "30px",
    lineHeight: "40px",
    textAlign: "center",
    marginBottom: "15px"
  };

  return (
    <div ref={ref} className={cx("Sidebar", { "Sidebar--mini": isCollapsed })}>
      <div className="Sidebar-wrapper">
        <div className="Sidebar-content">
          <div className="Sidebar-logo Sidebar-f"></div>
          <div className="Sidebar-logoMini Sidebar-m"></div>

          <div className="Sidebar-profile">
            <div className="Sidebar-name">{!isCollapsed && sessionStorage.getItem("user")}</div>
          </div>

          <div className={hasNarrowScreen ? "Sidebar-nav Sidebar-flex-narrow" : "Sidebar-nav Sidebar-flex-wide"}>
            <span className="Sidebar-spacer" />
            <a
              className={cx("Sidebar-link", {
                "Sidebar--active": isActive("/myInbox")
              })}
              onClick={() => navigate("/myInbox")}
            >
              <Mail color="white" className="Sidebar-f" size={20} />
              <Mail color="white" className="Sidebar-m" size={20} />
              <span className="Sidebar-f">Inbox</span>
              {!isCollapsed && unreadCount > 0 && (
                <div
                  className={cx("counter", {
                    counterActive: isActive("/myInbox"),
                    counterInactive: !isActive("/myInbox")
                  })}
                >
                  {unreadCount}
                </div>
              )}
            </a>
            <a
              className={cx("Sidebar-link", {
                "Sidebar--active": isActive("/patients")
              })}
              onClick={() => navigate("/patients")}
            >
              <BookOpen color="white" className="Sidebar-f" size={20} />
              <BookOpen color="white" className="Sidebar-m" size={20} />
              <span className="Sidebar-f">Clients</span>
            </a>
            {inviteEnabled && (
              <>
                <a
                  className={cx("Sidebar-link", {
                    "Sidebar--active": isActive("/invite")
                  })}
                  onClick={() => navigate("/invite")}
                >
                  <UserPlus color="white" className="Sidebar-f" size={20} />
                  <UserPlus color="white" className="Sidebar-m" size={20} />
                  <span className="Sidebar-f">Invite</span>
                </a>
                <Query query={THERAPIST_GET_INVITES}>
                  {({ loading, error, data }) => {
                    if (loading) return <></>;
                    if (error) return <p></p>;
                    if (data?.therapistInvites?.length) {
                      return (
                        <a
                          className={cx("Sidebar-link", {
                            "Sidebar--active": isActive("/pendingInvites")
                          })}
                          onClick={() => navigate("/pendingInvites")}
                        >
                          <Clock color="white" className="Sidebar-f" size={20} />
                          <span className="Sidebar-f">Pending Invites</span>
                          {isCollapsed ? (
                            <Clock color="white" className="Sidebar-m" size={20} />
                          ) : (
                            <div
                              className={cx("counter", {
                                counterActive: isActive("/pendingInvites"),
                                counterInactive: !isActive("/pendingInvites")
                              })}
                            >
                              {data.therapistInvites.length}
                            </div>
                          )}
                        </a>
                      );
                    }
                    return null;
                  }}
                </Query>
              </>
            )}
            {connectEnabled && manateeCareUser && (
              <a
                className={cx("Sidebar-link", {
                  "Sidebar--active": isActive("/connection")
                })}
                onClick={() => navigate("/connection")}
              >
                <Link color="white" className="Sidebar-f" size={20} />
                <Link color="white" className="Sidebar-m" size={20} />
                <span className="Sidebar-f">Connect Family</span>
              </a>
            )}
            <div
              className={cx("Sidebar-link", {
                "Sidebar--active": isActive("/logout")
              })}
              onClick={promptLogout}
            >
              <Power color="white" className="Sidebar-f" size={20} />
              <Power color="white" className="Sidebar-m" size={20} />
              <span className="Sidebar-f">Logout</span>
            </div>
          </div>

          <div
            className="Sidebar-collapse Sidebar-f"
            onClick={() => {
              setCollapsed(true);
              localStorage.setItem("navExpansion", "collapsed");
            }}
          >
            ← collapse
          </div>
          <div
            className="Sidebar-expand Sidebar-m"
            onClick={() => {
              setCollapsed(false);
              localStorage.setItem("navExpansion", "expanded");
            }}
          >
            →
          </div>
        </div>
      </div>
      {modal && (
        <Modal
          narrowScreen={hasNarrowScreen}
          title="Are you sure you want to logout?"
          titleStyle={modalTitleStyle}
          centeredMainPanel
          button1Label="Logout"
          button1Theme="red"
          onButton1Press={() => {
            logout();
          }}
          button2Label="Cancel"
          button2Theme="lightBlue"
          onButton2Press={() => {
            setModal(null);
          }}
        />
      )}
    </div>
  );
});

Sidebar.displayName = "Sidebar";

export default Sidebar;
