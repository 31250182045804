import { useReducer } from "react";

export function useGoalSelector() {
  const [state, dispatch] = useReducer(
    (state, action) => {
      const goal = action.goal;
      const key = getGoalKey(goal);
      const selectedGoals = new Map(state.selectedGoals);
      const selectedGoalNames = new Set(state.selectedGoalNames);
      switch (action.type) {
        case "add":
          selectedGoals.set(key, action.goal);
          selectedGoalNames.add(action.goal.name);
          return { selectedGoals, selectedGoalNames };
        case "remove":
          selectedGoals.delete(key);
          selectedGoalNames.delete(action.goal.name);
          return { selectedGoals, selectedGoalNames };
        default:
          throw Error(`Unknown action type ${action.type}`);
      }
    },
    {
      selectedGoals: new Set(),
      selectedGoalNames: new Set()
    }
  );

  function getGoalKey(goal) {
    return `${goal.id}.${goal.goalType}.${goal.objective}`;
  }

  return {
    getSelectedGoals: () => [...state.selectedGoals.values()],
    isGoalSelected: goal => state.selectedGoals.has(getGoalKey(goal)),
    isGoalUnselectable: goal => state.selectedGoalNames.has(goal.name),
    selectGoal: goal => dispatch({ type: "add", goal: goal }),
    unselectGoal: goal => dispatch({ type: "remove", goal: goal })
  };
}
