import _ from "lodash";
import caregiversIcon from "../assets/caregiver-icon.png";
import clientIcon from "../assets/client-icon.png";

export const URL_REGEX = /((?:(?:https?|ftp):\/\/)?[\w/\-?=%.]+\.[\w/\-&?=%]+)/i;
export const MENTION_REGEX = /(@{{.*?}}\[\[.*?]])/;
export const MENTION_DISPLAY_REGEX = /@{{(.*?)}}/;
export const MENTION_ID_REGEX = /\[\[(.*?)]]/;

export const TEMPLATE_PLACEHOLDER_REGEX = /{.*?}/g;

export function findConversationFromUserIds(conversations, userIds) {
  return conversations.find(convo => _.isEqual(userIds.sort(), convo.users.map(user => user.id).sort()));
}

export function getDisplayLabelFromComversation(conversation, userId) {
  if (conversation.type === "CARE_TEAM_INTERNAL" || conversation.type === "CARE_TEAM_EXTERNAL") {
    return "Care Coordination";
  }
  return conversation.users
    .filter(user => user.id !== userId)
    .map(user => user.firstName + " " + user.lastName)
    .join(", ");
}

export function getIconFromConversation(conversation) {
  let icon = caregiversIcon;
  conversation.users.forEach(user => {
    if (user.accountType === "CHILD" || user.accountType === "STANDALONE_CLIENT") {
      icon = clientIcon;
    }
  });
  return icon;
}

export function getTypeLabelFromConversaton(conversation) {
  if (conversation.type === "CARE_TEAM_INTERNAL" || conversation.type === "CARE_TEAM_EXTERNAL") {
    return conversation.users.map(user => user.firstName + " " + user.lastName).join(", ");
  }
  return "Direct Message";
}

export function getClientsLabel(clients) {
  return clients
    .map(client => {
      return `${client.firstName} ${client.lastName}`;
    })
    .join(", ");
}

export function getParentsLabel(adultUsers) {
  if (!adultUsers || !adultUsers.length) {
    return;
  }
  return adultUsers
    .filter(participant => {
      return participant.accountType === "PARENT";
    })
    .map(parent => {
      return `${parent.firstName} ${parent.lastName}`;
    })
    .join(", ");
}

export function normalizeMentionsInMessage(message) {
  const parts = message.split(MENTION_REGEX);
  const newMessage = parts
    .map(part => {
      if (MENTION_REGEX.test(part)) {
        return part.replace("{{", "").replace("}}", "").replace(MENTION_ID_REGEX, "");
      } else {
        return part;
      }
    })
    .flat();
  return newMessage;
}
