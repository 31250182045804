import React, { useState } from "react";
import Modal from "../../components/Modal";
import { TextField } from "@shopify/polaris";

export default function GoalAchievedAddNoteModal({ onMarkAchieved, onCancel, isVisible }) {
  const [note, setNote] = useState("");

  const inviteNoteInput = () => {
    return (
      <div style={{ paddingTop: "20px" }}>
        <TextField
          type="TextInput"
          placeholder="e.g. I'm so proud of you, when you ______!"
          onChange={value => setNote(value)}
          value={note}
          multiline={3}
          maxLength={800}
        />
      </div>
    );
  };

  return (
    <div>
      <div className="Modal-overlay" />
      <Modal
        hidden={!isVisible()}
        centeredAbsolute
        title="Give a compliment"
        titleStyle={titleStyle}
        content={inviteNoteInput()}
        button1Label="Confirm Approval"
        onButton1Press={() => onMarkAchieved(note)}
        button1Theme="green"
        button2Label="Cancel"
        onButton2Press={() => onCancel()}
        button2Theme="gray"
      />
    </div>
  );
}

const titleStyle = {
  color: "#000000",
  fontFamily: "Avenir Next",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "20px",
  lineHeight: "27px",
  textAlign: "center"
};
