import React, { useEffect } from "react";
import "./GoalManagement.css";
import { useActions } from "tiny-atom/react/hooks";
import GoalItemSeparator from "./GoalItemSeparator";
import ParentGoalItem from "./ParentGoalItem";
import defaultParentGoals from "../data/defaultParentGoals";
import { setGoalManagementScrollHeight } from "../utils/goalUtils";
import { GoalItemEditButton } from "./GoalItem";
import { TouchableOpacity } from "./react-native";
import { useQuery } from "@apollo/client";
import { THERAPIST_GET_CUSTOM_PARENT_GOALS } from "../queries";

export default function ParentGoalManagement({ parent, showParentGoal, openCreateGoal }) {
  const { setCurrentScreen } = useActions();
  setCurrentScreen("ParentGoalManagement");

  const { data } = useQuery(THERAPIST_GET_CUSTOM_PARENT_GOALS);

  useEffect(() => {
    let goalManagement = document.getElementsByClassName("GoalManagement")[0];
    if (goalManagement) {
      let savedHeight = localStorage.getItem("scrollTop");
      goalManagement.scrollTop = savedHeight;
    }
  });

  const getAssignedGoals = () => {
    const nonDeletedActiveGoals = parent?.goals || [];
    const assignedGoals = nonDeletedActiveGoals.map(goal => {
      let formattedAssignedGoal = { ...goal };
      return formattedAssignedGoal;
    });
    return assignedGoals;
  };

  const getDefaultGoals = () => {
    return defaultParentGoals.map(defaultGoal => {
      let returnDefaultGoal = { ...defaultGoal };
      const matchingGoalNames = assignedGoals.filter(activeGoal => {
        return defaultGoal.name === activeGoal.name;
      });
      if (matchingGoalNames.length > 0) {
        returnDefaultGoal.matchesAssignedGoal = true;
        returnDefaultGoal.reminderTime1 = matchingGoalNames[0].reminderTime1;
        returnDefaultGoal.reminderTime2 = matchingGoalNames[0].reminderTime2;
        returnDefaultGoal.id = matchingGoalNames[0].id;
      }
      returnDefaultGoal.isDefault = true;
      return returnDefaultGoal;
    });
  };

  const getCustomGoals = () => {
    const customGoals = data?.goals || [];
    return customGoals.map(customGoal => {
      return {
        ...customGoal,
        matchesAssignedGoal: assignedGoals.some(assignedGoal => assignedGoal.name === customGoal.name)
      };
    });
  };

  const getGoalObjectives = () => {
    const goalObjectivesWithDupes = defaultParentGoals.map(goal => goal.objective);
    return [...new Set(goalObjectivesWithDupes)];
  };

  const organizeDefaultGoalsUnderObjectives = (goalObjectives, defaultGoals) => {
    return goalObjectives.map(goalObjective => {
      const objectiveGoals = defaultGoals.filter(goal => goal.objective === goalObjective);
      return {
        objective: goalObjective,
        goals: objectiveGoals
      };
    });
  };

  const goToParentGoal = goal => {
    setGoalManagementScrollHeight();
    showParentGoal(goal);
  };

  const assignedGoals = getAssignedGoals();
  const goalObjectives = getGoalObjectives();
  const defaultGoals = getDefaultGoals();
  const customGoals = getCustomGoals();
  const organizedDefaultGoals = organizeDefaultGoalsUnderObjectives(goalObjectives, defaultGoals);

  const allGoals = [
    {
      objective: "Currently assigned",
      goals: [...assignedGoals]
    },
    {
      objective: "Create new",
      type: "new"
    },
    {
      objective: "Custom Goals",
      goals: customGoals
    },
    ...organizedDefaultGoals
  ];

  return (
    <div style={styles.goalManagement} className="GoalManagement">
      {allGoals.map((goalSection, idx) => {
        return (
          <div key={goalSection.objective}>
            <GoalItemSeparator
              label={goalSection.objective}
              key={goalSection.objective}
              fontStyle={idx === 0 ? styles.currentlyAssignedHeader : false}
              removeSeparatorLine={idx === 0}
              containerStyle={idx === 0 ? { marginTop: 0 } : {}}
            />
            {goalSection.type === "new" && (
              <TouchableOpacity
                onPress={() => {
                  setGoalManagementScrollHeight();
                  openCreateGoal();
                }}
              >
                <div style={styles.newGoalItem}>
                  <div>Create a new custom goal</div>
                  <GoalItemEditButton
                    onPress={() => {
                      setGoalManagementScrollHeight();
                      openCreateGoal();
                    }}
                  />
                </div>
              </TouchableOpacity>
            )}
            {goalSection.goals &&
              goalSection.goals.map(goal => {
                return (
                  <ParentGoalItem
                    key={goal.id}
                    parent={parent}
                    goal={goal}
                    showParentGoal={goal => goToParentGoal(goal)}
                  />
                );
              })}
          </div>
        );
      })}
    </div>
  );
}

const styles = {
  goalManagement: { padding: "20px", overflow: "auto" },
  currentlyAssignedHeader: {
    fontSize: "26px",
    padding: "20px",
    paddingLeft: "0px",
    fontWeight: "400",
    marginTop: "0px !important"
  },
  newGoalItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid rgba(145, 158, 171, 0.25)",
    borderRadius: "20px",
    marginBottom: "12px",
    padding: "20px"
  }
};
