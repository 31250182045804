import { useMemo } from "react";
import { useAtom } from "tiny-atom/react/hooks";
import { User } from "../model/userModels";

export default function useFamilyMembers() {
  const data = useAtom(state => state.patientData);

  const familyMembers: User[] = useMemo(() => {
    return [data?.childDetails, ...(data?.childParents || [])];
  }, [data]);

  const parents: User[] = useMemo(() => {
    return data?.childParents || [];
  }, [data]);

  return { familyMembers, parents };
}
