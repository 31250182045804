/** @format */

import React, { useState } from "react";
import { BlockStack, TextField, Button } from "@shopify/polaris";
import { Formik } from "formik";
import AuthService from "../services/AuthService";
import { useAtom, useActions } from "tiny-atom/react/hooks";
import Modal from "../components/Modal";
import "./Register.css";
import FormErrorMessage from "../components/FormErrorMessage";
import { isAllWhitespace, containsEmoji } from "../utils/formUtils";
import { useBeforeunload } from "react-beforeunload";

export default function PasswordReset() {
  const [code, setCode] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const { navigate, logEvent, setCurrentScreen, showModal, hideModal } = useActions();
  const error = useAtom(state => state.error);
  const isErrorModalVisible = useAtom(state => state.isErrorModalVisible);
  const consultCallback = useAtom(state => state.consultCallback);
  const [loading, setLoading] = useState(false);

  useBeforeunload(consultCallback ? event => event.preventDefault() : null);

  async function handleSubmit() {
    try {
      setLoading(true);
      const result = await AuthService.passwordReset({
        data: {
          code: code,
          password: password
        }
      });
      if (result && result.message) {
        showModal(result.message);
        logEvent("webPasswordResetFailed");
      } else {
        showModal("Your password has successfuly been reset.");
        logEvent("webPasswordResetSuccess");
        if (consultCallback) {
          await consultCallback(password);
        } else {
          navigate({ pathname: "/login" });
        }
      }
    } catch (e) {
      logEvent("webPasswordResetFailed");
    } finally {
      setLoading(false);
    }
  }

  setCurrentScreen("WebPasswordResetScreen");
  return (
    <div className="Register">
      <div
        style={{
          pointerEvents: isErrorModalVisible ? "none" : undefined
        }}
      >
        <Formik
          initialValues={{
            code: "",
            password: "",
            confirmPassword: ""
          }}
          validate={() => {
            let errors = {};
            if (!code) {
              errors.code = "Code Required";
            } else if (!password || isAllWhitespace(password)) {
              errors.password = "Required";
            } else if (containsEmoji(password)) {
              errors.password = "Cannot contain emojis";
            } else if (
              !window.location.href.includes("localhost") &&
              !window.location.href.includes("10.0.2.2") &&
              password.trim().length < 8
            ) {
              errors.password = "Must be at least 8 characters long";
            } else if (!confirmPassword) {
              errors.confirmPassword = "Confirm Password Required";
            } else if (password !== confirmPassword) {
              errors.confirmPassword = "Passwords must match";
            }
            return errors;
          }}
          onSubmit={() => {
            if (!loading) {
              handleSubmit();
            }
          }}
        >
          {({ handleSubmit }) => (
            <form
              className="Main-form"
              onSubmit={handleSubmit}
              onKeyDown={e => {
                if (e.key === "Enter" && !loading) {
                  handleSubmit();
                }
              }}
            >
              <div className="Manatee-logo" />
              <BlockStack gap={"200"}>
                <TextField placeholder="Code" onChange={setCode} type="code" name="code" value={code} />
                <FormErrorMessage name="code" component="div" />
                <TextField
                  placeholder="Password"
                  onChange={setPassword}
                  type="password"
                  name="password"
                  value={password}
                />
                <FormErrorMessage name="password" component="div" />
                <TextField
                  placeholder="Confirm Password"
                  onChange={setConfirmPassword}
                  type="password"
                  name="password"
                  value={confirmPassword}
                />
                <FormErrorMessage name="confirmPassword" component="div" />
                <Button fullWidth variant="primary" submit disabled={loading} onClick={handleSubmit}>
                  Submit
                </Button>
                <Button
                  fullWidth
                  disabled={loading}
                  onClick={() => navigate({ pathname: consultCallback ? "/consult" : "/login" })}
                >
                  Cancel
                </Button>
                <div className="Register-forgot">
                  Having trouble? Drop us a note at <a href="mailto:support@getmanatee.com">support@getmanatee.com</a>
                </div>
              </BlockStack>
            </form>
          )}
        </Formik>
      </div>
      <Modal
        title={error ? error.message : ""}
        hidden={!isErrorModalVisible}
        onButton1Press={hideModal}
        fullscreen={true}
      />
    </div>
  );
}
