import React from "react";
import { StyleSheet, css } from "aphrodite";
import FormsCompletedImage from "../assets/forms-completed.png";

export default function FormsCompleted() {
  return (
    <div className={css(styles.container)}>
      <div className={css(styles.header)} />
      <img src={FormsCompletedImage} className={css(styles.image)} />
      <p className={css(styles.text)}>
        Your documents
        <br />
        are all set!
      </p>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFFFFF"
  },
  image: {
    width: 250,
    height: 250,
    objectFit: "contain"
  },
  text: {
    color: "#1A2579",
    fontSize: 24,
    lineHeight: 1.4,
    fontWeight: 700,
    paddingTop: 20,
    textAlign: "center",
    marginBottom: "30%"
  },
  header: {
    backgroundColor: "#E5EEFF",
    height: 55,
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0
  }
});
