import React, { useState } from "react";
import { TouchableOpacity } from "../react-native";
import { StyleSheet, css } from "aphrodite";

export type TabData = { text: string; onPress: () => void; icon: string; getIndicator?: () => number };

export function TabulatedHeader({ tabs, defaultIndex }: { tabs: TabData[]; defaultIndex: number }) {
  const [selectedIndex, setSelectedIndex] = useState(defaultIndex);
  return (
    <div className={tabs.length === 1 ? css(styles.singleTabHeader) : css(styles.header)}>
      {tabs.map((tab, i) => {
        const indicator = tab.getIndicator && tab.getIndicator();
        return (
          <TouchableOpacity
            key={i}
            style={tabs.length === 1 ? { cursor: "auto" } : {}}
            onPress={() => {
              tab.onPress();
              setSelectedIndex(i);
            }}
          >
            <div
              className={css([
                styles.tab,
                selectedIndex === i ? (tabs.length === 1 ? styles.singleTab : styles.activeTab) : styles.inactiveTab
              ])}
            >
              {tab.icon && <img alt="tab" src={tab.icon} className={css(styles.icon)} />}
              <span>{tab.text}</span>
              {!!indicator && indicator > 0 && <p className={css(styles.indicator)}>{indicator}</p>}
            </div>
          </TouchableOpacity>
        );
      })}
    </div>
  );
}

const styles = StyleSheet.create({
  singleTabHeader: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#FFFFFF",
    height: 48,
    overflow: "hidden",
    borderBottom: "0.1rem solid #dfe3e8"
  },
  header: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#ECECEC",
    minHeight: 48,
    overflow: "hidden",
    overflowX: "auto"
  },
  singleTab: {
    fontWeight: 600,
    color: "#000000",
    background: "#FFFFFF",
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2
  },
  activeTab: {
    fontWeight: 500,
    textShadow: "0px 0px 1px #000000",
    color: "#000000",
    background: "#FFFFFF",
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
    boxShadow: "0px 2px 8px 0 #C0C0C0",
    zIndex: 70
  },
  inactiveTab: {
    fontWeight: 500,
    color: "#000000",
    background: "#ECECEC",
    boxShadow: "0px 2px 8px 0 #D0D0D0",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4
  },
  tab: {
    fontFamily: "Helvetica",
    fontStyle: "normal",
    fontSize: "18px",
    lineHeight: "27px",
    whiteSpace: "nowrap",
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 20px 10px 20px"
  },
  icon: {
    height: 24,
    width: 24,
    marginBottom: 4,
    marginLeft: 10,
    marginRight: 10
  },
  indicator: {
    borderRadius: 22,
    height: 22,
    lineHeight: "22px",
    marginLeft: 8,
    paddingLeft: 12,
    paddingRight: 12,
    backgroundColor: "#38D4DE",
    color: "#ffffff",
    fontWeight: 700,
    fontSize: 13
  }
});
