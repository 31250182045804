import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { useCallback, useEffect } from "react";
import { GET_CONVERSATIONS } from "../queries";
import usePollBasedOnBrowserTabFocus from "./usePollBasedOnBrowserTabFocus";

const pollIntervalMillis = 30000;

export default function useConversations({ familyId, asUserId, messageCount, skip = false } = {}) {
  const getOptions = props => ({
    userIds: undefined,
    messageCount: Number.isInteger(props?.messageCount) ? props.messageCount : 1,
    familyIds: props?.familyId ? [props.familyId] : undefined,
    providerId: undefined,
    asUserId: props?.asUserId
  });

  const { data, error, refetch, startPolling, stopPolling, loading } = useQuery(GET_CONVERSATIONS, {
    variables: {
      options: getOptions({ familyId, asUserId, messageCount })
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    skip
  });

  const fetchConversations = useCallback(
    props => {
      if (props?.familyId || props?.asUserId) {
        refetch({ options: getOptions(props) });
      }
    },
    [refetch]
  );

  useEffect(() => {
    fetchConversations({ familyId, asUserId, messageCount });
  }, [familyId, asUserId, messageCount, fetchConversations]);

  usePollBasedOnBrowserTabFocus(startPolling, stopPolling, pollIntervalMillis, refetch);

  const conversations = useMemo(() => data?.conversations || [], [data?.conversations]);
  const unreadCount = useMemo(() => conversations.filter(convo => !convo.read).length, [conversations]);

  return { conversations, refetch, unreadCount, loading, fetchConversations, error };
}
