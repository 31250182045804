import React, { useEffect, useMemo, useState } from "react";
import { useActions, useAtom } from "tiny-atom/react/hooks";
import "./Conversation.css";
import { useMutation, useQuery } from "@apollo/client";
import { GET_CONVERSATIONS, MARK_CONVERSATION_READ } from "../../queries";
import usePollBasedOnBrowserTabFocus from "../../hooks/usePollBasedOnBrowserTabFocus";
import ConversationHeader from "./conversation/ConversationHeader";
import ConversationMessages from "./conversation/ConversationMessages";
import ConversationNotes from "./conversation/ConversationNotes";
import ConversationActionBar from "./conversation/ConversationActionBar";
import Modal from "../Modal";
import { reportError } from "../../utils/errorUtils";
import { UserConversation } from "../../model/userModels";

const pollIntervalMillis = 10000;

type Props = {
  conversationId: number;
  conversation: UserConversation;
  asUserId: number;
  label: string;
  typeLabel: string;
  clientsLabel: string;
  parentsLabel: string;
  onExit: () => void;
};

export default function Conversation({
  conversationId,
  conversation: conversationInput,
  asUserId,
  label,
  typeLabel,
  clientsLabel,
  parentsLabel,
  onExit
}: Props) {
  const { logEvent } = useActions();
  const userId = useAtom(state => state.userId);

  const [showErrorModal, setShowErrorModal] = useState(false);

  const { data, loading, refetch, error, networkStatus, startPolling, stopPolling } = useQuery(GET_CONVERSATIONS, {
    variables: {
      options: {
        conversationIds: [conversationId],
        asUserId
      }
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network"
  });

  const [markConversationRead] = useMutation(MARK_CONVERSATION_READ);
  usePollBasedOnBrowserTabFocus(startPolling, stopPolling, pollIntervalMillis, refetch);

  const conversation: UserConversation = useMemo(
    () => (data?.conversations?.length > 0 ? data.conversations[0] : conversationInput ? conversationInput : {}),
    [conversationInput, data?.conversations]
  );

  useEffect(() => {
    if (userId === asUserId && conversation?.read === false) {
      markConversationRead({
        variables: {
          conversationId: conversation.id
        }
      })
        .then(() => {
          logEvent("markConversationRead");
        })
        .catch(err => {
          logEvent("markConversationReadError");
          reportError(`Conversation -- markConversationReadError -- ${err}`);
        });
    }
  }, [conversation, logEvent, markConversationRead, asUserId, userId]);

  useEffect(() => {
    if (error && !showErrorModal) {
      reportError(`Conversation -- getConversationQueryError -- ${error}`);
      logEvent(`getConversationQueryError`);
      setShowErrorModal(true);
    }
  }, [error, logEvent, showErrorModal]);

  return (
    <div className="conversation">
      <ConversationHeader
        conversation={conversation}
        label={label}
        typeLabel={typeLabel}
        clientsLabel={clientsLabel}
        parentsLabel={parentsLabel}
        onExit={onExit}
      />
      <ConversationMessages
        asUserId={asUserId}
        conversation={conversation}
        data={data}
        loading={loading}
        refetch={refetch}
        networkStatus={networkStatus}
      />
      {conversation?.permissions?.includes("WRITE") ? (
        <div className="conversation-action-bar-container">
          <ConversationNotes conversation={conversation} />
          <ConversationActionBar
            conversation={conversation}
            asUserId={asUserId}
            userId={userId}
            refetch={refetch}
            setShowErrorModal={setShowErrorModal}
            onExit={onExit}
          />
        </div>
      ) : (
        <div className={"readonly-message-info"}>This conversation is read-only</div>
      )}
      <Modal
        title="An error occurred"
        hidden={!showErrorModal}
        onButton1Press={() => {
          setShowErrorModal(false);
        }}
      />
    </div>
  );
}
