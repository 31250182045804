import left_speech_bubble from "../assets/goals/left_speech_bubble.png";
import speech_bubbles_purple from "../assets/goals/speech_bubbles_purple.png";
import speech_bubbles_yellow from "../assets/goals/speech_bubbles_yellow.png";
import slightly_smiling_face from "../assets/goals/slightly_smiling_face.png";
import slightly_smiling_faces_purple from "../assets/goals/slightly_smiling_faces_purple.png";
import slightly_smiling_faces_yellow from "../assets/goals/slightly_smiling_faces_yellow.png";
import skateboard from "../assets/goals/skateboard.png";
import fun_and_games from "../assets/goals/fun_and_games.png";
import handshake_bubble from "../assets/goals/handshake_bubble.png";
import handshake_bubble_blue from "../assets/goals/handshake_bubble_blue.png";
import handshake_bubble_yellow from "../assets/goals/handshake_bubble_yellow.png";
import confused_face_bubble from "../assets/goals/confused_face_bubble.png";
import confused_face_bubble_blue from "../assets/goals/confused_face_bubble_blue.png";
import confused_face_bubble_yellow from "../assets/goals/confused_face_bubble_yellow.png";
import phone from "../assets/goals/phone.png";
import phone_purple from "../assets/goals/phone_purple.png";
import phone_yellow from "../assets/goals/phone_yellow.png";
import listening from "../assets/goals/listening.png";
import listening_bubble_blue from "../assets/goals/listening_bubble_blue.png";
import listening_bubble_yellow from "../assets/goals/listening_bubble_yellow.png";
import pencil from "../assets/goals/pencil.png";
import open_book_purple from "../assets/goals/open_book_purple.png";
import open_book_yellow from "../assets/goals/open_book_yellow.png";
import stopwatch_icon from "../assets/goals/stopwatch_icon.png";
import stopwatch from "../assets/goals/stopwatch.png";
import pensive_breathing from "../assets/goals/pensive_breathing.png";
import pensive_breathing_blue from "../assets/goals/pensive_breathing_blue.png";
import pensive_breathing_yellow from "../assets/goals/pensive_breathing_yellow.png";
import headphones from "../assets/goals/headphones.png";
import music_blue from "../assets/goals/music_blue.png";
import music_yellow from "../assets/goals/music_yellow.png";
import relieved_face from "../assets/goals/relieved_face.png";
import relieved_sparkles_purple from "../assets/goals/relieved_sparkles_purple.png";
import relieved_sparkles_blue from "../assets/goals/relieved_sparkles_blue.png";
import sneaker from "../assets/goals/sneaker.png";
import sneakers_blue from "../assets/goals/sneakers_blue.png";
import sneakers_yellow from "../assets/goals/sneakers_yellow.png";
import happy_feet_blue from "../assets/goals/happy_feet_blue.png";
import happy_feet_yellow from "../assets/goals/happy_feet_yellow.png";
import starstruck_face from "../assets/goals/starstruck_face.png";
import starstruck_purple from "../assets/goals/starstruck_purple.png";
import starstruck_blue from "../assets/goals/starstruck_blue.png";
import squeeze_fists from "../assets/goals/squeeze_fists.png";
import squeeze_fists_blue from "../assets/goals/squeeze_fists_blue.png";
import squeeze_fists_yellow from "../assets/goals/squeeze_fists_yellow.png";
import paint_puzzle from "../assets/goals/paint_puzzle.png";
import paint_puzzle_purple from "../assets/goals/paint_puzzle_purple.png";
import paint_puzzle_yellow from "../assets/goals/paint_puzzle_yellow.png";
import sparkle_heart from "../assets/goals/sparkle_heart.png";
import sparkle_heart_purple from "../assets/goals/sparkle_heart_purple.png";
import sparkle_heart_yellow from "../assets/goals/sparkle_heart_yellow.png";
import star_bubble from "../assets/goals/star_bubble.png";
import stars_bubble_purple from "../assets/goals/stars_bubble_purple.png";
import stars_bubble_yellow from "../assets/goals/stars_bubble_yellow.png";
import question_bubble_chat from "../assets/goals/question_bubble_chat.png";
import question_bubble from "../assets/goals/question_bubble.png";
import grinning_and_smiling_face from "../assets/goals/grinning_and_smiling_face.png";
import grinning_smiling_sparkles_blue from "../assets/goals/grinning_smiling_sparkles_blue.png";
import grinning_smiling_sparkles_yellow from "../assets/goals/grinning_smiling_sparkles_yellow.png";
import envelope from "../assets/goals/envelope.png";
import postcard_writing_purple from "../assets/goals/postcard_writing_purple.png";
import postcard_writing_yellow from "../assets/goals/postcard_writing_yellow.png";
import dual_smiling_faces from "../assets/goals/dual_smiling_faces.png";
import dual_smiling_faces_purple from "../assets/goals/dual_smiling_faces_purple.png";
import dual_smiling_faces_blue from "../assets/goals/dual_smiling_faces_blue.png";
import worry_face_bubble from "../assets/goals/worry_face_bubble.png";
import heart_bubble from "../assets/goals/heart_bubble.png";
import sparkle_hearts_purple from "../assets/goals/sparkle_hearts_purple.png";
import sparkle_hearts_blue from "../assets/goals/sparkle_hearts_blue.png";
import broom from "../assets/goals/broom.png";
import chores_blue from "../assets/goals/chores_blue.png";
import chores_yellow from "../assets/goals/chores_yellow.png";
import writing from "../assets/goals/writing.png";
import writing_smiley_purple from "../assets/goals/writing_smiley_purple.png";
import writing_smiley_yellow from "../assets/goals/writing_smiley_yellow.png";
import shouting_head from "../assets/goals/shouting_head.png";
import shouting_stars_blue from "../assets/goals/shouting_stars_blue.png";
import shouting_stars_yellow from "../assets/goals/shouting_stars_yellow.png";
import good_letter from "../assets/goals/good_letter.png";
import good_letter_sparkle_purple from "../assets/goals/good_letter_sparkle_purple.png";
import good_letter_sparkle_yellow from "../assets/goals/good_letter_sparkle_yellow.png";
import muscle_zap from "../assets/goals/muscle_zap.png";
import muscle_zap_sparkle_purple from "../assets/goals/muscle_zap_sparkle_purple.png";
import muscle_zap_sparkle_yellow from "../assets/goals/muscle_zap_sparkle_yellow.png";
import cool_star from "../assets/goals/cool_star.png";
import cool_star_sparkle from "../assets/goals/cool_star_sparkle.png";
import calendar from "../assets/goals/calendar.png";
import calendar_sparkle_blue from "../assets/goals/calendar_sparkle_blue.png";
import calendar_sparkle_yellow from "../assets/goals/calendar_sparkle_yellow.png";
import bed from "../assets/goals/bed.png";
import sweet_dreams from "../assets/goals/sweet_dreams.png";
import relieved_dreams from "../assets/goals/relieved_dreams_sparkle.png";
import relieved_dreams_sparkle from "../assets/goals/relieved_dreams_sparkle.png";
import toothbrush from "../assets/goals/toothbrush.png";
import toothbrush_sparkle from "../assets/goals/toothbrush_sparkle.png";
import sudsy_hands from "../assets/goals/sudsy_hands.png";
import pray_for_soap_blue from "../assets/goals/pray_for_soap_blue.png";
import pray_for_soap_yellow from "../assets/goals/pray_for_soap_yellow.png";
import tub from "../assets/goals/tub.png";
import tub_sparkles_blue from "../assets/goals/tub_sparkles_blue.png";
import tub_sparkles_yellow from "../assets/goals/tub_sparkles_yellow.png";
import tree from "../assets/goals/tree.png";
import nature_rainbow_sparkle_purple from "../assets/goals/nature_rainbow_sparkle_purple.png";
import nature_rainbow_sparkle_blue from "../assets/goals/nature_rainbow_sparkle_blue.png";
import books from "../assets/goals/books.png";
import study_purple from "../assets/goals/study_purple.png";
import study_blue from "../assets/goals/study_blue.png";
import school from "../assets/goals/school.png";
import sun_school_blue from "../assets/goals/sun_school_blue.png";
import sun_school_yellow from "../assets/goals/sun_school_yellow.png";
import book from "../assets/goals/book.png";
import book_sparkle_purple from "../assets/goals/book_sparkle_purple.png";
import book_sparkle_yellow from "../assets/goals/book_sparkle_yellow.png";
import bedroom from "../assets/goals/bedroom.png";
import bedroom_sparkle_blue from "../assets/goals/bedroom_sparkle_blue.png";
import bedroom_sparkle_yellow from "../assets/goals/bedroom_sparkle_yellow.png";
import placesetting from "../assets/goals/placesetting.png";
import placesetting_sparkle from "../assets/goals/placesetting_sparkle.png";
import soap_plate from "../assets/goals/soap_plate.png";
import weekly_chores_sparkle_purple from "../assets/goals/weekly_chores_sparkle_purple.png";
import weekly_chores_sparkle_yellow from "../assets/goals/weekly_chores_sparkle_yellow.png";
import water_cup from "../assets/goals/water_cup.png";
import magic_water_purple from "../assets/goals/magic_water_purple.png";
import magic_water_yellow from "../assets/goals/magic_water_yellow.png";
import sun from "../assets/goals/sun.png";
import sun_sparkle_blue from "../assets/goals/sun_sparkle_blue.png";
import sun_sparkle_yellow from "../assets/goals/sun_sparkle_yellow.png";
import star from "../assets/goals/star.png";
import star_sparkle_blue from "../assets/goals/star_sparkle_blue.png";
import star_sparkle_yellow from "../assets/goals/star_sparkle_yellow.png";
import rainbow from "../assets/goals/rainbow.png";
import rainbow_sparkle from "../assets/goals/rainbow_sparkle.png";
import feet_sounds from "../assets/goals/feet_sounds.png";

export default {
  custom: [151, 152, 153],
  101: {
    // Start a conversation with someone - anyone!
    default: {
      icon: left_speech_bubble,
      cover: speech_bubbles_purple,
      colors: ["#FFF7B0", "#F6A17C"]
    },
    manaBlue: {
      icon: left_speech_bubble,
      cover: speech_bubbles_yellow,
      colors: ["#1A2579", "#1A2579"]
    }
  },
  102: {
    // Take turns sharing something about your day with a friend
    default: {
      icon: slightly_smiling_face,
      cover: slightly_smiling_faces_purple,
      colors: ["#F4B3DA", "#E18DFE"]
    },
    manaBlue: {
      icon: slightly_smiling_face,
      cover: slightly_smiling_faces_yellow,
      colors: ["#2562D8", "#2562D8"]
    }
  },
  103: {
    // Take the lead to make plans with a friend
    default: {
      icon: skateboard,
      cover: fun_and_games,
      colors: ["#ACFFFF", "#BCDBFF"]
    },
    manaBlue: {
      icon: skateboard,
      cover: fun_and_games,
      colors: ["#BBD2FF", "#BBD2FF"]
    }
  },
  104: {
    // Introduce yourself to someone new at school
    default: {
      icon: handshake_bubble,
      cover: handshake_bubble_blue,
      colors: ["#D4FFB1", "#B0FFF6"]
    },
    manaBlue: {
      icon: handshake_bubble,
      cover: handshake_bubble_yellow,
      colors: ["#1A2579", "#1A2579"]
    }
  },
  105: {
    // Practice telling a friend they've upset you
    default: {
      icon: confused_face_bubble,
      cover: confused_face_bubble_blue,
      colors: ["#FFF7B0", "#F6A17C"]
    },
    manaBlue: {
      icon: confused_face_bubble,
      cover: confused_face_bubble_yellow,
      colors: ["#2562D8", "#2562D8"]
    }
  },
  106: {
    // Connect with loved ones through a video or phone call
    default: {
      icon: phone,
      cover: phone_purple,
      colors: ["#F4B3DA", "#E18DFE"]
    },
    manaBlue: {
      icon: phone,
      cover: phone_yellow,
      colors: ["#2562D8", "#2562D8"]
    }
  },
  107: {
    // Connect with loved ones through a video or phone call
    default: {
      icon: listening,
      cover: listening_bubble_blue,
      colors: ["#FFF7B0", "#F6A17C"]
    },
    manaBlue: {
      icon: listening,
      cover: listening_bubble_yellow,
      colors: ["#1A2579", "#1A2579"]
    }
  },
  108: {
    // Write in your journal
    default: {
      icon: pencil,
      cover: open_book_purple,
      colors: ["#F4B3DA", "#E18DFE"]
    },
    manaBlue: {
      icon: pencil,
      cover: open_book_yellow,
      colors: ["#2562D8", "#2562D8"]
    }
  },
  109: {
    // Take 10-15min of “self-care” time as a break in the day
    default: {
      icon: stopwatch_icon,
      cover: stopwatch,
      colors: ["#ACFFFF", "#BCDBFF"]
    },
    manaBlue: {
      icon: stopwatch_icon,
      cover: stopwatch,
      colors: ["#BBD2FF", "#BBD2FF"]
    }
  },
  110: {
    // Practice your breathing exercises
    default: {
      icon: pensive_breathing,
      cover: pensive_breathing_blue,
      colors: ["#D4FFB1", "#B0FFF6"]
    },
    manaBlue: {
      icon: pensive_breathing,
      cover: pensive_breathing_yellow,
      colors: ["#1A2579", "#1A2579"]
    }
  },
  111: {
    // Listen to music
    default: {
      icon: headphones,
      cover: music_blue,
      colors: ["#FFF7B0", "#F6A17C"]
    },
    manaBlue: {
      icon: headphones,
      cover: music_yellow,
      colors: ["#2562D8", "#2562D8"]
    }
  },
  112: {
    // Listen to or watch a meditation video
    default: {
      icon: relieved_face,
      cover: relieved_sparkles_purple,
      colors: ["#F4B3DA", "#E18DFE"]
    },
    manaBlue: {
      icon: relieved_face,
      cover: relieved_sparkles_blue,
      colors: ["#BBD2FF", "#BBD2FF"]
    }
  },
  113: {
    // Exercise or move your body
    default: {
      icon: sneaker,
      cover: sneakers_blue,
      colors: ["#ACFFFF", "#BCDBFF"]
    },
    manaBlue: {
      icon: sneaker,
      cover: sneakers_yellow,
      colors: ["#1A2579", "#1A2579"]
    }
  },
  114: {
    // Blast some music and dance
    default: {
      icon: feet_sounds,
      cover: happy_feet_blue,
      colors: ["#D4FFB1", "#B0FFF6"]
    },
    manaBlue: {
      icon: feet_sounds,
      cover: happy_feet_yellow,
      colors: ["#2562D8", "#2562D8"]
    }
  },
  115: {
    // Spend 20 min doing something that makes you smile
    default: {
      icon: starstruck_face,
      cover: starstruck_purple,
      colors: ["#FFF7B0", "#F6A17C"]
    },
    manaBlue: {
      icon: starstruck_face,
      cover: starstruck_blue,
      colors: ["#BBD2FF", "#BBD2FF"]
    }
  },
  116: {
    // Squeeze your fists then relax them 3 times
    default: {
      icon: squeeze_fists,
      cover: squeeze_fists_blue,
      colors: ["#ACFFFF", "#BCDBFF"]
    },
    manaBlue: {
      icon: squeeze_fists,
      cover: squeeze_fists_yellow,
      colors: ["#1A2579", "#1A2579"]
    }
  },
  117: {
    // Build or create something to help you relax
    default: {
      icon: paint_puzzle,
      paint_puzzle_purple,
      colors: ["#F4B3DA", "#E18DFE"]
    },
    manaBlue: {
      icon: paint_puzzle,
      cover: paint_puzzle_yellow,
      colors: ["#2562D8", "#2562D8"]
    }
  },
  118: {
    // Spend at least 20 minutes hanging out with your family
    default: {
      icon: sparkle_heart,
      cover: sparkle_heart_purple,
      colors: ["#F4B3DA", "#E18DFE"]
    },
    manaBlue: {
      icon: sparkle_heart,
      cover: sparkle_heart_yellow,
      colors: ["#1A2579", "#1A2579"]
    }
  },
  119: {
    // Share with your parent(s) 1 thing you learned in therapy
    default: {
      icon: star_bubble,
      cover: stars_bubble_purple,
      colors: ["#FFF7B0", "#F6A17C"]
    },
    manaBlue: {
      icon: star_bubble,
      cover: stars_bubble_yellow,
      colors: ["#2562D8", "#2562D8"]
    }
  },
  120: {
    // Ask 1 question to someone in your family
    default: {
      icon: question_bubble_chat,
      cover: question_bubble,
      colors: ["#ACFFFF", "#BCDBFF"]
    },
    manaBlue: {
      icon: question_bubble_chat,
      cover: question_bubble,
      colors: ["#BBD2FF", "#BBD2FF"]
    }
  },
  121: {
    // Take turns sharing something about your day with family
    default: {
      icon: grinning_and_smiling_face,
      cover: grinning_smiling_sparkles_blue,
      colors: ["#D4FFB1", "#B0FFF6"]
    },
    manaBlue: {
      icon: grinning_and_smiling_face,
      cover: grinning_smiling_sparkles_yellow,
      colors: ["#1A2579", "#1A2579"]
    }
  },
  122: {
    // Write a letter or a postcard to someone you care about
    default: {
      icon: envelope,
      cover: postcard_writing_purple,
      colors: ["#FFF7B0", "#F6A17C"]
    },
    manaBlue: {
      icon: envelope,
      cover: postcard_writing_yellow,
      colors: ["#2562D8", "#2562D8"]
    }
  },
  123: {
    // Do a fun activity with your sibling(s)
    default: {
      icon: dual_smiling_faces,
      cover: dual_smiling_faces_purple,
      colors: ["#F4B3DA", "#E18DFE"]
    },
    manaBlue: {
      icon: dual_smiling_faces,
      cover: dual_smiling_faces_blue,
      colors: ["#BBD2FF", "#BBD2FF"]
    }
  },
  124: {
    // Talk to someone about what worries you
    default: {
      icon: worry_face_bubble,
      cover: worry_face_bubble,
      colors: ["#ACFFFF", "#BCDBFF"]
    },
    manaBlue: {
      icon: worry_face_bubble,
      cover: worry_face_bubble,
      colors: ["#1A2579", "#1A2579"]
    }
  },
  125: {
    // Connect with loved ones through a video or phone call
    default: {
      icon: phone,
      cover: phone_purple,
      colors: ["#F4B3DA", "#E18DFE"]
    },
    manaBlue: {
      icon: phone,
      cover: phone_yellow,
      colors: ["#2562D8", "#2562D8"]
    }
  },
  126: {
    // Go around the table and share what you’re grateful for
    default: {
      icon: heart_bubble,
      cover: sparkle_hearts_purple,
      colors: ["#FFF7B0", "#F6A17C"]
    },
    manaBlue: {
      icon: heart_bubble,
      cover: sparkle_hearts_blue,
      colors: ["#BBD2FF", "#BBD2FF"]
    }
  },
  127: {
    // Find something helpful to do around the house
    default: {
      icon: broom,
      cover: chores_blue,
      colors: ["#D4FFB1", "#B0FFF6"]
    },
    manaBlue: {
      icon: broom,
      cover: chores_yellow,
      colors: ["#1A2579", "#1A2579"]
    }
  },
  129: {
    // Write down 1 thing you like about yourself
    default: {
      icon: writing,
      cover: writing_smiley_purple,
      colors: ["#F4B3DA", "#E18DFE"]
    },
    manaBlue: {
      icon: writing,
      cover: writing_smiley_yellow,
      colors: ["#1A2579", "#1A2579"]
    }
  },
  130: {
    // Give yourself a shoutout
    default: {
      icon: shouting_head,
      cover: shouting_stars_blue,
      colors: ["#ACFFFF", "#BCDBFF"]
    },
    manaBlue: {
      icon: shouting_head,
      cover: shouting_stars_yellow,
      colors: ["#2562D8", "#2562D8"]
    }
  },
  133: {
    // Write yourself a letter like you're your best friend
    default: {
      icon: good_letter,
      cover: good_letter_sparkle_purple,
      colors: ["#FFF7B0", "#F6A17C"]
    },
    manaBlue: {
      icon: good_letter,
      cover: good_letter_sparkle_yellow,
      colors: ["#1A2579", "#1A2579"]
    }
  },
  134: {
    // Do your 'Power Pose' and hold it for 5 seconds!
    default: {
      icon: muscle_zap,
      cover: muscle_zap_sparkle_purple,
      colors: ["#F4B3DA", "#E18DFE"]
    },
    manaBlue: {
      icon: muscle_zap,
      cover: muscle_zap_sparkle_yellow,
      colors: ["#2562D8", "#2562D8"]
    }
  },
  135: {
    // Try something outside of your comfort zone
    default: {
      icon: cool_star,
      cover: cool_star_sparkle,
      colors: ["#ACFFFF", "#BCDBFF"]
    },
    manaBlue: {
      icon: cool_star,
      cover: cool_star_sparkle,
      colors: ["#BBD2FF", "#BBD2FF"]
    }
  },
  136: {
    // Set time to plan for the week
    default: {
      icon: calendar,
      cover: calendar_sparkle_blue,
      colors: ["#D4FFB1", "#B0FFF6"]
    },
    manaBlue: {
      icon: calendar,
      cover: calendar_sparkle_yellow,
      colors: ["#1A2579", "#1A2579"]
    }
  },
  137: {
    // Go to bed early and get 8+ hours of sleep
    default: {
      icon: bed,
      cover: sweet_dreams,
      colors: ["#FFF7B0", "#F6A17C"]
    },
    manaBlue: {
      icon: bed,
      cover: sweet_dreams,
      colors: ["#1A2579", "#1A2579"]
    }
  },
  138: {
    // Follow a relaxing bedtime routine
    default: {
      icon: relieved_dreams,
      cover: relieved_dreams_sparkle,
      colors: ["#F4B3DA", "#E18DFE"]
    },
    manaBlue: {
      icon: relieved_dreams,
      cover: relieved_dreams_sparkle,
      colors: ["#2562D8", "#2562D8"]
    }
  },
  139: {
    // Brush your teeth
    default: {
      icon: toothbrush,
      cover: toothbrush_sparkle,
      colors: ["#ACFFFF", "#BCDBFF"]
    },
    manaBlue: {
      icon: toothbrush,
      cover: toothbrush_sparkle,
      colors: ["#BBD2FF", "#BBD2FF"]
    }
  },
  140: {
    // Wash your hands
    default: {
      icon: sudsy_hands,
      cover: pray_for_soap_blue,
      colors: ["#D4FFB1", "#B0FFF6"]
    },
    manaBlue: {
      icon: sudsy_hands,
      cover: pray_for_soap_yellow,
      colors: ["#1A2579", "#1A2579"]
    }
  },
  141: {
    // Take a shower/bath
    default: {
      icon: tub,
      cover: tub_sparkles_blue,
      colors: ["#FFF7B0", "#F6A17C"]
    },
    manaBlue: {
      icon: tub,
      cover: tub_sparkles_yellow,
      colors: ["#2562D8", "#2562D8"]
    }
  },
  142: {
    // Spend some time outside
    default: {
      icon: tree,
      cover: nature_rainbow_sparkle_purple,
      colors: ["#F4B3DA", "#E18DFE"]
    },
    manaBlue: {
      icon: tree,
      cover: nature_rainbow_sparkle_blue,
      colors: ["#BBD2FF", "#BBD2FF"]
    }
  },
  144: {
    // Finish your homework
    default: {
      icon: books,
      cover: study_purple,
      colors: ["#FFF7B0", "#F6A17C"]
    },
    manaBlue: {
      icon: books,
      cover: study_blue,
      colors: ["#BBD2FF", "#BBD2FF"]
    }
  },
  145: {
    // Get ready for school tomorrow
    default: {
      icon: school,
      cover: sun_school_blue,
      colors: ["#ACFFFF", "#BCDBFF"]
    },
    manaBlue: {
      icon: school,
      cover: sun_school_yellow,
      colors: ["#BCDBFF", "#BCDBFF"]
    }
  },
  146: {
    // Read for at least 20 minutes
    default: {
      icon: book,
      cover: book_sparkle_purple,
      colors: ["#F4B3DA", "#E18DFE"]
    },
    manaBlue: {
      icon: book,
      cover: book_sparkle_yellow,
      colors: ["#1A2579", "#1A2579"]
    }
  },
  147: {
    // Clean up your bedroom
    default: {
      icon: bedroom,
      cover: bedroom_sparkle_blue,
      colors: ["#ACFFFF", "#BCDBFF"]
    },
    manaBlue: {
      icon: bedroom,
      cover: bedroom_sparkle_yellow,
      colors: ["#2562D8", "#2562D8"]
    }
  },
  148: {
    // Finish your meal
    default: {
      icon: placesetting,
      cover: placesetting_sparkle,
      colors: ["#D4FFB1", "#B0FFF6"]
    },
    manaBlue: {
      icon: placesetting,
      cover: placesetting_sparkle,
      colors: ["#BBD2FF", "#BBD2FF"]
    }
  },
  149: {
    // Do your weekly chores
    default: {
      icon: soap_plate,
      cover: weekly_chores_sparkle_purple,
      colors: ["#FFF7B0", "#F6A17C"]
    },
    manaBlue: {
      icon: soap_plate,
      cover: weekly_chores_sparkle_yellow,
      colors: ["#1A2579", "#1A2579"]
    }
  },
  150: {
    // Drink water!
    default: {
      icon: water_cup,
      cover: magic_water_purple,
      colors: ["#F4B3DA", "#E18DFE"]
    },
    manaBlue: {
      icon: water_cup,
      cover: magic_water_yellow,
      colors: ["#2562D8", "#2562D8"]
    }
  },
  151: {
    default: {
      icon: sun,
      cover: sun_sparkle_blue,
      colors: ["#C4D6F9", "#A1ABFF"]
    },
    manaBlue: {
      icon: sun,
      cover: sun_sparkle_yellow,
      colors: ["#1A2579", "#1A2579"]
    }
  },
  152: {
    default: {
      icon: star,
      cover: star_sparkle_blue,
      colors: ["#C4D6F9", "#A1ABFF"]
    },
    manaBlue: {
      icon: star,
      cover: star_sparkle_yellow,
      colors: ["#1A2579", "#1A2579"]
    }
  },
  153: {
    default: {
      icon: rainbow,
      cover: rainbow_sparkle,
      colors: ["#C4D6F9", "#A1ABFF"]
    },
    manaBlue: {
      icon: rainbow,
      cover: rainbow_sparkle,
      colors: ["#1A2579", "#1A2579"]
    }
  }
};
