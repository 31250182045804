import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Widget } from "@typeform/embed-react";
import { getTimeZone, getTimeZoneOffset } from "../../utils/dateUtils";
import CareScreenerPasswordInput from "./CareScreenerPasswordInput";
import { useActions, useAtom } from "tiny-atom/react/hooks";
import AppointmentScheduler from "../appointments/AppointmentScheduler";
import BookedConsult from "../appointments/BookedConsult";
import { useGlobalContext } from "../../context/GlobalComponentsContext";
import "./CareScreener.css";
import config from "../../config/env";
import { logEvent } from "../../actions";
import { reportError } from "../../utils/errorUtils";
import ReactGA from "react-ga4";
import { useBeforeunload } from "react-beforeunload";
import NoConsultNextSteps from "./NoConsultNextSteps";
import CareScreenerService from "../../services/CareScreenerService";
import InsuranceEligibility from "./InsuranceEligibility";
import CareScreenerLoading, { LoadingTextVariant } from "./CareScreenerLoading";
import { css } from "aphrodite";
import { screenerStyles } from "./careScreenerStyles";
import Flex from "../core/Flex";
import DirectIntakeRequested from "./DirectIntakeRequested";
import { User } from "../../model/userModels";
import views from "./screenerViewsNames";
import CareScreenerProgress from "./CareScreenerProgress";

const qualifiesRef = { current: false };
const accountCreatedRef = { current: false };
const directIntakeQualifiedRef = { current: false };
const careCoordinatorRef: { current: User | undefined } = { current: undefined };

type CareScreenerProps = {
  typeformId: string;
  typeformPath: "consult" | "lyra" | "pcscreener" | "newaccount" | "newaccountandconsult";
  allowAppointmentScheduling?: boolean;
  insuranceEligiblityCheckEnabled?: boolean;
  accountCreationPredicate?: {
    tfQuestionRefShown: string;
    tfQuestionRefShownAlt?: string;
  };
};

const loadingTextVariants: LoadingTextVariant[] = [
  { text: "Finishing up your account..." },
  { text: "Checking our calendars..." },
  { text: "Choosing your Care Coordinator..." },
  { text: "Almost there, hang tight!" }
];

export default function CareScreener({
  typeformId,
  typeformPath,
  allowAppointmentScheduling,
  accountCreationPredicate,
  insuranceEligiblityCheckEnabled
}: CareScreenerProps) {
  const { setConsultView, setCareScreenerSubmissionId, setTypeformResponseId } = useActions();
  const [scheduledAppointment, setScheduledAppointment] = useState();
  const { showError } = useGlobalContext();
  const consultView = useAtom(state => state.consultView) || views.typeform;
  const careScreenerSubmissionId = useAtom(state => state.careScreenerSubmissionId);
  const typeformResponseId = useAtom(state => state.typeformResponseId);

  const showProgress = typeformPath === "consult";

  useEffect(() => {
    logEvent("careScreenerShown", { path: typeformPath });
  }, [typeformPath]);

  useBeforeunload(
    [views.nextSteps, views.consultBooked, views.directIntakeRequested].includes(consultView)
      ? null
      : event => event.preventDefault()
  );

  const isAccountCreationEnabled = useCallback(
    () => !accountCreationPredicate || qualifiesRef.current,
    [accountCreationPredicate]
  );

  const handleTypeformSubmitted = useCallback(
    async (payload: { responseId: string }) => {
      logEvent("careScreenerSubmission", { path: typeformPath });
      if (payload?.responseId) {
        try {
          setTypeformResponseId(payload.responseId);
          const result = await CareScreenerService.createCareScreenerSubmission({
            typeformId: typeformId,
            typeformPath: typeformPath,
            typeformResponseId: payload.responseId
          });
          if ((typeformPath === "consult" || typeformPath === "pcscreener") && process.env.NODE_ENV === "production") {
            ReactGA.event({ action: "typeform_submit", category: "marketing_funnel" });
          }
          setCareScreenerSubmissionId(result.id);

          if (isAccountCreationEnabled()) {
            if (insuranceEligiblityCheckEnabled) {
              setConsultView(views.insuranceEligibility);
            } else {
              setConsultView(views.passwordInput);
            }
          }
        } catch (error) {
          reportError("handleTypeformSubmitted", error);
          showError("Something went wrong. Please contact support@getmanatee.com");
        }
      }
    },
    [
      insuranceEligiblityCheckEnabled,
      isAccountCreationEnabled,
      setCareScreenerSubmissionId,
      setConsultView,
      setTypeformResponseId,
      showError,
      typeformId,
      typeformPath
    ]
  );

  const handlePasswordSubmitted = async password => {
    try {
      setConsultView(views.loadingAccountCreation);
      const result = await CareScreenerService.completeCareScreener({
        careScreenerSubmissionId,
        typeformId: typeformId,
        typeformResponseId: typeformResponseId,
        password: password,
        timeZone: getTimeZone(),
        timeZoneOffset: getTimeZoneOffset()
      });
      logEvent("careScreenerCompletion", { path: typeformPath });

      if (result.directIntakeStatus === "QUALIFIED" || result.directIntakeStatus === "INTAKE_REQUESTED") {
        directIntakeQualifiedRef.current = true;
        careCoordinatorRef.current = result.careCoordinator;
        logEvent("directIntakeQualified");
      } else if (result.directIntakeStatus === "UNQUALIFIED") {
        logEvent("directIntakeUnqualified");
      } else if (result.directIntakeStatus === "NOT_APPLICABLE") {
        logEvent("directIntakeNotApplicable");
      }
      accountCreatedRef.current = true;
    } catch (error) {
      setConsultView(views.passwordInput);
      reportError(error);
      showError(error?.message);
    }
  };

  const handleQuestionChanges = useCallback(
    (event: { ref: string }) => {
      if (accountCreationPredicate) {
        if (
          event?.ref == accountCreationPredicate.tfQuestionRefShown ||
          (accountCreationPredicate.tfQuestionRefShownAlt &&
            event?.ref == accountCreationPredicate.tfQuestionRefShownAlt)
        ) {
          qualifiesRef.current = true;
        } else {
          qualifiesRef.current = false;
        }
      }
    },
    [accountCreationPredicate]
  );

  const handleAppointmentScheduled = appointment => {
    logEvent("appointmentScheduled", { path: typeformPath });
    if (process.env.NODE_ENV === "production") {
      ReactGA.event({ action: "consult_booked", category: "marketing_funnel" });
    }
    if (directIntakeQualifiedRef.current) {
      logEvent("consultBookedDirectIntakeQualified", { path: typeformPath });
    }
    logEvent("consultBooked", { path: typeformPath });
    setScheduledAppointment(appointment);
    setConsultView(views.consultBooked);
  };

  const handleCreateAccountsLoadingTextRotation = useCallback(() => {
    if (accountCreatedRef.current) {
      if (allowAppointmentScheduling) {
        if (directIntakeQualifiedRef.current && careCoordinatorRef.current) {
          setConsultView(views.directIntakeRequested);
        } else {
          setConsultView(views.scheduleConsult);
        }
      } else {
        setConsultView(views.nextSteps);
      }
    }
  }, [allowAppointmentScheduling, setConsultView]);

  const Typeform = useCallback(() => {
    return (
      <Widget
        onSubmit={handleTypeformSubmitted}
        onQuestionChanged={handleQuestionChanges}
        style={{
          height: "90vh",
          width: window.innerWidth < 768 ? 350 : 600
        }}
        id={typeformId}
        transitiveSearchParams={true}
      />
    );
  }, [handleQuestionChanges, handleTypeformSubmitted, typeformId]);

  const progressComponent = useMemo(() => <CareScreenerProgress currentView={consultView} />, [consultView]);

  return (
    <div className="consult-container">
      {consultView === views.loadingAccountCreation && (
        <Flex flexGrow center>
          <Flex flexShrink center className={css(screenerStyles.whiteContainerRoundedEdges, screenerStyles.padding)}>
            {showProgress && progressComponent}
            <CareScreenerLoading
              textVariants={loadingTextVariants}
              onTextRotation={handleCreateAccountsLoadingTextRotation}
            />
          </Flex>
        </Flex>
      )}
      {consultView === views.typeform && (
        <div className="column-centered">
          <Typeform />
        </div>
      )}
      {consultView === views.insuranceEligibility && !!(careScreenerSubmissionId && typeformResponseId) && (
        <div className="column-centered">
          <InsuranceEligibility
            careScreenerSubmission={{
              id: careScreenerSubmissionId,
              typeformId,
              typeformResponseId
            }}
            onContinue={() => {
              setConsultView(views.passwordInput);
            }}
            progressComponent={progressComponent}
          />
        </div>
      )}
      {consultView === views.passwordInput && (
        <div className="column-centered">
          <CareScreenerPasswordInput
            onPasswordSubmitted={handlePasswordSubmitted}
            consultPath={typeformPath}
            progressComponent={showProgress ? progressComponent : undefined}
          />
        </div>
      )}
      {consultView === views.scheduleConsult && (
        <AppointmentScheduler
          appointmentId={config.env.REACT_APP_CONSULT_APPT_TYPE_ID}
          appointmentUserId={undefined}
          appointmentContactMethod={"Phone Call"}
          availabilityRecurringInterval={undefined}
          availabilityRecurringCount={undefined}
          onAppointmentScheduled={handleAppointmentScheduled}
          careTeamMemberIds={careCoordinatorRef.current ? [careCoordinatorRef.current.id] : undefined}
          headerComponent={<Flex style={{ height: 20 }} />}
          style={{
            margin: "auto",
            marginTop: 30,
            marginBottom: 30,
            padding: 30
          }}
        />
      )}
      {consultView === views.consultBooked && <BookedConsult appointment={scheduledAppointment} />}
      {consultView === views.directIntakeRequested && !!careCoordinatorRef.current && (
        <DirectIntakeRequested
          careCoordinator={careCoordinatorRef.current}
          onBookConsultPressed={function (): void {
            logEvent("directIntakeBookConsultPressed");
            setConsultView(views.scheduleConsult);
          }}
        />
      )}
      {consultView === views.nextSteps && <NoConsultNextSteps typeformPath={typeformPath} />}
    </div>
  );
}
