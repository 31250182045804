import React, { useState } from "react";
import { BlockStack, Button, TextField } from "@shopify/polaris";
import AuthService from "../services/AuthService";
import { Formik } from "formik";
import { useAtom, useActions } from "tiny-atom/react/hooks";
import "./TwoFactorAuthSubmitCode.css";
import Modal from "../components/Modal";
import Cookies from "universal-cookie";
import FormErrorMessage from "../components/FormErrorMessage";

export default function TwoFactorAuthSubmitCode() {
  const [totp, setTOTP] = useState();
  const [loading, setLoading] = useState();
  const email = useAtom(state => state.email);
  const password = useAtom(state => state.password);
  const { navigate, logEvent, setCurrentScreen, showModal, hideModal } = useActions();
  const error = useAtom(state => state.error);
  const isErrorModalVisible = useAtom(state => state.isErrorModalVisible);

  async function handleSubmit() {
    try {
      setLoading(true);
      const result = await AuthService.verifyTOTP({
        userId: sessionStorage.getItem("userId"),
        email: email,
        password: password,
        totp: totp
      });
      if (!result.success) {
        showModal(result.message);
        logEvent("webTwoFactorAuthSubmitCodeFailed");
      }
      if (result.twoFAtoken) {
        storeCookie(result.twoFAtoken);
        navigate({ pathname: "/" });
        logEvent("twoFactorAuthPhoneSubmitSuccess");
      }
    } catch (e) {
      showModal("Error submitting verification code. Please contact support@getmanatee.com");
      logEvent("webTwoFactorAuthSubmitCodeFailed");
    } finally {
      setLoading(false);
    }
  }

  function storeCookie(twoFAToken) {
    const cookies = new Cookies();
    cookies.remove("manatee2FA");
    const prodOrStaging = !window.location.href.includes("localhost") && !window.location.href.includes("10.0.2.2");
    cookies.set("manatee2FA", twoFAToken, {
      path: "/",
      secure: prodOrStaging,
      sameSite: true,
      expires: new Date(Date.now() + 25920000000)
    });
  }

  async function requestSMS() {
    try {
      setLoading(true);
      const result = await AuthService.requestSMS({
        userId: sessionStorage.getItem("userId"),
        email: sessionStorage.getItem("email")
      });
      if (!result.success) {
        showModal(result.message);
      } else {
        showModal("Please check your phone for a new code. This may take a moment.");
      }
    } catch (e) {
      showModal("Error submitting verification code. Please contact support@getmanatee.com");
      logEvent("webTwoFactorAuthSubmitCodeFailed");
    } finally {
      setLoading(false);
    }
  }

  setCurrentScreen("TwoFactorAuthSubmitCodeScreen");
  const phone = sessionStorage.getItem("phone");
  return (
    <div className="TwoFactorAuthSubmitCode">
      <div
        style={{
          pointerEvents: isErrorModalVisible ? "none" : undefined
        }}
      >
        <Formik
          initialValues={{ totp: "" }}
          validate={() => {
            let errors = {};
            if (!totp) {
              errors.totp = "Required";
            } else if (!/^\d{6}$/i.test(totp)) {
              errors.totp = "Invalid verification code";
            }
            return errors;
          }}
          onSubmit={() => {
            handleSubmit();
          }}
        >
          {({ handleSubmit }) => (
            <form className="TwoFactorAuthSubmitCode-form" onSubmit={handleSubmit}>
              <div className="TwoFactorAuthSubmitCode-logo" />
              <div className="TwoFactorAuthSubmitCode-text">
                Please enter verification code we just messaged to {phone}
              </div>
              <BlockStack gap={"200"}>
                <TextField placeholder="Verification code" onChange={setTOTP} type="totp" name="totp" value={totp} />
                <FormErrorMessage name="totp" component="div" />
                <Button fullWidth variant="primary" submit disabled={loading} onClick={handleSubmit}>
                  Submit Code
                </Button>
                <Button fullWidth submit disabled={loading} onClick={() => requestSMS()}>
                  Receive New Code
                </Button>
                <Button fullWidth onClick={() => navigate({ pathname: "/login" })}>
                  Cancel
                </Button>
              </BlockStack>
            </form>
          )}
        </Formik>
      </div>
      <Modal
        title={error ? error.message : ""}
        hidden={!isErrorModalVisible}
        onButton1Press={hideModal}
        fullscreen={true}
      />
    </div>
  );
}
