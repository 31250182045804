import React, { useState } from "react";
import { BlockStack, TextField, Button, Select } from "@shopify/polaris";
import { Formik } from "formik";
import "./InviteDetails.css";
import { Mutation } from "@apollo/client/react/components";
import { THERAPIST_INVITE_USER, THERAPIST_GET_INVITES, THERAPIST_LINK_EXISTING } from "../queries";
import { logEvent } from "../actions";
import Modal from "../components/Modal";
import { useAtom, useActions } from "tiny-atom/react/hooks";
import InviteeGoalAssignment from "./InviteeGoals";
import FormErrorMessage from "../components/FormErrorMessage";
import chevronLeftImage from "../assets/chevron-left.png";
import { useMutation } from "@apollo/client";
import { graphQLError } from "../utils/errorUtils";
import useCareTeam from "../hooks/useCareTeam";
import _ from "lodash";

const languageOptions = [
  { label: "English", value: "en" },
  { label: "Spanish", value: "es" }
];

export default function InviteDetails() {
  const { navigate, setCurrentScreen, displayToast } = useActions();
  const inviteAccountType = useAtom(state => state.inviteAccountType);
  const [email, setEmail] = useState("");
  const [clientName, setClientName] = useState("");
  const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(undefined);
  const [language, setLanguage] = useState("en");
  const [primaryTherapistId, setPrimaryTherapistId] = useState(undefined);
  const [inviteId, setInviteId] = useState(null);
  const [displayLinkExistingModal, setDisplayLinkExistingModal] = useState(false);
  const [displayLinkExistingConfirmedModal, setDisplayLinkExistingConfirmedModal] = useState(false);
  const [linkedChildNames, setLinkedChildNames] = useState([]);
  const [errors, setErrors] = useState();
  const [linkExistingMutation] = useMutation(THERAPIST_LINK_EXISTING);
  const user = useAtom(state => state.user);

  const {
    error: errorCareTeam,
    therapists,
    parentCoaches,
    careCoordinationEnabled
  } = useCareTeam({ skip: user?.accountType !== "CARE_COORDINATOR" });
  if (errorCareTeam) {
    graphQLError();
  }

  const showTherapistSelection = careCoordinationEnabled && user?.accountType === "CARE_COORDINATOR";
  const selectTherapistOptions = [...therapists, ...parentCoaches].map(coTherapist => {
    return {
      label: `${coTherapist.firstName} ${coTherapist.lastName}`,
      value: coTherapist.id
    };
  });

  if (inviteAccountType === "PARENT") {
    setCurrentScreen("TherapistWebInviteFamilyScreen");
  } else {
    setCurrentScreen("TherapistWebInviteStandaloneClientScreen");
  }

  function handleLanguageSelectChange(language) {
    setLanguage(language);
  }

  function handlePrimaryTherapistSelectChange(therapistId) {
    setPrimaryTherapistId(parseInt(therapistId, 10));
  }

  function onSuccess() {
    logEvent("therapistWebInviteFamilySuccess");
    setEmail("");
    setNote("");
    setLoading(false);
    setSubmitted(true);
  }

  function onError(error) {
    setError(error?.message || "Something went wrong. Please try again later.");
    logEvent("therapistWebInviteFamilyFailed");
  }

  const getChevronImage = () => {
    return chevronLeftImage;
  };

  const navigateToInvitePage = () => {
    navigate({ pathname: "/invite" });
  };

  const getFamilyLinkedConfirmationMessage = () => {
    let message;
    if (!linkedChildNames.length) {
      message = "Parent(s) linked.\n\nNo children registered yet but they will be linked when they get registered.";
    } else if (linkedChildNames.length === 1) {
      message = `${linkedChildNames[0]} and the parent(s) have been linked.`;
    } else {
      const childNamesConcatenated = linkedChildNames.join(", ");
      message = `${childNamesConcatenated} and the parent(s) have been linked.\n\nIf a child shouldn't be linked, please contact support@getmanatee.com.`;
    }
    return message;
  };

  if (submitted && inviteId == null) {
    navigateToInvitePage();
  }

  return (
    <div>
      {submitted ? (
        <>{inviteId !== undefined && <InviteeGoalAssignment inviteId={inviteId} next={navigateToInvitePage} />}</>
      ) : (
        <div>
          <Formik
            initialValues={{ email: "", note: "", clientName: "" }}
            validate={() => {
              let errors = {};
              if (!clientName) {
                errors.clientName = "Required";
              } else if (!email) {
                errors.email = "Required";
              } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
                errors.email = "Invalid email address";
              }
              setErrors(errors);

              return errors;
            }}
            onSubmit={() => {
              setTimeout(() => {}, 400);
            }}
          >
            {({ handleSubmit, validateForm }) => (
              <div className="Invite">
                <form className="Invite-form" onSubmit={handleSubmit}>
                  <BlockStack gap={"200"}>
                    <a href="/invite">
                      <div className="back-button">
                        <img alt="back" height="14px" width="12px" src={getChevronImage()} />
                      </div>
                    </a>
                    <div className="invite-first-last-name Invite-label ">Client&apos;s first and last name</div>
                    <TextField
                      placeholder="Sally Smith"
                      onChange={clientName => {
                        setClientName(clientName);
                      }}
                      type="text"
                      name="clientName"
                      value={clientName}
                    />
                    <FormErrorMessage name="clientName" component="div" />
                    <div className="Invite-label">
                      {inviteAccountType === "PARENT" ? "Caregiver's email" : "Client's email"}
                    </div>
                    <TextField
                      placeholder="person@mail.net"
                      onChange={email => {
                        setEmail(email);
                      }}
                      type="email"
                      name="email"
                      value={email}
                    />
                    <FormErrorMessage name="email" component="div" />
                    {showTherapistSelection && (
                      <>
                        <div className="Invite-label">Therapist/Coach assigned</div>
                        <Select
                          options={selectTherapistOptions}
                          placeholder="choose care provider"
                          onChange={handlePrimaryTherapistSelectChange}
                          value={primaryTherapistId}
                          labelInline={true}
                          error={errors?.therapistSelection}
                        />
                      </>
                    )}
                    <div className="Invite-label">Invitation Language</div>
                    <Select
                      options={languageOptions}
                      onChange={handleLanguageSelectChange}
                      value={language}
                      labelInline={true}
                    />
                    <div className="Invite-label">Attach a note (optional)</div>
                    <TextField
                      name="note"
                      type="text"
                      placeholder="e.g. Hey, check out Manatee!"
                      onChange={note => {
                        setNote(note);
                      }}
                      multiline={inviteAccountType === "PARENT" ? 3 : true}
                      editable
                      maxLength={800}
                      value={note}
                    />
                    <Mutation
                      mutation={THERAPIST_INVITE_USER}
                      onCompleted={data => {
                        if (data?.therapistInviteUser === -1) {
                          setDisplayLinkExistingModal(true);
                        } else {
                          setInviteId(null);
                          if (data.therapistInviteUser != null) {
                            setInviteId(data.therapistInviteUser);
                          }
                          onSuccess();
                          displayToast({ text: "Invite Sent!" });
                          logEvent("therapistWebInviteSend");
                        }
                      }}
                      onError={error => onError(error)}
                      refetchQueries={[{ query: THERAPIST_GET_INVITES }]}
                    >
                      {mutation => (
                        <Button
                          id="Invite-submit"
                          fullWidth
                          variant="primary"
                          submit
                          disabled={loading}
                          onClick={async () => {
                            const validationErrors = await validateForm();
                            if (_.isEmpty(validationErrors)) {
                              mutation({
                                variables: {
                                  email: email,
                                  note: note,
                                  language: language,
                                  accountType: inviteAccountType,
                                  clientName: clientName,
                                  primaryTherapistId: primaryTherapistId
                                }
                              });
                            } else {
                              handleSubmit();
                            }
                          }}
                        >
                          Send Invite
                        </Button>
                      )}
                    </Mutation>
                  </BlockStack>
                </form>
                <Modal
                  title={"This parent email is already registered.\n\nWould you like to link the family now?"}
                  centeredMainPanel
                  hidden={!displayLinkExistingModal}
                  button1Label="Yes"
                  onButton1Press={async () => {
                    try {
                      const linkResult = await linkExistingMutation({
                        variables: {
                          parentEmail: email,
                          language: language,
                          primaryTherapistId: primaryTherapistId
                        }
                      });
                      const childNames =
                        linkResult?.data?.therapistLinkExistingFamily?.map(linkedChild => {
                          return `${linkedChild.firstName}`;
                        }) || [];
                      setLinkedChildNames(childNames);
                      setDisplayLinkExistingModal(false);
                      setDisplayLinkExistingConfirmedModal(true);
                      displayToast({ text: "Linked!" });
                      logEvent("therapistLinkExistingFamily");
                    } catch (e) {
                      setDisplayLinkExistingModal(false);
                      setError(`Error: ${JSON.stringify(e)}`);
                    }
                  }}
                  button2Label="Cancel"
                  onButton2Press={() => {
                    setDisplayLinkExistingModal(false);
                  }}
                />
                <Modal
                  title={getFamilyLinkedConfirmationMessage()}
                  centeredMainPanel
                  hidden={!displayLinkExistingConfirmedModal}
                  button1Label="Okay"
                  onButton1Press={() => {
                    setDisplayLinkExistingConfirmedModal(false);
                    onSuccess();
                  }}
                />
                <Modal
                  title={error}
                  hidden={!error}
                  onButton1Press={() => {
                    setError(undefined);
                  }}
                  fullscreen={true}
                />
              </div>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
}
