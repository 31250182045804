import React, { ReactNode } from "react";
import { InsuranceEstimate } from "../../../services/CareScreenerService";
import Flex from "../../core/Flex";
import { css } from "aphrodite";
import { screenerStyles } from "../careScreenerStyles";
import { insuranceStyles as styles } from "./insuranceStyles";

type Props = {
  insuranceEstimate: InsuranceEstimate | undefined;
  progressComponent: ReactNode;
  continueButton: ReactNode;
};

export default function InsuranceEligibleCoverage({ insuranceEstimate, progressComponent, continueButton }: Props) {
  const showRateDisclaimer = !insuranceEstimate?.memberObligationInCents != undefined;

  return (
    <Flex className={css(screenerStyles.whiteContainerRoundedEdges, screenerStyles.padding, screenerStyles.container)}>
      {progressComponent}
      <Flex flexGrow center>
        <p className={css(styles.blueText)}>Great news!</p>
        <p className={css(styles.title)}>You're covered.</p>
        <div className={css(styles.verticalSpacer)} />
        {!!insuranceEstimate && insuranceEstimate.memberObligationInCents != undefined && (
          <>
            <p className={css(styles.bodyText)}>
              Thanks for your patience! We’ve checked your
              {insuranceEstimate.planName ? ` plan with ${insuranceEstimate.planName}` : " insurance plan"}. Your
              estimated rate is:
            </p>
            <div className={css(styles.grayRoundedCorners)}>
              <p className={css(styles.sessionCostText)}>
                Regular 55-minute
                <br /> sessions <br />
              </p>
              <p className={css(styles.sessionPrice)}>
                ${convertCentsToDollars(insuranceEstimate.memberObligationInCents)}
              </p>
            </div>
            {insuranceEstimate.coinsurancePercent != undefined &&
              insuranceEstimate.coinsurancePercent > 0 &&
              insuranceEstimate.deductibleInCents != undefined &&
              insuranceEstimate.deductibleInCents > 0 &&
              withBenefitThresholdAmounts({
                thresholdAmountInCents: insuranceEstimate.deductibleInCents,
                amountInCentsUntilThresholdMet: insuranceEstimate.remainingDeductibleInCents,
                sessionCostInCentsBeforeThesholdMet: insuranceEstimate.memberObligationInCents,
                sessionCostInCentsAfterThesholdMet: insuranceEstimate.postDeductibleMemberObligationInCents,
                benefitDescription: "deductible"
              })}
            {insuranceEstimate.oopMaxInCents != undefined &&
              insuranceEstimate.oopMaxInCents > 0 &&
              withBenefitThresholdAmounts({
                thresholdAmountInCents: insuranceEstimate.oopMaxInCents,
                amountInCentsUntilThresholdMet: insuranceEstimate.remainingOopMaxInCents,
                sessionCostInCentsBeforeThesholdMet: insuranceEstimate.memberObligationInCents,
                sessionCostInCentsAfterThesholdMet: insuranceEstimate.postOopMaxMemberObligationInCents,
                benefitDescription: "out-of-pocket max"
              })}
            <p className={css(styles.bodyText)}>The hard part’s over - just a couple more steps.</p>
          </>
        )}
      </Flex>
      <Flex center bottom>
        {continueButton}
        {showRateDisclaimer && (
          <p className={css(styles.bodyText, styles.smallText)}>
            Rates are subject to change based on your insurance carrier's policies.
          </p>
        )}
      </Flex>
      {}
    </Flex>
  );
}

function withBenefitThresholdAmounts({
  thresholdAmountInCents,
  amountInCentsUntilThresholdMet,
  sessionCostInCentsAfterThesholdMet,
  sessionCostInCentsBeforeThesholdMet,
  benefitDescription
}: {
  thresholdAmountInCents: number | undefined;
  amountInCentsUntilThresholdMet: number | undefined;
  sessionCostInCentsBeforeThesholdMet: number | undefined;
  sessionCostInCentsAfterThesholdMet: number | undefined;
  benefitDescription: "out-of-pocket max" | "deductible";
}) {
  return (
    <>
      {amountInCentsUntilThresholdMet == undefined ? (
        <p className={css(styles.bodyText)}>We'll need to check your {benefitDescription} to see if it has been met.</p>
      ) : (
        <>
          {amountInCentsUntilThresholdMet === 0 && (
            <p className={css(styles.bodyText)}>
              You've met your {benefitDescription}
              {thresholdAmountInCents && thresholdAmountInCents > 0 ? (
                <> of {withBenefitAmountStyling(thresholdAmountInCents)}. </>
              ) : (
                ". "
              )}
            </p>
          )}
          {amountInCentsUntilThresholdMet > 0 &&
            sessionCostInCentsAfterThesholdMet != undefined &&
            sessionCostInCentsBeforeThesholdMet != undefined && (
              <p className={css(styles.bodyText)}>
                Once you’ve met your {benefitDescription}, your out-of-pocket responsibility will go from{" "}
                {withBenefitAmountStyling(sessionCostInCentsBeforeThesholdMet)}
                {" to "}
                {withBenefitAmountStyling(sessionCostInCentsAfterThesholdMet)}.
              </p>
            )}
        </>
      )}
    </>
  );
}

function withBenefitAmountStyling(amountInCents: number) {
  return <span className={css(styles.darkBlue, styles.bold)}>${convertCentsToDollars(amountInCents)}</span>;
}

function convertCentsToDollars(numberInCents: number) {
  if (numberInCents % 100 !== 0) {
    return Number(numberInCents / 100).toFixed(2);
  }
  return numberInCents / 100;
}
