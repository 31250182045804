import React from "react";
import moment from "moment";
import { getDayNames, getDaysOfMonth, getMonth } from "../utils/dateUtils";
import "./Calendar.css";
import CameraImg from "../assets/camera-icon-white.png";

function TouchableOpacity({ onPress, style, children }) {
  return (
    <div
      className="Calendar-touchableOpacity"
      onClick={onPress}
      style={{
        display: "flex",
        flexDirection: "column",
        cursor: "pointer",
        ...style
      }}
    >
      {children}
    </div>
  );
}

function View({ style, children }) {
  return <div style={{ display: "flex", flexDirection: "column", ...style }}>{children}</div>;
}

function Text({ style, children }) {
  return <span style={{ display: "flex", flexDirection: "column", ...style }}>{children}</span>;
}

class Day extends React.Component {
  render() {
    const {
      item: { date, isVisible },
      styleConfig: { highlight, backgroundImg }
    } = this.props;

    let fontColor = highlight === "white" ? "rgb(34, 34, 34)" : "white";

    const useImage = backgroundImg && backgroundImg === "camera";
    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <View
          style={{
            // flex: 1,
            alignItems: "center",
            justifyContent: "center",
            margin: "7px",
            height: "22px",
            width: "22px",
            borderRadius: "30px",
            backgroundColor: highlight
          }}
        >
          {useImage ? (
            <img style={{ height: 10, width: 13 }} src={CameraImg} alt="camera" />
          ) : (
            <Text
              style={{
                color: isVisible ? fontColor : "#d3d3d3",
                fontSize: "12px"
              }}
            >
              {date.getDate()}
            </Text>
          )}
        </View>
      </View>
    );
  }
}

class WeekColumns extends React.Component {
  renderWeekText(day, i) {
    return (
      <View key={i} style={{ flex: 1, alignItems: "center" }}>
        <Text
          style={{
            fontSize: "13px",
            fontWeight: "600",
            color: "rgb(145, 158, 171)"
          }}
        >
          {day}
        </Text>
      </View>
    );
  }

  render() {
    return (
      <View
        style={{
          flexDirection: "row",
          borderTop: "1px solid rgb(243, 243, 243)",
          borderBottom: "1px solid rgb(243, 243, 243)",
          padding: "10px 0"
        }}
      >
        {this.props.days.map((day, i) => this.renderWeekText(day, i))}
      </View>
    );
  }
}

class MonthTitle extends React.Component {
  render() {
    return (
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between"
          // alignItems: "center"
        }}
      >
        <TouchableOpacity onPress={this.props.prev} style={{ padding: "10px", paddingTop: "0" }}>
          <Text style={{ fontSize: "16px", color: "rgb(34, 34, 34)" }}>←</Text>
        </TouchableOpacity>
        <Text
          style={{
            textAlign: "center",
            fontSize: "14px",
            fontWeight: "600",
            color: "rgb(34, 34, 34)"
          }}
        >
          {this.props.name.toUpperCase()}
        </Text>
        <TouchableOpacity onPress={this.props.next} style={{ padding: "10px", paddingTop: "0" }}>
          <Text style={{ fontSize: "16px", color: "rgb(34, 34, 34)" }}>→</Text>
        </TouchableOpacity>
      </View>
    );
  }
}

class Calendar extends React.Component {
  constructor(props) {
    super();
    this.state = {
      date: props.date
    };
  }

  prev() {
    this.setState({
      date: moment(this.state.date).subtract(1, "month").toDate()
    });
  }

  next() {
    this.setState({
      date: moment(this.state.date).add(1, "month").toDate()
    });
  }

  render() {
    const { firstDayMonday } = this.props;
    const { name, monthNumber, year } = getMonth(this.state.date);
    const DAY_NAMES = getDayNames(firstDayMonday);
    const days = getDaysOfMonth(monthNumber, year, firstDayMonday);
    const weeks = [];

    while (days.length) {
      weeks.push(days.splice(0, 7));
    }

    return (
      <View>
        <MonthTitle name={name} prev={() => this.prev()} next={() => this.next()} />
        <WeekColumns days={DAY_NAMES} />
        {weeks.map((week, index) => (
          <View key={index} style={{ flexDirection: "row" }}>
            {week.map((day, index) => (
              <Day
                key={index}
                item={day}
                styleConfig={this.props.dayStyleConfigurator && this.props.dayStyleConfigurator(day.id)}
              />
            ))}
          </View>
        ))}
      </View>
    );
  }
}

Calendar.defaultProps = {
  firstDayMonday: false,
  date: new Date()
};

export default Calendar;
