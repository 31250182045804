import { Route } from "react-router-dom";
import ScheduleAppointment from "../pages/ScheduleAppointment";
import React, { ReactNode, useContext, useMemo } from "react";
import ScheduleIntake from "../pages/ScheduleIntake";
import { UserContext } from "../context/UserContext";
import AccountTypeRoutes from "./AccountTypeRoutes";
import { AccountType } from "../model/userModels";
import Forms from "../pages/Forms";

export default function CaregiverRoutes() {
  const { authenticated } = useContext(UserContext) || {};

  const routes: ReactNode = useMemo(
    () => (
      <>
        {authenticated && <Route path="/" element={<ScheduleIntake />} />}
        <Route path="/complete-forms" element={<Forms />} />
        <Route path="/schedule-appointment" element={<ScheduleAppointment />} />
        <Route path="/schedule-intake" element={<ScheduleIntake />} />
      </>
    ),
    [authenticated]
  );

  return <AccountTypeRoutes routeElements={routes} supportedAccountTypes={[AccountType.PARENT]} />;
}
