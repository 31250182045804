import React, { useContext } from "react";
import { FamilyAssessmentContext } from "../../context/FamilyAssessmentContext";
import "./Overview.css";
import ActivityFeed from "./overview/ActivityFeed";
import WeeklySurvey from "./overview/WeeklySurvey";
import OverviewStats from "./overview/OverviewStats";
import { AppContext } from "../../context/AppContext";
import SendAssessmentsButton from "../assessments/SendAssessmentsButton";
import useClient from "../../hooks/useClient";
import GuidingStar from "./overview/GuidingStar";

export default function Overview() {
  const { assignable, showSendAssessmentsButton } = useContext(FamilyAssessmentContext);
  const { user } = useContext(AppContext);
  const client = useClient();

  const showGuidingStar = client?.accountType === "CHILD";

  const showClientSurvey =
    assignable?.assessmentIdentifiers &&
    (assignable.assessmentIdentifiers.includes("bbk-client-survey") ||
      assignable.assessmentIdentifiers.includes("manatee-parent-survey"));

  return (
    <div className="overview">
      <div className="activity-feed-container">
        {showGuidingStar && <GuidingStar />}
        {showClientSurvey && <WeeklySurvey />}
        <ActivityFeed />
      </div>
      <div className="stats-container">
        {user?.accountType === "CARE_COORDINATOR" && showSendAssessmentsButton && (
          <div className="send-assessment-button-container">
            <SendAssessmentsButton />
          </div>
        )}
        <OverviewStats />
      </div>
    </div>
  );
}
