import { useCallback, useMemo } from "react";
import { assignParentGoal, deleteParentGoal, updateParentGoal } from "../utils/goalUtils";
import { useAtom } from "tiny-atom/react/hooks";

export default function useParentGoalActions() {
  const patientRefetch = useAtom(state => state.patientRefetch);

  const createAndAssignGoal = useCallback(
    async (parent, goal) => {
      if (parent) {
        const mutations = [];
        const payload = { ...goal };
        payload.createUnassignedCopy = true;
        mutations.push(assignParentGoal(parent.id, parent.timeZone, payload));
        await Promise.all(mutations);
      }
      await patientRefetch();
    },
    [patientRefetch]
  );

  const assignGoal = useCallback(
    async (parent, goal) => {
      if (parent) {
        const mutations = [];
        mutations.push(assignParentGoal(parent.id, parent.timeZone, goal));
        await Promise.all(mutations);
      }
      await patientRefetch();
    },
    [patientRefetch]
  );

  const deleteGoal = useCallback(
    async (parent, goal) => {
      deleteParentGoal(goal.id);
      await patientRefetch();
    },
    [patientRefetch]
  );

  const updateAssignedGoal = useCallback(
    async (parent, goal, update) => {
      updateParentGoal({ ...update, id: goal.id }, parent.timeZone);
      await patientRefetch();
    },
    [patientRefetch]
  );

  const parentActions = useMemo(
    () => ({
      createAndAssign: createAndAssignGoal,
      assign: assignGoal,
      delete: deleteGoal,
      update: updateAssignedGoal
    }),
    [createAndAssignGoal, assignGoal, deleteGoal, updateAssignedGoal]
  );

  return {
    parentActions
  };
}
