import React from "react";
import { reportError } from "../utils/errorUtils";
import ErrorLandingView from "./ErrorLandingView";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  async componentDidCatch(error, _errorInfo) {
    try {
      await reportError(error, _errorInfo?.componentStack);
    } catch (error2) {
      await reportError(`ErrorBoundary - 2nd log attempt: ${error}`, _errorInfo?.componentStack);
      await reportError(`ErrorBoundary - 2nd error caught while reporting error: ${error2}`);
    }
  }

  render() {
    if (this.state.hasError) {
      return <ErrorLandingView />;
    }
    return this.props.children;
  }
}
