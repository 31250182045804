import _ from "lodash";
import React, { useCallback, useContext, useEffect, useMemo, useState, useRef } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { useAtom } from "tiny-atom/react/hooks";
import { AppContext } from "../../context/AppContext";
import Drawer from "../../pages/Drawer";
import {
  getDisplayLabelFromComversation,
  getTypeLabelFromConversaton,
  getClientsLabel,
  getParentsLabel,
  normalizeMentionsInMessage
} from "../../utils/messagesUtils";
import moment from "moment";
import "./AllConversations.css";
import { FetchResult, useQuery } from "@apollo/client";
import { GET_FAMILIES } from "../../queries";
import { Check, User as FeatherUser, Users as FeatherUsers } from "react-feather";
import { useMutation } from "@apollo/client";
import { DELETE_PENDING_ACTIONS } from "../../queries";
import useHasNarrowScreen from "../../hooks/useHasNarrowScreen";
import useHasMobileScreen from "../../hooks/useHasMobileScreen";
import { AccountType, User } from "../../model/userModels";
import { UserConversation } from "../../model/userModels";
import { reportError } from "../../utils/errorUtils";
import Conversation from "../messages/Conversation";
import { useParams } from "react-router-dom";

type Props = {
  conversations: UserConversation[];
  searchText: string;
  loading: boolean;
  showDoneButtons: boolean;
  asUserId: number;
  refetch: () => void;
  error: boolean;
};

export default function AllConversations(props: Props) {
  type DataRow = {
    lastDate: Date;
    conversation: UserConversation;
    hasUnread: boolean;
    name: string;
    clients: User[];
    included: User[];
    linkedClient: boolean;
    lastMessage: string;
    mentioned: boolean;
    showDoneButtons: boolean;
    familyId: number;
  };

  const conversations: UserConversation[] = props.conversations;
  const searchText = props.searchText;
  const loading = props.loading;
  const showDoneButtons = props.showDoneButtons || false;
  const asUserId = props.asUserId;
  const refetch = props.refetch;
  const error = props.error;

  const userId: number = useAtom(state => state.userId);
  const routeParams = useParams();
  const { children }: { children: { user: User }[] } = useContext(AppContext);
  const hasNarrowScreen: boolean = useHasNarrowScreen();
  const hasMobileScreen: boolean = useHasMobileScreen();
  const narrowOrMobile: boolean = useMemo(() => {
    return hasNarrowScreen || hasMobileScreen;
  }, [hasNarrowScreen, hasMobileScreen]);

  const [selectedRow, setSelectedRow] = useState<DataRow | undefined>(undefined);
  const [routeConversationId, setRouteConversationId] = useState(routeParams?.conversationId);
  const [removedConversationIds, setRemovedConversationIds] = useState<number[]>([]);

  const promiseArray = useRef<Promise<FetchResult<any>>[]>([]);
  const timer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    setRemovedConversationIds([]);
  }, [conversations]);

  const [deletePendingActions] = useMutation(DELETE_PENDING_ACTIONS);

  const childrenByFamilyId = useMemo(() => {
    const map = {};
    if (children?.length > 0) {
      children.forEach(child => {
        let key = child.user.familyId?.toString();
        if (!key) {
          key = "none";
        }
        if (map[key]) {
          map[key].push(child.user);
        } else {
          map[key] = [child.user];
        }
      });
    }
    return map;
  }, [children]);

  const missingFamilyIds = useMemo(
    () => conversations.filter(c => c.familyId && !childrenByFamilyId[c.familyId]).map(c => c.familyId),
    [childrenByFamilyId, conversations]
  );

  const { data: familyData } = useQuery(GET_FAMILIES, {
    variables: { ids: missingFamilyIds },
    skip: !missingFamilyIds?.length
  });

  const { data: selectedConversationClientFamily } = useQuery(GET_FAMILIES, {
    variables: { ids: [selectedRow?.familyId] },
    skip: !selectedRow?.familyId
  });

  const loadedFamiliesById = useMemo(() => _.keyBy(familyData?.families || [], f => f.id), [familyData]);

  const getFamilyIdFromUsers = useCallback(
    users => users.filter(user => user.id != userId && !AccountType.isCareTeamMember(user.accountType))[0]?.familyId,
    [userId]
  );

  const dateOrNumberSort = (a, b) => {
    if (!a || !b) {
      return -1;
    } else {
      return moment(a).diff(moment(b));
    }
  };

  const firstNameSort = (a, b) => a.firstName?.localeCompare(b.firstName);

  const data: DataRow[] = useMemo(() => {
    const convoData = conversations
      .filter(convo => !removedConversationIds.includes(convo.id))
      .map(convo => {
        const otherUsers = convo.users.filter(user => user.id != userId);
        const childUsers = otherUsers.filter(u => u.accountType === "CHILD").sort(firstNameSort);
        const careProviderUsers = otherUsers.filter(u => u.accountType === "PARENT").sort(firstNameSort);
        const careTeamUsers = otherUsers.filter(u => !["PARENT", "CHILD"].includes(u.accountType)).sort(firstNameSort);
        const included = [...childUsers, ...careProviderUsers, ...careTeamUsers];

        const familyId = convo.familyId !== null ? convo.familyId : getFamilyIdFromUsers(otherUsers);
        let clients = childrenByFamilyId[familyId];
        const linkedClient = !!clients;

        if (!clients) {
          const family = loadedFamiliesById[familyId];
          if (family && family.users) {
            clients = family.users.filter(user => user.accountType != "PARENT");
          } else {
            clients = [];
          }
        }

        const mostRececentMessage = _.first(convo.messages);

        const lastDate = mostRececentMessage?.date;
        const fromUser = mostRececentMessage?.from;
        const name = fromUser ? fromUser.firstName + " " + fromUser.lastName : "";

        return {
          name,
          hasUnread: !convo.read,
          lastMessage: mostRececentMessage?.message || "",
          lastDate: lastDate ? new Date(lastDate) : new Date(0),
          mentioned: convo.pendingActions?.some(a => a?.reason === "MENTIONED"),
          conversation: convo,
          clients: clients,
          included: included,
          linkedClient,
          familyId,
          showDoneButtons
        };
      })
      .filter(convo => {
        const clientNames = convo.clients.map(client => client.firstName + " " + client.lastName).join(", ");
        const searchTextMatches =
          convo.name.toLowerCase().includes(searchText) || clientNames.toLowerCase().includes(searchText);

        return searchText?.length > 0 ? searchTextMatches : true;
      })
      .sort((a, b) => dateOrNumberSort(a.lastDate, b.lastDate));

    return convoData;
  }, [
    conversations,
    childrenByFamilyId,
    userId,
    searchText,
    getFamilyIdFromUsers,
    loadedFamiliesById,
    removedConversationIds,
    showDoneButtons
  ]);

  useEffect(() => {
    if (routeConversationId && data) {
      const row = data.find(row => `${row.conversation.id}` == routeConversationId);
      if (row) {
        showConversationDrawer(row);
        setRouteConversationId(undefined);
      }
    }
  }, [routeConversationId, data]);

  const showConversationDrawer = row => {
    setSelectedRow(row);
  };

  const hideConversationDrawer = () => {
    setSelectedRow(undefined);
    refetch();
  };

  const getConversationTypeClass = conversation => {
    if (conversation.type === "CARE_TEAM_INTERNAL") {
      return "purple-indicator";
    } else {
      return "manablue-indicator";
    }
  };

  const getSelectedFamilyParents = () => {
    return (selectedConversationClientFamily?.families[0]?.users || []).filter(u => u.accountType === "PARENT");
  };

  const columns: TableColumn<DataRow>[] = [
    {
      omit: true,
      sortFunction: (rowA, rowB) => dateOrNumberSort(rowA.lastDate, rowB.lastDate)
    },
    {
      name: "",
      cell: row => <div className={`inbox-column-type ${getConversationTypeClass(row.conversation)}`} />,
      width: narrowOrMobile ? "22px" : "3%",
      style: { paddingLeft: "0px" }
    },
    {
      omit: hasMobileScreen,
      name: "",
      cell: row => {
        const type = row.conversation.type;
        if (type === "CARE_TEAM_INTERNAL") {
          return <span className={"internal-icon cc-icon"}>CC</span>;
        } else if (type === "CARE_TEAM_EXTERNAL") {
          return <span className={"external-icon cc-icon"}>CC</span>;
        } else if (type === "DIRECT_MESSAGE") {
          if (row.conversation.users?.length > 2) {
            return <FeatherUsers className={"external-icon"} size={20} />;
          } else {
            return <FeatherUser className={"external-icon"} size={20} />;
          }
        }
      },
      width: "40px",
      style: { padding: "8px" }
    },
    {
      name: (
        <span className={narrowOrMobile ? "inbox-column-name inbox-column-name-narrow" : "inbox-column-name"}>
          FROM
        </span>
      ),
      selector: row => row.name,
      wrap: true,
      width: hasMobileScreen ? "100px" : "15%"
    },
    {
      name: (
        <span className={narrowOrMobile ? "inbox-column-name inbox-column-name-narrow" : "inbox-column-name"}>
          {hasMobileScreen ? "KIDS" : "CHILDREN"}
        </span>
      ),
      cell: row => (
        <div>
          {row.clients.map((client, i) => {
            return (
              <React.Fragment key={i}>
                {row.linkedClient ? (
                  <a className="client-link" key={i} href={`${process.env.REACT_APP_BASE_URL}/patient/${client.id}`}>
                    {client.firstName} {client.lastName}
                  </a>
                ) : (
                  <>
                    {client.firstName} {client.lastName}
                  </>
                )}
                {i < row.clients.length - 1 && ", "}
              </React.Fragment>
            );
          })}
        </div>
      ),
      wrap: true,
      width: hasMobileScreen ? "125px" : "22%",
      sortFunction: (rowA, rowB) => {
        const rowAName = rowA.clients.map(client => client.firstName + " " + client.lastName).join(", ");
        const rowBName = rowB.clients.map(client => client.firstName + " " + client.lastName).join(", ");

        return rowAName.localeCompare(rowBName);
      },
      sortable: true
    },
    {
      name: (
        <span className={narrowOrMobile ? "inbox-column-name inbox-column-name-narrow" : "inbox-column-name"}>
          {hasMobileScreen ? "WITH" : "INCLUDED"}
        </span>
      ),
      cell: row => (
        <>
          <span className="included" onClick={() => showConversationDrawer(row)}>
            {row.included?.map(user => `${user.firstName} ${user.lastName}`).join(", ")}
          </span>
        </>
      ),
      wrap: false,
      width: hasMobileScreen ? "125px" : "22%"
    },
    {
      name: (
        <span className={narrowOrMobile ? "inbox-column-name inbox-column-name-narrow" : "inbox-column-name"}>
          MESSAGE
        </span>
      ),
      cell: row => (
        <>
          <div className="last-message-row" onClick={() => showConversationDrawer(row)}>
            {normalizeMentionsInMessage(row.lastMessage)}
          </div>
          {row.mentioned && (
            <div onClick={() => showConversationDrawer(row)}>{<p className="row-mentioned">{"mentioned"}</p>}</div>
          )}
        </>
      ),
      wrap: false,
      width: hasMobileScreen ? "125px" : "22%"
    },
    {
      omit: hasMobileScreen,
      name: "",
      cell: row =>
        row.showDoneButtons &&
        asUserId === userId && (
          <div
            className="done-check-button"
            onClick={async () => {
              setRemovedConversationIds([...removedConversationIds, row.conversation.id]);

              const deletePendingActionsPromise = deletePendingActions({
                variables: { conversationIds: [row.conversation.id] }
              });
              promiseArray.current.push(deletePendingActionsPromise);

              if (timer.current) {
                clearTimeout(timer.current);
              }

              timer.current = setTimeout(() => {
                timer.current = null;
                Promise.allSettled(promiseArray.current).then(result => {
                  if (!timer.current) {
                    refetch();
                  }
                  promiseArray.current = [];
                  result.forEach(res => {
                    if (res.status === "rejected") {
                      reportError(`deletePendingActions -- ${res?.reason}`);
                    }
                  });
                });
              }, 500);
            }}
          >
            <Check size={22} color="#1A2579" />
          </div>
        ),
      width: "85px"
    }
  ];

  function getRowBackgroundColor(row: DataRow) {
    const type = row.conversation?.type;

    if (row === selectedRow) {
      return type === "CARE_TEAM_INTERNAL" ? "#C694CA4D" : "#2562D82E";
    } else if (row.hasUnread) {
      return "#FFFFFF";
    } else if (type === "CARE_TEAM_INTERNAL") {
      return "#F8F4F9";
    } else {
      return "#F4F7FB";
    }
  }

  const conditionalRowStyles = [
    {
      when: row => !!row,
      style: (row: DataRow) => ({
        backgroundColor: getRowBackgroundColor(row),
        fontWeight: row.hasUnread ? 700 : 400
      })
    }
  ];

  const customStyles = {
    rows: {
      style: {
        "&:hover": {
          backgroundColor: "#FBEDBF80!important"
        }
      }
    }
  };

  return (
    <div>
      <DataTable
        columns={columns}
        data={data}
        onRowClicked={row => showConversationDrawer(row)}
        noHeader
        defaultSortFieldId={1}
        defaultSortAsc={false}
        customStyles={customStyles}
        conditionalRowStyles={conditionalRowStyles}
        noDataComponent={
          <div className="empty-conversation-list">
            {loading ? "Loading!" : error ? "Something went wrong - retrying..." : "No new messages!"}
          </div>
        }
      />
      {!!selectedRow && (
        <div className="Modal">
          <Drawer title={""} onBack={undefined} onExit={hideConversationDrawer} headerBorder={false} scrollable={false}>
            <div className={hasNarrowScreen ? "modal-conversation-mobile" : "modal-conversation"}>
              <Conversation
                conversationId={selectedRow.conversation.id}
                conversation={selectedRow.conversation}
                asUserId={asUserId}
                label={getDisplayLabelFromComversation(selectedRow.conversation, userId)}
                typeLabel={getTypeLabelFromConversaton(selectedRow.conversation)}
                clientsLabel={getClientsLabel(selectedRow.clients)}
                parentsLabel={getParentsLabel(getSelectedFamilyParents())}
                onExit={hideConversationDrawer}
              />
            </div>
          </Drawer>
        </div>
      )}
    </div>
  );
}
