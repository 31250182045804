import { useCallback, useContext, useEffect, useRef } from "react";
import { useAtom } from "tiny-atom/react/hooks";
import { MessagingContext } from "../context/MessagingContext";
import useConversations from "./useConversations";

export default function useInboxConversations({ asUserId, messageCount } = {}) {
  const userId = useAtom(state => state.userId);
  const { conversations, loading, refetch, error } = useContext(MessagingContext);
  const lastUserIdRef = useRef(userId);

  const {
    conversations: conversationsAsUser,
    loading: loadingAsUser,
    fetchConversations: fetchAsUser,
    error: errorAsUser
  } = useConversations({
    asUserId,
    messageCount,
    skip: !asUserId || asUserId === userId
  });

  const fetchConversations = useCallback(() => {
    if (!asUserId || userId === asUserId) {
      refetch();
    } else {
      fetchAsUser({ asUserId, messageCount });
    }
  }, [asUserId, messageCount, userId, refetch, fetchAsUser]);

  useEffect(() => {
    if (asUserId && (!lastUserIdRef.current || lastUserIdRef.current !== asUserId)) {
      fetchConversations();
    }
    lastUserIdRef.current = asUserId || userId;
  }, [asUserId, fetchConversations, userId]);

  if (asUserId && asUserId !== userId) {
    return {
      conversations: conversationsAsUser,
      loading: loadingAsUser,
      refetch: fetchConversations,
      error: errorAsUser
    };
  }
  return { conversations, loading, refetch: fetchConversations, error: error };
}
