/** @format */

import React from "react";
import { View } from "../react-native";
import { TextField, BlockStack, Button } from "@shopify/polaris";
import ButtonStack from "../ButtonStack";
import Label from "../Label";
import SegmentedInput from "../SegmentedInput";
import RangeInput from "../RangeInput";
import { CUSTOM } from "../../utils/dateUtils";
import { Formik } from "formik";
import { setCurrentScreen } from "../../actions";
import Modal from "../Modal";
import DatePicker from "react-datepicker";
import "../Datepicker.css";
import {
  createGoal,
  gatherGoalProperties,
  unassignGoal,
  updateGoal,
  adjustTimezoneServerToClient
} from "../../utils/goalUtils";
import FormErrorMessage from "../FormErrorMessage";

class StandardGoal extends React.Component {
  constructor(props) {
    super();
    this.threeOclockPM = new Date("July 1, 1999 15:00");
    let goal = props.goal || {};

    this.state = {
      days: [],
      description: goal.description,
      dueAt: CUSTOM,
      expiresAt: goal.expiresAt,
      dailyFrequency: 1,
      id: goal.id,
      imageId: goal.imageId,
      name: goal.name,
      value: goal.value,
      assignedTo: goal.assignedTo,
      formIsValid: false,
      showDays: false,
      isUnassignedModalVisible: false,
      isUnassignConfirmationModalVisible: false,
      isSaveModalVisible: false,
      isAssignedModalVisible: false,
      isCreateModalVisible: false,
      makeUnassignedCopy: false,
      reminder: "yes"
    };
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleReminderChange = this.handleReminderChange.bind(this);
    this.handleDaysChange = this.handleDaysChange.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
  }

  componentDidMount() {
    setCurrentScreen("TherapistWebCreateEditGoalScreen");
  }

  handleNameChange(value) {
    this.setState({ name: value, changesMade: true });
  }

  handleDescriptionChange(value) {
    this.setState({ description: value, changesMade: true });
  }

  handleReminderChange(value) {
    this.setState({
      reminder: value,
      days: value === "no" ? undefined : [],
      changesMade: true
    });
  }

  handleDaysChange(value) {
    this.setState({ days: value, changesMade: true });
  }

  handleValueChange(value) {
    this.setState({ value: value, changesMade: true });
  }

  render() {
    const goal = {
      description: this.state.description,
      dueAt: this.state.dueAt,
      expiresAt: this.state.expiresAt,
      name: this.state.name,
      value: this.state.value,
      dailyFrequency: this.state.dailyFrequency,
      reminderTime1: this.state.reminderTime1,
      days: this.state.days,
      assignedTo: this.state.assignedTo
    };

    if (goal.reminderTime1) {
      if (!this.state.reminderTime1Local) {
        this.setState({
          reminderTime1Local: adjustTimezoneServerToClient(goal.reminderTime1, this.props.patient.timeZone)
        });
      }
    }

    return (
      <Formik
        initialValues={goal}
        validate={() => {
          let errors = {};
          if (!this.state.name) {
            errors.name = "Name Required";
          } else if (this.state.reminder === "yes" && (!this.state.days || this.state.days.length === 0)) {
            errors.days = "Must select at least one day";
          }
          return errors;
        }}
        onSubmit={() => {
          setTimeout(() => {
            if (goal.createAndAssign) {
              this.setState({ makeUnassignedCopy: true });
              createGoal(
                this.props,
                gatherGoalProperties(this.props, this.state, undefined, this.state.reminder === "yes")
              );
              this.setState({ isCreateModalVisible: true });
            } else {
              updateGoal(
                this.props,
                gatherGoalProperties(this.props, this.state, undefined, this.state.reminder === "yes")
              );
            }
          }, 400);
        }}
      >
        {({ handleSubmit }) => (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              flexGrow: 1,
              overflow: "auto"
            }}
          >
            <div style={{ padding: 20 }}>
              <BlockStack gap={"200"}>
                <Label first>Name</Label>
                <TextField
                  name="title"
                  type="TextInput"
                  placeholder="e.g. Respond to questions"
                  value={this.state.name}
                  onChange={this.handleNameChange}
                  style={{
                    height: 40,
                    paddingLeft: 15,
                    paddingRight: 15,
                    borderRadius: 12,
                    fontSize: 16,
                    backgroundColor: "rgb(244, 246, 248)"
                  }}
                />
                <FormErrorMessage name="name" component="div" />
                <Label>Description</Label>
                <TextField
                  name="description"
                  type="TextInput"
                  defaultValue={goal.description}
                  placeholder="e.g. Respond to a question from your parents, from your teacher or anyone else"
                  value={this.state.description}
                  onChange={this.handleDescriptionChange}
                  multiline
                  style={{
                    height: 90,
                    padding: 15,
                    paddingTop: 15,
                    borderRadius: 12,
                    fontSize: 16,
                    backgroundColor: "rgb(244, 246, 248)"
                  }}
                />
                <Label>Points at completion</Label>
                <RangeInput
                  min={1}
                  max={100}
                  defaultValue={goal.value}
                  type="RangeInput"
                  value={this.state.value}
                  onChange={this.handleValueChange}
                  name="points"
                />
                <Label marginTop={40}>Give them a reminder?</Label>
                <SegmentedInput
                  options={[
                    { label: "Yes", value: "yes" },
                    { label: "No", value: "no" }
                  ]}
                  defaultValue={"custom"}
                  type="SegmentedInput"
                  name="reminder"
                  value={this.state.reminder}
                  onChange={this.handleReminderChange}
                />
                <FormErrorMessage name="reminder" component="div" />
                {this.state.reminder === "yes" && (
                  <View>
                    <Label>Days</Label>
                    <SegmentedInput
                      options={[
                        { label: "S", value: 0 },
                        { label: "M", value: 1 },
                        { label: "T", value: 2 },
                        { label: "W", value: 3 },
                        { label: "T", value: 4 },
                        { label: "F", value: 5 },
                        { label: "S", value: 6 }
                      ]}
                      defaultValue={goal.days || []}
                      type="SegmentedInput"
                      multiselect="true"
                      name="days"
                      value={this.state.days}
                      onChange={this.handleDaysChange}
                    />
                    <View style={{ marginTop: 15 }}>
                      <SegmentedInput
                        options={[{ label: "Daily", value: 0 }]}
                        defaultValue={{}}
                        type="SegmentedInput"
                        multiselect="true"
                        name="daily"
                        value={this.state.days?.length === 7 ? [0] : []}
                        onChange={selection => {
                          if (selection.length > 0) {
                            this.handleDaysChange([0, 1, 2, 3, 4, 5, 6]);
                          }
                        }}
                      />
                    </View>
                    <FormErrorMessage name="days" component="div" />

                    <Label marginTop={40}>At what time?</Label>
                    <View style={{ flexDirection: "row" }}>
                      <DatePicker
                        selected={this.state.reminderTime1Local ? this.state.reminderTime1Local : this.threeOclockPM}
                        onChange={time => {
                          this.setState({
                            reminderTime1Local: time,
                            changesMade: true
                          });
                        }}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        className="react-datepicker__manatee-input-container"
                      />
                    </View>
                  </View>
                )}
              </BlockStack>
            </div>
            <ButtonStack fullWidth horizontal bottomSticky>
              {!this.state.changesMade && this.props.goal.matchesCustomGoal && !this.props.goal.isParentAssigned && (
                <Button
                  fullWidth
                  small
                  onClick={() => {
                    this.setState({
                      isUnassignConfirmationModalVisible: true,
                      matchesCustomGoal: false
                    });
                  }}
                >
                  UNASSIGN
                </Button>
              )}

              <Button
                fullWidth
                small
                onClick={() => {
                  goal.createAndAssign = true;
                  handleSubmit();
                }}
              >
                SAVE AND ASSIGN
              </Button>
            </ButtonStack>
            <Modal
              title="Goal successfully created"
              hidden={!this.state.isCreateModalVisible}
              onButton1Press={() => this.mutationComplete()}
            />
            <Modal
              title="Goal saved"
              hidden={!this.state.isSaveModalVisible}
              onButton1Press={() => this.mutationComplete()}
            />
            <Modal
              title="Goal unassigned"
              hidden={!this.state.isUnassignedModalVisible}
              onButton1Press={() => this.mutationComplete()}
            />
            <Modal
              title="Goal assigned"
              hidden={!this.state.isAssignedModalVisible}
              onButton1Press={() => this.mutationComplete()}
            />
            <Modal
              title="Are you sure you want to unassign this goal?"
              hidden={!this.state.isUnassignConfirmationModalVisible}
              button1Label="Yes"
              onButton1Press={() => {
                unassignGoal(this.props);
                this.setState({
                  isUnassignConfirmationModalVisible: false,
                  isUnassignedModalVisible: true
                });
              }}
              button2Label="No"
              onButton2Press={() => {
                this.setState({
                  isUnassignConfirmationModalVisible: false
                });
              }}
            />
          </div>
        )}
      </Formik>
    );
  }

  mutationComplete() {
    this.props.onLeave();
  }
}

export default StandardGoal;
