import React from "react";
import ProgressHeader from "./ProgressHeader";
import views from "./screenerViewsNames";

const steps = [
  { viewNames: undefined, displayName: "Tell us about your family" },
  { viewNames: [views.insuranceEligibility], displayName: "Check your coverage" },
  { viewNames: [views.passwordInput, views.loadingAccountCreation], displayName: "Create your account" },
  { viewNames: [views.scheduleConsult, views.scheduleIntake], displayName: "Book your session" }
];

export default function CareScreenerProgress({ currentView }) {
  const stepNames = steps.map(s => s.displayName);
  const currentStep = steps.find(s => s.viewNames && s.viewNames.includes(currentView));
  const currentStepIndex = currentStep ? steps.indexOf(currentStep) : -1;

  return <ProgressHeader steps={stepNames} index={currentStepIndex} />;
}
