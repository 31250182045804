import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../pages/Login";
import TwoFactorAuthPhone from "../pages/TwoFactorAuthPhone";
import TwoFactorAuthSubmitCode from "../pages/TwoFactorAuthSubmitCode";
import Register from "../pages/Register";
import ForgotPassword from "../pages/ForgotPassword";
import PasswordReset from "../pages/PasswordReset";
import Logout from "../pages/Logout";
import { UserContext } from "../context/UserContext";

export default function AuthRoutes() {
  const { authenticated } = useContext(UserContext) || {};
  return (
    <Routes>
      {!authenticated && <Route path="/" element={<Login />} />}
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/twoFactorAuthPhone" element={<TwoFactorAuthPhone />} />
      <Route path="/twoFactorAuthSubmitCode" element={<TwoFactorAuthSubmitCode />} />
      <Route path="/register" element={<Register />} />
      <Route path="/forgotPassword" element={<ForgotPassword />} />
      <Route path="/passwordReset" element={<PasswordReset />} />
    </Routes>
  );
}
