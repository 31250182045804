import { Query } from "@apollo/client/react/components";
import { Select, Tooltip } from "@shopify/polaris";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useActions, useAtom } from "tiny-atom/react/hooks";
import familyWhiteImage from "../assets/familyWhite.png";
import standaloneWhiteImage from "../assets/standaloneWhite.png";
import Chat from "../components/Chat";
import DefaultRewardEdit from "../components/DefaultRewardEdit";
import AssignedGoal from "../components/edit-create-assign-goal-views/AssignedGoal";
import CreateGoal from "../components/edit-create-assign-goal-views/CreateGoal";
import CustomGoal from "../components/edit-create-assign-goal-views/CustomGoal";
import StandardGoal from "../components/edit-create-assign-goal-views/StandardGoal";
import GoalManagement from "../components/GoalManagement";
import ParentGoal from "../components/ParentGoal";
import ParentGoalManagement from "../components/ParentGoalManagement";
import PatientTabs from "../components/patient/PatientTabs";
import RewardManagement from "../components/RewardManagement";
import Spinner from "../components/Spinner";
import { AppContext } from "../context/AppContext";
import { THERAPIST_GET_CHILD } from "../queries";
import { getGMT } from "../utils/dateUtils";
import "./Patient.css";
import Drawer from "./Drawer";
import { FamilyAssessmentContextProvider } from "../context/FamilyAssessmentContext";
import ParentProfiles from "../components/ParentProfiles";
import ClientProfile from "../components/ClientProfile";
import { useLocation, useParams } from "react-router-dom";
import MatchProviders from "../components/MatchProviders";
import { AccountType } from "../model/userModels";

export default function Patient() {
  const [modal, setModal] = useState();
  const [isClickedFromPlus, setisClickedFromPlus] = useState(0);
  const [parentGoal, setParentGoal] = useState(0);
  const [defaultTab, setDefaultTab] = useState("engagement");
  const patient = useAtom(state => state.patient);
  const goal = useAtom(state => state.goal);
  const reward = useAtom(state => state.reward);
  const location = useLocation();
  const routeParams = useParams();
  const appContext = useContext(AppContext);

  const patientData = useAtom(state => state.patientData);
  const [selectedCaregiver, setSelectedCareGiver] = useState();

  const {
    navigate,
    setPatient,
    setPatientData,
    setCurrentScreen,
    setOpenDefaultRewardEditScreen,
    setOpenCreateGoalScreen,
    setOpenStandardGoalScreen,
    setOpenAssignedGoalScreen,
    setOpenCustomGoalScreen,
    setPatientRefetch
  } = useActions();

  setCurrentScreen("TherapistWebPatientScreen");

  useEffect(() => {
    if (selectedCaregiver) {
      setSelectedCareGiver(patientData?.childParents.find(p => p.id === selectedCaregiver.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientData]);

  const patientSelectOptions = useMemo(() => {
    if (!patient) {
      return [];
    }
    const selectOptions = [
      {
        label: "Assign goals",
        value: "goal-management",
        onSelect: () => {
          localStorage.removeItem("scrollTop");
          showModal("goal-management");
        }
      },
      {
        label: "View rewards",
        value: "reward-management",
        onSelect: () => showModal("reward-management")
      },
      {
        label: "Profile",
        value: "client-profile",
        onSelect: () => {
          localStorage.removeItem("scrollTop");
          showModal("client-profile");
        }
      }
    ];
    if (appContext?.user?.accountType === AccountType.CARE_COORDINATOR) {
      selectOptions.push({
        label: "Match providers",
        value: "match-providers",
        onSelect: () => showModal("match-providers")
      });
    }
    return selectOptions;
  }, [patient, appContext?.user?.accountType]);

  const caregiverSelectOptions = useMemo(() => {
    const selectOptions = [];
    if (!patient) {
      return selectOptions;
    }
    if (patient.accountType === "CHILD") {
      patientData?.childParents?.forEach(caregiver => {
        selectOptions.push({
          label: "Assign goals for " + caregiver.firstName,
          value: "parent-goal-management-" + caregiver.id,
          onSelect: () => {
            localStorage.removeItem("scrollTop");
            setSelectedCareGiver(caregiver);
            showModal("parent-goal-management");
          }
        });
      });
      selectOptions.push({
        label: "Profile",
        value: "parent-profiles",
        onSelect: () => {
          localStorage.removeItem("scrollTop");
          showModal("parent-profiles");
        }
      });
    }
    return selectOptions;
  }, [patientData, patient]);

  const allSelectOptions = [...patientSelectOptions, ...caregiverSelectOptions];

  const linking = useMemo(() => {
    if (routeParams.patientId) {
      if (!patient || `${patient.id}` !== routeParams.patientId) {
        if (appContext?.loading) {
          return true;
        }
        if (appContext?.children?.length) {
          const match = appContext.children.find(child => `${child.user.id}` === routeParams.patientId);
          if (match) {
            setPatient({ params: { patient: match.user } });
            if (routeParams.conversationId) {
              setDefaultTab("messages");
            } else if (
              location.pathname.includes("assessment") &&
              (appContext?.user?.accountType === "THERAPIST" || appContext?.user?.accountType === "PARENT_COACH")
            ) {
              setDefaultTab("assessment");
            }
            return true;
          }
        }
      }
    }
    return false;
  }, [
    routeParams.patientId,
    routeParams.conversationId,
    patient,
    appContext?.loading,
    appContext.children,
    appContext?.user?.accountType,
    setPatient,
    location.pathname
  ]);

  if (linking) {
    return <Spinner />;
  }

  if (!patient) {
    navigate({ pathname: "/" });
    return <div></div>;
  }

  function showModal(modal) {
    setModal(modal);
  }

  function hideModal() {
    setModal(null);
  }

  function handleDefaultGoalEdit(goalData) {
    let goal = goalData.goal || goalData;
    goal.dailyFrequency = 1;
    goal.reminderTime1 = getGMT(new Date("July 1, 1999 15:00"));
    goal.copy = true;
    showModal("goal-default-edit");
  }

  const handleSelect = selection => {
    const match = allSelectOptions.find(option => option.value === selection);
    if (match) {
      match.onSelect();
    }
  };

  return (
    <Query
      query={THERAPIST_GET_CHILD}
      variables={{ childId: patient.id }}
      fetchPolicy="network-only"
      partialRefetch={true}
    >
      {({ loading, error, refetch, data }) => {
        if (loading) return <Spinner></Spinner>;
        if (error) return <p></p>;
        setPatientData({ data });
        setPatientRefetch({ params: { patientRefetch: refetch } });
        return (
          <FamilyAssessmentContextProvider>
            <div className="Patient">
              <div className="Patient-header">
                <div className="Patient-title">
                  <div className="Column">
                    <div className="Patient-name">{`${patient.firstName} ${
                      patient.lastName ? patient.lastName : ""
                    }`}</div>
                    <Tooltip
                      content={
                        patient.accountType === "CHILD"
                          ? "Client/patient & caregiver are both registered. Caregiver can set and approve goals & rewards and track progress."
                          : "Standalone Client. Only client/patient is enrolled. Caregiver does not have a Manatee account."
                      }
                    >
                      <img
                        alt="account type"
                        src={patient.accountType === "CHILD" ? familyWhiteImage : standaloneWhiteImage}
                      />
                    </Tooltip>
                  </div>
                  <div className="Patient-username">{`(${patient.username || patient.email})`}</div>
                </div>
                <div className="patient-tools">
                  <Select
                    options={patientSelectOptions}
                    placeholder={patient.firstName}
                    onChange={handleSelect}
                    id={"patient-select"}
                  />
                  {patient.accountType === "CHILD" && (
                    <Select options={caregiverSelectOptions} placeholder="Caregiver(s)" onChange={handleSelect} />
                  )}
                </div>
              </div>
              <div className="Patient-content">
                <div className="Patient-statsCard">
                  <PatientTabs patient={patient} achievements={data.achievements} defaultTab={defaultTab} />
                </div>

                {modal && (
                  <div>
                    <div className="Modal">
                      {modal === "chat-history" && (
                        <Drawer title="Chat interactions" onExit={hideModal}>
                          <Chat patient={patient} />
                        </Drawer>
                      )}
                      {modal === "goal-management" && (
                        <Drawer title="Goals" onExit={hideModal}>
                          <GoalManagement
                            patient={patient}
                            openCreateGoalScreen={setOpenCreateGoalScreen({
                              params: {
                                openCreateGoalScreen: () => showModal("goal-create")
                              }
                            })}
                            openStandardGoalScreen={setOpenStandardGoalScreen({
                              params: {
                                openStandardGoalScreen: goal => {
                                  handleDefaultGoalEdit(goal);
                                }
                              }
                            })}
                            openAssignedGoalScreen={setOpenAssignedGoalScreen({
                              params: {
                                openAssignedGoalScreen: () => showModal("goal-assigned")
                              }
                            })}
                            openCustomGoalScreen={setOpenCustomGoalScreen({
                              params: {
                                openCustomGoalScreen: (goal, isClickedFromPlus) => {
                                  setisClickedFromPlus(isClickedFromPlus);
                                  showModal("goal-custom");
                                }
                              }
                            })}
                            patientRefetch={setPatientRefetch({
                              params: {
                                patientRefetch: () => refetch()
                              }
                            })}
                          />
                        </Drawer>
                      )}
                      {modal === "client-profile" && (
                        <Drawer title="Client Profile" onExit={hideModal}>
                          <ClientProfile user={patient} />
                        </Drawer>
                      )}
                      {modal === "parent-profiles" && (
                        <Drawer title="Caregiver Profiles" onExit={hideModal}>
                          <ParentProfiles />
                        </Drawer>
                      )}
                      {modal === "parent-goal-management" && (
                        <Drawer title={`Assign to ${selectedCaregiver?.firstName}`} onExit={hideModal}>
                          <ParentGoalManagement
                            parent={selectedCaregiver}
                            showParentGoal={goal => {
                              setParentGoal(goal);
                              showModal("parent-goal");
                            }}
                            openCreateGoal={() => {
                              showModal("parent-goal-create");
                            }}
                          />
                        </Drawer>
                      )}
                      {modal === "parent-goal" && (
                        <Drawer
                          title={`Assign to ${selectedCaregiver?.firstName}`}
                          onExit={hideModal}
                          onBack={() => showModal("parent-goal-management")}
                        >
                          <ParentGoal
                            parent={selectedCaregiver}
                            goal={parentGoal}
                            onLeave={() => showModal("parent-goal-management")}
                          />
                        </Drawer>
                      )}
                      {modal === "parent-goal-create" && (
                        <Drawer
                          title={`Assign to ${selectedCaregiver?.firstName}`}
                          onExit={hideModal}
                          onBack={() => showModal("parent-goal-management")}
                        >
                          <ParentGoal
                            mode="create"
                            parent={selectedCaregiver}
                            onLeave={() => showModal("parent-goal-management")}
                          />
                        </Drawer>
                      )}
                      {modal === "goal-create" && (
                        <Drawer title="Create goal" onExit={hideModal} onBack={() => showModal("goal-management")}>
                          <CreateGoal patient={patient} onLeave={() => showModal("goal-management")} />
                        </Drawer>
                      )}
                      {modal === "goal-assigned" && (
                        <Drawer title="Assigned goal" onExit={hideModal} onBack={() => showModal("goal-management")}>
                          <AssignedGoal goal={goal} patient={patient} onLeave={() => showModal("goal-management")} />
                        </Drawer>
                      )}
                      {modal === "goal-default-edit" && (
                        <Drawer title="Standard goal" onExit={hideModal} onBack={() => showModal("goal-management")}>
                          <StandardGoal
                            goal={goal}
                            patient={patient}
                            patientRefetch={refetch}
                            onLeave={() => showModal("goal-management")}
                          />
                        </Drawer>
                      )}
                      {modal === "goal-custom" && (
                        <Drawer title="Custom goal" onExit={hideModal} onBack={() => showModal("goal-management")}>
                          <CustomGoal
                            goal={goal}
                            isClickedFromPlus={isClickedFromPlus}
                            patient={patient}
                            onLeave={() => showModal("goal-management")}
                          />
                        </Drawer>
                      )}
                      {modal === "reward-management" && (
                        <Drawer title="Rewards" onExit={hideModal}>
                          <RewardManagement
                            patient={patient}
                            openCreateRewardScreen={() => showModal("reward-create")}
                            setOpenDefaultRewardEditScreen={setOpenDefaultRewardEditScreen({
                              params: {
                                openDefaultRewardEditScreen: () => showModal("reward-default-edit")
                              }
                            })}
                          />
                        </Drawer>
                      )}
                      {modal === "reward-default-edit" && (
                        <Drawer title="View reward" onExit={hideModal} onBack={() => showModal("reward-management")}>
                          <DefaultRewardEdit patient={patient} mode="create" reward={reward} />
                        </Drawer>
                      )}
                      {modal === "match-providers" && (
                        <Drawer title={`Match ${patient.firstName} with Providers`} onExit={hideModal}>
                          <MatchProviders clientId={patient.id} />
                        </Drawer>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </FamilyAssessmentContextProvider>
        );
      }}
    </Query>
  );
}
