import gameNightImage from "../assets/rewards-game-night.jpg";
import dinnerImage from "../assets/rewards-dinner.jpg";
import pickDessertImage from "../assets/rewards-pick-dessert.jpg";
import pickMovieImage from "../assets/rewards-pick-movie.jpg";
import moviesImage from "../assets/rewards-movies.jpg";
import sportsImage from "../assets/rewards-sports.jpg";
import remoteControlImage from "../assets/rewards-remote-control.jpg";
import lateBedtimeImage from "../assets/rewards-late-bedtime.jpg";
import videoGamesImage from "../assets/rewards-video-games.jpg";
import farmImage from "../assets/rewards-farm.jpg";
import zooImage from "../assets/rewards-zoo.jpg";
import planActivityImage from "../assets/rewards-plan-activity.jpg";
import tripImage from "../assets/rewards-trip.jpg";
import dayOffChoresImage from "../assets/rewards-day-off-chores.jpg";
import sleepoverImage from "../assets/rewards-sleepover.jpg";
import iceCreamImage from "../assets/rewards-ice-cream.jpg";
import booksImage from "../assets/rewards-books.jpg";
import gameImage from "../assets/rewards-game.jpg";
import mysteryImage from "../assets/rewards-mystery.jpg";
import birthdayDogImage from "../assets/rewards-birthday-dog.jpg";
import airBalloonImage from "../assets/rewards-air-balloon.jpg";
import familyDesertImage from "../assets/rewards-family-desert.jpg";
import fireworksImage from "../assets/rewards-fireworks.jpg";
import balloonsImage from "../assets/rewards-balloons.jpg";
import lunchImage from "../assets/rewards-lunch.jpg";
import lunchTreatImage from "../assets/rewards-lunch-treat.jpg";
import pizzaImage from "../assets/rewards-pizza.jpg";
import playtimeImage from "../assets/rewards-playtime.jpg";
import downloadAppImage from "../assets/rewards-download-app.jpg";
import screentimeImage from "../assets/rewards-screentime.jpg";
import allowanceImage from "../assets/rewards-allowance.jpg";
import animalShelterImage from "../assets/rewards-animal-shelter.jpg";
import audiobookImage from "../assets/rewards-audiobook.jpg";
import campingBackyardImage from "../assets/rewards-camping-backyard.jpg";
import curfewImage from "../assets/rewards-curfew.jpg";
import dadDateImage from "../assets/rewards-dad-date.jpg";
import goForHikeImage from "../assets/rewards-go-for-hike.jpg";
import grandparentsImage from "../assets/rewards-grandparents.jpg";
import hotChocolateImage from "../assets/rewards-hot-chocolate.jpg";
import magazineImage from "../assets/rewards-magazine.jpg";
import mallTripImage from "../assets/rewards-mall-trip.jpg";
import momDateImage from "../assets/rewards-mom-date.jpg";
import paintRoomImage from "../assets/rewards-paint-room.jpg";
import sleepInImage from "../assets/rewards-sleep-in.jpg";
import sleepoverFriendsImage from "../assets/rewards-sleepover-friends.jpg";
import swimmingImage from "../assets/rewards-swimming.jpg";
import takeoutImage from "../assets/rewards-takeout.jpg";

export const imageMap = {
  1001: gameNightImage,
  1002: dinnerImage,
  1003: pickDessertImage,
  1004: pickMovieImage,
  1005: moviesImage,
  1006: sportsImage,
  1007: remoteControlImage,
  1008: lateBedtimeImage,
  1009: videoGamesImage,
  1010: farmImage,
  1011: zooImage,
  1012: planActivityImage,
  1013: tripImage,
  1014: dayOffChoresImage,
  1015: sleepoverImage,
  1016: iceCreamImage,
  1017: booksImage,
  1018: gameImage,
  1019: mysteryImage,
  1020: birthdayDogImage,
  1021: airBalloonImage,
  1022: familyDesertImage,
  1023: fireworksImage,
  1024: balloonsImage,
  1025: lunchImage,
  1026: lunchTreatImage,
  1027: pizzaImage,
  1028: playtimeImage,
  1029: downloadAppImage,
  1030: screentimeImage,
  1031: allowanceImage,
  1032: animalShelterImage,
  1033: audiobookImage,
  1034: campingBackyardImage,
  1035: curfewImage,
  1036: dadDateImage,
  1037: goForHikeImage,
  1038: grandparentsImage,
  1039: hotChocolateImage,
  1040: magazineImage,
  1041: mallTripImage,
  1042: momDateImage,
  1043: paintRoomImage,
  1044: sleepInImage,
  1045: sleepoverFriendsImage,
  1046: swimmingImage,
  1047: takeoutImage
};

export function getImage(imageId) {
  return imageMap[parseInt(imageId)];
}
