import React, { useState } from "react";
import { BlockStack, Button } from "@shopify/polaris";
import { TouchableOpacity } from "../components/react-native";
import { useActions } from "tiny-atom/react/hooks";
import "./Connection.css";
import chainlinkWhiteImage from "../assets/chainlinkWhite.png";
import chainlinkGrayImage from "../assets/chainlinkGray.png";
import unlinkWhiteImage from "../assets/unlinkWhite.png";
import unlinkGrayImage from "../assets/unlinkGray.png";

export default function Connection() {
  const { continueInvite, setCurrentScreen } = useActions();
  const [connectionType, setSelection] = useState(0);
  if (!connectionType) {
    setSelection("addConnection");
  }
  setCurrentScreen("connectionScreen");

  return (
    <div className="Connection">
      <form className="Connection-form">
        <BlockStack gap={"200"}>
          <div className="Connection-label">Choose an action</div>
          <TouchableOpacity
            onPress={() => {
              setSelection("addConnection");
            }}
          >
            <div
              className={
                connectionType === "addConnection"
                  ? "Connection-type-label-selected"
                  : "Connection-type-label-unselected"
              }
            >
              <div className="Connection-type-text">
                <img
                  alt="connect"
                  className="image"
                  src={connectionType === "addConnection" ? chainlinkWhiteImage : chainlinkGrayImage}
                />
                <div className="Connection-text">
                  <p id="Weight-bold">Connect Family</p>
                  <p id="Weight">
                    Connect family of an invited or registered user with an additional therapist, coach or care
                    coordinator.
                  </p>
                </div>
              </div>
            </div>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              setSelection("removeConnection");
            }}
          >
            <div
              className={
                connectionType === "removeConnection"
                  ? "Connection-type-label-selected"
                  : "Connection-type-label-unselected"
              }
            >
              <div className="Connection-type-text">
                <img
                  alt="disconnect"
                  className="image"
                  src={connectionType === "removeConnection" ? unlinkWhiteImage : unlinkGrayImage}
                />
                <div className="Connection-text">
                  <p id="Weight-bold">Disconnect Family</p>
                  <p id="Weight">Disconnect a client from one or all Manatee Care Team members.</p>
                </div>
              </div>
            </div>
          </TouchableOpacity>
          <Button
            id="Connection-submit"
            fullWidth
            variant="primary"
            submit
            onClick={() => {
              continueInvite({ pathname: `/${connectionType}`, params: { inviteAccountType: connectionType } });
            }}
          >
            Continue
          </Button>
        </BlockStack>
      </form>
    </div>
  );
}
