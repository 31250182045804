/* eslint-disable no-irregular-whitespace */
export default [
  {
    id: 30,
    objective: "Daily Routines",
    name: "Prep your child to do morning routine independently",
    description: `
After being woken up in the morning, children 4+ should be expected to accomplish 3 tasks: 
  • Go potty
  • Get dressed (even on weekends!) 
  • Go to the table for breakfast
    
The sequence of events in the morning routine is important because your child will wake up in a productive state of mind as opposed to a “play” mindset . Food is a great natural reward so they should learn that they will not be served breakfast until all of their clothes are on!
    
✅ To help your child be successful: 
  • Give them their space to accomplish these tasks independently, without help (unless truly necessary) and/or reminders. 
  • Keep the same order every time.
    
💡 Parents will know they have achieved this goal when their child accomplishes the tasks within the morning routine independently, without reminders or complaint`,
    expiration: 30,
    frequency: "daily"
  },
  {
    id: 31,
    objective: "Daily Routines",
    name: "Teach your child to prepare for bed independently",
    description: `
After dinner, children ages 4+ should be expected to complete 5 tasks

  • Get undressed for bath/shower
  • Place dirty clothes in the laundry basket
  • Take bath/shower (with parent in bathroom for those children not old enough to be left unsupervised)
  • PJs on
  • Teeth brushed
    
The chronology or sequence of events is important in the evening routine because it helps children to understand that bathing, brushing teeth and getting ready for bed is a priority (their responsibility) and if they get it done before bed, the remaining time is theirs to be used as free time.
    
✅ To help your child be successful:
    
  • Refrain from giving reminders and warnings during this time, natural consequences are built in as your child will have less time to play before bed if they choose to resist or goofs around.
  • Ensure that evening activities are quiet and calming in nature as opposed to stimulating and exciting, so that children transition into bed in a calm, relaxed state.
    
💡 Parents will know they have achieved this goal when their child accomplishes the tasks within the evening routine independently, without reminders or complaint`,
    expiration: 30,
    frequency: "daily"
  },
  {
    id: 32,
    objective: "Daily Routines",
    name: "Teach your child to be a cooperative participant during mealtimes.",
    description: `
Before, during and after mealtimes, children should be expected to complete the following tasks and adhere to the following expectations:

  • Set the table. (Even toddlers are capable of bringing their bowl/plate, cup and napkin to the table. Older children should be expected to do more)
  • Sit at the table until they are finished eating.
  • If parents are serving items that children typically like, children should be expected to accept what is being served with the understanding that if they are hungry then they need to eat the foods their parents prepare.
  • Quiet conversation is okay, but it is also important to let everyone at the table have a turn to speak and share in conversation.
  • Clean up after meals. (Even toddlers can throw napkins in the trash or put a utensil in the sink. Older children should be expected to do more.)
    
✅ To help your child be successful:
    
  • Rules and limitations established and maintained during mealtime allow kids to understand what they need to do if they are hungry and want to eat. Establishing age-appropriate expectations naturally encourages cooperative behavior and provides opportunities for children to be an essential and active part of the household functioning.
    
  • Children learn to demonstrate table manners and also share quality conversation and time with others when mealtime rules are established and upheld.
    
  • When you give your child opportunities to participate in the set up for mealtimes, you send a message to your child that they are capable people and an important part of the family.
    
  • Children gain a sense of appreciation when they are asked to eat what is served as opposed to entitlement when you consistently alter mealtime menus to give your child what they want.
    
💡 Parents will know they have achieved this goal when their child is helping to set up meals without reminders or complaints, following the rules at the table and eating what is served.`,
    expiration: 30,
    frequency: "daily"
  },
  {
    id: 33,
    objective: "Daily Routines",
    name: "Teach your child to independently prepare and complete homework assignments.",
    description: `
After school, children of elementary school age and older, should be expected to independently complete the following 4 tasks:

  • Put away school items, backpacks, jackets, shoes, etc.
  • Eat a snack (If you give snacks after school)
  • Sit at workstation (all children should have a designated workstation to study and complete school assignments).
  • Ask you to check homework and then put it away in folders and backpacks. 
    
✅ To help your child be successful:
  • Part of being a successful student is completing assignments outside of school hours. It is important that your child be given opportunities to do the work they are capable of doing on their own, even if the quality and accuracy of the work is not as good as it is with your assistance. Your child will learn to take their time and put more effort into their assignments by experiencing and seeing the actual results of their own efforts, i.e. grades and test scores.
    
When your child truly needs assistance/tutoring, it is first important that they have the proper attitude before and while receiving the academic support. Children who complain and/or get down on themselves while receiving assistance are not in a learning or receptive mindset. You should walk away when your child is being inappropriate or demonstrating a negative attitude and return when they are ready to work hard, do their best and never give up.
    
💡 Parents will know they have achieved this goal when their child completes school assignments independently and receives necessary help with their studies without complaint, procrastination and/or negative attitudes.`,
    expiration: 30,
    frequency: "daily"
  },
  {
    id: 34,
    objective: "Daily Routines",
    name: "Provide your child opportunities to listen the first time you ask them to clean up.",
    description: `
For children ages 4+, parents should the following two steps:

  • Give the direction: “It's time for dinner, so please clean up your toys, wash your hands and come to the table.”
  • Walk away.
  • Do not respond to any kind of negative communication that comes back from your child, “I don't want to.” “But I don't want to eat dinner.” Do not respond to any type of negotiation response that comes back from your child. “Can I have five more minutes to finish my house I'm building?” “Can I clean up after dinner?”
    
    
    
✅ To help your child be successful:
  • Be directional in your communication as in the example above. If you ask your child, “Are you ready for dinner?” they may believe that mealtime is optional. 
  • Giving a direction and walking away provides your child space to follow your direction. Responding to your child's negative comments, functions as a distraction and may impede your child from following through.
    
💡 Parents will know they have achieved this goal when their child cleans up after being asked the first time, without resistance and without reminders or warnings.`,
    expiration: 30,
    frequency: "daily"
  },
  {
    id: 35,
    objective: "Communication & Relationship",
    name: "Teach your child to talk to you in kind and respectful ways",
    description: `
For children ages 3.5 and older, walk away at anytime during a conversation or interaction when your child does the following:

  • Whines
  • Screams
  • Yells
  • Complains
  • Uses profanity
  • Is resistant/oppositional
    
Patterns of negative communication are built when parents consistently respond to their children when they are speaking to them in ways that are inappropriate, negative and/or disrespectful. Kids whine to get what they want, scream because they don’t get what they want and sometimes use profanity because they think it is funny, Most parents respond to these communications with the intention of soothing, teaching and correcting, however, the unintended outcome is that parents are actually nurturing the very communication they are trying to eliminate.
    
✅ To help your child be successful and communicate in more age appropriate and respectful ways:
    
  • Walk away or disengage at the first sign of whining, screaming, crying, complaining or use of inappropriate language.
  • When your child is calm and in more of a receptive mode, return to discuss with your child alternative and more appropriate ways to express feelings and communicate needs and ideas.
    
💡 Parents will know they have achieved this goal as they witness the gradual reduction of inappropriate communications which will lead to the natural segue of a kid who not only communicates in more age-appropriate ways, but also listens and is more cooperative throughout the day. `,
    expiration: 30,
    frequency: "daily"
  },
  {
    id: 40,
    objective: "Child focused",
    name: "Create 10-15 minutes of special play time with your child.",
    description:
      "Genuine attention and focus is really important for your child to feel special and seen. We know there is always a lot going on, but sometimes putting your phone in a drawer can do wonders.",
    expiration: 30,
    frequency: "daily"
  },
  {
    id: 41,
    objective: "Child focused",
    name: "Praise your child for something they did well today.",
    description:
      "Celebrating the moments when your child is behaving well builds their confidence and motivation to take ownership of their behavior. In short, high-fiving your child when they cleaned up their room may work better than giving a time-out for making a mess!",
    expiration: 30,
    frequency: "daily"
  },
  {
    id: 42,
    objective: "Child focused",
    name: "Double the amount of compliments you give your child.",
    description:
      "Communicating your positive emotions and joy to your child can strengthen your bond and reduce the likelihood of them having behavioral challenges as a teenager. Besides, who doesn’t love a compliment?",
    expiration: 30,
    frequency: "daily"
  },
  {
    id: 43,
    objective: "Child focused",
    name: "Share a moment of joy or silliness with your child.",
    description:
      "Play and laughter perform an essential role in building strong and healthy relationships. These moments can help to resolve conflict, create a positive bond, and bring you closer together. Reconnect with your inner child. What used to make you laugh?",
    expiration: 30,
    frequency: "daily"
  },
  {
    id: 44,
    objective: "Child focused",
    name: "Demonstrate physical affection by giving your child a hug or a kiss.",
    description:
      "We all need hugs. Did you know we need 4 hugs a day for survival, 8 hugs for maintenance and 12 hugs for growth? A 20 second hug can help your kid grow smarter, healthier, happier, more resilient and closer to you.",
    expiration: 30,
    frequency: "daily"
  },
  {
    id: 45,
    objective: "Child focused",
    name: "Give your child space and time to play by themselves.",
    description:
      "Giving your child time to play by themselves supports creativity, empowerment, and self-determination. It’s not only adults that need alone time!",
    expiration: 30,
    frequency: "daily"
  },
  {
    id: 46,
    objective: "Child focused",
    name: "Help your child follow their morning routine.",
    description:
      "Creating and following a morning routine not only decreases everyone's stress, but also helps your child be their best self at home and at school. Get the entire family off on the right foot!",
    expiration: 30,
    frequency: "daily"
  },
  {
    id: 47,
    objective: "Child focused",
    name: "Help your child follow their bedtime routine.",
    description:
      "Creating and following a bedtime routine will help your child establish good lifelong sleeping habits. They will wake up feeling refreshed and ready to conquer the day! As a bonus, it gives you a little more time to wind down!",
    expiration: 30,
    frequency: "daily"
  },
  {
    id: 48,
    objective: "Child focused",
    name: "Have a meal with your child.",
    description:
      "Whether it’s brunch on a Sunday or family dinner, having a meal with your child can create a strong bond. An added benefit can be your child performing better at school and feeling happier overall. What’s for dinner tonight?",
    expiration: 30,
    frequency: "daily"
  },
  {
    id: 49,
    objective: "Child focused",
    name: "Follow through with consequences for the day.",
    description:
      "Boundaries and consequences teach children accountability and responsibility. Helping your child know you’re in charge will help them feel safe. We know it’s not always easy, but remember you’re setting a good example for your child to set their own boundaries.",
    expiration: 30,
    frequency: "daily"
  },
  {
    id: 50,
    objective: "Child focused",
    name: "Keep a calm voice.",
    description:
      "We know sometimes your inner football coach wants to come out, but take a deep breath and model how to be kind and respectful when feelings run hot.",
    expiration: 30,
    frequency: "daily"
  },
  {
    id: 51,
    objective: "Child focused",
    name: "When your child is having big feelings, be their emotional coach.",
    description:
      "When you become your child’s emotional coach, you help them develop the tools to be resilient and become a rockstar little human. Follow these steps: 1. Take a deep breath  2. Get down to their level 3. help them name their feelings 4. Help them calm their body 5. And figure out a solution together! Keep calm and carry on!",
    expiration: 30,
    frequency: "daily"
  },
  {
    id: 52,
    objective: "Child focused",
    name: "Recognize and praise your child’s 'brave behaviors'.",
    description:
      "Celebrating moments when your child is facing their fears or worries builds their courage to be brave. Little by little they will feel more confident. Just like the lion from the Wizard of Oz, they had their courage all along!",
    expiration: 30,
    frequency: "daily"
  },
  {
    id: 53,
    objective: "Child focused",
    name: "Model thoughts as being 'just thoughts' out loud to your child.",
    description:
      "It’s helpful for kids (and caregivers!) to recognize that their thoughts are not the same as reality. Just because we have a thought, doesn’t make it true. Thoughts can be just thoughts, and we can let them go if they do not help us. For example, you may say, 'Hmm, I am having a thought that I’m dumb because I forgot to buy eggs at the store. I am going to let that thought go, it’s just a thought and not a helpful one.'",
    expiration: 30,
    frequency: "daily"
  },
  {
    id: 54,
    objective: "Child focused",
    name: "Do a mindfulness activity with your child",
    description:
      "Mindfulness activities with your child will help them build their ability to manage their feelings no matter how big they get. This type of quality time with your child will bring you closer together. Plus it will give you a little zen...double points!",
    expiration: 30,
    frequency: "daily"
  },
  {
    id: 55,
    objective: "Caregiver self-care",
    name: "Spend 15 minutes taking care of yourself.",
    description:
      "A parent’s mental health has a direct impact on their children. Go for a walk, light your favorite candle, or just dance in the living room! It’s important to take care of you too!",
    expiration: 30,
    frequency: "daily"
  },
  {
    id: 56,
    objective: "Caregiver self-care",
    name: "Write down or think about 3 things you did well today as a parent.",
    description:
      "Celebrating the wins, even the little ones, helps build gratitude. These little wins can give you energy for when things get hard. Did you build a lego city with your child today? Write that down!",
    expiration: 30,
    frequency: "daily"
  },
  {
    id: 57,
    objective: "Caregiver self-care",
    name: "Reach out to a friend.",
    description:
      "In the busyness of family life, good friends can fall to the back burner. It’s important to stay connected to your community. We all need people in our corners!",
    expiration: 30,
    frequency: "daily"
  },
  {
    id: 58,
    objective: "Child focused",
    name: "Lead your child through at least one of the week 3 activities",
    description: `Work on the "Gratitude Jar" activity with your child. Lead your child through a Mindfulness eating activity together. Practice the "Leaves on a Stream" exercise.`,
    expiration: 30,
    frequency: "daily"
  },
  {
    id: 59,
    objective: "Building Brave Kids",
    name: "Set up Simple Practice Portal",
    description:
      "Allows you to sign consent forms, make payments, and obtain Super Bills (if desired) and get started with therapy!",
    expiration: 30
  },
  {
    id: 60,
    objective: "Building Brave Kids",
    name: 'Praise your child\'s "Brave Behaviors"',
    description:
      "Celebrating moments when your child is facing their fears or worries builds their courage to be brave. Little by little they will feel more confident. Just like the lion from the Wizard of Oz, they had their courage all along!",
    expiration: 30
  },
  {
    id: 61,
    objective: "Building Brave Kids",
    name: "Complete questionnaires",
    description: "Let's see how your child is doing so we can best tailor our treatment and helps track progress.",
    expiration: 30
  },
  {
    id: 62,
    objective: "Building Brave Kids",
    name: "Help your child complete Fear Ladder Step",
    description:
      "We tend to avoid situations that make us anxious, but doing that actually makes us feel more anxious over time! Teach your child that they are brave and can handle whatever life throws at them by helping your child take this small but important step.",
    expiration: 30
  },
  {
    id: 63,
    objective: "Building Brave Kids",
    name: 'Model "just thoughts"',
    description:
      "It’s helpful for kids (and caregivers!) to recognize that their thoughts are not the same as reality. Just because we have a thought, doesn’t make it true. Thoughts can be just thoughts, and we can let them go if they do not help us. For example, you may say, 'Hmm, I am having a thought that I'm dumb because I forgot to buy eggs at the store. I am going to let that thought go, it’s just a thought and not a helpful one.",
    expiration: 30
  },
  {
    id: 64,
    objective: "Building Brave Kids",
    name: "Complete mindfulness eating activity with your child",
    description:
      "Mindfulness activities with your child will help them build their ability to manage their feelings no matter how big they get. This type of quality time with your child will bring you closer together. Plus it will give you a little zen...double points!",
    expiration: 30
  },
  {
    id: 65,
    objective: "Building Brave Kids",
    name: "Complete a progressive muscle relaxation activity with your child",
    description:
      "Progressive muscle relaxation is a great tool your child can use anytime they are feeling worried, angry, or stressed. Follow along the Progressive muscle relaxation handout in your Parenting envelope to help your child build their ability to manage their feelings no matter how big they get.",
    expiration: 30
  },
  {
    id: 66,
    objective: "Building Brave Kids",
    name: "Complete 2 mindfulness activities with your child this week",
    description:
      "Mindfulness activities with your child will help them build their ability to manage their feelings no matter how big they get. This type of quality time with your child will bring you closer together. Plus it will give you a little zen...double points!",
    expiration: 30
  }
];
