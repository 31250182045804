import React, { useContext, useEffect, useState } from "react";
import AutomatedResponse from "./AutomatedResponse";
import { AppContext } from "../../../context/AppContext";
import { AccountType } from "../../../model/userModels";

export default function ConversationNotes({ conversation }) {
  const { user, canReceiveMessages } = useContext(AppContext);

  const [canRecipientsSendMessages, setCanRecipientsSendMessages] = useState(true);
  const [isConversationWithCaregivers, setIsConversationWithCaregivers] = useState(true);

  useEffect(() => {
    if (conversation?.users?.length === 2 && user?.id) {
      const otherParticipant = conversation.users.find(u => u.id !== user.id);

      setIsConversationWithCaregivers(otherParticipant.accountType === AccountType.PARENT);
      setCanRecipientsSendMessages(canReceiveMessages);
    }
  }, [user, canReceiveMessages, conversation]);

  if (!conversation) {
    return <></>;
  }

  if (conversation.type === "CARE_TEAM_INTERNAL") {
    return <p className="internal-message-note">You are currently messaging internally.</p>;
  }

  if (!conversation.permissions?.includes("WRITE")) {
    return <div className={"readonly-message-info"}>This conversation is read-only</div>;
  }

  if (canRecipientsSendMessages) {
    return <AutomatedResponse />;
  }

  return (
    <div className="message-note-container">
      <p className="message-note">{`Please note this ${
        isConversationWithCaregivers ? "caregiver" : "client"
      } will not be able to respond to your message.`}</p>
    </div>
  );
}
