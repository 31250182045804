import React, { useCallback, useContext, useEffect, useState } from "react";
import moment from "moment";
import { StyleSheet, css } from "aphrodite";
import Button from "../Button";
import { FamilyAssessmentContext } from "../../context/FamilyAssessmentContext";
import useFamilyMembers from "../../hooks/useFamilyMembers";
import { useAtom } from "tiny-atom/react/hooks";
import { ChevronLeft, ChevronRight } from "react-feather";

export default function AssessmentAnswers({ assessment, completedAt, userId, onBackPressed }) {
  const { completions: allCompletions } = useContext(FamilyAssessmentContext);

  const [selectedDate, setSelectedDate] = useState(completedAt);
  const [selectedCompletion, setSelectedCompletion] = useState(undefined);
  const [filteredCompletions, setFilteredCompletions] = useState([]);

  const { familyMembers } = useFamilyMembers();
  const patient = useAtom(state => state.patient);

  useEffect(() => {
    setFilteredCompletions(
      allCompletions.filter(ac => ac.clientId === userId && ac.assessmentIdentifier === assessment.identifier)
    );
  }, [allCompletions, assessment, userId]);

  useEffect(() => {
    setSelectedCompletion(
      allCompletions?.find(
        ac =>
          ac.clientId === userId && ac.completedAt === selectedDate && ac.assessmentIdentifier === assessment.identifier
      )
    );
  }, [allCompletions, selectedDate, setSelectedCompletion, assessment.identifier, userId]);

  const user = familyMembers.find(fm => fm.id === userId);

  const getPrev = useCallback(() => {
    return filteredCompletions
      .filter(
        fc => fc.id !== selectedCompletion.id && moment(new Date(fc.completedAt)).isBefore(new Date(selectedDate))
      )
      .map(fc => ({ ...fc, timeDistance: moment(new Date(fc.completedAt)).diff(new Date(selectedDate), "minutes") }))
      .sort((a, b) => {
        return b.timeDistance - a.timeDistance;
      })[0];
  }, [filteredCompletions, selectedDate, selectedCompletion]);

  const getNext = useCallback(() => {
    return filteredCompletions
      .filter(fc => fc.id !== selectedCompletion.id && moment(new Date(fc.completedAt)).isAfter(new Date(selectedDate)))
      .map(fc => ({ ...fc, timeDistance: moment(new Date(fc.completedAt)).diff(new Date(selectedDate), "minutes") }))
      .sort((a, b) => {
        return a.timeDistance - b.timeDistance;
      })[0];
  }, [filteredCompletions, selectedDate, selectedCompletion]);

  const getCategoryCriteriaForScore = (score, assessmentIdentifier) => {
    const categoryCriteria =
      assessment.subAssessments?.find(sa => sa.identifier === assessmentIdentifier)?.categoryCriteria ||
      assessment.categoryCriteria;
    return categoryCriteria.find(c => score >= c.minScore && score <= c.maxScore);
  };

  if (!selectedCompletion || !user) {
    return <></>;
  }

  const responses = [...(selectedCompletion.subResponses?.flat() || [selectedCompletion])];

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.backButtonContainer)}>
        <Button theme="manaBlue" onPress={() => onBackPressed()} title={"Back to charts"} />
      </div>
      <div className={css(styles.dateRow)}>
        <Button
          disabled={!getPrev()}
          hidden={!getPrev()}
          theme="iconButton"
          icon={ChevronLeft}
          onPress={() => {
            setSelectedDate(getPrev().completedAt);
          }}
        />
        <span className={css(styles.date)}>{moment(new Date(selectedCompletion.completedAt)).format("LL")}</span>
        <Button
          disabled={!getNext()}
          hidden={!getNext()}
          theme="iconButton"
          icon={ChevronRight}
          onPress={() => {
            setSelectedDate(getNext().completedAt);
          }}
        />
      </div>
      <div
        className={css(
          styles.answerText,
          StyleSheet.create({
            style: {
              marginTop: 18
            }
          }).style
        )}
      >
        {assessment.name} | Reported by {user.firstName}{" "}
        {selectedCompletion.assessmentParameters?.subjectUserId === patient.id ? `| Client: ${patient.firstName}` : ""}
      </div>
      <div className={css(styles.answersRow)}>
        {responses.map((response, i) => {
          const scoreCriteria = getCategoryCriteriaForScore(response.score, response.assessmentIdentifier);
          return (
            <div className={css(styles.answersColumn)} key={i}>
              <div className={css(styles.answerHeader)}>{response.name}</div>
              <div
                className={css(
                  styles.answerHeader,
                  StyleSheet.create({
                    style: {
                      marginTop: 12,
                      marginBottom: 20,
                      color: scoreCriteria.color
                    }
                  }).style
                )}
              >
                {scoreCriteria.clinicalCategory || scoreCriteria.category} - {response.score}
              </div>
              {response.answers.map((a, r) => (
                <div key={r}>
                  <div className={css(styles.answerLabel)}>{a.questionText}</div>
                  <div className={css(styles.answerText)}>{a.choiceText}</div>
                </div>
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: "42px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#ffffff"
  },
  backButtonContainer: {
    display: "flex"
  },
  dateRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: "23px"
  },
  date: {
    fontWeight: 500,
    fontSize: "40px",
    marginRight: "50px",
    marginLeft: "50px"
  },
  answersRow: {
    marginTop: "40px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center"
  },
  answersColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minWidth: 350,
    maxWidth: 500,
    margin: 10,
    padding: 10,
    paddingTop: 20,
    paddingBottom: 20,
    border: "1px solid #E0E0E0",
    borderRadius: 5
  },

  answerItem: {
    marginTop: "32px"
  },
  answerHeader: {
    fontWeight: 800,
    fontSize: "20px",
    textAlign: "center"
  },
  answerLabel: {
    fontWeight: 800,
    fontSize: "15px",
    textAlign: "center",
    marginTop: 5
  },
  answerText: {
    fontWeight: 400,
    fontSize: "15px",
    marginTop: "6px",
    textAlign: "center"
  }
});
