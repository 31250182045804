import React from "react";
import RewardItem from "./RewardItem";
import defaultRewards from "../data/defaultRewards";
import { graphQLError } from "../utils/errorUtils";
import Spinner from "./Spinner";
import "./RewardManagement.css";
import { ADULT_GET_REWARDS } from "../queries";
import { useQuery } from "@apollo/client";
import { useAtom, useActions } from "tiny-atom/react/hooks";

const list = (assigned, parentAssigned, available) => {
  let l = []
    .concat({
      id: "assigned",
      type: "separator",
      title: "Assigned"
    })
    .concat(assigned)
    .concat(parentAssigned);

  const custom = available.filter(g => g.custom);
  if (custom.length) {
    l.push({
      id: "custom",
      type: "separator",
      title: "Custom"
    });
    l = l.concat(custom);
  }

  let group;
  available.forEach(reward => {
    if (reward.custom) return;
    if (!group || reward.objective !== group) {
      group = reward.objective;
      l.push({
        id: reward.objective,
        type: "separator",
        title: reward.objective
      });
    }
    l.push(reward);
  });

  return l;
};

export default function RewardManagement() {
  const { setCurrentScreen, setReward } = useActions();
  const openDefaultRewardEditScreen = useAtom(state => state.openDefaultRewardEditScreen);
  setCurrentScreen("TherapistWebRewardManagementScreen");

  function rewardSelected(reward) {
    setReward({ params: { reward } });
  }

  const patient = useAtom(state => state.patient);
  const { loading, error, data } = useQuery(ADULT_GET_REWARDS, {
    variables: {
      childId: patient.id
    }
  });
  if (error) {
    graphQLError();
  }
  if (loading) {
    return <Spinner></Spinner>;
  }

  const { childDetails } = data;
  // values in data have .preventExtensions() set on them. Not a best practice, but our management of goals is much simpler if we can safely extend (add fields) to these objects.
  // so we make a deep copy of data.rewards to avoid the .preventExtensions() and be able to extend the object
  const rewardsNoExtensionsAllowed = data.rewards;
  const rewards = [];
  // Deep copy each item in GQL response arry to new array so that we can extend objects with our own fields
  rewardsNoExtensionsAllowed.forEach(goal => {
    rewards.push(JSON.parse(JSON.stringify(goal)));
  });

  let available = defaultRewards.concat();

  for (let i = 0, len = defaultRewards.length; i < len; i++) {
    available[i] = {};
    for (let prop in defaultRewards[i]) {
      available[i][prop] = defaultRewards[i][prop];
    }
  }

  let assigned = [];
  let parentAssigned = [];

  if (rewards && rewards.length > 0) {
    rewards.forEach(reward => {
      if (!reward.assignedTo) {
        reward.custom = true;
        reward.matchesDefaultReward = false;
        reward.matchesCustomReward = false;
        available.push(reward);
      } else if (reward.assignedTo.id === childDetails.id) {
        reward.matchesDefaultReward = false;
        reward.matchesCustomReward = false;
        assigned.push(reward);
      }
    });
    available.forEach(availableReward =>
      assigned.forEach(assignedReward => {
        if (availableReward.name === assignedReward.name) {
          availableReward.matchesCustomReward = true;
        }
      })
    );
    available.forEach(availableReward =>
      parentAssigned.forEach(parentAssignedReward => {
        if (availableReward.name === parentAssignedReward.name) {
          availableReward.matchesCustomReward = true;
        }
      })
    );
    assigned.forEach(assignedReward =>
      available.forEach(availableReward => {
        if (assignedReward.name === availableReward.name) {
          assignedReward.matchesDefaultReward = true;
        }
      })
    );
  }

  return (
    <div className="RewardManagement">
      <div className="RewardManagement-title">Rewards available for {childDetails.firstName}</div>
      <div>
        {list(assigned, parentAssigned, available).map(reward => (
          <RewardItem
            key={reward.id}
            item={reward}
            openDefaultRewardEditScreen={() => openDefaultRewardEditScreen()}
            rewardSelected={reward => rewardSelected(reward)}
          />
        ))}
      </div>
    </div>
  );
}
