import React, { useContext, useMemo } from "react";
import Spinner from "../Spinner";
import Text from "../core/Text";
import MatchedCareProviderSelector from "./MatchedCareProviderSelector";
import useCareTeamMemberMatches from "../../hooks/useCareTeamMemberMatches";
import { AccountType, User } from "../../model/userModels";
import { UserContext } from "../../context/UserContext";

type Props = {
  userId: number;
  onCareProviderSelected: (careTeamMember: User) => void;
  selectButtonTitle: string;
};

export default function MatchedCareProviderSelection({ userId, onCareProviderSelected, selectButtonTitle }: Props) {
  const { user } = useContext(UserContext) || {};
  const { data, loading, careTeamMembers, familyMembers } = useCareTeamMemberMatches([userId]);

  const appointmentUser = familyMembers.find(m => m.id === userId);

  const careProviders = useMemo(
    () => careTeamMembers.filter(ctm => AccountType.isCareProvider(ctm.accountType)),
    [careTeamMembers]
  );

  const showNoResults = useMemo(() => !loading && data && !careProviders.length, [loading, data, careProviders.length]);

  return (
    <>
      {user && (
        <Text theme="manaBlue" size={20} center style={{ padding: 10, paddingTop: 20 }}>
          Hi {user.firstName},
        </Text>
      )}
      {appointmentUser && !showNoResults && (
        <Text weight={700} size={24} center theme="manaBlueDark" style={{ marginBottom: 20 }}>
          Let's schedule {appointmentUser.id === user?.id ? "your" : `${appointmentUser.firstName}'s`} first session! ☀️
        </Text>
      )}
      {loading && <Spinner />}
      {showNoResults && (
        <Text center style={{ padding: 20 }}>
          No care providers have been matched yet. Please contact your care coordinator about identifying specific care
          providers for {appointmentUser.id === user?.id ? "you" : `${appointmentUser.firstName}`}.
        </Text>
      )}
      {data && !!careProviders && careProviders.length > 0 && (
        <>
          {careProviders.length > 1 && (
            <Text center theme="manaBlue" weight={700} size={16} style={{ paddingBottom: 20 }}>
              Choose a matched provider:
            </Text>
          )}
          {careProviders.map(careProvider => (
            <MatchedCareProviderSelector
              key={careProvider.id}
              careProvider={careProvider}
              onCareProviderSelected={() => {
                onCareProviderSelected(careProvider);
              }}
              selectButtonTitle={selectButtonTitle}
            />
          ))}
        </>
      )}
    </>
  );
}
