import { Route } from "react-router-dom";
import React, { ReactNode, useContext, useMemo } from "react";
import Forms from "../pages/Forms";
import { AccountType } from "../model/userModels";
import AccountTypeRoutes from "./AccountTypeRoutes";
import { UserContext } from "../context/UserContext";

export default function ChildRoutes() {
  const { authenticated } = useContext(UserContext) || {};

  const routes: ReactNode = useMemo(
    () => (
      <>
        {authenticated && <Route path="/" element={<Forms />} />}
        <Route path="/complete-forms" element={<Forms />} />
      </>
    ),
    [authenticated]
  );

  return <AccountTypeRoutes routeElements={routes} supportedAccountTypes={[AccountType.CHILD]} />;
}
