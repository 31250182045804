import React from "react";

export const sideBarWidthRef = React.createRef();

export const SideBarContext = React.createContext({
  sideBarRef: undefined
});

export function SideBarContextProvider({ sideBarRef, children }) {
  sideBarWidthRef.current = sideBarRef?.current?.clientWidth;
  return <SideBarContext.Provider value={{ sideBarRef }}>{children}</SideBarContext.Provider>;
}
