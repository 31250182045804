import React from "react";
import { ReactNode, useMemo } from "react";
import { createRoutesFromElements, matchPath } from "react-router-dom";

type Props = {
  routeElements: ReactNode;
  children: ReactNode;
};

export default function WhenRouteMatched({ routeElements: routeComponents, children }: Props) {
  const routes = useMemo(() => createRoutesFromElements(routeComponents), [routeComponents]);
  const routeMatch = routes.some(r => r.path && matchPath(r.path, location.pathname));

  return <>{routeMatch && children}</>;
}
