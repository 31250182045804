import _ from "lodash";

// Since default goals get sorted with actual goals, they must have unique IDs to the assigned ones.
// Default go IDs should NEVER be persisted so I've chosen max int value for Javascript which is higher than MySQL.
// A new, valid, ID will be set upon assignment.
const MaxInt = 9007199254740991;

export default _([
  {
    id: MaxInt - 1,
    objective: "Bond as a family",
    name: "Host a game night",
    imageId: 1001,
    size: "Easy",
    cost: 5
  },
  {
    id: MaxInt - 2,
    objective: "Give additional freedom/responsibility",
    name: "Decide what is for dinner",
    imageId: 1002,
    size: "Easy",
    cost: 5
  },
  {
    id: MaxInt - 3,
    objective: "Give perk or privilege",
    name: "Pick out dessert",
    imageId: 1003,
    size: "Easy",
    cost: 5
  },
  {
    id: MaxInt - 4,
    objective: "Give additional freedom/responsibility",
    name: "Choose a movie to watch",
    imageId: 1004,
    size: "Medium",
    cost: 10
  },
  {
    id: MaxInt - 5,
    objective: "Bond as a family",
    name: "Go to the movies",
    imageId: 1005,
    size: "Medium",
    cost: 10
  },
  {
    id: MaxInt - 6,
    objective: "Bond as a family",
    name: "Go to a game together (any sports)",
    imageId: 1006,
    size: "Medium",
    cost: 20
  },
  {
    id: MaxInt - 7,
    objective: "Give additional freedom/responsibility",
    name: "Own the remote control for two hours",
    imageId: 1007,
    size: "Medium",
    cost: 10
  },
  {
    id: MaxInt - 8,
    objective: "Give additional freedom/responsibility",
    name: "Late bedtime",
    imageId: 1008,
    size: "Medium",
    cost: 15
  },
  {
    id: MaxInt - 9,
    objective: "Give perk or privilege",
    name: "Extra hour of playing video games",
    imageId: 1009,
    size: "Medium",
    cost: 15
  },
  {
    id: MaxInt - 10,
    objective: "Give perk or privilege",
    name: "Extend screentime",
    imageId: 1030,
    size: "Medium",
    cost: 15
  },
  {
    id: MaxInt - 11,
    objective: "Bond as a family",
    name: "Trip to the zoo (museum, library etc.)",
    imageId: 1011,
    size: "Medium",
    cost: 20
  },
  {
    id: MaxInt - 12,
    objective: "Give additional freedom/responsibility",
    name: "Plan a weekend activity",
    imageId: 1012,
    size: "Hard",
    cost: 25
  },
  {
    id: MaxInt - 13,
    objective: "Bond as a family",
    name: "Schedule a trip together",
    imageId: 1013,
    size: "Hard",
    cost: 75
  },
  {
    id: MaxInt - 14,
    objective: "Give perk or privilege",
    name: "Day off chores",
    imageId: 1014,
    size: "Hard",
    cost: 30
  },
  {
    id: MaxInt - 15,
    objective: "Give additional freedom/responsibility",
    name: "Have a sleepover",
    imageId: 1015,
    size: "Hard",
    cost: 30
  },
  {
    id: MaxInt - 16,
    objective: "Buy something",
    name: "Buy ice cream",
    imageId: 1016,
    size: "Easy",
    cost: 10
  },
  {
    id: MaxInt - 17,
    objective: "Buy something",
    name: "Buy a new book",
    imageId: 1017,
    size: "Hard",
    cost: 30
  },
  {
    id: MaxInt - 18,
    objective: "Buy something",
    name: "Buy a new game",
    imageId: 1018,
    size: "Hard",
    cost: 35
  },
  {
    id: MaxInt - 19,
    objective: "Buy something",
    name: "Earn an item",
    imageId: 1019,
    size: "Hard",
    cost: 50
  },
  {
    id: MaxInt - 20,
    objective: "Give additional freedom/responsibility",
    name: "Pick out lunch",
    imageId: 1025,
    size: "Easy",
    cost: 5
  },
  {
    id: MaxInt - 21,
    objective: "Give perk or privilege",
    name: "Get extra treat in lunch box",
    imageId: 1026,
    size: "Easy",
    cost: 5
  },
  {
    id: MaxInt - 22,
    objective: "Give perk or privilege",
    name: "Pizza night!",
    imageId: 1027,
    size: "Medium",
    cost: 10
  },
  {
    id: MaxInt - 23,
    objective: "Give perk or privilege",
    name: "Extra hour of play time",
    imageId: 1028,
    size: "Medium",
    cost: 15
  },
  {
    id: MaxInt - 24,
    objective: "Give perk or privilege",
    name: "Download new app",
    imageId: 1029,
    size: "Hard",
    cost: 50
  },
  {
    id: MaxInt - 25,
    objective: "Give perk or privilege",
    name: "Increase weekly allowance one time",
    imageId: 1031,
    size: "Hard",
    cost: 10
  },
  {
    id: MaxInt - 26,
    objective: "Bond as a family",
    name: "Visit an animal shelter",
    imageId: 1032,
    size: "Medium",
    cost: 7
  },
  {
    id: MaxInt - 27,
    objective: "Buy something",
    name: "Buy a new audiobook",
    imageId: 1033,
    size: "Medium",
    cost: 10
  },
  {
    id: MaxInt - 28,
    objective: "Give additional freedom/responsibility",
    name: "Go camping in the backyard",
    imageId: 1034,
    size: "Medium",
    cost: 7
  },
  {
    id: MaxInt - 29,
    objective: "Give additional freedom/responsibility",
    name: "Extend curfew",
    imageId: 1035,
    size: "Medium",
    cost: 7
  },
  {
    id: MaxInt - 30,
    objective: "Bond as a family",
    name: "Date with dad",
    imageId: 1036,
    size: "Medium",
    cost: 7
  },
  {
    id: MaxInt - 31,
    objective: "Bond as a family",
    name: "Go for a hike at a nearby forest",
    imageId: 1037,
    size: "Medium",
    cost: 5
  },
  {
    id: MaxInt - 32,
    objective: "Give additional freedom/responsibility",
    name: "Stay over at your grandparents",
    imageId: 1038,
    size: "Medium",
    cost: 7
  },
  {
    id: MaxInt - 33,
    objective: "Give perk or privilege",
    name: "Make hot chocolate",
    imageId: 1039,
    size: "Easy",
    cost: 3
  },
  {
    id: MaxInt - 34,
    objective: "Buy something",
    name: "Get a new magazine subscription",
    imageId: 1040,
    size: "Hard",
    cost: 15
  },
  {
    id: MaxInt - 35,
    objective: "Bond as a family",
    name: "Trip to the mall",
    imageId: 1041,
    size: "Medium",
    cost: 5
  },
  {
    id: MaxInt - 36,
    objective: "Bond as a family",
    name: "Date with mom",
    imageId: 1042,
    size: "Medium",
    cost: 7
  },
  {
    id: MaxInt - 37,
    objective: "Give additional freedom/responsibility",
    name: "Paint your bedroom a new color",
    imageId: 1043,
    size: "Hard",
    cost: 10
  },
  {
    id: MaxInt - 38,
    objective: "Give additional freedom/responsibility",
    name: "Sleep in on the weekend",
    imageId: 1044,
    size: "Medium",
    cost: 7
  },
  {
    id: MaxInt - 39,
    objective: "Give additional freedom/responsibility",
    name: "Sleepover at a friend's house",
    imageId: 1045,
    size: "Hard",
    cost: 10
  },
  {
    id: MaxInt - 40,
    objective: "Bond as a family",
    name: "Go swimming",
    imageId: 1046,
    size: "1046",
    cost: 5
  },
  {
    id: MaxInt - 41,
    objective: "Give perk or privilege",
    name: "Choose take-out",
    imageId: 1047,
    size: "Medium",
    cost: 5
  }
])
  .sortBy(r => r.cost)
  .sortBy(r => r.objective)
  .value();
