import { StyleSheet, css } from "aphrodite";
import React from "react";
import BlueCheckMark from "../../assets/icons/check-mark-contained-round-blue.png";
import BlueCircle from "../../assets/icons/circle-blue.png";
import BlueCircleLight from "../../assets/icons/circle-blue-light.png";

type Props = {
  steps: string[];
  index: number;
};

export default function ProgressHeader({ steps, index }: Props) {
  return (
    <div className={css(styles.row)}>
      {steps.map((name: string, i: number) => (
        <ProgressStep key={name} number={i + 1} isActive={index === i} name={name} isCompleted={i < index} />
      ))}
    </div>
  );
}

type ProgressStepProps = {
  number: number;
  isActive?: boolean;
  isCompleted?: boolean;
  name: string;
};

export function ProgressStep({ number, isActive, isCompleted, name }: ProgressStepProps) {
  return (
    <div className={css(styles.container)}>
      <div className={css(styles.iconContainer)}>
        <img className={css(styles.icon)} src={isCompleted ? BlueCheckMark : isActive ? BlueCircle : BlueCircleLight} />
        {!isCompleted && <p className={css([styles.iconNumber, isActive ? styles.white : styles.blue])}>{number}</p>}
      </div>
      <p className={css(styles.name)}>{name}</p>
    </div>
  );
}

const styles = StyleSheet.create({
  iconContainer: {
    position: "relative"
  },
  icon: {
    width: 30,
    height: 30
  },
  iconNumber: {
    position: "absolute",
    fontWeight: 800,
    fontSize: 18,
    margin: "auto",
    left: 0,
    right: 0,
    top: 5,
    bottom: 0,
    zIndex: 100
  },
  white: {
    color: "#FFFFFF"
  },
  blue: {
    color: "#2562D8"
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: 10,
    maxWidth: 125
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-start",
    textAlign: "center"
  },
  name: {
    color: "#1A2579",
    fontSize: 16,
    fontWeight: 700,
    lineHeight: 1.2
  }
});
