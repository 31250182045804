import React from "react";
import { useAtom } from "tiny-atom/react/hooks";
import ClientProfile from "./ClientProfile";

export default function ParentProfiles() {
  const patientData = useAtom(state => state.patientData);
  const parents = patientData?.childParents || [];

  return (
    <>
      {parents.map(parent => (
        <ClientProfile key={parent.id} user={parent} />
      ))}
    </>
  );
}
