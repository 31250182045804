import React from "react";

import "./Header.css";

const Header = ({ text, children, narrow }) => (
  <div className={narrow ? "header-container-narrow" : "header-container"}>
    <div className={narrow ? "header-text-narrow" : "header-text"}>{text}</div>
    {children}
  </div>
);

export default Header;
