import React, { useState } from "react";
import { BlockStack, TextField, Button, Select } from "@shopify/polaris";
import { Formik } from "formik";
import "./RemoveConnection.css";
import { REMOVE_CONNECTION, GET_CHILDREN_BY_PARENT_EMAIL } from "../queries";
import { logEvent } from "../actions";
import Modal from "../components/Modal";
import { useActions } from "tiny-atom/react/hooks";
import FormErrorMessage from "../components/FormErrorMessage";
import { View } from "../components/react-native";
import { useQuery, useMutation } from "@apollo/client";

export default function RemoveConnection() {
  const { setCurrentScreen, displayToast } = useActions();
  const [parentEmail, setParentEmail] = useState("");
  const [validatedParentEmail, setValidatedParentEmail] = useState("");
  const [disconnectFromCTMUserId, setDisconnectToCareMemberUserId] = useState("");
  const [disconnectFromCTMUserName, setDisconnectToCareMemberUserName] = useState("");
  const [selectedClientId, setSelectedClientId] = useState("");
  const [selectedClientName, setSelectedClientName] = useState("");
  const [loading, setLoading] = useState(false);
  const [careTeam, setCareTeam] = useState(undefined);
  const [showConfirmationModal, setShowConfirmationModalMessage] = useState(false);
  const [modalMessage, setModalMessage] = useState(undefined);
  const [removeConnectionMutation] = useMutation(REMOVE_CONNECTION);

  setCurrentScreen("RemoveConnectionScreen");

  const { data: childData } = useQuery(GET_CHILDREN_BY_PARENT_EMAIL, {
    variables: { parentEmail: validatedParentEmail },
    skip: !validatedParentEmail,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    onError: error => {
      onError(error);
    }
  });

  const childList = childData?.childrenByParentEmail
    ?.filter(client => client.therapists?.length || client.careCoordinators?.length || client.parentCoaches?.length)
    .map(client => {
      return {
        label: `${client.firstName} ${client.lastName}`,
        value: client.id
      };
    });

  const ctmList = careTeam?.map(ctm => {
    return {
      label: `${ctm.user.firstName} ${ctm.user.lastName}`,
      value: ctm.user.id
    };
  });

  function handleChildSelectChange(userId) {
    setSelectedClientId(parseInt(userId, 10));
    const child = childData.childrenByParentEmail.find(kid => kid.id === parseInt(userId));
    let CTMs = [];
    for (const therapist of child.therapists) {
      CTMs.push(therapist);
    }
    for (const parentcoach of child.parentCoaches) {
      CTMs.push(parentcoach);
    }
    for (const cc of child.careCoordinators) {
      CTMs.push(cc);
    }
    if (CTMs.length > 1) {
      CTMs.push({
        user: {
          id: -1,
          firstName: "All",
          lastName: "Care Team Members"
        }
      });
    }
    setSelectedClientName(`${child.firstName} ${child.lastName}`);
    setCareTeam(CTMs);
  }

  function handleCareTeamSelectChange(userId) {
    const userIdInt = parseInt(userId, 10);
    setDisconnectToCareMemberUserId(userIdInt);
    let ctmName;
    if (userIdInt === -1) {
      ctmName = "all care team members";
    } else {
      ctmName = `care team member ${
        ctmList.find(ctm => {
          return ctm.value === userIdInt;
        }).label
      }`;
    }
    setDisconnectToCareMemberUserName(ctmName);
  }

  async function removeConnection() {
    setLoading(true);
    try {
      const disconnectAll = disconnectFromCTMUserId === -1;
      await removeConnectionMutation({
        variables: {
          disconnectCareMemberUserId: disconnectAll ? undefined : disconnectFromCTMUserId,
          disconnectAllCareTeamMembers: disconnectAll,
          disconnectUserId: selectedClientId
        }
      });
      onMutationSuccess();
    } catch (e) {
      onError(e);
    } finally {
      setShowConfirmationModalMessage(false);
      setLoading(false);
    }
  }

  function onMutationSuccess() {
    logEvent("removeConnectionSuccess");
    setParentEmail("");
    setValidatedParentEmail("");
    setDisconnectToCareMemberUserId("");
    setSelectedClientId("");
    setCareTeam(undefined);
    displayToast({ text: "Disconnected!" });
  }

  function onError(error) {
    setModalMessage(error?.message || "Something went wrong. Please try again later.");
    setParentEmail("");
    setValidatedParentEmail("");
    setSelectedClientId("");
    setCareTeam(undefined);
    setDisconnectToCareMemberUserId("");
  }

  return (
    <div>
      <div>
        <Formik
          initialValues={{ parentEmail: "", selectedClientId: "", disconnectFromCTMUserId: "" }}
          validate={() => {
            let errors = {};
            if (!parentEmail) {
              errors.parentEmail = "Required";
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(parentEmail)) {
              errors.parentEmail = "Invalid email address";
            }
            if (childData && !selectedClientId) {
              errors.selectedClientId = "Required";
            }
            if (careTeam && !disconnectFromCTMUserId) {
              errors.disconnectFromCTMUserId = "Required";
            }
            return errors;
          }}
          onSubmit={() => {
            setTimeout(() => {}, 400);
          }}
        >
          {({ handleSubmit, validateForm }) => (
            <div className="Connection">
              <form className="Connection-form" onSubmit={handleSubmit}>
                <div className="Connection-header">Disconnect Family</div>
                <div className="Connection-subtitle">
                  Disconnect a client from one or all Manatee Care Team members.
                </div>
                <BlockStack>
                  <div className="connection-parent-email Connection-label ">Parent&apos;s email</div>
                  <div className="Margin-bottom">
                    <TextField
                      placeholder="person@mail.net"
                      onChange={email => {
                        setSelectedClientId("");
                        setDisconnectToCareMemberUserId("");
                        setCareTeam(undefined);
                        setValidatedParentEmail("");
                        setParentEmail(email);
                      }}
                      type="email"
                      name="parentEmail"
                      value={parentEmail}
                    />
                  </div>
                  <FormErrorMessage name="parentEmail" component="div" />
                  {childData && validatedParentEmail && (
                    <View>
                      <div className="connection-parent-email Connection-label">Child (client)</div>
                      <div className="Margin-bottom">
                        <Select
                          options={childList}
                          placeholder="choose child"
                          onChange={handleChildSelectChange}
                          value={selectedClientId}
                          labelInline={true}
                        />
                      </div>
                      <FormErrorMessage name="selectedClientId" component="div" />
                    </View>
                  )}
                  {childData && careTeam && (
                    <View>
                      <div className="connection-parent-email Connection-label ">Care Team Member</div>
                      <Select
                        options={ctmList}
                        placeholder="choose care team member"
                        onChange={handleCareTeamSelectChange}
                        value={disconnectFromCTMUserId}
                        labelInline={true}
                      />
                      <FormErrorMessage name="disconnectFromCTMUserId" component="div" />
                    </View>
                  )}
                  <View>
                    <Button
                      id="Connection-submit"
                      fullWidth
                      variant="primary"
                      submit
                      disabled={loading}
                      onClick={async () => {
                        const validationErrors = await validateForm();
                        if (
                          !validationErrors.parentEmail &&
                          !validationErrors.selectedClientId &&
                          !validationErrors.disconnectFromCTMUserId
                        ) {
                          if (validatedParentEmail && selectedClientId && disconnectFromCTMUserId) {
                            setShowConfirmationModalMessage(true);
                          } else {
                            setValidatedParentEmail(parentEmail);
                          }
                        } else {
                          handleSubmit();
                        }
                      }}
                    >
                      {selectedClientId && disconnectFromCTMUserId ? "Disconnect" : "Continue"}
                    </Button>
                  </View>
                </BlockStack>
              </form>
              <Modal
                title={modalMessage}
                hidden={!modalMessage}
                onButton1Press={() => {
                  setModalMessage(undefined);
                }}
                fullscreen={true}
              />
              {showConfirmationModal && (
                <>
                  <div className="Modal-overlay" />
                  <Modal
                    title="Disconnect Family"
                    subtitle={`Disconnect client ${selectedClientName} from ${disconnectFromCTMUserName}?`}
                    centeredMainPanel
                    titleStyle={titleStyle}
                    subtitleStyle={subtitleStyle}
                    button1Label="Confirm"
                    button1Theme="red"
                    onButton1Press={async () => {
                      await removeConnection();
                    }}
                    button2Label="Cancel"
                    button2Theme="gray"
                    onButton2Press={() => {
                      setShowConfirmationModalMessage(false);
                    }}
                  />
                </>
              )}
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
}

const titleStyle = {
  color: "#000000",
  fontFamily: "Avenir Next",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "40px",
  lineHeight: "27px",
  textAlign: "center",
  marginBottom: "50px"
};

const subtitleStyle = {
  color: "#000000",
  fontFamily: "Avenir Next",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "18px",
  lineHeight: "27px",
  textAlign: "center",
  marginBottom: "15px"
};
