import React, { useCallback, useState } from "react";
import { useAtom } from "tiny-atom/react/hooks";
import moment from "moment";
import { MENTION_DISPLAY_REGEX, MENTION_ID_REGEX, MENTION_REGEX, URL_REGEX } from "../../utils/messagesUtils";
import { MoreHorizontal, Trash2 } from "react-feather";
import { Popover } from "@shopify/polaris";
import { DELETE_MESSAGE } from "../../queries";
import { useMutation } from "@apollo/client";
import ManateeModal from "../Modal";
import MessageAttachments from "./MessageAttachments";

export default function Message({ message, conversation, asUserId, index, refetch }) {
  const userId = useAtom(state => state.userId);

  const [showDotMenu, setShowDotMenu] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const [hideConfirmDeleteModal, setHideConfirmDeleteModal] = useState(true);

  const [deleteMessage] = useMutation(DELETE_MESSAGE);

  const getConversationBackgroundColor = useCallback(
    message => {
      if (message?.automated) {
        return "#BBD2FF";
      }
      if (conversation.type === "CARE_TEAM_INTERNAL") {
        return "#8d2995";
      }
      return "#2562d8";
    },
    [conversation]
  );

  const formatUrl = url => {
    let formatted = url;
    if (!url.toLowerCase().includes("https://") && !url.toLowerCase().includes("http://")) {
      formatted = "https://" + url;
    }
    return formatted;
  };

  const renderText = (txt, urlColor, style, mentionStyle) => {
    const mentionSplit = txt.split(MENTION_REGEX);
    const urlsSplit = mentionSplit.map(part => part.split(URL_REGEX)).flat();

    return (
      <div style={style}>
        {urlsSplit.map((part, index) =>
          URL_REGEX.test(part) ? (
            <a
              key={index}
              style={{ color: urlColor, ...styles.linkText }}
              href={formatUrl(part)}
              target={"_blank"}
              rel="noreferrer"
            >
              {part}
            </a>
          ) : (
            <React.Fragment key={index}>
              {MENTION_DISPLAY_REGEX.test(part) ? (
                <span style={mentionStyle}>
                  {part.replace("{{", "").replace("}}", "").replace(MENTION_ID_REGEX, "")}
                </span>
              ) : (
                <span>{part}</span>
              )}
            </React.Fragment>
          )
        )}
        {message.hasAttachments && <MessageAttachments message={message} asUserId={asUserId} />}
      </div>
    );
  };

  let seenAtLabel = undefined;
  if (conversation?.recipientReadAt === "unknown") {
    seenAtLabel = "seen";
  } else if (conversation?.recipientReadAt) {
    seenAtLabel = `seen ${moment(conversation.recipientReadAt).calendar().toLowerCase()}`;
  }

  if (userId !== message.from.id) {
    return (
      <div style={styles.messageLeftContainer}>
        <div style={styles.messageDateText}>{moment(message.date).calendar().toLowerCase()}</div>
        {renderText(message.message, "#2562D8", { ...styles.message, ...styles.messageLeft }, styles.leftMention)}
        <div style={styles.messageFromText}>
          {message?.automated ? (
            <b>
              Automated Message from {message.from.firstName} {message.from.lastName}
            </b>
          ) : (
            <b>
              {message.from.firstName} {message.from.lastName}
            </b>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div
        style={styles.messageRightContainer}
        onMouseEnter={() => {
          setShowDotMenu(true);
        }}
        onMouseLeave={() => {
          if (hideConfirmDeleteModal) {
            setShowDotMenu(false);
            setShowPopover(false);
          }
        }}
      >
        <div style={styles.messageContainerWithDots}>
          {showDotMenu ? (
            <div>
              <Popover
                active={showPopover}
                fullHeight
                activator={
                  <MoreHorizontal
                    color="#1A2579"
                    size={26}
                    style={styles.messageDotsMenu}
                    onClick={() => {
                      setShowPopover(true);
                    }}
                  />
                }
                autofocusTarget="first-node"
                onClose={() => {}}
              >
                <div style={styles.contextMenuContent}>
                  <ManateeModal
                    title="Delete message"
                    subtitle="Are you sure you want to delete this message?"
                    subtitleStyle={styles.modalSubtitle}
                    hidden={hideConfirmDeleteModal}
                    button2Label="Cancel"
                    button2Theme="gray"
                    onButton2Press={() => setHideConfirmDeleteModal(true)}
                    button3Label="Delete"
                    button3Theme="red"
                    onButton3Press={async () => {
                      await deleteMessage({ variables: { messageId: message.id } });
                      setHideConfirmDeleteModal(true);
                      setShowDotMenu(false);
                      refetch();
                    }}
                    centeredAbsolute
                    horizontalButtons
                  />
                  <div onClick={async () => setHideConfirmDeleteModal(false)} style={styles.contextMenuButton}>
                    Delete message
                    <Trash2 color="#FF2132" size={20} style={styles.contextMenuIcon} />
                  </div>
                </div>
              </Popover>
            </div>
          ) : (
            <div style={styles.messageDotsNoMenu} />
          )}
          <div>
            <div style={styles.messageDateText}>{moment(message.date).calendar().toLowerCase()}</div>
            {renderText(
              message.message,
              message?.automated ? "#2562D8" : "#FFFFFF",
              {
                ...styles.message,
                ...styles.messageRight,
                color: message?.automated ? "#1A2579" : "#FFFFFF",
                backgroundColor: getConversationBackgroundColor(message)
              },
              styles.rightMention
            )}
            <div>
              <p style={styles.sentMessageFooter}>
                {message?.automated && <span style={styles.automatedLabel}>Automated Message </span>}
                {seenAtLabel && index === 0 && <span style={styles.seenDateText}>{seenAtLabel}</span>}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  messageContainerWithDots: {
    display: "flex"
  },
  messageRightContainer: {
    position: "relative",
    margin: "15px 15px 15px auto",
    maxWidth: "65%"
  },
  messageLeftContainer: {
    position: "relative",
    margin: "15px auto 15px 15px",
    maxWidth: "65%"
  },
  messageDateText: {
    color: "#999999",
    textAlign: "center",
    paddingBottom: 5,
    fontSize: 12,
    fontWeight: 400
  },
  linkText: { fontFamily: "Rota-ExtraBold" },
  leftMention: { backgroundColor: "#F4D35E", paddingLeft: 2, paddingRight: 2, borderRadius: 4, color: "#1A2579" },
  rightMention: { backgroundColor: "#EECCF1", paddingLeft: 2, paddingRight: 2, borderRadius: 4, color: "#1A2579" },
  sentMessageFooter: {
    textAlign: "center",
    paddingTop: 5
  },
  seenDateText: {
    color: "#999999",
    fontSize: 12,
    fontWeight: 400,
    paddingRight: 3,
    paddingLeft: 3
  },
  automatedLabel: {
    color: "#2562D8",
    paddingLeft: 5,
    paddingRight: 5,
    fontSize: 12,
    fontWeight: "bold"
  },
  message: {
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    padding: 10,
    borderRadius: 15,
    fontWeight: 400,
    fontFamily: "Helvetica Neue"
  },
  messageRight: {
    backgroundColor: "#5C76F4",
    borderBottomRightRadius: 0
  },
  messageLeft: {
    color: "#242424",
    backgroundColor: "#FFFFFF",
    borderBottomLeftRadius: 0
  },
  messageFromText: {
    color: "#999999",
    textAlign: "left",
    marginTop: 5,
    fontSize: 12,
    fontWeight: "bold"
  },
  messageDotsMenu: {
    marginTop: 18,
    marginRight: 8,
    cursor: "pointer"
  },
  messageDotsNoMenu: {
    marginRight: 8,
    minWidth: 26
  },
  contextMenuContent: {
    display: "flex",
    flexDirection: "column",
    margin: 10
  },
  contextMenuButton: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#ffffff",
    cursor: "pointer",
    fontWeight: "bold",
    padding: 8,
    color: "#FF2132"
  },
  contextMenuIcon: {
    marginLeft: 15,
    fill: "none"
  },
  modalSubtitle: {
    marginTop: 15,
    marginBottom: 30
  }
};
