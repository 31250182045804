/* eslint-disable no-unused-vars */
import { ApolloClient, defaultDataIdFromObject } from "@apollo/client";
import { InMemoryCache } from "@apollo/client/cache";
import { HttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { ApolloLink } from "@apollo/client";
import Cookies from "universal-cookie";
import config from "../config/env";
import AuthService from "../services/AuthService";
import { reportError } from "../utils/errorUtils";
import { RetryLink } from "@apollo/client/link/retry";

const retryLink = new RetryLink({
  delay: {
    initial: 500,
    max: 30000,
    jitter: true
  },
  attempts: {
    max: 10,
    retryIf: (error, operation) => {
      const retry = operation?.query?.definitions?.length && operation.query.definitions[0]?.operation === "query";

      if (config.ENV !== "production" && retry) {
        console.log(`Query failed. Retrying.`);
      }
      return retry;
    }
  }
});

const client = new ApolloClient({
  link: ApolloLink.from([
    retryLink,
    onError(({ graphQLErrors, networkError }) => {
      const cookies = new Cookies();

      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) => {
          reportError(`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`);
          if (message === "User not authorized" || message === "User is not a care team member") {
            AuthService.logout();
            window.location.href = "/login";
          }
        });
      if (networkError) {
        if (networkError.statusCode === 401) {
          AuthService.removeAuthToken();
        } else {
          reportError(`[Network error]: ${networkError}`);
        }
      }

      if (!cookies.get("manateeAuthToken")) {
        AuthService.logout();
        sessionStorage.removeItem("familyId");
        window.location.href = "/login";
      }
    }),
    setContext((_, { headers }) => {
      const cookies = new Cookies();
      const authToken = cookies.get("manateeAuthToken");
      const twoFAtoken = cookies.get("manatee2FA");

      // return the headers to the context so httpLink can read them
      const resp = {
        headers: {
          ...headers,
          "x-access-token": authToken ? `${authToken}` : "",
          "x-2FA-token": twoFAtoken ? `${twoFAtoken}` : ""
        }
      };
      return resp;
    }),
    new HttpLink({
      uri: config.env.REACT_APP_GRAPHQL,
      credentials: "omit"
    })
  ]),
  cache: new InMemoryCache({
    dataIdFromObject(responseObject) {
      switch (responseObject.__typename) {
        case "Conversation":
          return responseObject._id && responseObject.__typename ? responseObject._id + responseObject._typename : null;
        default:
          return defaultDataIdFromObject(responseObject);
      }
    },
    typePolicies: {
      PendingGoal: {
        keyFields: ["goalId", "goalType"]
      },
      therapistToUserInvite: {
        keyFields: ["inviteId"]
      },
      Query: {
        fields: {
          me: {
            merge(existing, incoming) {
              return { ...existing, ...incoming };
            }
          },
          therapistInvites: {
            merge(existing = [], incoming) {
              return incoming;
            }
          },
          adultGoals: {
            merge(existing = [], incoming) {
              return incoming;
            }
          },
          childGoals: {
            merge(existing = [], incoming) {
              return incoming;
            }
          },
          goals: {
            merge(existing = [], incoming) {
              return incoming;
            }
          },
          assessmentAssignments: {
            merge(existing = [], incoming) {
              return incoming;
            }
          }
        }
      },
      Parent: {
        fields: {
          goals: {
            merge(existing = [], incoming) {
              return incoming;
            }
          }
        }
      }
    }
  })
});

export default client;
