import React from "react";
import { useAtom } from "tiny-atom/react/hooks";
import "./OverviewStats.css";

function Progress({ count, total, max }) {
  if (!count) {
    return <div></div>;
  }

  return (
    <div className="progress">
      <div className="progress-bar" style={{ width: `${Math.round((count / (total || max)) * 100)}%` }} />
      <div className="progress-label">{total ? `${count}/${total}` : `${count}`}</div>
    </div>
  );
}

export default function OverviewStats() {
  const patient = useAtom(state => state.patient);
  const data = useAtom(state => state.patientData);

  const top3G = topGoals(data);
  const top1Goal = top3G[0] ? top3G[0].split("%") : [];
  const top2Goal = top3G[1] ? top3G[1].split("%") : [];
  const top3Goal = top3G[2] ? top3G[2].split("%") : [];
  const top3R = topRewards(data.rewards, 7);
  const top1Reward = top3R[0] ? top3R[0].split("%") : [];
  const top2Reward = top3R[1] ? top3R[1].split("%") : [];
  const top3Reward = top3R[2] ? top3R[2].split("%") : [];

  function topGoals(patientData) {
    const topGoals = [...patientData.childGoals]
      .sort((a, b) => {
        return b.achievements.length - a.achievements.length;
      })
      .slice(0, 3)
      .map(goal => goal.name + "%" + goal.achievements.length);

    return topGoals;
  }

  function topRewards(rewards) {
    let topRewards = ["", "", ""];
    let rewardCount = [];

    for (let i = 0; i < rewards.length; i++) {
      rewardCount[i] = rewards[i].redemptions.length;

      if (rewardCount[i] > topRewards[0]) {
        topRewards.splice(0, 0, `${rewards[i].name}%${rewards[i].redemptions.length}`);
      } else if (rewardCount[i] > topRewards[1]) {
        topRewards.splice(1, 0, `${rewards[i].name}%${rewards[i].redemptions.length}`);
      } else if (rewardCount[i] > topRewards[2]) {
        topRewards.splice(2, 0, `${rewards[i].name}%${rewards[i].redemptions.length}`);
      }
    }

    // Sort rewards based on redemptions length
    topRewards = topRewards.sort((a, b) => {
      return parseInt(b.split("%")[1]) - parseInt(a.split("%")[1]);
    });

    if (topRewards[0].length < 1) {
      return [];
    }

    return topRewards;
  }

  function ifEmpty(type, top) {
    if ((type === "GOALS" && top.length < 1) || (type === "REWARDS" && top.length < 1)) {
      return <div className="noElements">{`NO ${type} YET!`}</div>;
    } else {
      return <div></div>;
    }
  }

  return (
    <div className="overview-stats">
      <div className="stats-section">
        <div className="stats-title">Top 3 Goals accomplished</div>
        <div className="stats-wrapper">
          <div className="stats-label">{top1Goal[0]}</div>
          <Progress count={top1Goal[1]} max={top1Goal[1]} />
          <div className="stats-label">{top2Goal[0]}</div>
          <Progress count={top2Goal[1]} max={top1Goal[1]} />
          <div className="stats-label">{top3Goal[0]}</div>
          <Progress count={top3Goal[1]} max={top1Goal[1]} />
          {ifEmpty("GOALS", top3G)}
        </div>
      </div>
      {patient.accountType === "CHILD" && (
        <>
          <hr size="1" color="#C9C9C9" className="section-divider" />
          <div className="stats-section">
            <div className="stats-title">Top 3 Rewards claimed</div>
            <div className="stats-wrapper">
              <div className="stats-label">{top1Reward[0]}</div>
              <Progress count={top1Reward[1]} max={top1Reward[1]} />
              <div className="stats-label">{top2Reward[0]}</div>
              <Progress count={top2Reward[1]} max={top1Reward[1]} />
              <div className="stats-label">{top3Reward[0]}</div>
              <Progress count={top3Reward[1]} max={top1Reward[1]} />
              {ifEmpty("REWARDS", top3R)}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
