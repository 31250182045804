import Modal from "../Modal";
import React, { useCallback, useContext, useEffect, useState } from "react";
import "./NewConversationModal.css";
import caregiversIcon from "../../assets/caregiver-icon.png";
import clientIcon from "../../assets/client-icon.png";
import Button from "../Button";
import { ChoiceList } from "@shopify/polaris";
import { Edit, AlertCircle, X } from "react-feather";
import { useAtom } from "tiny-atom/react/hooks";
import { findConversationFromUserIds } from "../../utils/messagesUtils";
import { useMutation } from "@apollo/client";
import { CREATE_CONVERSATION } from "../../queries";
import { TouchableOpacity } from "../react-native";
import { AccountType } from "../../model/userModels";
import { AppContext } from "../../context/AppContext";

export default function NewConversationModal(props) {
  const hidden = props.hidden;

  const conversations = props.conversations;
  const conversationCreatedCallback = props.createdCallback;
  const onClose = props.onClose;

  const patient = useAtom(state => state.patient);
  const patientData = useAtom(state => state.patientData);

  const [selectedContacts, setSelectedContacts] = useState([]);
  const [caregivers, setCaregivers] = useState([]);
  const [clients, setClients] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const userId = useAtom(state => state.userId);
  const { user } = useContext(AppContext);

  const [createConversationMutation] = useMutation(CREATE_CONVERSATION);

  const closeModal = useCallback(() => {
    setSelectedContacts([]);
    onClose();
  }, [onClose]);

  useEffect(() => {
    const caregiversItems = [];

    if (conversations) {
      if (patient.accountType === "CHILD") {
        if (patientData && patientData.childParents) {
          caregiversItems.push(
            ...patientData.childParents.map(parent => {
              return {
                label: parent.firstName + " " + parent.lastName,
                value: parent.id,
                user: parent
              };
            })
          );
          setCaregivers(caregiversItems);
        }
      }
      setClients([
        {
          label: patient.firstName + " " + patient.lastName,
          value: patient.id,
          user: patient
        }
      ]);
    }
  }, [patientData, patient, conversations, userId]);

  useEffect(() => {
    setError("");
    const contacts = [...caregivers, ...clients].filter(contact => {
      return selectedContacts.includes(contact.user.id);
    });
    const conversationIncludesChild = contacts.find(contact => contact.user.accountType === "CHILD");

    if (conversationIncludesChild && user.accountType === AccountType.PARENT_COACH) {
      setError("Cannot create conversation containing parent coaches and child.");
    }

    if (conversations && selectedContacts.length) {
      const existingConvo = findConversationFromUserIds(conversations, [...selectedContacts, userId]);
      if (existingConvo) {
        setError("You already have a conversation with this person(s)");
      }
    }
  }, [selectedContacts, clients, caregivers, conversations, userId]);

  const createConversation = useCallback(() => {
    setLoading(true);
    const userIds = [...selectedContacts, userId];
    createConversationMutation({ variables: { userIds } })
      .then(result => {
        conversationCreatedCallback(result.data.createConversation);
        closeModal();
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedContacts, conversationCreatedCallback, createConversationMutation, userId, closeModal]);

  return (
    <Modal hidden={hidden}>
      <div className="new-convo-modal-content">
        <div className="new-convo-close-container">
          <TouchableOpacity
            style={{ cursor: "pointer" }}
            onPress={() => {
              closeModal();
            }}
          >
            <X color="#000000" />
          </TouchableOpacity>
        </div>
        <div className="new-convo-title-text">
          <Edit size={18} color="#2F7AC8" style={{ marginRight: 20 }} />
          New Conversation
        </div>
        {caregivers.length > 0 && (
          <>
            <div className="new-convo-header-text">
              <img className="new-convo-header-icon" src={caregiversIcon} />
              Caregivers
            </div>
            <ChoiceList
              allowMultiple
              choices={caregivers}
              selected={selectedContacts}
              onChange={value => {
                setSelectedContacts(value);
              }}
            />
          </>
        )}
        {user.accountType !== AccountType.PARENT_COACH && clients.length > 0 && (
          <>
            <div className="new-convo-header-text">
              <img className="new-convo-header-icon" src={clientIcon} />
              Clients
            </div>
            <ChoiceList
              disabled={user.accountType !== AccountType.THERAPIST}
              allowMultiple
              choices={clients}
              selected={selectedContacts}
              onChange={value => {
                setSelectedContacts(value);
              }}
            />
          </>
        )}
        {error?.length > 0 && (
          <div className="new-convo-error-text">
            <AlertCircle size={26} color="#ff2132" style={{ marginRight: 20 }} />
            {error}
          </div>
        )}
        <div className="new-convo-modal-buttons">
          <Button
            title="create"
            theme="manaBlue"
            style={{ paddingLeft: 80, paddingRight: 80 }}
            disabled={loading || error?.length > 0 || selectedContacts.length === 0}
            onPress={() => {
              createConversation();
            }}
          />
        </div>
      </div>
    </Modal>
  );
}
