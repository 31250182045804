import React, { useContext, useEffect } from "react";
import { format } from "date-fns";
import { toDate } from "./dateUtils";

import { useQuery } from "@apollo/client";
import { GET_AVAILABLE_TIME_SLOTS } from "../../queries";
import { AppointmentSchedulerContext } from "./AppointmentScheduler";
import { Spinner } from "@shopify/polaris";
import Button from "../Button";
import Flex from "../core/Flex";
import moment from "moment/moment";

function AppointmentTimePicker({
  selectedDay,
  onContinuePressed,
  availabilityRecurringInterval,
  availabilityRecurringCount
}) {
  const { appointmentType, careTeamMemberIds, appointmentContactMethod, selectedTimeSlot, setSelectedTimeSlot } =
    useContext(AppointmentSchedulerContext);

  useEffect(() => {
    setSelectedTimeSlot(undefined);
  }, [selectedDay, setSelectedTimeSlot]);

  const { data, loading } = useQuery(GET_AVAILABLE_TIME_SLOTS, {
    variables: {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone || "America/New_York",
      userIds: careTeamMemberIds?.length > 0 ? careTeamMemberIds : appointmentType.applicableCareTeamMemberIds,
      endDate: selectedDay && moment(selectedDay, "ddd MMM DD YYYY HH:mm:ss Z").format("YYYY-MM-DD"),
      startDate: selectedDay && moment(selectedDay, "ddd MMM DD YYYY HH:mm:ss Z").format("YYYY-MM-DD"),
      appointmentTypeId: appointmentType.id,
      contactMethod: appointmentContactMethod,
      recurringInterval: availabilityRecurringInterval,
      recurringCount: availabilityRecurringCount
    },
    fetchPolicy: "cache-and-network",
    skip: !selectedDay
  });

  const { morningTimes, afternoonTimes, eveningTimes } = categorizeSlots(data?.availableTimeSlots || []);

  function availableSlot(slot, index) {
    return (
      <div
        key={index}
        onClick={() => setSelectedTimeSlot(slot)}
        className={`available-slot ${selectedTimeSlot == slot ? "active-slot" : ""}`}
      >
        {format(toDate(slot.startTime), "h:mm a").toLowerCase()}
      </div>
    );
  }

  return (
    <Flex
      flex
      style={{
        padding: 10,
        paddingBottom: 20,
        minHeight: 400,
        backgroundColor: "#e5eeff",
        borderRadius: 10,
        width: "100%"
      }}
    >
      <div className="availability-on-header">
        <span className="availability-on-header-date">{format(selectedDay, "MMMM d, yyyy")}</span>
        <p className="availability-on-header-timezone">
          Timezone: {Intl.DateTimeFormat().resolvedOptions().timeZone || "America/New_York"}
        </p>
      </div>
      {loading ? (
        <Flex center flex fullWidth style={{ height: 200 }}>
          <Spinner />
        </Flex>
      ) : (
        <>
          <div className="areas-of-day-flexbox">
            {!!morningTimes.length && (
              <div className="day-area">
                <span className="day-area-header">Morning</span>
                <div className="available-slots-for-day">
                  {morningTimes.map((slot, index) => availableSlot(slot, index))}
                </div>
              </div>
            )}

            {!!afternoonTimes.length && (
              <div className="day-area">
                <span className="day-area-header">Afternoon</span>
                <div className="available-slots-for-day">
                  {afternoonTimes.map((slot, index) => availableSlot(slot, index))}
                </div>
              </div>
            )}

            {!!eveningTimes.length && (
              <div className="day-area">
                <span className="day-area-header">Evening</span>
                <div className="available-slots-for-day">
                  {eveningTimes.map((slot, index) => availableSlot(slot, index))}
                </div>
              </div>
            )}

            {morningTimes.length === 0 && afternoonTimes.length === 0 && eveningTimes.length === 0 ? (
              <div className="appointment-time-picker-empty-state">
                <p>No available time slots</p>
                <p>Please select a different date in the calendar.</p>
                <p>You can change the month by pressing the arrow at the top right of the calendar.</p>
              </div>
            ) : undefined}
          </div>
          <Button
            style={styles.button}
            theme="manaBlue"
            title="Confirm Appointment"
            disabled={!selectedTimeSlot}
            onPress={onContinuePressed}
          />
        </>
      )}
    </Flex>
  );
}

const styles = {
  button: {
    minWidth: 250,
    maxWidth: 300,
    alignSelf: "center",
    paddingleft: 10,
    paddingRight: 10
  }
};

function categorizeSlots(availableTimeSlots) {
  const morningTimes = [];
  const afternoonTimes = [];
  const eveningTimes = [];

  const times = {
    morningTimes,
    afternoonTimes,
    eveningTimes
  };

  if (availableTimeSlots == null) {
    return times;
  }

  availableTimeSlots.forEach(slot => {
    const date = toDate(slot.startTime);

    if (format(date, "a") === "AM" && parseInt(format(date, "H"), 10) < 12) {
      morningTimes.push(slot);
    } else if (format(date, "H") === "12" || format(date, "H") === "17" || parseInt(format(date, "H"), 10) < 17) {
      afternoonTimes.push(slot);
    } else {
      eveningTimes.push(slot);
    }
  });

  return times;
}

export default AppointmentTimePicker;
