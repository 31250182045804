import { Select, TextField } from "@shopify/polaris";
import _ from "lodash";
import React, { useEffect } from "react";
import { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import Modal from "../Modal";
import { StyleSheet, css } from "aphrodite";
import { isAllWhitespace } from "../../utils/formUtils";
import AddTemplateIcon from "../../assets/icons/list-plus-icon.png";
import { TEMPLATE_PLACEHOLDER_REGEX } from "../../utils/messagesUtils";

export default function MessageTemplateModal({ hidden, setHidden, onSubmit, ...props }) {
  const { messageTemplates: templates } = useContext(AppContext);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [message, setMessage] = useState();
  const [placeholderCount, setPlaceholderCount] = useState();

  const sortOrder = ["THERAPIST", "PARENT COACHES", "GENERAL", "AUTOMATED"];
  const categories = _.uniq(templates.map(t => t.category)).sort((a, b) => {
    // end of list will display first, unlisted categories will display last
    return sortOrder.indexOf(b) - sortOrder.indexOf(a);
  });
  const byCategory = _.groupBy(templates, t => t.category);

  const selectGroups = categories.map(category => ({
    title: category,
    options: byCategory[category]?.map(template => template.name)
  }));

  useEffect(() => {
    if (message) {
      const placeholders = message.match(TEMPLATE_PLACEHOLDER_REGEX);
      setPlaceholderCount(placeholders?.length || 0);
    }
  }, [message]);
  return (
    <Modal
      hidden={hidden}
      onPressExit={() => setHidden(true)}
      button1Label="Add template to message"
      button1Theme="lightBlue"
      button1Disabled={isAllWhitespace(message) || placeholderCount > 0}
      onButton1Press={() => {
        onSubmit(message, selectedTemplate?.key);
        setHidden(true);
        setMessage(undefined);
        setSelectedTemplate(undefined);
      }}
      {...props}
    >
      <div className={css(styles.content)}>
        <div className={css(styles.header)}>
          <img alt="add-template" src={AddTemplateIcon} className={css(styles.titleIcon)} />
          <div className={css(styles.title)}>{"Message Templates"}</div>
        </div>
        <Select
          value={selectedTemplate?.name}
          placeholder="Choose template"
          options={selectGroups}
          onChange={value => {
            const template = templates.find(t => t.name === value);
            setSelectedTemplate(template);
            setMessage(template.message);
          }}
        />
        <div className={css(styles.textInputContainer)}>
          <div className={css(styles.textInputLabel)}>Edit Message</div>
          <TextField
            name="message"
            type="TextInput"
            value={message}
            onChange={setMessage}
            editable
            multiline={20}
            maxLength={3500}
            maxHeight={"40vh"}
            showCharacterCount
          />
        </div>
        {message && (
          <div style={{ fontWeight: 600, textAlign: "center", color: placeholderCount ? "#DE3618" : "#3DC1D3" }}>
            {placeholderCount > 0
              ? placeholderCount === 1
                ? `There is (${placeholderCount}) unresolved placeholder in your message`
                : `There are (${placeholderCount}) unresolved placeholders in your message`
              : `Please make sure that any placeholders are filled out.`}
          </div>
        )}
      </div>
    </Modal>
  );
}

const styles = StyleSheet.create({
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: 30
  },
  content: {
    minWidth: 450,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  title: {
    fontWeight: 800,
    fontSize: 28
  },
  titleIcon: {
    height: 30,
    width: 30,
    marginRight: 10
  },
  textInputContainer: {
    marginTop: 30,
    marginBottom: 15
  },
  textInputLabel: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 20
  }
});
