import React from "react";
import Flex from "../core/Flex";
import Button from "../Button";
import Text from "../core/Text";

type MatchedCareProvider = {
  id: number;
  firstName: string;
  lastName: string;
  profileImageUrl: string;
  credentialing: string;
};

type Props = {
  careProvider: MatchedCareProvider;
  onCareProviderSelected: () => void;
  selectButtonTitle: string;
};

export default function MatchedCareProviderSelector({
  careProvider,
  onCareProviderSelected,
  selectButtonTitle
}: Props) {
  return (
    <Flex center>
      <Flex row center spaceBetween style={{ padding: 10 }}>
        <Flex flex={1} style={{ width: 75, height: 75 }}>
          {careProvider.profileImageUrl && careProvider.profileImageUrl.startsWith("https://") ? (
            <img src={careProvider.profileImageUrl} alt={"profile picture"} />
          ) : (
            <div style={{ height: 75, widows: 75, borderRadius: 75, backgroundColor: "#D3D3D3" }}></div>
          )}
        </Flex>
        <Flex column left center style={{ padding: 10, width: 150 }}>
          <Text theme="manaBlueDark" weight={700} size={16} style={{ lineHeight: 1.5 }}>
            {careProvider.firstName} {careProvider.lastName}
          </Text>
          {!!careProvider.credentialing && (
            <Text weight={400} size={14} style={{ lineHeight: 1.3 }}>
              {careProvider.credentialing}
            </Text>
          )}
        </Flex>
        <Flex style={{ width: 75 }}>
          <Button theme="manaBlue" title={selectButtonTitle || "select"} onPress={onCareProviderSelected} />
        </Flex>
      </Flex>
    </Flex>
  );
}
