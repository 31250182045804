import React from "react";
import { TextField } from "@shopify/polaris";

export function TouchableOpacity({ onPress, style, children }) {
  return (
    <div
      className="TouchableOpacity"
      onClick={onPress}
      style={{
        display: "flex",
        flexDirection: "column",
        cursor: "pointer",
        position: "relative",
        ...convert(style)
      }}
    >
      {children}
    </div>
  );
}

export function View({ style, children }) {
  return (
    <div className="View" style={{ display: "flex", flexDirection: "column", ...convert(style) }}>
      {children}
    </div>
  );
}

export function Text({ style, children }) {
  return (
    <span className="Text" style={{ display: "flex", flexDirection: "column", ...convert(style) }}>
      {children}
    </span>
  );
}

export function Image({ style, source }) {
  return (
    <div
      className="Image"
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundSize: "cover",
        backgroundImage: `url(${source})`,
        ...convert(style)
      }}
    />
  );
}

export function TextInput(props) {
  return <TextField {...props} />;
}

function convert(style) {
  if (Array.isArray(style)) {
    return style.reduce((memo, s) => {
      if (s) {
        return Object.assign({}, memo, s);
      }
      return memo;
    }, {});
  } else {
    return style;
  }
}
