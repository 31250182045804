export const screenerViews = {
  typeform: "typeform",
  insuranceEligibility: "insuranceEligibility",
  passwordInput: "passwordInput",
  scheduleConsult: "scheduleConsult",
  consultBooked: "consultBooked",
  loadingAccountCreation: "loadingAccountCreation",
  nextSteps: "nextSteps",
  directIntakeRequested: "directIntakeRequested",
  scheduleIntake: "scheduleIntake"
};

export default screenerViews;
