import React, { useState } from "react";
import Button from "../Button";
import AssignAssessmentModal from "./AssignAssessmentModal";

export default function SendAssessmentsButton() {
  const [showAssignmentModal, setShowAssignmentModal] = useState(false);

  return (
    <>
      {showAssignmentModal && <AssignAssessmentModal hide={() => setShowAssignmentModal(false)} />}
      <Button small title="Send assessment" theme="lightBlue" onPress={() => setShowAssignmentModal(true)} />
    </>
  );
}
