import { useEffect } from "react";

export default function useBrowserTabFocus(callback) {
  useEffect(() => {
    const onFocus = () => {
      callback(true);
    };

    const onBlur = () => {
      callback(false);
    };

    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
    onFocus();
    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  }, [callback]);
}
