import React from "react";
import "./GoalReflectionCard.css";
import emoji from "emoji-dictionary";
import moment from "moment";

export default function GoalReflectionCard({ achievements, firstName, isParent = false }) {
  if (achievements.length === 0) {
    return (
      <div id="noReflections" className="noGoals">
        {firstName} may share a reflection when a goal is accomplished, which will be shown here.
      </div>
    );
  }
  function convertFaceEmojis(feedback) {
    if (feedback === "POSITIVE") {
      return emoji.getUnicode("slightly_smiling_face");
    } else if (feedback === "NEGATIVE") {
      return emoji.getUnicode("slightly_frowning_face");
    } else if (feedback === "NEUTRAL") {
      return emoji.getUnicode("neutral_face");
    } else if (feedback === "happy") {
      return emoji.getUnicode("blush");
    } else if (feedback === "proud") {
      return emoji.getUnicode("sunglasses");
    } else if (feedback === "calm") {
      return emoji.getUnicode("relieved");
    } else if (feedback === "strong") {
      return emoji.getUnicode("muscle");
    } else if (feedback === "whatever") {
      return emoji.getUnicode("roll_eyes");
    } else if (feedback === "nervous") {
      return emoji.getUnicode("cold_sweat");
    } else if (feedback === "frustrated") {
      return emoji.getUnicode("tired_face");
    } else if (feedback === "motivated") {
      return emoji.getUnicode("star_struck");
    } else {
      const feelings = feedback.split(",");
      if (feelings.length === 1) {
        return feelings[0];
      }
      return feelings.map((f, i) => (
        <span key={i} className="reflectionsText">
          {!isParent && <span className="reflectionsEmoji">{convertFaceEmojis(f)}</span>} {f}
          {i < feelings.length - 1 && ","}
        </span>
      ));
    }
  }
  function convertHandEmojis(feedback) {
    if (feedback === "POSITIVE") {
      return emoji.getUnicode("+1");
    } else if (feedback === "NEGATIVE") {
      return emoji.getUnicode("-1");
    }
  }

  return (
    <React.Fragment>
      {achievements.map(achievement => {
        const { id, achievedAt, goal, goalFeedback, feelingDuringCompletion, feedbackText } = achievement;
        if (!goalFeedback && !feelingDuringCompletion && !feedbackText) {
          return <div key={id}></div>;
        } else {
          return (
            <div id="goalReflectionContainer" key={id}>
              <div id="achievedDate">{moment(achievedAt).format("LL")}</div>
              <div className="feedbackContainer">
                Goal: <b>{goal.name}</b>
              </div>
              {goalFeedback && (
                <div className="feedbackContainer">
                  <span className="reflectionsLabel">Helpful?</span>
                  <span className={`reflectionsEmoji ${goalFeedback === "NEGATIVE" ? "offsetThumbsDown" : ""}`}>
                    {convertHandEmojis(goalFeedback)}
                  </span>
                  <span className="reflectionsText">{goalFeedback === "NEGATIVE" ? "nope." : "yes!"}</span>
                </div>
              )}
              {feelingDuringCompletion && (
                <div className="feedbackContainer">
                  <span className="reflectionsLabel">Feeling after achievement:</span>
                  <span className="reflectionsEmoji">{convertFaceEmojis(feelingDuringCompletion)}</span>
                </div>
              )}
              {feedbackText && (
                <React.Fragment>
                  <div id="reflectionLabel">Reflection:</div>
                  <div id="reflection">&ldquo;{feedbackText}&rdquo;</div>
                </React.Fragment>
              )}
            </div>
          );
        }
      })}
    </React.Fragment>
  );
}
