import React, { useState, useRef } from "react";
import { Card } from "@shopify/polaris";
import SwitchToggle from "../SwitchToggle";
import ActiveGoals from "../goal-card-views/ActiveGoals";
import CompletedGoals from "../goal-card-views/CompletedGoals";
import GoalMarkAchievedView from "../goal-card-views/GoalMarkAchievedView";
import {
  renewGoal as renewMutation,
  completeGoal as completeMutation,
  markGoalAchieved as markGoalAchievedMutation
} from "../../utils/goalUtils";
import client from "../../apollo/client";
import { getEndOfDays } from "../../utils/dateUtils";
import "./GoalCalendarCard.css";
import { useAtom } from "tiny-atom/react/hooks";
import { logEvent } from "../../actions";

export default function GoalCalendarCard({ familyUser }) {
  const goalTypes = ["Active", "Completed"];
  const [goalType, setGoalType] = useState(goalTypes[0]);
  const [showMarkAchievedView, setShowMarkAchievedView] = useState(false);
  const [goalToMarkAchieved, setGoalToMarkAchieved] = useState(null);
  const [achievementDate, setAchievementDate] = useState(null);

  function beginMarkAchieved(goal) {
    setShowMarkAchievedView(true);
    setGoalToMarkAchieved(goal);
  }

  function endMarkAchieved() {
    setShowMarkAchievedView(false);
    setGoalToMarkAchieved(null);
  }

  function achievementDateSetter(achievementDate) {
    setAchievementDate(achievementDate);
  }

  async function renewGoal(goal) {
    logEvent("renewGoal");
    const newExpirationDate = getEndOfDays(30);
    await renewMutation({ familyUser, goal, client }, newExpirationDate);
  }

  async function completeGoal(goal) {
    logEvent("completeGoal");
    // scroll to top of card, so new completed card will be visible
    const topY = topRef?.current?.offsetTop;
    if (window.scrollY > topY) {
      window.scrollTo(0, topRef?.current?.offsetTop - 100);
    }

    await completeMutation({ familyUser, goal, client });

    setGoalType(goalTypes[1]);
  }

  async function markGoalAchieved(note) {
    setShowMarkAchievedView(false);
    await markGoalAchievedMutation({ goal: goalToMarkAchieved, familyUser, note, achievementDate });
    setGoalToMarkAchieved(null);
  }

  const topRef = useRef(null);

  function Header() {
    return (
      <div ref={topRef} className="GoalCard-header">
        <div className="GoalCard-title">Goals</div>
        {(familyUser.accountType === "CHILD" || familyUser.accountType === "STANDALONE_CLIENT") && (
          <SwitchToggle
            className="GoalCard-switch"
            options={goalTypes}
            selection={goalType}
            onChange={type => setGoalType(type)}
          />
        )}
      </div>
    );
  }

  let minHeight;
  const patientData = useAtom(state => state.patientData);
  if (familyUser.accountType === "CHILD" || familyUser.accountType === "STANDALONE_CLIENT") {
    minHeight = patientData.childGoals && patientData.childGoals.length > 0 ? 900 : 0;
  } else {
    minHeight = familyUser.goals.length > 0 ? 900 : 0;
  }

  return (
    <div style={{ minHeight: minHeight }}>
      {showMarkAchievedView && (
        <GoalMarkAchievedView
          onCancel={endMarkAchieved}
          onMarkAchieved={markGoalAchieved}
          goal={goalToMarkAchieved}
          setAchievementDate={achievementDateSetter}
        />
      )}
      <Card>
        <Header />
        <div style={{ minHeight: 120 }}>
          <ActiveGoals
            visible={goalType === goalTypes[0]}
            familyUser={familyUser}
            onMarkAchieved={beginMarkAchieved}
            onRenew={renewGoal}
            onComplete={completeGoal}
          />
          <CompletedGoals visible={goalType === goalTypes[1]} />
        </div>
      </Card>
    </div>
  );
}
