import { useEffect, useState } from "react";
import React from "react";
import { StyleSheet, css } from "aphrodite";
import bounceOutDown from "react-animations/lib/bounce-out-down";
import bounceInUp from "react-animations/lib/bounce-in-up";
import checkMarkImage from "../assets/check-mark.png";
const animationTime = 1000;

export default function Toast({ text, customPosition, duration = 1500, after, size }) {
  const [done, setDone] = useState(false);
  const [exiting, setExiting] = useState(false);
  const [entering, setEntering] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setEntering(false);
    }, animationTime);
    return () => {
      clearTimeout(timeout);
    };
  }, [duration]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setExiting(true);
    }, duration);
    return () => {
      clearTimeout(timeout);
    };
  }, [duration]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDone(true);
    }, duration + animationTime - 100);
    return () => {
      clearTimeout(timeout);
    };
  }, [duration]);

  const animation = entering ? styles.enter : exiting ? styles.exit : undefined;
  const position = customPosition ? undefined : styles.toastPosition;

  useEffect(() => {
    if (done && after) {
      after();
    }
  }, [done, after]);

  return (
    <>
      {!done && (
        <div
          className={css(styles.toast, animation, position, size === "small" ? styles.small : styles.defaultSize)}
          onClick={() => setDone(true)}
        >
          <div className={css(styles.content)}>
            <img alt="checkMark" className={css(styles.img)} src={checkMarkImage} />
            <div className={css(styles.text, size === "small" ? styles.smallText : styles.defaultTextSize)}>{text}</div>
          </div>
        </div>
      )}
    </>
  );
}

const height = 100;
const styles = StyleSheet.create({
  toast: {
    position: "relative",
    pointerEvents: "auto",
    margin: 10,
    height: height,
    backgroundColor: "#FFFFFF",
    zIndex: 100,
    boxShadow: "4px 4px 23px -6px rgba(0, 0, 0, 0.25)",
    borderRadius: 4,
    display: "flex",
    justifyContent: "center"
  },
  small: {
    width: 300
  },
  defaultSize: {
    width: 400
  },
  toastPosition: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "auto",
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 40,
    right: 0
  },
  img: {
    height: 30,
    width: 30
  },
  text: {
    marginLeft: 20,
    fontFamily: "Helvetica Neue",
    fontWeight: "500",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#575F67"
  },
  smallText: {
    fontSize: "18px",
    lineHeight: "24px"
  },
  defaultTextSize: {
    fontSize: "24px",
    lineHeight: "29px"
  },
  enter: {
    animationName: bounceInUp,
    animationDuration: `${animationTime}ms`
  },
  exit: {
    animationName: bounceOutDown,
    animationDuration: `${animationTime}ms`
  },
  content: {
    padding: 20,
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start"
  }
});
