import React from "react";
import { View } from "./react-native";
import "./DefaultRewardEdit.css";
import Label from "./Label";
import { TextField, BlockStack } from "@shopify/polaris";

class DefaultRewardEdit extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const reward = this.props.reward;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          flexGrow: 1,
          overflow: "auto"
        }}
      >
        <div style={{ padding: 20 }}>
          <BlockStack gap={"200"}>
            <Label first>Name</Label>
            <TextField
              name="title"
              type="TextInput"
              defaultValue={reward.name}
              value={reward.name}
              style={{
                height: 40,
                paddingLeft: 15,
                paddingRight: 15,
                borderRadius: 12,
                fontSize: 16,
                backgroundColor: "rgb(244, 246, 248)"
              }}
            />
            {reward.description && <Label>Description</Label>}
            {reward.description && (
              <TextField
                name="description"
                type="TextInput"
                defaultValue={reward.description}
                value={reward.description}
                multiline
                style={{
                  height: 90,
                  padding: 15,
                  paddingTop: 15,
                  borderRadius: 12,
                  fontSize: 16,
                  backgroundColor: "rgb(244, 246, 248)"
                }}
              />
            )}
            <Label>Cost</Label>
            <TextField
              name="title"
              type="TextInput"
              value={reward.cost}
              style={{
                height: 40,
                paddingLeft: 15,
                paddingRight: 15,
                borderRadius: 12,
                fontSize: 16,
                backgroundColor: "rgb(244, 246, 248)"
              }}
            />
          </BlockStack>
        </div>
        <View
          style={{
            width: "100%",
            marginTop: 20,
            padding: 20,
            borderTop: "1px solid #ececec"
          }}
        ></View>
      </div>
    );
  }
}

export default DefaultRewardEdit;
