import React, { useState } from "react";
import Calendar from "../Calendar";
import "./ActiveGoals.css";
import moment from "moment";
import { useAtom } from "tiny-atom/react/hooks";
import { getReminderDays } from "../../utils/goalUtils";
import { isExpired } from "../../utils/dateUtils";
import Button from "../../components/Button";
import GoalAchievementImageViewer from "./GoalAchievementImageViewer";
import { AccountType } from "../../model/userModels";

let preemptiveAchievement = false;

function Goal({ goal, familyUser, onComplete, onRenew, onMarkAchieved }) {
  const [showPhotos, setShowPhotos] = useState(false);
  const parentGoal = familyUser.accountType !== "CHILD" && familyUser.accountType !== "STANDALONE_CLIENT";
  const totalPoints = goal.value * goal.achievements.length;
  const reminderDays = getReminderDays(goal);
  const hasPhotos = goal.achievements && goal.achievements.find(a => a.image);

  function getDayStyleConfig(day) {
    const dayMoment = moment(day, "YYYY-MM-DD");
    const assignedMoment = moment(goal.assignedAt).subtract(1, "days");
    const dow = goal.dueAt.split("59 59 23 * * ")[1] || "*";

    if (dayMoment.isBefore(assignedMoment) || dayMoment.isAfter(moment(new Date()))) {
      return { highlight: "white" };
    }

    let hasAchievement = false;
    let hasPhoto = false;

    for (let i = 0; i < goal.achievements.length; i++) {
      let achievementMoment = moment(goal.achievements[i].achievedAt);

      if (dayMoment.format("YYYY-MM-DD") === achievementMoment.format("YYYY-MM-DD")) {
        if (dow !== "*" && !dow.includes(String(achievementMoment.day()))) {
          preemptiveAchievement = !preemptiveAchievement;
        }
        hasAchievement = true;
        if (goal.achievements[i].image) {
          // don't return early on first achieved
          // because there could be multiple achievements
          // on the day, but only one might have a photo
          hasPhoto = true;
        }
      }
    }

    const config = {
      highlight: hasPhoto ? "rgb(92, 118, 244)" : hasAchievement ? "rgb(61, 193, 211)" : "white",
      backgroundImg: hasPhoto ? "camera" : undefined
    };
    return config;
  }

  function ExpirationHeader({ goal }) {
    if (isExpired(goal.expiresAt) && AccountType.isCareProvider(goal.assignerType)) {
      return (
        <div className="ExpirationHeader">
          <div className="ExpirationHeader-label">Are they still working on this goal?</div>
          <div className="ExpirationHeader-buttons">
            <Button
              theme="goalReportCardRenew"
              onPress={() => onRenew(goal)}
              title="yes"
              style={{ height: 30, width: 101, fontSize: 16 }}
            />
            <Button
              theme="goalReportCardRenew"
              onPress={() => onComplete(goal)}
              title="no"
              style={{ height: 30, width: 101, fontSize: 16, marginLeft: 16 }}
            />
          </div>
        </div>
      );
    }
    return null;
  }

  return (
    <div className="ActiveGoal">
      <ExpirationHeader goal={goal} />
      <div className="ActiveGoal-content">
        {goal.assignerType === "PARENT" && (
          <div className="assigned-header">{<div className="OtherOwnerGoal-label">Parent assigned</div>}</div>
        )}
        {goal.assignerType === "SERVICE" && (
          <div className="assigned-header">{<div className="OtherOwnerGoal-label">Manatee assigned</div>}</div>
        )}
        <div
          className="goal-name-and-mark-aAchieved-header"
          style={goalNameAndMarkAchievedHeaderStyle(goal.assignerType)}
        >
          <div className="ActiveGoal-title">{goal.name}</div>
          {familyUser.accountType === "CHILD" && (
            <div className="mark-achieved-button">
              <Button
                theme="lightTeal"
                onPress={() => onMarkAchieved(goal)}
                title="Mark Achieved"
                style={styles.markAchievedButton}
              />
            </div>
          )}
        </div>
        <div className="due-header">
          Reminders: <strong>{reminderDays}</strong>
        </div>
        {parentGoal && (
          <div style={{ paddingBottom: 40 }}>
            Assigned: <strong>{moment(goal.assignedAt).format("LL")}</strong>
          </div>
        )}
        <div className="ActiveGoal-container">
          <div className="ActiveGoal-calendar">
            <Calendar dayStyleConfigurator={getDayStyleConfig} />
            {hasPhotos && (
              <div className="view-photos-button">
                <Button
                  theme="blue"
                  onPress={() => setShowPhotos(true)}
                  title="View Photos"
                  style={styles.viewPhotosButton}
                />
              </div>
            )}
          </div>
          {!parentGoal && (
            <div className="ActiveGoal-details">
              <div className="ActiveGoal-meta">
                <div>
                  Points per goal: <strong>{goal.value}</strong>
                </div>
                <div>
                  Points collected: <strong>{totalPoints}</strong>
                </div>
                <div>
                  Assigned: <strong>{moment(goal.assignedAt).format("LL")}</strong>
                </div>
              </div>
            </div>
          )}
        </div>
        {showPhotos && (
          <GoalAchievementImageViewer
            isVisible={showPhotos}
            hide={() => setShowPhotos(false)}
            userId={familyUser.id}
            goal={goal}
          />
        )}
      </div>
    </div>
  );
}

export default function ActiveGoals({ familyUser, limit, onComplete, onRenew, onMarkAchieved, visible }) {
  const patientData = useAtom(state => state.patientData);
  // FYI - .goals == parent; .childGoals == child or SC
  let activeGoals = familyUser.goals ? familyUser.goals : patientData.childGoals.filter(g => !g.finalizedAt);

  const items = activeGoals.map((goal, i) => {
    if (!limit || i < limit) {
      return (
        <Goal
          key={goal.id}
          goal={goal}
          familyUser={familyUser}
          onComplete={onComplete}
          onRenew={onRenew}
          onMarkAchieved={onMarkAchieved}
        />
      );
    } else {
      return <></>;
    }
  });
  return (
    <div hidden={!visible} className="ActiveGoals">
      {items}
      {items.length === 0 && <div className="noGoals">NO ACTIVE GOALS!</div>}
    </div>
  );
}

const styles = {
  markAchievedButton: { height: 30, paddingLeft: 38, paddingRight: 38, fontSize: 16 },
  viewPhotosButton: {
    height: 30,
    paddingLeft: 20,
    paddingRight: 20,
    fontSize: 16
  }
};

function goalNameAndMarkAchievedHeaderStyle(assignerType) {
  return { marginTop: AccountType.isCareProvider(assignerType) ? 0 : 20 };
}
