import React, { useState } from "react";
import { TouchableOpacity } from "../../react-native";
import PaperClipIcon from "../../../assets/icons/paper-clip-icon.png";
import { useRef } from "react";
import { XSmallIcon } from "@shopify/polaris-icons";
import { Icon } from "@shopify/polaris";

const MAX_FILE_SIZE_BYTES = 20 * 1024 * 1024;

export default function FileAttachmentButton({ file, setFile, ...props }) {
  const inputRef = useRef();
  const [invalidFile, setInvalidFile] = useState();

  const handleChange = event => {
    if (event?.target?.files) {
      const file = event.target.files[0];
      if (file.size && file.size > MAX_FILE_SIZE_BYTES) {
        setInvalidFile(file);
      } else {
        setFile(file);
        setInvalidFile(undefined);
      }
    }
  };

  const handleRemoveFile = () => {
    setFile(undefined);
    setInvalidFile(undefined);
  };

  const selectedFile = file || invalidFile;

  let selectedFileStyle = invalidFile
    ? { ...styles.fileNameContainer, ...styles.invalidFile }
    : styles.fileNameContainer;

  return (
    <>
      {!selectedFile && (
        <TouchableOpacity
          {...props}
          onPress={() => {
            if (inputRef.current) {
              inputRef.current.click();
            }
          }}
        >
          <img src={PaperClipIcon} style={styles.icon} />
          <input
            hidden={true}
            ref={inputRef}
            type="file"
            accept="image/*,video/*,.pdf,.doc,.docx"
            onChangeCapture={handleChange}
          />
        </TouchableOpacity>
      )}
      {selectedFile && (
        <div style={selectedFileStyle}>
          <img src={PaperClipIcon} style={styles.icon} />
          {invalidFile && <p style={styles.fileName}>{"Your file exceeds 20 MB"}</p>}
          {file && <p style={styles.fileName}>{selectedFile?.name}</p>}
          <TouchableOpacity style={styles.cancel} onPress={handleRemoveFile}>
            <Icon color="#3DC1D3" source={XSmallIcon} />
          </TouchableOpacity>
        </div>
      )}
    </>
  );
}

const styles = {
  icon: {
    marginTop: "auto",
    marginBottom: "auto",
    height: 20,
    width: 20,
    resize: "contain"
  },
  cancel: {
    marginLeft: 10,
    width: 40
  },
  fileName: {
    paddingLeft: 10,
    maxLines: 1,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    alignSelf: "center"
  },
  fileNameContainer: {
    padding: 5,
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    maxWidth: 250,
    height: 40,
    backgroundColor: "#3DC1D31F",
    color: "#4F4F4F"
  },
  validFile: {
    backgroundColor: "#3DC1D31F",
    color: "#4F4F4F"
  },
  invalidFile: {
    backgroundColor: "#FFEBEB",
    color: "#FF2132"
  }
};
