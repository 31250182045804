import React from "react";
import { Text, View } from "./react-native";

export default function GoalItemSeparator({ label, fontStyle, removeSeparatorLine, containerStyle }) {
  return (
    <View style={{ ...styles.container, ...styles.separator, ...containerStyle }}>
      <Text style={[styles.separatorTitle, fontStyle]}>{label}</Text>
      {!removeSeparatorLine && <View style={styles.separatorLine} />}
    </View>
  );
}

const styles = {
  container: {
    marginTop: 10,
    marginBottom: 10,
    marginRight: 30,
    padding: 0,
    flexDirection: "row"
  },
  separator: {
    alignItems: "center",
    marginTop: 20,
    marginBottom: 20
  },
  separatorTitle: {
    fontSize: 16,
    fontWeight: "600",
    color: "rgb(0, 0, 0)"
  },
  separatorLine: {
    flex: 1,
    borderBottom: "1px solid rgba(145, 158, 171, 0.25)",
    marginLeft: 32
  }
};
