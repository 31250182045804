/** @format */

import _ from "lodash";
import React from "react";
import { View } from "./react-native";
import animalsImage from "../assets/animals.png";
import articleImage from "../assets/teenGoalImages/article.png";
import ballImage from "../assets/teenGoalImages/ball.png";
import sunImage from "../assets/teenGoalImages/sun.png";
import blackholeImage from "../assets/teenGoalImages/blackhole.png";
import bookmarkImage from "../assets/teenGoalImages/bookmark.png";
import candyImage from "../assets/teenGoalImages/candy.png";
import penguinImage from "../assets/teenGoalImages/penguin.png";
import cat2Image from "../assets/teenGoalImages/cat2.png";
import cherriesImage from "../assets/teenGoalImages/cherries.png";
import cloneJediImage from "../assets/teenGoalImages/clone-jedi.png";
import countrysideImage from "../assets/teenGoalImages/countryside.png";
import discoveryImage from "../assets/teenGoalImages/discovery.png";
import mountainImage from "../assets/teenGoalImages/mountain.png";
import hotdogTruckImage from "../assets/teenGoalImages/hotdog-truck.png";
import marioMushroomImage from "../assets/teenGoalImages/mario-mushroom.png";
import monstersImage from "../assets/teenGoalImages/monsters.png";
import megaphoneImage from "../assets/teenGoalImages/megaphone.png";
import pacmanImage from "../assets/teenGoalImages/pacman.png";
import spaceshipImage from "../assets/teenGoalImages/spaceship.png";
import studiesImage from "../assets/teenGoalImages/studies.png";
import targetFlagImage from "../assets/teenGoalImages/target-flag.png";
import treasureImage from "../assets/teenGoalImages/treasure.png";
import twilightCountrysideImage from "../assets/teenGoalImages/twilight-countryside.png";
import weatherImage from "../assets/teenGoalImages/weather.png";
import winnerImage from "../assets/teenGoalImages/winner.png";

function Image({ style, source }) {
  return (
    <img
      className="Image"
      alt=""
      src={source}
      style={{
        display: "flex",
        flexDirection: "column",
        ...style
      }}
    />
  );
}

const childImageMap = {
  1: [0, "rgba(107,119,124,0.25)"], // cat
  2: [5, "rgba(110,70,60,0.25)"], // dog
  3: [8, "rgba(232,178,16,0.25)"], // lion
  4: [9, "rgba(58,125,177,0.25)"], // hippo
  5: [12, "rgba(251,198,8,0.25)"], // giraffe
  6: [15, "rgba(230,95,86,0.25)"], // unicorn
  7: [16, "rgba(175,46,27,0.25)"], // monkey
  8: [20, "rgba(181,81,47,0.25)"], // koala
  9: [21, "rgba(223,170,106,0.25)"], // beaver
  10: [23, "rgba(74,80,84,0.25)"], // panda
  11: [25, "rgba(241,225,221,0.75)"], // lemur
  12: [29, "rgba(221,238,241,1)"], // sheep
  13: [31, "rgba(246,159,7,0.25)"], // leopard
  14: [32, "rgba(213,91,26,0.25)"], // fox
  15: [34, "rgba(252,199,8,0.25)"], // penguin
  16: [40, "rgba(252,199,8,0.25)"], // owl
  17: [47, "rgba(49,177,226,0.25)"], // whale
  18: [50, "rgba(49,177,226,0.25)"], // dolphin
  19: [51, "rgba(249,124,124,0.25)"], // jellyfish
  20: [52, "rgba(38,153,198,0.25)"], // lochness
  21: [55, "rgba(160,121,186,0.25)"], // octopus
  22: [60, "rgba(251,106,6,0.25)"], // crab
  23: [64, "rgba(70,151,117,0.25)"], // turtle
  24: [67, "rgba(246,159,7,0.25)"], // pufferfish
  25: [69, "rgba(251,106,6,0.25)"] // fish
};

const teenImageMap = {
  1: [articleImage, "rgba(164,226,251,1)"],
  2: [ballImage, "rgba(240,205,117,1)"],
  3: [sunImage, "rgba(137,220,198,1)"],
  4: [blackholeImage, "rgba(220,220,220,1)"],
  5: [bookmarkImage, "rgba(201,201,201,1)"],
  6: [candyImage, "rgba(255,146,203,1)"],
  7: [penguinImage, "rgba(255,146,203,1)"],
  8: [cat2Image, "rgba(255,146,203,1)"],
  9: [cherriesImage, "rgba(220,220,220,1)"],
  10: [cloneJediImage, "rgba(220,220,220,1)"],
  11: [countrysideImage, "rgba(220,220,220,1)"],
  12: [discoveryImage, "rgba(201,201,201,1)"],
  13: [mountainImage, "rgba(164,226,251,1)"],
  14: [hotdogTruckImage, "rgba(240,205,117,1)"],
  15: [marioMushroomImage, "rgba(164,226,251,1)"],
  16: [monstersImage, "rgba(201,201,201,1)"],
  17: [megaphoneImage, "rgba(217,202,191,1)"],
  18: [pacmanImage, "rgba(164,226,251,1)"],
  19: [spaceshipImage, "rgba(220,220,220,1)"],
  20: [studiesImage, "rgba(137,220,198,1)"],
  21: [targetFlagImage, "rgba(240,205,117,1)"],
  22: [treasureImage, "rgba(217,202,191,1)"],
  23: [twilightCountrysideImage, "rgba(255,146,203,1)"],
  24: [weatherImage, "rgba(207,241,255,1)"],
  25: [winnerImage, "rgba(240,205,117,1)"]
};

export function randomImageId() {
  return parseInt(Math.random() > 0.25 ? _.shuffle(Object.keys(childImageMap))[0] : 6);
}

const Animal = React.memo(function Animal({ index }) {
  const offset = -110;
  const left = -190 * (index % 12);
  const top = -190 * Math.floor(index / 12);
  const scale = 0.6;
  return (
    <View
      style={{
        display: "flex",
        width: 110,
        height: 110
      }}
    >
      <View
        style={{
          display: "flex",
          width: `${100 / scale}%`,
          height: `${100 / scale}%`,
          transform: `scale(${scale}) translate(-50%, -50%)`
        }}
      >
        <Image
          source={animalsImage}
          style={{
            position: "absolute",
            top: offset + top,
            left: offset + left
          }}
        />
      </View>
    </View>
  );
});

const AnimalCard = React.memo(function AnimalCard({ imageId, small, children, theme }) {
  if (!childImageMap[imageId]) {
    return null;
  }

  const [index, childColor] = childImageMap[imageId];

  return (
    <View
      style={{
        display: "flex",
        flex: 1,
        backgroundColor: theme === "TEEN" ? teenImageMap[imageId][1] : childColor,
        justifyContent: "flex-end",
        borderRadius: 12
      }}
    >
      {theme === "TEEN" ? (
        <Image
          source={teenImageMap[imageId][0]}
          style={{
            width: "100%",
            height: "100%"
          }}
        />
      ) : (
        <View style={[small && { transform: `scale(0.6) translate(-7px, -19px)` }]}>
          <Animal index={index} />
        </View>
      )}
      {children}
    </View>
  );
});

export default AnimalCard;
