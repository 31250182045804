import { Select, TextField } from "@shopify/polaris";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useActions, useAtom } from "tiny-atom/react/hooks";
import Header from "../components/Header";
import "./MyInbox.css";
import AllConversations from "../components/my-inbox/AllConversations";
import { Component } from "../components/tabs/ComponentSwitch";
import { Inbox } from "react-feather";
import { ComponentSwitch } from "../components/tabs/ComponentSwitch";
import { Check } from "react-feather";
import useCareTeam from "../hooks/useCareTeam";
import { AppContext } from "../context/AppContext";
import { StyleSheet, css } from "aphrodite";
import useInboxConversations from "../hooks/useInboxConversations";
import useHasNarrowScreen from "../hooks/useHasNarrowScreen";
import { useParams } from "react-router-dom";

const defaultTab = "actionRequired";

export default function MyInbox() {
  const { setCurrentScreen } = useActions();

  setCurrentScreen("myInboxScreen");

  const routeParams = useParams();
  const userId = useAtom(state => state.userId);

  const [searchInput, setSearchInput] = useState("");
  const [tabSelection, setTabSelection] = useState(routeParams?.conversationId ? "allConversations" : defaultTab);
  const [inboxSelection, setInboxSelection] = useState();

  const asUserId = useMemo(() => inboxSelection && parseInt(inboxSelection), [inboxSelection]);

  const { conversations, loading, refetch, error } = useInboxConversations({ asUserId });

  const { careCoordinationEnabled, members } = useCareTeam();
  const { canReceiveMessages } = useContext(AppContext);
  const hasNarrowScreen = useHasNarrowScreen();

  const actionRequiredConvos = useMemo(
    () => (conversations || []).filter(c => c.pendingActions?.length),
    [conversations]
  );

  const handleSelectChange = useCallback(value => setInboxSelection(value), []);

  const viewAsOptions = members
    ? members.map(member => {
        return { label: member.firstName + " " + member.lastName, value: member.id.toString() };
      })
    : [];

  useEffect(() => {
    if (userId) {
      setInboxSelection(userId.toString());
    }
  }, [userId]);

  const conversationTabs = [
    Component({
      text: "Action Required",
      getIcon: selected => (
        <div className={css(styles.blueDotIcon, selected && styles.blueDotSelected)}>
          <Check size={18} color="#ffffff" />
        </div>
      ),
      onPress: () => setTabSelection("actionRequired")
    }),
    Component({
      text: "All Conversations",
      getIcon: selected => <Inbox size={26} color={selected ? "#2562D8" : "#C4C4C4"} />,
      onPress: () => setTabSelection("allConversations")
    })
  ];

  const tabComponents = {
    actionRequired: (
      <AllConversations
        conversations={actionRequiredConvos}
        asUserId={parseInt(inboxSelection)}
        searchText={searchInput.toLowerCase()}
        loading={loading}
        refetch={refetch}
        error={error}
        showDoneButtons
      />
    ),
    allConversations: (
      <AllConversations
        conversations={conversations}
        asUserId={parseInt(inboxSelection)}
        searchText={searchInput.toLowerCase()}
        loading={loading}
        refetch={refetch}
        error={error}
      />
    )
  };

  return (
    <div className="MyInbox">
      <Header
        narrow={hasNarrowScreen}
        text={
          (inboxSelection && inboxSelection != userId
            ? members.find(member => member.id === parseInt(inboxSelection)).firstName + "'s"
            : "My") + " Inbox"
        }
      >
        <div
          className={
            hasNarrowScreen
              ? "inbox-custom-polaris-text-field inbox-custom-polaris-text-field-narrow"
              : "inbox-custom-polaris-text-field"
          }
        >
          <TextField
            placeholder="Search by name"
            onChange={setSearchInput}
            type="search"
            name="search"
            value={searchInput}
          />
        </div>
        <div className="inbox-selection-container">
          {careCoordinationEnabled && inboxSelection && (
            <>
              <span className="view-inbox-label">View inbox of</span>
              <div className="inbox-custom-polaris">
                <Select options={viewAsOptions} onChange={handleSelectChange} value={inboxSelection} />
              </div>
            </>
          )}
        </div>
      </Header>
      {careCoordinationEnabled || canReceiveMessages ? (
        <>
          <ComponentSwitch
            tabs={conversationTabs}
            defaultIndex={Object.keys(tabComponents).indexOf(defaultTab) || 0}
            large
          />
          {tabComponents[tabSelection]}
        </>
      ) : (
        <>{tabComponents["allConversations"]}</>
      )}
    </div>
  );
}

const styles = StyleSheet.create({
  blueDotIcon: {
    backgroundColor: "#C4C4C4",
    height: 24,
    width: 24,
    borderRadius: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  blueDotSelected: {
    backgroundColor: "#2562D8"
  }
});
