import { FlexProps } from "../../components/core/Flex";
import useWindowDimensions from "../screen/useWindowDimensions";

export default function useCareScreenerWidth(props?: {
  smallScreenWidth?: string;
  smallScreenWidthTrigger?: number;
  largScreenMinWidth?: number;
}) {
  const windowDimensions = useWindowDimensions();
  const isSmallScreen = windowDimensions.width < (props?.smallScreenWidthTrigger || 660);

  const smallScreenWidth = props?.smallScreenWidth || "90%";
  const largScreenMinWidth = props?.largScreenMinWidth || 600;

  const widthStyle: FlexProps["style"] = {
    minWidth: isSmallScreen ? smallScreenWidth : largScreenMinWidth,
    maxWidth: isSmallScreen ? smallScreenWidth : largScreenMinWidth
  };

  return { widthStyle };
}
