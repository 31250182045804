import React, { ReactNode, useEffect, useMemo, useRef } from "react";
import Sidebar from "../components/Sidebar";
import "./ProviderApp.css";
import ToastList from "../components/ToastList";

import { SideBarContextProvider } from "../context/SideBarContext";
import { AppContextProvider } from "../context/AppContext";
import { MessagingContextProvider } from "../context/MessagingContext";
import { Route, Routes } from "react-router-dom";
import Directory from "../pages/Directory";
import Patient from "../pages/Patient";
import Invite from "../pages/Invite";
import InviteDetails from "../pages/InviteDetails";
import PendingInvites from "../pages/PendingInvites";
import Connection from "../pages/Connection";
import AddConnection from "../pages/AddConnection";
import RemoveConnection from "../pages/RemoveConnection";
import MyInbox from "../pages/MyInbox";
import AccountTypeRoutes from "./AccountTypeRoutes";
import { CARE_TEAM_MEMBER_ACCOUNT_TYPES_ARRAY } from "../model/userModels";

export default function ProviderApp() {
  const sideBarRef = useRef();

  const routeElements: ReactNode = useMemo(
    () => (
      <>
        <Route path="/" element={<Directory />} />
        <Route path="/patients" element={<Directory />} />
        <Route path="/patient" element={<Patient />} />
        <Route path="/patient/:patientId" element={<Patient />} />
        <Route path="/patient/:patientId/caregivers/:caregiverId/conversations/:conversationId" element={<Patient />} />
        <Route path="/patient/:patientId/conversations/:conversationId" element={<Patient />} />
        <Route path="/patient/:patientId/assessment" element={<Patient />} />
        <Route path="/invite" element={<Invite />} />
        <Route path="/inviteDetails" element={<InviteDetails />} />
        <Route path="/pendingInvites" element={<PendingInvites />} />
        <Route path="/connection" element={<Connection />} />
        <Route path="/addConnection" element={<AddConnection />} />
        <Route path="/removeConnection" element={<RemoveConnection />} />
        <Route path="/myInbox" element={<MyInbox />} />
        <Route path="/conversations/:conversationId" element={<MyInbox />} />
      </>
    ),
    []
  );

  return (
    <AccountTypeRoutes routeElements={routeElements} supportedAccountTypes={CARE_TEAM_MEMBER_ACCOUNT_TYPES_ARRAY}>
      <div className="Chrome">
        <AppContextProvider>
          <MessagingContextProvider>
            <Sidebar ref={sideBarRef} />
            <SideBarContextProvider sideBarRef={sideBarRef}>
              <div className="Chrome-content">
                <Routes>{routeElements}</Routes>
                <ToastList />
              </div>
            </SideBarContextProvider>
          </MessagingContextProvider>
        </AppContextProvider>
      </div>
    </AccountTypeRoutes>
  );
}
