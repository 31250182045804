import { useEffect, useRef, useState } from "react";

export default function useItemIterator(items) {
  const prevRef = useRef();
  const currentRef = useRef();
  const nextRef = useRef();
  const currentIndex = useRef(0);
  const [, forceRender] = useState(false);

  useEffect(() => {
    currentIndex.current = 0;
    setRefsForCurrentIndex();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rerender = () => {
    forceRender(value => !value);
  };

  const setRefsForCurrentIndex = () => {
    if (currentIndex.current >= 0 && currentIndex.current < items.length) {
      currentRef.current = items[currentIndex.current];
    } else {
      currentRef.current = undefined;
    }
    nextRef.current = hasNext() ? items[currentIndex.current + 1] : undefined;
    prevRef.current = hasPrev() ? items[currentIndex.current - 1] : undefined;
    rerender();
  };

  const getPrev = () => {
    return prevRef.current;
  };

  const getNext = () => {
    return nextRef.current;
  };

  const hasPrev = () => {
    return currentIndex.current - 1 >= 0;
  };

  const hasNext = () => {
    return currentIndex.current + 1 < items.length;
  };

  const getCurrent = () => {
    return currentRef.current;
  };

  const prev = () => {
    if (hasPrev()) {
      currentIndex.current = currentIndex.current - 1;
      setRefsForCurrentIndex();
    }
  };

  const next = () => {
    if (hasNext()) {
      currentIndex.current = currentIndex.current + 1;
      setRefsForCurrentIndex();
    }
  };

  const setIndex = index => {
    currentIndex.current = index;
    setRefsForCurrentIndex();
  };

  const getIndex = () => {
    return currentIndex.current;
  };

  return { getCurrent, getNext, getPrev, hasNext, hasPrev, next, prev, setIndex, getIndex };
}
