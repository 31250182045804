import React, { useContext } from "react";
import { StyleSheet, css } from "aphrodite";
import EmojiPickerButton from "./EmojiPickerButton";
import MessageTemplateButton from "./MessageTemplateButton";
import { AppContext } from "../../../context/AppContext";
import FileAttachmentButton from "./FileAttachmentButton";
import { MANATEE_CARE_PROVIDER } from "../../../../src/utils/constants";

export default function MessageActionBar({
  textInputId,
  setMessage,
  message,
  setShowTemplateModal,
  setFileAttachment,
  fileAttachment
}) {
  const { messageTemplates, user } = useContext(AppContext);

  return (
    <div className={css(styles.container)}>
      {messageTemplates.length > 0 && (
        <>
          <MessageTemplateButton onPress={() => setShowTemplateModal(true)} />
          <div className={css(styles.spacer)} />
        </>
      )}
      <EmojiPickerButton textInputId={textInputId} setMessage={setMessage} message={message} />
      <div className={css(styles.emojiSpacer)} />
      {setFileAttachment && (user?.providerName === "testProvider" || user?.providerName === MANATEE_CARE_PROVIDER) && (
        <>
          <FileAttachmentButton setFile={setFileAttachment} file={fileAttachment} />
          <div className={css(styles.spacer)} />
        </>
      )}
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  spacer: {
    width: 4
  },
  emojiSpacer: {
    width: 8
  }
});
