import React, { ReactNode, useCallback } from "react";
import { createRoutesFromElements, matchPath, RouteObject } from "react-router-dom";
import { AccountType } from "../model/userModels";
import { isString, uniq } from "lodash";

type Context = {
  getRedirect: () => string | null;
  setRedirect: (redirect: string | undefined) => void;
  registerRoutes: (
    routeElements: ReactNode,
    options?: { authRequired?: boolean; requiredAccountTypes?: AccountType[] }
  ) => { remove: () => void };
  routesExistsForPath: (path: string) => boolean;
  routeRequiresAuth: (path: string) => boolean;
  routeSupportsAccountType: (path: string, accountType: AccountType) => boolean;
  getRequiredAppointmentTypesForRoute: (path: string) => AccountType[] | undefined;
};

export const RoutingContext = React.createContext<Context>({} as Context);

const redirectKey = "app-redirect-pathname";

type AuthRequiredRoute = {
  routeObject: RouteObject;
  requiredAccountTypes?: AccountType[];
};

export function RoutingContextProvider({ children }) {
  const [authRequiredRoutes, setAuthRequiredRoutes] = React.useState<AuthRequiredRoute[]>([]);
  const [allRoutes, setAllRoutes] = React.useState<RouteObject[]>([]);

  const getRedirect = useCallback(() => {
    return window.sessionStorage.getItem(redirectKey);
  }, []);

  const setRedirect = useCallback(redirect => {
    if (redirect) {
      window.sessionStorage.setItem(redirectKey, redirect);
    } else {
      window.sessionStorage.removeItem(redirectKey);
    }
  }, []);

  const registerRoutes = useCallback(
    (routeElements: ReactNode, options?: { authRequired?: boolean; requiredAccountTypes?: AccountType[] }) => {
      const routes = createRoutesFromElements(routeElements);
      if (options?.authRequired) {
        setAuthRequiredRoutes(authRoutes => [
          ...authRoutes,
          ...routes.map(route => ({ routeObject: route, requiredAccountTypes: options.requiredAccountTypes }))
        ]);
      }
      setAllRoutes(allRoutes => [...allRoutes, ...routes]);

      return {
        remove: () => {
          setAllRoutes(allRoutes => allRoutes.filter(route => !routes.includes(route)));
          if (options?.authRequired) {
            setAuthRequiredRoutes(authRoutes =>
              authRoutes.filter(authRoute => !routes.includes(authRoute.routeObject))
            );
          }
        }
      };
    },
    []
  );

  const routesExistsForPath = useCallback(
    (path: string) => {
      return allRoutes.some(r => r.path && matchPath(r.path, path));
    },
    [allRoutes]
  );

  const routeRequiresAuth = useCallback(
    (path: string) => {
      return authRequiredRoutes.some(r => r.routeObject.path && matchPath(r.routeObject.path, path));
    },
    [authRequiredRoutes]
  );

  const routeSupportsAccountType = useCallback(
    (path: string, accountType: AccountType) => {
      const supportedAuthRoutes = authRequiredRoutes.filter(
        r => r.routeObject.path && matchPath(r.routeObject.path, path)
      );

      if (supportedAuthRoutes.length > 0) {
        const matchingRoute = supportedAuthRoutes.some(
          r =>
            r.requiredAccountTypes === undefined ||
            r.requiredAccountTypes.length === 0 ||
            r.requiredAccountTypes.includes(accountType)
        );
        return matchingRoute;
      }

      return true;
    },
    [authRequiredRoutes]
  );

  const getRequiredAppointmentTypesForRoute = useCallback(
    (path: string) => {
      const supportedAuthRoutes = authRequiredRoutes.filter(
        r => r.routeObject.path && matchPath(r.routeObject.path, path)
      );

      if (supportedAuthRoutes.length > 0) {
        const requiredAppointmentTypes: AccountType[] = supportedAuthRoutes
          .filter(r => r.requiredAccountTypes && r.requiredAccountTypes.length > 0)
          .flatMap(r => r.requiredAccountTypes || []);

        return requiredAppointmentTypes;
      }
      return undefined;
    },
    [authRequiredRoutes]
  );

  return (
    <RoutingContext.Provider
      value={{
        getRedirect,
        setRedirect,
        registerRoutes,
        routesExistsForPath,
        routeRequiresAuth,
        routeSupportsAccountType,
        getRequiredAppointmentTypesForRoute
      }}
    >
      {children}
    </RoutingContext.Provider>
  );
}
