import moment from "moment-timezone";
import {
  THERAPIST_CREATE_GOAL,
  THERAPIST_UPDATE_GOAL,
  DELETE_GOAL,
  RENEW_GOAL,
  FINALIZE_GOAL,
  THERAPIST_GET_CUSTOM_GOALS,
  THERAPIST_GET_CUSTOM_PARENT_GOALS,
  COMPLETE_AND_VERIFY_GOAL,
  THERAPIST_GET_CHILD
} from "../queries";
import { logEvent } from "../actions";
import { getEndOfDays, DAILY, CUSTOM, getGMT } from "./dateUtils";
import { randomImageId } from "../components/goal-card-views/GoalIcon";
import client from "../apollo/client";
import defaultGoalGroups from "../data/defaultGoals-en";
import { getDayOfWeek } from "./dateUtils";
import _ from "lodash";

const threeOclockPM = new Date("July 1, 1999 15:00");

export const GOAL_TYPES = {
  default: "DEFAULT",
  custom: "CUSTOM"
};
export const DEFAULT_GOAL_GROUPS = groupAndSortGoalsByObjective(defaultGoalGroups, GOAL_TYPES.default);

function getFrequency(goal) {
  return goal.dueAt.split("59 59 23 * * ")[1];
}

function getDigitDaysOfWeek(frequency) {
  return frequency.split(",");
}

export function getReminderDays(goal) {
  if (!goal.reminderTime1) {
    return "None";
  }

  const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  let frequency = getFrequency(goal);
  let digitDaysOfWeek = getDigitDaysOfWeek(frequency);
  let reminderDays = "";

  if (frequency === "*") {
    reminderDays = "Daily";
  } else {
    for (let i = 0; i < digitDaysOfWeek.length; i++) {
      reminderDays += `${days[digitDaysOfWeek[i]]}, `;
    }

    reminderDays = reminderDays.slice(0, -1);
    reminderDays = reminderDays.slice(0, -1);
  }
  return reminderDays;
}

export function defaultGoalSettings() {
  return {
    name: "",
    description: "",
    frequency: "custom",
    dueAt: CUSTOM,
    expiresAt: 30,
    value: 2,
    days: [],
    changesMade: false,
    dailyFrequency: 1
  };
}

export function gatherGoalProperties(props, state, goalAction, reminderEnabled) {
  let goal = {};
  goal.name = state.name;
  goal.description = state.description;
  goal.expiresAt = getEndOfDays(30);
  goal.dailyFrequency = state.dailyFrequency;
  const time = state.reminderTime1Local ? getGMT(state.reminderTime1Local) : getGMT(threeOclockPM);
  goal.reminderTime1 = reminderEnabled ? time : undefined;
  goal.days = _.difference([0, 1, 2, 3, 4, 5, 6], state.days || []).length === 0 ? "*" : state.days || "*";
  goal.dueAt = reminderEnabled ? CUSTOM + goal.days.toString() : DAILY;
  goal.imageId = state.imageId || randomImageId(); // copy & edit goal should retain image; otherwise use a random
  goal.value = state.value;
  if (
    goalAction === "create" ||
    state.makeUnassignedCopy ||
    (props.mode === "edit" && props.goal.copy && state.changesMade) ||
    (props.clickedPlusButton && state.changesMade)
  ) {
    goal.createUnassignedCopy = true;
  }
  return goal;
}

export async function assignGoal(props, goal) {
  localStorage.removeItem("scrollTop");
  goal.reminderTime1 = adjustTimezoneClientToServer(goal.reminderTime1, props.patient.timeZone);
  await client.mutate({
    mutation: THERAPIST_CREATE_GOAL,
    variables: {
      assignedTo: props.patient.id,
      ...goal
    },
    refetchQueries: [{ query: THERAPIST_GET_CHILD, variables: { childId: props.patient.id } }]
  });
  logEvent("therapistWebGoalAssigned");
}

export async function assignParentGoal(parentId, assigneeTimezone, goal) {
  localStorage.removeItem("scrollTop");
  goal.unassignedCopyReminderTime1 = goal.reminderTime1;
  goal.unassignedCopyReminderTime2 = goal.reminderTime2;
  goal.reminderTime1 = adjustTimezoneClientToServer(goal.reminderTime1, assigneeTimezone);
  goal.reminderTime2 = adjustTimezoneClientToServer(goal.reminderTime2, assigneeTimezone);
  await client.mutate({
    mutation: THERAPIST_CREATE_GOAL,
    variables: {
      assignedTo: parentId,
      ...goal
    },
    refetchQueries: [{ query: THERAPIST_GET_CUSTOM_PARENT_GOALS }]
  });
  logEvent("therapistWebParentGoalAssigned");
}

export async function createGoal(props, goal) {
  localStorage.removeItem("scrollTop");
  goal.unassignedCopyReminderTime1 = goal.reminderTime1;
  goal.reminderTime1 = adjustTimezoneClientToServer(goal.reminderTime1, props.patient.timeZone);
  await client.mutate({
    mutation: THERAPIST_CREATE_GOAL,
    variables: {
      assignedTo: props.patient.id,
      ...goal
    },
    refetchQueries: [
      { query: THERAPIST_GET_CUSTOM_GOALS },
      { query: THERAPIST_GET_CHILD, variables: { childId: props.patient.id } }
    ]
  });
  logEvent("therapistWebGoalCreated");
}

export async function updateGoal(props, goal, assignmentStatus) {
  if (assignmentStatus != "unassigned") {
    goal.reminderTime1 = adjustTimezoneClientToServer(goal.reminderTime1, props.patient.timeZone);
  }
  await client.mutate({
    mutation: THERAPIST_UPDATE_GOAL,
    variables: {
      goalId: props.goal.id,
      ...goal
    },
    refetchQueries: [{ query: THERAPIST_GET_CUSTOM_GOALS }]
  });
  logEvent("therapistWebGoalUpdated");
}

export async function updateParentGoal(goal, assigneeTimezone, assignmentStatus) {
  if (assignmentStatus != "unassigned") {
    goal.reminderTime1 = adjustTimezoneClientToServer(goal.reminderTime1, assigneeTimezone);
    goal.reminderTime2 = adjustTimezoneClientToServer(goal.reminderTime2, assigneeTimezone);
  }
  await client.mutate({
    mutation: THERAPIST_UPDATE_GOAL,
    variables: {
      goalId: goal.id,
      ...goal
    },
    refetchQueries: [{ query: THERAPIST_GET_CUSTOM_PARENT_GOALS }]
  });
  logEvent("therapistWebGoalUpdated");
}

export async function renewGoal(props, newExpirationDate) {
  await client.mutate({
    mutation: RENEW_GOAL,
    variables: {
      childId: props.familyUser.id,
      goalId: props.goal.id,
      newExpirationDate
    }
  });
  logEvent("therapistWebGoalRenewed");
}

export async function completeGoal(props) {
  await client.mutate({
    mutation: FINALIZE_GOAL,
    variables: {
      childId: props.familyUser.id,
      goalId: props.goal.id
    }
  });
  logEvent("therapistWebGoalMarkedComplete");
}

export async function markGoalAchieved(props) {
  await client.mutate({
    mutation: COMPLETE_AND_VERIFY_GOAL,
    variables: {
      goalId: props.goal.id,
      childId: props.familyUser.id,
      note: props.note,
      achievedDate: props.achievementDate
    },
    refetchQueries: [{ query: THERAPIST_GET_CHILD, variables: { childId: props.familyUser.id } }]
  });
  logEvent("therapistWebGoalMarkedAchieved");
}

export async function unassignGoal(props) {
  const goal = props.goal || props.item;
  const childId = (props.patient && props.patient.id) || goal.assignedTo.id;
  if (goal.matchedCustomGoal) {
    localStorage.removeItem("scrollTop");
    await client.mutate({
      mutation: DELETE_GOAL,
      variables: {
        goalId: goal.matchedCustomGoal
      },
      refetchQueries: [{ query: THERAPIST_GET_CHILD, variables: { childId } }]
    });
    logEvent("therapistWebGoalUnassigned");
  } else {
    deleteGoal(props, goal.id);
  }
}

export async function deleteGoal(props, goalId) {
  const childId = (props.patient && props.patient.id) || props.item.assignedTo.id;
  await client.mutate({
    mutation: DELETE_GOAL,
    variables: {
      goalId: goalId
    },
    refetchQueries: [{ query: THERAPIST_GET_CUSTOM_GOALS }, { query: THERAPIST_GET_CHILD, variables: { childId } }]
  });
  logEvent("therapistWebGoalDeleted");
}

export async function deleteParentGoal(goalId) {
  await client.mutate({
    mutation: DELETE_GOAL,
    variables: {
      goalId: goalId
    },
    refetchQueries: [{ query: THERAPIST_GET_CUSTOM_PARENT_GOALS }]
  });
  logEvent("therapistWebParentGoalDeleted");
}

export function groupAndSortGoalsByObjective(goalGroups, goalType) {
  const goalsByObjective = new Map();

  function addToGroup(objective, goal) {
    if (!goalsByObjective.has(objective)) {
      goalsByObjective.set(objective, [goal]);
    } else {
      goalsByObjective.get(objective).push(goal);
    }
  }
  const goals = defaultGoalGroups
    .map(group => {
      group.goals = group.goals.map(goal => {
        return {
          ...goal,
          objective: group.title
        };
      });
      return group.goals;
    })
    .flat();
  goals.forEach(g => {
    if (!g.objective) {
      addToGroup("Other", g);
      return;
    }
    addToGroup(g.objective, g);
  });

  const groups = [];
  goalsByObjective.forEach((goals, objective) => {
    groups.push(createSortedGoalGroup(objective, goals, goalType));
  });

  const sortedGroups = groups.sort((a, b) => (a.objective < b.objective ? -1 : b.objective < a.objective ? 1 : 0));
  return sortedGroups;
}

export function createSortedGoalGroup(objective, goals, goalType) {
  const goalsWithType = goals ? goals.map(g => ({ ...g, goalType })) : [];
  const sortedGoals = sortGoalsByName(goalsWithType);
  return {
    objective,
    goals: sortedGoals
  };
}

export function sortGoalsByName(goals) {
  return goals.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
}

export function isSameDay(date1, date2) {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
}

function achievementCountsForGivenDate(achievements, dateInQuestion) {
  let achievementsThatDay = 0;
  achievements.forEach(achievement => {
    if (isSameDay(dateInQuestion, new Date(achievement.achievedAt))) {
      achievementsThatDay++;
    }
  });

  return achievementsThatDay;
}

function availableForAchievement(dateInQuestion, assignedAt, achievements, dailyFrequency) {
  if (
    new Date(new Date(assignedAt) - new Date().getTimezoneOffset()).setHours(0, 0, 0, 0) >
    dateInQuestion.setHours(0, 0, 0, 0)
  ) {
    return false;
  }

  const achievementsThisIteration = achievementCountsForGivenDate(achievements, dateInQuestion);
  return achievementsThisIteration < dailyFrequency;
}

export function pastSevenDaysAvailableForAchievement(goal) {
  let result = [];
  for (let i = 0; i < 7; i++) {
    let dateInQuestion = new Date();
    dateInQuestion.setDate(dateInQuestion.getDate() - i);
    const available = goal
      ? availableForAchievement(dateInQuestion, goal.assignedAt, goal.achievements, goal.dailyFrequency)
      : false;
    result.push({
      label: getDayOfWeek(dateInQuestion),
      value: available
        ? moment(dateInQuestion)
            .add(1, "days") // add day then subtract a minute to be after assigned_at within same day. Don't add 23:59:59 due to DST days
            .subtract(1, "seconds")
            .toISOString()
        : undefined
    });
  }

  return result;
}

export const resetGoalManagementScrollHeight = () => {
  localStorage.setItem("scrollTop", 0);
};

export const setGoalManagementScrollHeight = () => {
  const goalManagement = document.getElementsByClassName("GoalManagement")[0];
  if (goalManagement) {
    const scrollTop = goalManagement.scrollTop;
    localStorage.setItem("scrollTop", scrollTop);
  }
};

export const mapGoalDueAtToWeekdayOptions = goalDueAt => {
  return goalDueAt
    .substring(13)
    .split(",")
    .map(item => {
      return parseInt(item, 10);
    })
    .filter(item => !isNaN(item));
};

const getLocalToAssigneeTimeZoneOffset = assigneeTimezone => {
  var now = moment();
  var localUTCOffset = now.utcOffset();
  now.tz(assigneeTimezone);
  var assigneeUTCOffset = now.utcOffset();
  return localUTCOffset - assigneeUTCOffset;
};

// Convert from local timezone to the assignee's timezone, if available.
const adjustTimezoneClientToServer = (reminderTimeLocal, assigneeTimezone) => {
  if (!assigneeTimezone || !reminderTimeLocal) {
    return reminderTimeLocal;
  }

  const localToAssigneeOffset = getLocalToAssigneeTimeZoneOffset(assigneeTimezone);
  return moment(`${moment().format("YYYY-MM-DD").toString()} ${reminderTimeLocal}`)
    .add(localToAssigneeOffset, "minutes")
    .format("HH:mm");
};

// Convert from UTC timezone of reminder time from assignee's timezone (or therapist's if client has no timezone) to local timezone for UI display.
// This way therapist sees the time displayed in the time desired for the assignee to get reminded locally.
export const adjustTimezoneServerToClient = (reminderTimeUTC, assigneeTimezone) => {
  if (!reminderTimeUTC) {
    return null;
  }
  let adjustedTime;
  if (assigneeTimezone) {
    const localToAssigneeOffset = getLocalToAssigneeTimeZoneOffset(assigneeTimezone);
    adjustedTime = moment(`${moment().format("YYYY-MM-DD").toString()} ${reminderTimeUTC}`)
      .local()
      .subtract(localToAssigneeOffset, "minutes")
      .add(moment().utcOffset(), "minutes")
      .format("HH:mm");
  } else {
    adjustedTime = moment(moment.utc(`${moment().format("YYYY-MM-DD").toString()} ${reminderTimeUTC}`).toDate())
      .local()
      .format("HH:mm");
  }
  const date = new Date(`July 1, 1999 ${adjustedTime}`);
  return date;
};
