import { useEffect, useState } from "react";
import useBrowserTabFocus from "./useBrowserTabFocus";

export default function usePollBasedOnBrowserTabFocus(startPolling, stopPolling, pollIntervalMillis, refetch) {
  const [broswerInFocus, setBrowserInFocus] = useState(true);
  useBrowserTabFocus(setBrowserInFocus);

  useEffect(() => {
    if (broswerInFocus) {
      refetch();
      startPolling(pollIntervalMillis);
    } else {
      stopPolling();
    }
  }, [broswerInFocus, pollIntervalMillis, refetch, startPolling, stopPolling]);
}
