import config from "../config/env";
import { User } from "../model/userModels";
import { setAuthTokenCookie } from "./AuthService";

type CreateCareScreenerSubmissionRequest = {
  typeformResponseId: string;
  typeformId: string;
  typeformPath: string;
};

type CreateCareScreenerSubmissionResponse = {
  id: number;
};

type CheckCareScreenerInsuranceEligibilityRequest = {
  careScreenerSubmissionId: number;
  typeformId: string;
  typeformResponseId: string;
  cptCode?: string;
};

type CreateCareScreenerAccounts = {
  careScreenerSubmissionId: number;
  typeformId: string;
  typeformResponseId: string;
  password: string;
  timeZone: string;
  timeZoneOffset: string;
};

export type InsuranceEligibilityStatus = "ELIGIBLE" | "INELIGIBLE" | "NOT_APPLICABLE" | "UNKNOWN";
export type DirectIntakeStatus = "UNQUALIFIED" | "QUALIFIED" | "INTAKE_REQUESTED" | "NOT_APPLICABLE";

export type InsuranceEligibilityCheckResponse = {
  careScreenerSubmissionId: number;
  eligibilityStatus: InsuranceEligibilityStatus;
  insuranceEstimate?: InsuranceEstimate;
};

export type InsuranceEstimate = {
  payerId: string;
  planName: string;
  planStatus: "ACTIVE" | "INACTIVE" | "ACTIVE_AT_RISK";
  mentalHealthCoverage: "UNKNOWN" | "ACTIVE" | "INACTIVE";
  copaymentInCents: number | undefined;
  coinsurancePercent: number | undefined;
  deductibleInCents: number | undefined;
  remainingDeductibleInCents: number | undefined;
  oopMaxInCents: number | undefined;
  remainingOopMaxInCents: number | undefined;
  memberObligationInCents: number | undefined;
  postDeductibleMemberObligationInCents: number | undefined;
  postOopMaxMemberObligationInCents: number | undefined;
};

export type CompleteCareScreenerResponse = {
  directIntakeStatus: DirectIntakeStatus | undefined;
  careCoordinator?: User;
};

const CareScreenerService = {
  async createCareScreenerSubmission({
    typeformId,
    typeformResponseId,
    typeformPath
  }: CreateCareScreenerSubmissionRequest): Promise<CreateCareScreenerSubmissionResponse> {
    try {
      const response = await fetch(`${config.env.REACT_APP_API}/careScreenerSubmission${window.location.search}`, {
        body: JSON.stringify({ typeformResponseId, typeformId, typeformPath }),
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        const result = await response.json();
        throw new Error(result.message || "Failed to create care screener submission");
      } else {
        return await response.json();
      }
    } catch (e) {
      console.log(JSON.stringify(e));
      throw e;
    }
  },
  async checkCareScreenerInsuranceEligibility({
    typeformId,
    typeformResponseId,
    careScreenerSubmissionId: id,
    cptCode
  }: CheckCareScreenerInsuranceEligibilityRequest): Promise<InsuranceEligibilityCheckResponse> {
    try {
      const response = await fetch(
        `${config.env.REACT_APP_API}/careScreenerSubmissions/${id}/actions/checkInsuranceEligibility`,
        {
          body: JSON.stringify({ typeformResponseId, typeformId, cptCode }),
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          }
        }
      );
      if (!response.ok) {
        const result = await response.json();
        throw new Error(result.message || "Failed to check care screener insurance eligibility");
      } else {
        return await response.json();
      }
    } catch (e) {
      console.log(JSON.stringify(e));
      throw e;
    }
  },
  async completeCareScreener(request: CreateCareScreenerAccounts): Promise<CompleteCareScreenerResponse> {
    try {
      const response = await fetch(`${config.env.REACT_APP_API}/completeCareScreener`, {
        body: JSON.stringify(request),
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        const result = await response.json();
        throw new Error(result.message || "Failed to complete care screener");
      } else {
        const result = await response.json();
        const token = result.token;
        setAuthTokenCookie(token, { expiresInMillis: 24 * 60 * 60 * 1000 });
        sessionStorage.setItem("role", "PARENT");
        sessionStorage.setItem("userId", result.userId);

        return { ...result, token: undefined };
      }
    } catch (e) {
      console.log(JSON.stringify(e));
      throw e;
    }
  }
};

export default CareScreenerService;
