import React, { useContext } from "react";
import { SideBarContext } from "../context/SideBarContext";

export default function OverlayCenteredView({ dim = true, style = undefined, children }) {
  const sideBarContext = useContext(SideBarContext);

  const rightOfNavBar = <div style={{ ...styles.getPosition(sideBarContext.sideBarRef), ...style }}>{children}</div>;
  const withFullScreenDim = () => <div style={styles.fullScreenDim}>{rightOfNavBar}</div>;

  if (dim) {
    return withFullScreenDim();
  }
  return rightOfNavBar;
}

const styles = {
  fullScreenDim: {
    zIndex: 100,
    margin: 0,
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    userSelect: "none"
  },
  getPosition: sideBarRef => {
    return {
      zIndex: 100,
      margin: "auto",
      position: "fixed",
      right: 0,
      left: sideBarRef && sideBarRef.current && sideBarRef.current.clientWidth ? sideBarRef.current.clientWidth : 0,
      top: 0,
      bottom: 0,
      overflow: "scroll"
    };
  }
};
