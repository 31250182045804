import React, { useState } from "react";
import { TextField, Button, RadioButton, BlockStack } from "@shopify/polaris";
import AuthService from "../services/AuthService";
import { Formik } from "formik";
import { useAtom, useActions } from "tiny-atom/react/hooks";
import Modal from "../components/Modal";
import "./Register.css";
import FormErrorMessage from "../components/FormErrorMessage";
import { Text } from "../components/react-native";
import { StyleSheet, css } from "aphrodite";
import { getPasswordError } from "../utils/formUtils";
import moment from "moment";
import momentTimeZone from "moment-timezone";

export default function PasswordReset() {
  const [email, setEmail] = useState<string>();
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>();
  const [careCoordinationEnabled, setCareCoordinationEnabled] = useState<boolean | undefined>();
  const [accountType, setAccountType] = useState<string | undefined>();
  const { navigate, logEvent, setCurrentScreen, showModal, hideModal } = useActions();
  const error = useAtom(state => state.error);
  const [loading, setLoading] = useState<boolean>(false);
  const isErrorModalVisible = useAtom(state => state.isErrorModalVisible);
  const [showVerifyModal, setShowVerifyModal] = useState<boolean>(false);

  const getEmailRegistrationOptions = async () => {
    if (email) {
      const registrationOptions = await AuthService.getEmailRegistrationOptions(email);
      setCareCoordinationEnabled(prev => {
        const newValue = !!registrationOptions?.careCoordinationEnabled;
        if (prev !== newValue) {
          if (!newValue) {
            setAccountType("THERAPIST");
          } else {
            setAccountType(undefined);
          }
        }
        return newValue;
      });
    }
  };

  async function handleSubmit() {
    try {
      await AuthService.register({
        email: email,
        firstName: firstName,
        lastName: lastName,
        password: password,
        accountType: accountType,
        timeZoneOffset: moment().format("Z"),
        timeZone: momentTimeZone.tz.guess()
      })
        .then(async result => {
          if (result && result.message) {
            if (result.message === "ER_DUP_ENTRY") {
              showModal("Email already in use");
            } else {
              showModal(result.message);
            }
            logEvent("webRegisterFailed");
          } else {
            logEvent("webRegisterSuccess");
            setShowVerifyModal(true);
          }
        })
        .catch(() => {
          logEvent("webRegisterFailed");
        });
    } finally {
      setLoading(false);
    }
  }

  setCurrentScreen("WebRegisterScreen");
  return (
    <div className="Register">
      <div
        style={{
          pointerEvents: isErrorModalVisible ? "none" : undefined
        }}
      >
        <Formik
          initialValues={{
            email: "",
            firstName: "",
            lastName: "",
            password: "",
            confirmPassword: "",
            accountType: ""
          }}
          validate={() => {
            const errors: { [key: string]: string } = {};
            const passwordError = getPasswordError(password);

            if (!email) {
              errors.email = "Required";
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
              errors.email = "Invalid email address";
            } else if (!firstName) {
              errors.firstName = "First Name Required";
            } else if (!lastName) {
              errors.lastName = "Last Name Required";
            } else if (passwordError) {
              errors.password = passwordError;
            } else if (!confirmPassword) {
              errors.confirmPassword = "Confirm Password Required";
            } else if (password !== confirmPassword) {
              errors.confirmPassword = "Passwords must match";
            } else if (!accountType) {
              errors.accountType = "Role selection required";
            }
            return errors;
          }}
          onSubmit={() => {
            if (!loading) {
              handleSubmit();
            }
          }}
        >
          {({ handleSubmit }) => (
            <form
              className="Main-form"
              onKeyDown={e => {
                if (e.key === "Enter" && !loading) {
                  handleSubmit();
                }
              }}
            >
              <div className="Manatee-logo" />
              <BlockStack gap={"200"}>
                <TextField
                  placeholder="Email"
                  onChange={setEmail}
                  onBlur={getEmailRegistrationOptions}
                  type="email"
                  name="email"
                  value={email}
                  label=""
                  autoComplete="on"
                />
                <FormErrorMessage name="email" component="div" />
                <TextField
                  placeholder="First Name"
                  onChange={setFirstName}
                  type="text"
                  name="firstName"
                  value={firstName}
                  label=""
                  autoComplete="on"
                />
                <FormErrorMessage name="firstName" component="div" />
                <TextField
                  placeholder="Last Name"
                  onChange={setLastName}
                  type="text"
                  name="lastName"
                  value={lastName}
                  label=""
                  autoComplete="on"
                />
                <FormErrorMessage name="lastName" component="div" />
                <TextField
                  placeholder="Password"
                  onChange={setPassword}
                  type="password"
                  name="password"
                  value={password}
                  label=""
                  autoComplete="off"
                />
                <FormErrorMessage name="password" component="div" />
                <TextField
                  placeholder="Confirm Password"
                  onChange={setConfirmPassword}
                  type="password"
                  name="password"
                  value={confirmPassword}
                  label=""
                  autoComplete="off"
                />
                <FormErrorMessage name="confirmPassword" component="div" />
                {careCoordinationEnabled && (
                  <>
                    <div className={css(styles.accountTypeView)}>
                      <BlockStack gap={"400"}>
                        <Text style={{}}>Role in care team:</Text>
                        <RadioButton
                          label="Therapist"
                          checked={accountType === "THERAPIST"}
                          onChange={() => setAccountType("THERAPIST")}
                        />
                        <RadioButton
                          label="Parent Coach"
                          checked={accountType === "PARENT_COACH"}
                          onChange={() => setAccountType("PARENT_COACH")}
                        />
                        <RadioButton
                          label="Care Coordinator"
                          checked={accountType === "CARE_COORDINATOR"}
                          onChange={() => setAccountType("CARE_COORDINATOR")}
                        />
                      </BlockStack>
                    </div>
                  </>
                )}
                <FormErrorMessage name="accountType" component="div" />
                <Button fullWidth variant="primary" disabled={loading} onClick={handleSubmit}>
                  Register
                </Button>
                <Button fullWidth variant="secondary" onClick={() => navigate({ pathname: "/login" })}>
                  Cancel
                </Button>
              </BlockStack>
            </form>
          )}
        </Formik>
      </div>
      <Modal
        title={error ? error.message : ""}
        hidden={!isErrorModalVisible}
        onButton1Press={hideModal}
        fullscreen={true}
      />
      <Modal
        title={"Registration Successful"}
        titleStyle={modalTitleStyle}
        subtitle={"Please check your email and click the link to verify your email address."}
        subtitleStyle={modalSubtitleStyle}
        hidden={!showVerifyModal}
        onButton1Press={() => {
          setShowVerifyModal(false);
          navigate({ pathname: "/login" });
        }}
        fullscreen={true}
      />
    </div>
  );
}

const modalTitleStyle = {
  color: "#000000",
  fontFamily: "Avenir Next",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "30px",
  lineHeight: "40px",
  textAlign: "center",
  marginBottom: "15px"
};

const modalSubtitleStyle = {
  color: "#000000",
  fontFamily: "Avenir Next",
  fontStyle: "normal",
  textAlign: "center",
  marginBottom: "15px"
};

const styles = StyleSheet.create({
  accountTypeView: {
    padding: 20,
    backgroundColor: "#F4F6F8",
    borderRadius: 10
  }
});
