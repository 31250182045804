import Assessments from "../assessments/Assessments";
import ClientGoalCard from "./ClientGoalCard";
import ParentingGoalCard from "./ParentingGoalCard";
import React, { useContext, useMemo, useState } from "react";
import { Box } from "@shopify/polaris";
import { TabulatedHeader, TabData } from "../tabs/TabulatedHeader";
import engagementIcon from "../../assets/bar-chart-icon.png";
import assessmentIcon from "../../assets/line-chart-icon.png";
import parentingGoalsIcon from "../../assets/parent-child-icon.png";
import clientGoalsIcon from "../../assets/user-icon.png";
import messagesIcon from "../../assets/messages-icon.png";
import { useAtom } from "tiny-atom/react/hooks";
import { FamilyAssessmentContext } from "../../context/FamilyAssessmentContext";
import Overview from "./Overview";
import MessagesTab from "../messages/MessagesTab";
import { MessagingContext } from "../../context/MessagingContext";

export default function PatientTabs({ patient, achievements, defaultTab }) {
  const [tabSelection, setTabSelection] = useState(defaultTab);
  const patientData = useAtom(state => state.patientData);

  const { chartableAssessments } = useContext(FamilyAssessmentContext);

  const { conversations, refetch } = useContext(MessagingContext);

  const messageContactIds = useMemo(() => {
    return [...patientData.childParents.map(parent => parent.id), patientData.childDetails.id];
  }, [patientData]);
  const contactConversations = useMemo(() => {
    return (conversations || []).filter(
      convo =>
        (convo.familyId && convo.familyId === patient.familyId) ||
        convo.users.some(user => messageContactIds.includes(user.id))
    );
  }, [conversations, messageContactIds, patient.familyId]);
  const unreadCount: number = useMemo(() => {
    return contactConversations?.map(convo => convo.read).filter(read => !read).length;
  }, [contactConversations]);

  const tabComponents = useMemo(() => {
    return {
      engagement: <Overview />,
      patientGoals: <ClientGoalCard patient={patient} achievements={achievements} />,
      parentingGoals: <ParentingGoalCard />,
      assessment: <Assessments />,
      messages: <MessagesTab conversations={contactConversations} refetch={refetch} />
    };
  }, [achievements, patient, contactConversations, refetch]);

  const tabs: TabData[] = useMemo(() => {
    const engagementTab = {
      text: "Overview",
      icon: engagementIcon,
      onPress: () => setTabSelection("engagement")
    };
    const patientGoalsTab = {
      text: "Client Goals",
      icon: clientGoalsIcon,
      onPress: () => setTabSelection("patientGoals")
    };
    const assessmentTab = {
      text: "Clinical Assessments",
      icon: assessmentIcon,
      onPress: () => setTabSelection("assessment")
    };
    const parentingGoalsTab = {
      text: "Parenting Goals",
      icon: parentingGoalsIcon,
      onPress: () => setTabSelection("parentingGoals")
    };
    const messagesTab = {
      text: "Messages",
      icon: messagesIcon,
      onPress: () => {
        refetch();
        setTabSelection("messages");
      },
      getIndicator: () => {
        return unreadCount;
      }
    };

    const availableTabs = [engagementTab, patientGoalsTab];
    if (patientData?.childDetails.accountType === "CHILD") {
      availableTabs.push(parentingGoalsTab);
    }
    availableTabs.push(assessmentTab);
    availableTabs.push(messagesTab);

    return availableTabs;
  }, [patientData?.childDetails.accountType, refetch, unreadCount]);

  return (
    <Box>
      <TabulatedHeader tabs={tabs} defaultIndex={Object.keys(tabComponents).indexOf(defaultTab) || 0} />
      {tabComponents[tabSelection]}
    </Box>
  );
}
