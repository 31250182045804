import React from "react";
import Modal from "../Modal";
import { pastSevenDaysAvailableForAchievement } from "../../utils/goalUtils";

export default function GoalMarkAchievedModal({ goal, showAddNote, onCancel, isVisible, setAchievementDate }) {
  const pastSevenDays = pastSevenDaysAvailableForAchievement(goal);

  const onPress = achievementDate => {
    setAchievementDate(achievementDate);
    showAddNote();
  };

  return (
    <div>
      <div className="Modal-overlay" />
      <Modal
        hidden={!isVisible()}
        centeredHorizontallyHigh
        title="Which day was this goal achieved?"
        titleStyle={titleStyle}
        button1Label={pastSevenDays[0].label}
        button1Disabled={!pastSevenDays[0].value}
        onButton1Press={() => onPress(pastSevenDays[0].value)}
        button1Theme="green"
        button2Label={pastSevenDays[1].label}
        button2Disabled={!pastSevenDays[1].value}
        onButton2Press={() => onPress(pastSevenDays[1].value)}
        button2Theme="green"
        button3Label={pastSevenDays[2].label}
        button3Disabled={!pastSevenDays[2].value}
        onButton3Press={() => onPress(pastSevenDays[2].value)}
        button3Theme="green"
        button4Label={pastSevenDays[3].label}
        button4Disabled={!pastSevenDays[3].value}
        onButton4Press={() => onPress(pastSevenDays[3].value)}
        button4Theme="green"
        button5Label={pastSevenDays[4].label}
        button5Disabled={!pastSevenDays[4].value}
        onButton5Press={() => onPress(pastSevenDays[4].value)}
        button5Theme="green"
        button6Label={pastSevenDays[5].label}
        button6Disabled={!pastSevenDays[5].value}
        onButton6Press={() => onPress(pastSevenDays[5].value)}
        button6Theme="green"
        button7Label={pastSevenDays[6].label}
        button7Disabled={!pastSevenDays[6].value}
        onButton7Press={() => onPress(pastSevenDays[6].value)}
        button7Theme="green"
        button8Label="Cancel"
        onButton8Press={() => onCancel()}
        button8Theme="gray"
      />
    </div>
  );
}

const titleStyle = {
  color: "#000000",
  fontFamily: "Avenir Next",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "20px",
  lineHeight: "27px",
  textAlign: "center"
};
