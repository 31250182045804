import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import { StyleSheet, css } from "aphrodite";
import { Spinner } from "@shopify/polaris";
import { MESSAGE_ATTACHMENTS } from "../../queries";
import { useQuery } from "@apollo/client";
import { toDataUrl } from "../../utils/fileUtils";
import PaperClip from "../../assets/icons/paper-clip-navy.png";

export default function MessageAttachments({ message, asUserId }) {
  const dataFetched = useRef();
  const [selectedAttachment, setSelectedAttachment] = useState();
  const selectedAttachmentLinkRef = useRef();

  const [initialData, setInitialData] = useState(false);

  const { data, loading, error, refetch } = useQuery(MESSAGE_ATTACHMENTS, {
    variables: {
      messageId: message.id,
      asUserId,
      includeData: false
    },
    notifyOnNetworkStatusChange: true,
    skip: !message.hasAttachments || !message.id
  });

  useLayoutEffect(() => {
    if (!initialData && data) {
      setInitialData(data);
    }
  }, [data, initialData]);

  const AttachmentItem = ({ children, ...props }) => (
    <div {...props} className={css(styles.attachmentItem)}>
      <img src={PaperClip} className={css(styles.attachmentIcon)} />
      {children}
    </div>
  );

  useEffect(() => {
    if (selectedAttachment) {
      if (selectedAttachmentLinkRef.current) {
        selectedAttachmentLinkRef.current.click();
        selectedAttachmentLinkRef.current = undefined;
        setSelectedAttachment(undefined);
      } else if (!dataFetched.current) {
        refetch({
          includeData: true
        });
        dataFetched.current = true;
      }
    }
  }, [selectedAttachment, data, refetch]);

  return (
    <>
      <div className={css(styles.container)}>
        {(data || initialData)?.messageAttachments?.map((attachment, i) => {
          const isSelected = selectedAttachment?.name === attachment.name;
          const downloading = isSelected && loading;

          return (
            <AttachmentItem key={i}>
              <p>
                {downloading ? "Downloading: " : "Attachment: "}
                <span onClick={() => setSelectedAttachment(attachment)} className={css(styles.underlined)}>
                  {attachment.name}
                </span>
                {isSelected && attachment?.base64 && (
                  <a
                    ref={selectedAttachmentLinkRef}
                    hidden={true}
                    href={toDataUrl(attachment)}
                    download={attachment.name}
                  />
                )}
              </p>
              {downloading && (
                <div className={css(styles.spinner)}>
                  <Spinner size={"small"} />
                </div>
              )}
            </AttachmentItem>
          );
        })}
        {initialData && initialData.messageAttachments?.length === 0 && (
          <AttachmentItem>
            <div>{"Attachments: none found 🧐"}</div>
          </AttachmentItem>
        )}
        {!initialData && loading && (
          <AttachmentItem>
            <div>{"Attachment: "}</div>
            <div className={css(styles.spinner)}>
              <Spinner size={"small"} />
            </div>
          </AttachmentItem>
        )}
        {error && <div>{"Attachments: Failed to load 🙁"}</div>}
      </div>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    color: "#1A2579",
    backgroundColor: "#BBD2FF",
    borderRadius: 5,
    padding: 10,
    marginTop: 10
  },
  attachmentItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  attachmentIcon: {
    width: 25,
    height: 25,
    paddingRight: 5,
    objectFit: "contain"
  },
  spinner: {
    paddingLeft: 3,
    position: "relative",
    top: 3
  },
  underlined: {
    textDecoration: "underline",
    fontWeight: 800,
    cursor: "pointer"
  }
});
