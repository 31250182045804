import React, { CSSProperties, ReactNode } from "react";

type PaddingProps = {
  padding?: number;
  paddingVertical?: number;
  paddingHorizontal?: number;
  paddingTop?: number;
  paddingBottom?: number;
  paddingLeft?: number;
  paddingRight?: number;
};

type DivProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export type FlexProps = DivProps &
  PaddingProps & {
    column?: boolean;
    row?: boolean;
    center?: boolean;
    top?: boolean;
    left?: boolean;
    right?: boolean;
    bottom?: boolean;

    spaceAround?: boolean;
    spaceEvenly?: boolean;
    spaceBetween?: boolean;

    fullHeight?: boolean;
    fullWidth?: boolean;

    flex?: boolean | number;
    flexGrow?: boolean | number;
    flexShrink?: boolean | number;
  };

interface PropsStyles {
  flexDirection: Pick<CSSProperties, "flexDirection">;
  top: CSSProperties;
  bottom: CSSProperties;
  left: CSSProperties;
  right: CSSProperties;
}

interface CommonPropsStyles {
  center: CSSProperties;
  spaceAround: CSSProperties;
  spaceEvenly: CSSProperties;
  spaceBetween: CSSProperties;
  fullHeight: CSSProperties;
  fullWidth: CSSProperties;
}

const commonStyles: CommonPropsStyles = {
  center: { justifyContent: "center", alignItems: "center" },
  spaceAround: { justifyContent: "space-around" },
  spaceEvenly: { justifyContent: "space-evenly" },
  spaceBetween: { justifyContent: "space-between" },
  fullHeight: { height: "100%" },
  fullWidth: { width: "100%" }
};

const rowPropStyles: PropsStyles = {
  flexDirection: { flexDirection: "row" },
  top: { alignItems: "flex-start" },
  bottom: { alignItems: "flex-end" },
  left: { justifyContent: "flex-start" },
  right: { justifyContent: "flex-end" }
};

const columnPropStyles: PropsStyles = {
  flexDirection: { flexDirection: "column" },
  top: { justifyContent: "flex-start" },
  bottom: { justifyContent: "flex-end" },
  left: { alignItems: "flex-start" },
  right: { alignItems: "flex-end" }
};

export default function Flex({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  column,
  row,
  center,
  top,
  left,
  right,
  bottom,
  fullHeight,
  fullWidth,
  spaceBetween,
  spaceEvenly,
  spaceAround,
  flex,
  flexGrow,
  flexShrink,
  style,
  children,
  ...remainingProps
}: FlexProps) {
  const isRow = row === true;

  const propStyles = isRow ? rowPropStyles : columnPropStyles;

  const styles: CSSProperties[] = [{ display: "flex", ...propStyles.flexDirection }];

  if (center) styles.push(commonStyles.center);
  if (top) {
    styles.push(propStyles.top);
  } else if (bottom) {
    styles.push(propStyles.bottom);
  }

  if (left) {
    styles.push(propStyles.left);
  } else if (right) {
    styles.push(propStyles.right);
  }
  if (fullHeight) styles.push(commonStyles.fullHeight);
  if (fullWidth) styles.push(commonStyles.fullWidth);

  if (spaceBetween) {
    styles.push(commonStyles.spaceBetween);
  } else if (spaceEvenly) {
    styles.push(commonStyles.spaceEvenly);
  } else if (spaceAround) {
    styles.push(commonStyles.spaceAround);
  }

  if (flex === true) {
    styles.push({ flex: 1 });
  } else if (flex && typeof flex === "number") {
    styles.push({ flex });
  }
  if (flexGrow === true) {
    styles.push({ flexGrow: 1 });
  } else if (flexGrow && typeof flexGrow === "number") {
    styles.push({ flexGrow });
  }
  if (flexShrink === true) {
    styles.push({ flexShrink: 1 });
  } else if (flexShrink && typeof flexShrink === "number") {
    styles.push({ flexShrink });
  }

  return (
    <div style={{ ...styles.reduce((p, c) => ({ ...p, ...c })), ...style }} {...remainingProps}>
      {children}
    </div>
  );
}
