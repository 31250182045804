type Events = {
  loginBegin: undefined;
  loginComplete: undefined;
  logoutBegin: undefined;
  authTokenSet: undefined;
  authTokenRemoval: undefined;
};

type Event<T extends keyof Events> = {
  name: T;
  data?: Events[T];
};

type EventListener<T extends keyof Events> = {
  id: number;
  name: string;
  callback: (event: Event<T>) => void;
};

export type ListenerSubscription = {
  id: number;
  remove: () => void;
};

let i = 0;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let listeners: EventListener<any>[] = [];

export function addEventListener<T extends keyof Events>(
  name: T,
  callback: (event: Event<T>) => void
): ListenerSubscription {
  const id = i++;

  listeners.push({ id, name, callback });

  const subscription = {
    id,
    remove: () => {
      listeners = listeners.filter(listener => listener.id !== id);
    }
  };

  return subscription;
}

export function fireEvent<T extends keyof Events>(name: T, data?: Events[T]): void {
  listeners
    .filter(listener => listener.name === name)
    .forEach(listener => {
      const event: Event<T> = {
        name,
        data
      };
      listener.callback(event);
    });
}
