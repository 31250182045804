import { useQuery } from "@apollo/client";
import { CAREGIVER_GET_CARE_TEAM_MATCHES } from "../queries";
import { useGlobalContext } from "../context/GlobalComponentsContext";
import { useEffect, useMemo } from "react";

export default function useCareTeamMemberMatches(userIds: number[]) {
  const { showError } = useGlobalContext();

  const { data, loading, error } = useQuery(CAREGIVER_GET_CARE_TEAM_MATCHES, {
    fetchPolicy: "cache-and-network",
    variables: { userIds: userIds },
    skip: !userIds.length
  });

  useEffect(() => {
    if (error) {
      reportError(error);
      showError();
    }
  }, [error, showError]);

  const careTeamMembers = useMemo(() => data?.careTeamMemberMatches?.map(m => m.careTeamMember) || [], [data]);
  const familyMembers = useMemo(() => data?.familyMembers || [], [data]);

  return { data, loading, error, careTeamMembers, familyMembers };
}
