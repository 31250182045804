import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import { useGlobalContext } from "../context/GlobalComponentsContext";
import { GET_USER } from "../queries";

export default function useQueryUser(options) {
  const { data, loading, error } = useQuery(GET_USER, { fetchPolicy: "no-cache", ...options });
  const { showError } = useGlobalContext();

  useEffect(() => {
    if (error) {
      showError();
    }
  }, [error, showError]);

  const user = data?.me;

  return { user, loading };
}
