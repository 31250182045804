import React, { useEffect } from "react";
import { XIcon, ChevronLeftIcon } from "@shopify/polaris-icons";
import { Icon } from "@shopify/polaris";
import { TouchableOpacity } from "../components/react-native";
import { StyleSheet, css } from "aphrodite";

export default function Drawer({ onBack, onExit, title, children, headerBorder = true, scrollable = true }) {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div>
      <div className={css([styles.headerRow, headerBorder ? styles.headerRowBorder : undefined])}>
        {onBack && (
          <TouchableOpacity style={{ position: "absolute", left: 10, width: 40, height: 40 }} onPress={onBack}>
            <Icon source={ChevronLeftIcon} />
          </TouchableOpacity>
        )}
        <div className={css(onBack ? styles.header : styles.headerWithoutBack)}>{title}</div>
        {onExit && (
          <TouchableOpacity style={{ position: "absolute", right: 10, width: 40, height: 40 }} onPress={onExit}>
            <Icon source={XIcon} />
          </TouchableOpacity>
        )}
      </div>
      <div className={css([styles.content, scrollable && styles.scrollable])}>{children}</div>
    </div>
  );
}

const styles = StyleSheet.create({
  headerRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: 25,
    paddingLeft: 20,
    paddingRight: 20
  },
  headerRowBorder: {
    borderBottom: "1px solid #D8D8D8"
  },
  header: {
    fontSize: 25,
    marginLeft: 35,
    marginRight: 15
  },
  headerWithoutBack: {
    fontSize: 25,
    marginLeft: 0,
    marginRight: 15
  },
  content: {
    height: "calc(100vh - 75px)"
  },
  scrollable: {
    overflowY: "scroll",
    overflowStyle: "scrollbar"
  }
});
