import React, { useEffect, useMemo } from "react";
import { ChoiceList } from "@shopify/polaris";

export default function useAssessmentFilter({ assessments, selectable, selected, setSelected }) {
  const choices = useMemo(
    () =>
      assessments.map(a => ({
        label: a.name,
        value: a,
        key: a.identifier,
        disabled: !selectable?.some(s => s.identifier === a.identifier)
      })),
    [assessments, selectable]
  );

  useEffect(() => {
    if (selected.some(s => !selectable.some(a => a.identifier === s.identifier))) {
      setSelected(selected => selected.filter(s => selectable.some(a => a.identifier === s.identifier)));
    }
  }, [selectable, selected, setSelected]);

  const filter = {
    key: "assessment",
    label: "Assessments",
    filter: (
      <ChoiceList
        title="Assessments"
        key="assessment"
        choices={choices}
        selected={selected || []}
        onChange={values => {
          setSelected(values);
        }}
        allowMultiple
      />
    ),
    hideClearButton: false,
    shortcut: true,
    onRemove: () => {
      setSelected([]);
    }
  };

  return filter;
}
