import React from "react";
import { useAtom } from "tiny-atom/react/hooks";
import moment from "moment";
import confetti from "../../assets/confetti.png";
import trophy from "../../assets/trophy.png";
import calendarIcon from "../../assets/calendar-icon.png";
import "./CompletedGoals.css";
import { zoomIn } from "react-animations";
import { StyleSheet, css } from "aphrodite";

const styles = StyleSheet.create({
  bounce: {
    animationName: zoomIn,
    animationDuration: "1s"
  }
});

export default function CompletedGoals({ visible }) {
  const extractCompletedGoals = goals =>
    goals.filter(g => g.finalizedAt).sort((a, b) => new Date(b.finalizedAt) - new Date(a.finalizedAt));

  const patientData = useAtom(state => state.patientData);
  const goals = extractCompletedGoals(patientData.childGoals);

  const items = !goals
    ? []
    : goals.map(goal => {
        return (
          <div className={css(styles.bounce)} key={goal.id}>
            <div hidden={!visible}>
              <div className="Goal-header">
                <img className="Goal-header-image" src={confetti} alt="confetti" />
              </div>
              <div className="Goal-container">
                <div className="Goal-content-container">
                  <img className="Goal-image" src={trophy} alt="trophy" />
                  <div className="Goal-content">
                    <div className="Goal-title">Completed!</div>
                    <div className="Goal-date-container">
                      <img className="Goal-calendar-image" src={calendarIcon} alt="calendar" />
                      <div className="Goal-date">{moment(goal.finalizedAt).format("MM/DD/YY")}</div>
                    </div>
                    <div className="Goal-name">{goal.name}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });

  return (
    <div className="CompletedGoals">
      {items}
      {visible && items.length === 0 && <div className="noGoals">NO COMPLETED GOALS.</div>}
    </div>
  );
}
