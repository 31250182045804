import React from "react";
import { Box, Card, Scrollable } from "@shopify/polaris";
import GoalReflectionCard from "./GoalReflectionCard";
import GoalCalendarCard from "./GoalCalendarCard";
import { useAtom } from "tiny-atom/react/hooks";
import "./GoalCard.css";

export default function ParentingGoalCard() {
  const data = useAtom(state => state.patientData);

  return (
    <>
      {data.childParents.map((parent, i) => {
        return (
          <Box key={i}>
            <div className="GoalCard-parentName">{parent.firstName}</div>
            <div className="GoalCard-content">
              <div className="GoalCard-goalsCard">
                <GoalCalendarCard familyUser={parent} />
              </div>
              <div className="GoalCard-goalReflections">
                <Card>
                  <p className="GoalCard-goalReflections-title">Goal reflections</p>
                  <Scrollable shadow style={{ maxHeight: "800px", minHeight: "120px" }}>
                    <GoalReflectionCard achievements={parent.achievements} firstName={parent.firstName} isParent />
                  </Scrollable>
                </Card>
              </div>
            </div>
          </Box>
        );
      })}
    </>
  );
}
