import React, { useCallback, useContext, useState } from "react";
import ManateeModal from "../components/Modal";

export const GlobalComponentContext = React.createContext();

export function GlobalComponentContextProvider({ children }) {
  const [errorMessage, setErrorMessage] = useState();

  const showError = useCallback((message = "Something went wrong - please try again :(") => {
    setErrorMessage(message);
  }, []);

  return (
    <GlobalComponentContext.Provider value={{ showError }}>
      {!!errorMessage && (
        <ManateeModal
          title={errorMessage}
          onButton1Press={() => {
            setErrorMessage(undefined);
          }}
        />
      )}
      {children}
    </GlobalComponentContext.Provider>
  );
}

export function useGlobalContext() {
  return useContext(GlobalComponentContext);
}
