import React from "react";
import { Text, View, TouchableOpacity } from "./react-native";
import { Image, Check, Plus, Edit2 } from "react-feather";
import Button from "./Button";
import Modal from "../components/Modal";
import GoalItemSeparator from "./GoalItemSeparator";
import GoalIcon from "./goal-card-views/GoalIcon";

let modalDisplayed = false;

export function GoalItemIcon({ item, theme }) {
  return (
    <View style={styles.icon}>
      <View style={{ flex: 1 }}>
        <GoalIcon imageId={item.imageId} small theme={theme} />
      </View>
    </View>
  );
}

export function GoalItemText({ item, expired = false }) {
  return (
    <View style={styles.body}>
      <Text style={styles.title}>{item.name}</Text>
      {!item.type && item.assignedTo && expired && <Text>{"Expired!"}</Text>}
      {!item.type && (
        <Text style={styles.points}>
          {item.value} point{item.value === 1 ? "" : "s"} worth
        </Text>
      )}
    </View>
  );
}

export function GoalItemPlusButton({ disabled, onPress }) {
  return (
    <Button
      disabled={disabled}
      theme="gray"
      icon={Plus}
      small
      feather
      onPress={() => {
        if (!disabled) onPress();
      }}
    />
  );
}
export function GoalItemCheckButton({ disabled, onPress }) {
  return (
    <Button
      theme="green"
      disabled={disabled}
      icon={Check}
      small
      feather
      onPress={() => {
        if (!disabled) onPress();
      }}
    />
  );
}

export function GoalItemEditButton({ disabled, onPress }) {
  return (
    <Button
      theme="gray"
      disabled={disabled}
      icon={Edit2}
      small
      onPress={() => {
        if (!disabled) onPress();
      }}
    />
  );
}

class GoalItem extends React.Component {
  constructor(props) {
    super();
    this.state = {
      goal: props.item,
      isUnassignModalVisible: false
    };
  }

  render() {
    modalDisplayed = this.state.isUnassignModalVisible;
    const { item, theme } = this.props;
    const expired = new Date(item.expiresAt) < new Date();
    if (item.type === "separator") {
      return <GoalItemSeparator label={item.name} />;
    }

    const unassignWarning =
      item.matchesCustomGoal || item.matchesDefaultGoal
        ? "Are you sure you want to unassign this goal?"
        : "Are you sure you want to unassign & delete this goal?";

    return (
      <View style={styles.container}>
        <TouchableOpacity
          disabled={item.matchesCustomGoal}
          activeOpacity={0.4}
          style={styles.clickable}
          onPress={() => {
            if (item.id === "new") {
              this.props.goalSelected(item);
              this.props.openCreateGoalScreen();
            } else if (item.assignedTo) {
              this.props.openAssignedGoalScreen(this.goalScreenObject());
            } else if (item.isStandard) {
              this.props.openStandardGoalScreen(this.goalScreenObject());
            } else if (!item.isStandard) {
              this.props.openCustomGoalScreen(this.goalScreenObject(), false);
            }
          }}
        >
          {!item.type && <GoalItemIcon item={item} theme={theme} />}
          {item.type === "new" && (
            <View style={styles.createIcon}>
              <Image size={32} color="rgb(0, 122, 206)" />
            </View>
          )}
          {<GoalItemText item={item} expired={expired} />}
        </TouchableOpacity>
        <View style={modalDisplayed ? styles.inactive : undefined}>
          {item.assignedTo && (
            <GoalItemCheckButton
              disabled={item.readOnly}
              onPress={() => {
                this.setState({ isUnassignModalVisible: true });
              }}
            />
          )}
          {item.type === "new" && (
            <GoalItemEditButton
              onPress={() => {
                this.props.openCreateGoalScreen();
              }}
            />
          )}
          {!item.type && !item.assignedTo && (
            <GoalItemPlusButton
              disabled={item.matchesCustomGoal}
              onPress={() => {
                if (item.isStandard) {
                  this.props.openStandardGoalScreen(this.goalScreenObject());
                } else {
                  this.props.openCustomGoalScreen(this.goalScreenObject(), true);
                }
              }}
            />
          )}
        </View>
        <View>
          <Modal
            title={unassignWarning}
            hidden={!this.state.isUnassignModalVisible}
            button1Label="Yes"
            onButton1Press={() => {
              this.setState({
                isUnassignModalVisible: false
              });
              this.props.deleteGoal(item);
            }}
            button2Label="No"
            onButton2Press={() => {
              this.setState({
                isUnassignModalVisible: false
              });
            }}
          />
        </View>
      </View>
    );
  }

  goalSetup() {
    const goal = this.props.item;
    if (goal.type === "separator") return;
    this.props.goalSelected(goal);
    return goal;
  }

  goalScreenObject() {
    return {
      goal: this.goalSetup(),
      refetch: this.props.refetch
    };
  }
}

const styles = {
  container: {
    marginTop: 10,
    marginBottom: 10,
    marginRight: 30,
    padding: 0,
    flexDirection: "row"
  },
  clickable: {
    flexDirection: "row",
    flex: 1
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  body: {
    flex: 1,
    paddingRight: 20
  },
  title: {
    marginBottom: 4,
    fontSize: "15px"
  },
  points: {
    fontSize: 13,
    color: "rgb(145, 158, 171)"
  },
  separator: {
    alignItems: "center",
    marginTop: 20,
    marginBottom: 20
  },
  separatorTitle: {
    fontSize: 16,
    fontWeight: "600",
    color: "rgb(0, 0, 0)"
  },
  separatorLine: {
    flex: 1,
    borderBottom: "1px solid rgba(145, 158, 171, 0.25)",
    marginLeft: 32
  },
  icon: {
    borderRadius: 12,
    width: 64,
    height: 64,
    overflow: "hidden",
    marginRight: 10,
    backgroundColor: "white",
    boxShadow: "0 2px 10px 4px rgba(0, 0, 0, 0.05)"
  },
  createIcon: {
    borderRadius: 12,
    width: 64,
    height: 64,
    marginRight: 10,
    paddingTop: 4,
    backgroundColor: "rgba(0, 122, 206, 0.25)",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0 2px 8px 4px rgba(0, 0, 0, 0.05)"
  }
};

export default GoalItem;
