import React, { useEffect, useRef } from "react";
import emoji from "emoji-dictionary";
import cx from "classnames";
import "./Chat.css";

export default function Chat({ patient, summary }) {
  const firstName = patient.firstName;

  const chatEl = useRef(null);
  useEffect(() => {
    chatEl.current.scrollTop = chatEl.current.scrollHeight;
  }, []);

  return (
    <div className={cx("Chat", !summary && "Chat--large")} ref={chatEl}>
      {!summary && (
        <div>
          <div className="Chat-day">MONDAY</div>

          <div className="Chat-bubble">
            <div className="Chat-avatar Chat-bot" />
            <div className="Chat-timestamp">Monday at 15:37pm</div>
            <div className="Chat-user">ML powered therapist</div>
            <div className="Chat-message">Hi {firstName}, how are you feeling today?</div>
          </div>

          <div className="Chat-bubble">
            <div
              className="Chat-avatar Chat-user"
              style={{
                backgroundImage: `url(/assets/${patient.avatar}.jpg)`
              }}
            />
            <div className="Chat-timestamp">Monday at 15:45pm</div>
            <div className="Chat-user">{firstName}</div>
            <div className="Chat-message">I&apos;m fine.</div>
          </div>

          <div className="Chat-bubble">
            <div className="Chat-avatar Chat-bot" />
            <div className="Chat-timestamp">Monday at 15:45pm</div>
            <div className="Chat-user">ML powered therapist</div>
            <div className="Chat-message">
              That&apos;s great. I have a new video for you: https://www.youtube.com/watch?v=A2RlHM8xfmM.
            </div>
          </div>

          <div className="Chat-bubble">
            <div
              className="Chat-avatar Chat-user"
              style={{
                backgroundImage: `url(/assets/${patient.avatar}.jpg)`
              }}
            />
            <div className="Chat-timestamp">Monday at 15:45pm</div>
            <div className="Chat-user">{firstName}</div>
            <div className="Chat-message">Thanks..</div>
          </div>

          <div className="Chat-day">WEDNESDAY</div>

          <div className="Chat-bubble">
            <div className="Chat-avatar Chat-bot" />
            <div className="Chat-timestamp">Wednesday at 12:37pm</div>
            <div className="Chat-user">ML powered therapist</div>
            <div className="Chat-message">Hi {firstName}, how are you feeling today?</div>
          </div>

          <div className="Chat-bubble">
            <div
              className="Chat-avatar Chat-user"
              style={{
                backgroundImage: `url(/assets/${patient.avatar}.jpg)`
              }}
            />
            <div className="Chat-timestamp">Wednesday at 12:45pm</div>
            <div className="Chat-user">{firstName}</div>
            <div className="Chat-message">Today I am angry! Other kids are saying mean things.</div>
          </div>

          <div className="Chat-bubble">
            <div className="Chat-avatar Chat-bot" />
            <div className="Chat-timestamp">Wednesday at 12:51pm</div>
            <div className="Chat-user">ML powered therapist</div>
            <div className="Chat-message">
              That&apos;s terrible. Check out this video, maybe it will help you relax:
              https://www.youtube.com/watch?v=bRkILioT_NA.
            </div>
          </div>
        </div>
      )}

      <div className="Chat-day">YESTERDAY</div>

      <div className="Chat-bubble">
        <div className="Chat-avatar Chat-bot" />
        <div className="Chat-timestamp">Yesterday at 12:37pm</div>
        <div className="Chat-user">ML powered therapist</div>
        <div className="Chat-message">Hi {firstName}, how are you feeling today?</div>
      </div>

      <div className="Chat-bubble">
        <div
          className="Chat-avatar Chat-user"
          style={{
            backgroundImage: `url(/assets/${patient.avatar}.jpg)`
          }}
        />
        <div className="Chat-timestamp">Yesterday at 12:45pm</div>
        <div className="Chat-user">{firstName}</div>
        <div className="Chat-message">
          Today I am feeling really great! I went to the Zoo with grandma in the afternoon and loved it!
        </div>
      </div>

      <div className="Chat-bubble Chat--compact">
        <div className="Chat-message">But now we’re back and I have to prepare for school tomorrow.</div>
      </div>

      <div className="Chat-bubble">
        <div className="Chat-avatar Chat-bot" />
        <div className="Chat-timestamp">Yesterday at 12:37pm</div>
        <div className="Chat-user">ML powered therapist</div>
        <div className="Chat-message">Amazing! Keep up the good work {emoji.getUnicode("smile")}️</div>
      </div>

      <div className="Chat-day">TODAY</div>

      <div className="Chat-bubble">
        <div className="Chat-avatar Chat-bot" />
        <div className="Chat-timestamp">Yesterday at 12:37pm</div>
        <div className="Chat-user">ML powered therapist</div>
        <div className="Chat-message">Hi {firstName}, how’re you getting on with brushing teeth?</div>
      </div>
    </div>
  );
}
