import { ReactNode, useContext } from "react";
import { AccountType } from "../model/userModels";
import { UserContext } from "../context/UserContext";
import useAuthRequiredRouting from "./hooks/useAuthRequiredRouting";
import WhenRouteMatched from "./WhenRouteMatched";
import { Routes } from "react-router-dom";
import React from "react";

type Props = {
  routeElements: ReactNode;
  supportedAccountTypes: AccountType[];
  children?: ReactNode;
};

export default function AccountTypeRoutes({ routeElements, supportedAccountTypes, children }: Props) {
  const { authenticated, user } = useContext(UserContext) || {};

  useAuthRequiredRouting(routeElements, supportedAccountTypes);

  return (
    <>
      {!!authenticated && !!user?.accountType && supportedAccountTypes.includes(user.accountType) && (
        <WhenRouteMatched routeElements={routeElements}>
          {children || <Routes>{routeElements}</Routes>}
        </WhenRouteMatched>
      )}
    </>
  );
}
