import React from "react";
import "./SwitchToggle.css";

export default function SwitchToggle({ options, selection, onChange, className }) {
  const children = options.map((option, index) => {
    const className = selection === option ? "ToggleActive" : "ToggleInactive";
    return (
      <input key={index} className={className} type="button" onClick={() => onChange(option, index)} value={option} />
    );
  });
  return <div className={`ToggleContainer ${className}`}>{children}</div>;
}
