import { useActions } from "tiny-atom/react/hooks";
import React, { useState, useEffect } from "react";
import Toast from "./Toast";
import { StyleSheet, css } from "aphrodite";

export default function ToastList() {
  const { removeToast, setToastRender } = useActions();
  const [toasts, setToasts] = useState([]);

  useEffect(() => {
    setToastRender(activeToasts => setToasts(activeToasts));
    return () => {
      setToastRender(null);
    };
  });

  return (
    <div className={css(styles.position)}>
      {toasts &&
        toasts.map(toast => {
          return (
            <Toast
              key={toast.id}
              customPosition
              text={toast.text}
              size={toast.size}
              after={() => {
                removeToast(toast);
                if (toast.onComplete) {
                  toast.onComplete();
                }
              }}
            />
          );
        })}
    </div>
  );
}
const styles = StyleSheet.create({
  position: {
    zIndex: 100,
    pointerEvents: "none",
    margin: "auto",
    position: "sticky",
    left: 0,
    bottom: 20,
    right: 0,
    display: "flex",
    flexDirection: "column-reverse",
    alignItems: "center"
  }
});
