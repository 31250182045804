import React, { useEffect } from "react";
import GoalItem from "./GoalItem";
import { graphQLError } from "../utils/errorUtils";
import defaultGoalGroups from "../data/defaultGoals-en";
import {
  GOAL_TYPES,
  groupAndSortGoalsByObjective,
  sortGoalsByName,
  setGoalManagementScrollHeight
} from "../utils/goalUtils";
import Spinner from "./Spinner";
import "./GoalManagement.css";
import { ADULT_GET_GOALS, DELETE_GOAL } from "../queries";
import { useQuery, useMutation } from "@apollo/client";
import { useActions } from "tiny-atom/react/hooks";
import { logEvent } from "../actions";
import { useAtom } from "tiny-atom/react/hooks";

const createSidebarGoalList = (assigned, parentAssigned, manateeAssigned, available, accountType) => {
  // default goals are not guaranteed to have different ids than custom goals
  [...assigned, ...parentAssigned, ...manateeAssigned, ...available].forEach(
    goal => (goal.key = `${goal.id}.${goal.isStandard ? "STANDARD" : "CUSTOM"}`)
  );

  let list = []
    .concat({ id: "assigned", type: "separator", name: "Therapist/Coach Assigned" })
    .concat(sortGoalsByName(assigned));

  if (accountType === "CHILD") {
    list.push({ id: "parentAssigned", type: "separator", name: "Caregiver Assigned" });
    list = list.concat(sortGoalsByName(parentAssigned));

    list.push({ id: "manateeAssigned", type: "separator", name: "Manatee Assigned" });
    list = list.concat(sortGoalsByName(manateeAssigned));
  }
  list.push({ id: "createNew", type: "separator", name: "Create new" });
  list = list.concat({ id: "new", type: "new", name: "Create a custom goal and set points rewarded" });

  const custom = available.filter(g => g.custom);
  if (custom.length) {
    list.push({ id: "custom", type: "separator", name: "Custom" });
    list = list.concat(sortGoalsByName(custom));
  }

  const defaultGoals = available.filter(g => !g.custom);
  const defaultGoalGroups = groupAndSortGoalsByObjective(defaultGoals, GOAL_TYPES.default);

  defaultGoalGroups.forEach(({ objective, goals }) => {
    list.push({ id: objective, type: "separator", name: objective });
    goals.forEach(goal => {
      list.push(goal);
    });
  });

  return list;
};

export default function GoalManagement() {
  useEffect(() => {
    let goalManagement = document.getElementsByClassName("GoalManagement")[0];
    if (goalManagement) {
      let savedHeight = localStorage.getItem("scrollTop");
      goalManagement.scrollTop = savedHeight;
    }
  });

  const { setCurrentScreen, setGoal } = useActions();
  const openCreateGoalScreen = useAtom(state => state.openCreateGoalScreen);
  const openAssignedGoalScreen = useAtom(state => state.openAssignedGoalScreen);
  const openCustomGoalScreen = useAtom(state => state.openCustomGoalScreen);
  const openStandardGoalScreen = useAtom(state => state.openStandardGoalScreen);
  const patientRefetch = useAtom(state => state.patientRefetch);
  const patient = useAtom(state => state.patient);
  const [deleteGoalMutation] = useMutation(DELETE_GOAL);
  const { loading, error, data, refetch } = useQuery(ADULT_GET_GOALS, {
    variables: {
      childId: patient.id
    }
  });
  if (error) {
    graphQLError();
  }
  if (loading) {
    return <Spinner></Spinner>;
  }
  const { childDetails } = data;
  // values in data have .preventExtensions() set on them. Not a best practice, but our management of goals is much simpler if we can safely extend (add fields) to these objects.
  // so we make a deep copy of data.adultGoals to avoid the .preventExtensions() and be able to extend the object
  const adultGoalsNoExtensionsAllowed = data.adultGoals;
  const adultGoals = [];
  // Deep copy each item in GQL response arry to new array so that we can extend objects with our own fields
  adultGoalsNoExtensionsAllowed.forEach(goal => {
    adultGoals.push(JSON.parse(JSON.stringify(goal)));
  });

  setCurrentScreen("TherapistWebGoalManagementScreen");
  if (refetch) {
    // TODO this is a hack to reload data after create/edit; Find a better way to do this
    refetch();
  }
  let available = defaultGoalGroups
    .map(group => {
      group.goals = group.goals.map(goal => {
        return {
          ...goal,
          objective: group.title,
          matchesCustomGoal: false,
          isStandard: true
        };
      });
      return group.goals;
    })
    .flat();
  let assigned = [];
  let parentAssigned = [];
  let manateeAssigned = [];
  if (adultGoals && adultGoals.length > 0) {
    adultGoals.forEach(goal => {
      goal.persisted = true;
      goal.matchesDefaultGoal = false;
      goal.matchesCustomGoal = false;
      if (goal.readOnly) {
        if (goal.assignerType === "SERVICE") {
          manateeAssigned.push(goal);
        } else if (goal.assignerType === "PARENT") {
          parentAssigned.push(goal);
        }
      } else if (!goal.assignedTo) {
        goal.custom = true;
        available.push(goal);
      } else if (goal.assignedTo.id === childDetails.id) {
        assigned.push(goal);
      }
    });
    available.forEach(availableGoal =>
      assigned.forEach(assignedGoal => {
        if (availableGoal.name === assignedGoal.name) {
          availableGoal.matchesCustomGoal = true;
          availableGoal.matchedCustomGoal = assignedGoal.id;
        }
      })
    );
    available.forEach(availableGoal =>
      parentAssigned.forEach(parentAssignedGoal => {
        if (availableGoal.name === parentAssignedGoal.name) {
          availableGoal.matchesCustomGoal = true;
          availableGoal.isParentAssigned = true;
        }
      })
    );
    assigned.forEach(assignedGoal =>
      available.forEach(availableGoal => {
        if (assignedGoal.name === availableGoal.name) {
          assignedGoal.matchesDefaultGoal = true;
        }
      })
    );
  }

  async function deleteGoal(goal) {
    await deleteGoalMutation({
      variables: {
        childId: childDetails.id,
        goalId: goal.id
      }
    });
    logEvent("therapistWebDeleteGoal");
    refetch();
    patientRefetch();
  }

  function goalSelected(goal) {
    setGoal({ params: { goal } });
  }

  const goToCreateGoalScreen = () => {
    setGoalManagementScrollHeight();
    openCreateGoalScreen();
  };

  const goToStandardGoalScreen = goal => {
    setGoalManagementScrollHeight();
    openStandardGoalScreen(goal);
  };

  const goToAssignedGoalScreen = goal => {
    setGoalManagementScrollHeight();
    openAssignedGoalScreen(goal);
  };

  const goToCustomGoalScreen = (goal, isClickedFromPlus) => {
    setGoalManagementScrollHeight();
    openCustomGoalScreen(goal, isClickedFromPlus);
  };

  return (
    <div className="GoalManagement">
      <div className="GoalManagement-title">
        Add the goals that {childDetails.firstName} will have to complete to unlock rewards
      </div>
      <div>
        {createSidebarGoalList(assigned, parentAssigned, manateeAssigned, available, childDetails.accountType).map(
          goal => (
            <GoalItem
              key={goal.key ? goal.key : goal.id}
              item={goal}
              deleteGoal={deleteGoal}
              openCreateGoalScreen={() => goToCreateGoalScreen()}
              openAssignedGoalScreen={goal => goToAssignedGoalScreen(goal)}
              openCustomGoalScreen={(goal, isClickedFromPlus) => goToCustomGoalScreen(goal, isClickedFromPlus)}
              openStandardGoalScreen={goal => goToStandardGoalScreen(goal)}
              goalSelected={goal => goalSelected(goal)}
              theme={childDetails.theme}
            />
          )
        )}
      </div>
    </div>
  );
}
