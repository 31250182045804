import React from "react";
import { useState } from "react";
import GoalAchievedDateModal from "./GoalAchievedDateModal";
import GoalAchievedAddNoteModal from "./GoalAchievedAddNoteModal";

const modalIds = { selectDate: "selectDate", addNote: "addNote" };

export default function GoalMarkAchievedView({ goal, onMarkAchieved, onCancel, setAchievementDate }) {
  const [activeModal, setActiveModal] = useState(modalIds.selectDate);

  const reset = () => {
    setActiveModal(modalIds.selectDate);
  };

  const showAddNote = () => {
    setActiveModal(modalIds.addNote);
  };

  return (
    <React.Fragment>
      <GoalAchievedDateModal
        isVisible={() => activeModal === modalIds.selectDate}
        showAddNote={showAddNote}
        goal={goal}
        onCancel={onCancel}
        setAchievementDate={setAchievementDate}
      />
      <GoalAchievedAddNoteModal
        isVisible={() => activeModal === modalIds.addNote}
        onCancel={() => {
          onCancel();
          reset();
        }}
        onMarkAchieved={onMarkAchieved}
      />
    </React.Fragment>
  );
}
