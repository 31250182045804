import React, { useEffect } from "react";
import Spinner from "./Spinner";
import useImageLoader from "../hooks/useImageLoader";

export function wrapAsImageLoader({ query, variables, dataImageExtractor, imageDate, metadata }) {
  const componetDefn = function ImageLoader({ load, isVisible, style, onRenderImage }) {
    if (!isVisible && !load) {
      return <></>;
    }
    return (
      <ImageGqlQueryLoader
        isVisible={isVisible}
        query={query}
        variables={variables}
        dataImageExtractor={dataImageExtractor}
        style={style}
        onRenderImage={onRenderImage}
      />
    );
  };

  return { component: componetDefn, metadata: { imageDate, ...metadata } };
}

const ImageGqlQueryLoader = ({ isVisible, query, variables, dataImageExtractor, style, onRenderImage }) => {
  const { loading, error, image } = useImageLoader(query, variables, dataImageExtractor);

  if (!isVisible) {
    return <></>;
  }

  if (loading) {
    return (
      <div style={style}>
        <Spinner />
      </div>
    );
  }
  if (error) {
    return (
      <div style={{ ...style, ...styles.errorContainer }}>
        <div style={styles.error}>
          <span>{"Unable to load image at this time."}</span>
          <br />
          <br />
          <span>{"Please try again later."}</span>
        </div>
      </div>
    );
  }
  if (!image) {
    return <></>;
  }

  const Image = ({ image }) => {
    const src = `data:image/jpg;base64, ${image}`;
    useEffect(() => {
      onRenderImage(src);
    }, [image, src]);
    return <img style={{ ...styles.img, ...style }} src={src} alt="achievement" />;
  };

  return <Image image={image} />;
};

ImageGqlQueryLoader.displayName = "ImageGqlQueryLoader";

export default ImageGqlQueryLoader;

const styles = {
  error: {
    width: 400,
    backgroundColor: "#000000",
    color: "#FFFFFF",
    fontSize: 24,
    padding: 20,
    justifySelf: "center",
    alignSelf: "center",
    textAlign: "center"
  },
  errorContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  img: {
    objectFit: "contain"
  }
};
