import React from "react";
import { Text } from "./react-native";

const Label = ({ first, children, marginTop = 20 }) => (
  <Text
    style={{
      fontSize: 16,
      fontWeight: "600",
      marginBottom: 12,
      marginTop: first ? 0 : marginTop
    }}
  >
    {children}
  </Text>
);

export default Label;
