import React, { useState } from "react";
import { TouchableOpacity } from "../../react-native";
import { ChevronDown, ChevronUp } from "react-feather";
import { Collapsible } from "@shopify/polaris";

export default function AutomatedResponse() {
  const [collapseAutomatedMessage, setCollapseAutomatedMessage] = useState(
    localStorage.getItem("collapseAutomatedMessage") || false
  );
  return (
    <TouchableOpacity
      onPress={() => {
        setCollapseAutomatedMessage(!collapseAutomatedMessage);
        localStorage.setItem("collapseAutomatedMessage", !collapseAutomatedMessage);
      }}
    >
      <div className="message-note-container">
        <div className="message-note-header-row">
          <p className="message-note-header">Automated Response</p>
          {collapseAutomatedMessage ? <ChevronUp size={17} /> : <ChevronDown size={17} />}
        </div>
        <Collapsible open={!collapseAutomatedMessage} transition={{ duration: "500ms", timingFunction: "ease-in-out" }}>
          <p className="message-note">
            Thank you for the message! I will get back to you as soon as I can, no later than 2-3 business days. Have a
            great day!
          </p>
        </Collapsible>
      </div>
    </TouchableOpacity>
  );
}
