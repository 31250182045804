import config from "../config/env";
import StackdriverErrorReporter from "stackdriver-errors-js";
const { version } = require("../../package.json");

const ERRORS_NOT_TO_BE_REPORTED_TO_GCP = [
  "Password is too common",
  "Failed to fetch",
  "Network error",
  "Unauthenticated",
  "Incorrect login or password"
];

export function graphQLError() {
  alert(
    "An error has occurred.",
    undefined,
    [
      {
        text: "Logout",
        onPress: async () => {}
      }
    ],
    {
      cancelable: false
    }
  );
}

export async function reportError(error, stack) {
  if (!stack) {
    stack = new Error().stack;
  }

  const ignoreReporting = ERRORS_NOT_TO_BE_REPORTED_TO_GCP.some(errorToIgnore => {
    if (typeof error === "string" || error instanceof String) {
      return error.includes(errorToIgnore);
    } else {
      return error.message?.includes(errorToIgnore);
    }
  });

  if (!ignoreReporting) {
    if (process.env.NODE_ENV === "production") {
      const errorHandler = new StackdriverErrorReporter();
      errorHandler.start({
        key: config.env.REACT_APP_GCP_ERROR_REPORTING_API_KEY,
        projectId: config.env.REACT_APP_GCP_PROJECT_ID,
        service: "web"
      });
      const userId = parseInt(sessionStorage.getItem("userId")) || "unknown";
      errorHandler.report(`userId: ${userId} | version: ${version} | error: ${error} | stack: \n${stack}`);
    } else {
      console.log(`ERROR REPORTED: ${error} | stack: \n${stack}`);
    }
  }
}
