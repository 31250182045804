import React, { useContext } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { parse } from "date-fns";

import { useQuery } from "@apollo/client";
import { GET_AVAILABLE_DAYS } from "../../queries";
import { AppointmentSchedulerContext } from "./AppointmentScheduler";
import moment from "moment";

function AppointmentDayPicker({
  selectedDay,
  setSelectedDay,
  availabilityRecurringInterval,
  availabilityRecurringCount
}) {
  const { appointmentType, careTeamMemberIds, appointmentContactMethod } = useContext(AppointmentSchedulerContext);

  const { data } = useQuery(GET_AVAILABLE_DAYS, {
    variables: {
      dateInMonth: moment(selectedDay).startOf("month").toISOString(),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone || "America/New_York",
      appointmentTypeId: appointmentType.id,
      contactMethod: appointmentContactMethod,
      userIds: careTeamMemberIds?.length > 0 ? careTeamMemberIds : appointmentType.applicableCareTeamMemberIds,
      recurringInterval: availabilityRecurringInterval,
      recurringCount: availabilityRecurringCount
    },
    fetchPolicy: "cache-and-network",
    skip: !selectedDay
  });

  const highlightDates = data?.availableDays?.availableDates
    ? data.availableDays.availableDates.map(day => parse(day, "yyyy-MM-dd", new Date()))
    : [];

  return (
    <div className="appointment-date-picker-container">
      <DatePicker
        inline
        useWeekdaysShort={true}
        onMonthChange={e => setSelectedDay(e)}
        onChange={e => setSelectedDay(e)}
        selected={selectedDay}
        highlightDates={highlightDates}
      />
    </div>
  );
}

export default AppointmentDayPicker;
