import { StyleSheet, css } from "aphrodite";
import moment from "moment";
import React from "react";
import config from "../config/env";

const HEALTHIE_URL = config.env.REACT_APP_EHR_UI_URL;

type User = {
  id: number;
  accountType: string;
  firstName: string;
  lastName: string;
  createdAt: string;
  lastActiveAt: string;
  ehrUserId: string | undefined;
  username?: string;
  email?: string;
  dateOfBirth?: string;
};

type Field = {
  key: string;
  getValue: (user: User) => string | number | undefined;
  getLink?: (user: User) => string | undefined;
};

export default function ClientProfile({ user }: { user: User }) {
  const fields: Field[] = [
    { key: "Email", getValue: user => user.email },
    { key: "Username", getValue: user => user.username },
    {
      key: "Last active",
      getValue: user => user.lastActiveAt && moment(user.lastActiveAt).format("LL")
    },
    { key: "Registered", getValue: user => moment(user.createdAt).format("LL") },
    { key: "Date of Birth", getValue: user => user.dateOfBirth && moment(user.dateOfBirth).format("LL") },
    {
      key: "Manatee ID",
      getValue: user => user.id
    },
    {
      key: "Healthie ID",
      getValue: user => user.ehrUserId || "Client is not linked in Healthie",
      getLink: user => (user.ehrUserId ? `${HEALTHIE_URL}/users/${user.ehrUserId}` : undefined)
    }
  ];

  return (
    <div key={user.id} className={css(styles.container)}>
      <div className={css(styles.name)}>{`${user.firstName} ${user.lastName}`}</div>
      <div className={css(styles.infoContainer)}>
        {fields.map(field => {
          const key = field.key;
          const value = field.getValue(user);
          const link = field.getLink ? field.getLink(user) : undefined;

          if (link) {
            return (
              <div className={css(styles.field)} key={key} style={{ display: "flex", flexDirection: "row" }}>
                <p className={css(styles.fieldName)}>{`${key}: `}</p>
                <a href={link} target="_blank" className={css(styles.fieldValue)}>
                  {value || link}
                </a>
              </div>
            );
          }
          if (value) {
            return (
              <div className={css(styles.field)} key={key} style={{ display: "flex", flexDirection: "row" }}>
                <p className={css(styles.fieldName)}>{`${key}: `}</p>
                <p className={css(styles.fieldValue)}>{value}</p>
              </div>
            );
          }
          return <div key={key}></div>;
        })}
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 10,
    paddingLeft: 30,
    paddingRight: 30
  },
  infoContainer: {
    backgroundColor: "#E5EEFF",
    borderRadius: 8,
    padding: 10
  },
  name: {
    color: "#1A2579",
    fontSize: 18,
    lineHeight: 1.4,
    fontWeight: 800,
    paddingBottom: 10
  },
  field: {
    fontWeight: 600,
    display: "flex",
    flexDirection: "row",
    paddingTop: 2,
    paddingBottom: 2
  },
  fieldName: {
    width: 90,
    fontWeight: 600,
    textAlign: "right"
  },
  fieldValue: {
    paddingLeft: 10
  }
});
