import _ from "lodash";
import moment from "moment";
import defaultGoals from "./data/goals";
import { randomImageId } from "./components/goal-card-views/GoalIcon";
import AuthService from "./services/AuthService";
import { initializeApp } from "firebase/app";
import {
  getAnalytics,
  logEvent as firebaseLogEvent,
  setCurrentScreen as firebaseSetCurrentScreen,
  setUserProperties as firebaseSetUserProperties,
  setUserId as firebaseSetUserId
} from "firebase/analytics";

import { v4 as uuid } from "uuid";
import React from "react";
let analytics;

const firebaseConfig = {
  apiKey: "AIzaSyDbWu8nCGijdlRBZdXOOj-XLaPKELdk1eM",
  authDomain: "manatee-production.firebaseapp.com",
  databaseURL: "https://manatee-production.firebaseio.com",
  projectId: "manatee-production",
  storageBucket: "manatee-production.appspot.com",
  messagingSenderId: "370963521814",
  appId: "1:370963521814:web:6fc72a2b387b8341263037",
  measurementId: "G-LH896V0284"
};

const testUserFamilyIds = [
  5, 9, 24, 29, 14, 22, 46, 170, 206, 207, 275, 276, 284, 362, 394, 547, 549, 855, 1015, 1018, 1028, 1047, 1448, 1676,
  1757, 1799, 1147, 1183, 1217, 1352, 1830, 1850, 1853, 1856, 1871, 1994
];

const useAnalytics = window.location.href.includes("home.getmanatee.com");

const navigateRef = React.createRef();

export function setNavigateRef(navigate) {
  navigateRef.current = navigate;
}

if (useAnalytics) {
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  analytics = getAnalytics(app);
}

const qualified = () => {
  if (!useAnalytics) {
    return false;
  }
  const familyId = sessionStorage.getItem("familyId");
  return !familyId || !testUserFamilyIds.includes(parseInt(familyId));
};

export default () => {
  return {
    initialize({ set }) {
      set({
        goals: defaultGoals,
        filterMonth: moment().format("MMMM"),
        filterYear: moment().format("YYYY"),
        filterDay: "14",
        isErrorModalVisible: false,
        children: []
      });
    },

    logout({ set, dispatch }) {
      dispatch("navigate", { pathname: "/login" });
      AuthService.logout();
      set({ loggedIn: false });
      set({ user: { loggedOut: true } });
      AuthService.removeAuthToken();
      logEvent("logOutSuccess");
      sessionStorage.removeItem("familyId"); // perform after last analytics call
    },

    navigate2FA({ set, dispatch }, { pathname, params }) {
      set({ email: params.email, password: params.password });
      dispatch("navigate", { pathname, options: { params } });
    },

    setUser({ set }, user) {
      set({ user });
    },

    setConsultView({ set }, consultView) {
      set({ consultView });
    },

    setCareScreenerSubmissionId({ set }, careScreenerSubmissionId) {
      set({ careScreenerSubmissionId });
    },

    setTypeformResponseId({ set }, typeformResponseId) {
      set({ typeformResponseId });
    },

    setInsuranceEligibilityStatus({ set }, insuranceEligibilityStatus) {
      set({ insuranceEligibilityStatus });
    },

    setUserId({ set }, userId) {
      set({ userId });
      if (qualified()) {
        firebaseSetUserId(analytics, userId);
      }
    },

    setPatient({ set, dispatch }, { pathname, params }) {
      set({ patient: params.patient });
      if (pathname) {
        dispatch("navigate", { pathname, options: { params } });
      }
    },

    setPatientData({ set }, { data }) {
      set({ patientData: data });
    },

    setChildren({ set }, { children }) {
      set({ children });
    },

    setGoal({ set }, { params }) {
      set({ goal: params.goal });
    },

    setReward({ set }, { params }) {
      set({ reward: params.reward });
    },

    setOnCancel({ set }, { params }) {
      set({ onCancel: params.onCancel });
    },

    setFilter({ set }, { filter }) {
      set({ filter });
    },

    setFilterMonth({ set }, { filterMonth }) {
      set({ filterMonth });
    },

    setFilterYear({ set }, { filterYear }) {
      set({ filterYear });
    },

    setFilterDay({ set }, { filterDay }) {
      set({ filterDay });
    },

    setAssessments({ set }, { assessments }) {
      set({ assessments });
    },

    setMessage({ set }, { message }) {
      set({ message });
    },

    setOpenDefaultRewardEditScreen({ set }, { params }) {
      set({ openDefaultRewardEditScreen: params.openDefaultRewardEditScreen });
    },

    setOpenCreateGoalScreen({ set }, { params }) {
      set({ openCreateGoalScreen: params.openCreateGoalScreen });
    },

    setOpenAssignedGoalScreen({ set }, { params }) {
      set({ openAssignedGoalScreen: params.openAssignedGoalScreen });
    },

    setOpenCustomGoalScreen({ set }, { params }) {
      set({ openCustomGoalScreen: params.openCustomGoalScreen });
    },

    setOpenStandardGoalScreen({ set }, { params }) {
      set({ openStandardGoalScreen: params.openStandardGoalScreen });
    },

    setPatientRefetch({ set }, { params }) {
      set({ patientRefetch: params.patientRefetch });
    },

    continueInvite({ set, dispatch }, { pathname, params }) {
      set({ inviteAccountType: params.inviteAccountType });
      dispatch("navigate", { pathname });
    },

    setConsultCallback({ set, dispatch }, { params }) {
      set({ consultCallback: params.consultCallback });
      dispatch("navigate", { pathname: "/forgotPassword", options: { params } });
    },

    // eslint-disable-next-line no-empty-pattern
    navigate({}, { pathname }) {
      if (navigateRef.current) {
        navigateRef.current(pathname);
      } else {
        console.log(`unable to navigate to ${pathname} due to undefined navigateRef`);
      }
    },

    async createGoal({ get, set }, goal) {
      const { goals } = get();
      const newGoal = {
        ...goal,
        id:
          (_(goals)
            .map(g => g.id)
            .max() || 0) + 1,
        custom: true,
        theme: "nightSky",
        animal: randomImageId()
      };
      const nextGoals = goals.concat([newGoal]);
      set({ goals: nextGoals });
    },

    async createReward({ get, set }, reward) {
      const { rewards } = get();
      const newReward = {
        ...reward,
        id:
          (_(rewards)
            .map(r => r.id)
            .max() || 0) + 1,
        custom: true
      };
      const nextRewards = rewards.concat([newReward]);
      set({ rewards: nextRewards });
    },

    /* eslint-disable no-empty-pattern */
    setProvider({}, providerName) {
      if (qualified()) {
        firebaseSetUserProperties(analytics, { healthcare_provider: providerName });
      }
    },

    setUserRole({}, role) {
      if (qualified()) {
        firebaseSetUserProperties(analytics, { user_role: role });
      }
    },

    setCurrentScreen({}, screen) {
      if (qualified()) {
        firebaseSetCurrentScreen(analytics, screen);
      }
    },

    logEvent({}, event, obj) {
      if (qualified()) {
        firebaseLogEvent(analytics, event, obj);
      }
    },
    /* eslint-enable no-empty-pattern */

    showModal({ set }, message) {
      set({ error: { message } });
      set({ isErrorModalVisible: true });
    },

    hideModal({ set }) {
      set({ isErrorModalVisible: false });
    },

    displayToast({ get, set }, toast) {
      let { activeToasts } = get();
      activeToasts = activeToasts ? activeToasts : [];

      if (!toast.id) {
        toast.id = uuid();
      }
      activeToasts.push(toast);

      const newActiveToasts = [...activeToasts];
      set({ activeToasts: newActiveToasts });
      const { toastRender } = get();

      if (toastRender) {
        toastRender(newActiveToasts);
      }
    },
    removeToast({ get, set }, toast) {
      let { activeToasts } = get();
      activeToasts = activeToasts ? activeToasts : [];

      const index = activeToasts.findIndex(t => t.id === toast.id);
      if (index >= 0) {
        activeToasts.splice(index, 1);
      }
      const newActiveToasts = [...activeToasts];
      set({ activeToasts: newActiveToasts });
      const { toastRender } = get();
      if (toastRender) {
        toastRender(newActiveToasts);
      }
    },
    setToastRender({ set }, renderFunction) {
      set({ toastRender: renderFunction });
    }
  };
};

// This version of the functions is for components that are not using atom yet.

// Analytics actions
export const setUserId = userId => {
  if (qualified()) {
    firebaseSetUserId(analytics, userId);
  }
};

export const setCurrentScreen = screen => {
  if (qualified()) {
    firebaseSetCurrentScreen(analytics, screen);
  }
};

export const logEvent = (event, obj) => {
  if (qualified()) {
    firebaseLogEvent(analytics, event, obj);
  }
};
