import "./BatchMessageModal.css";

import { Icon, Select, Button, TextField } from "@shopify/polaris";
import { XIcon } from "@shopify/polaris-icons";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { TouchableOpacity } from "./react-native";
import { useQuery, useMutation } from "@apollo/client";
import { SEND_MESSAGE_BATCH, GET_ALL_THERAPIST_CHILD_PARENTS } from "../queries";
import _ from "lodash";
import { useActions } from "tiny-atom/react/hooks";
import "../components/DataTable.css";
import MessageActionBar from "./messages/actions/MessageActionBar";
import MessageTemplateModal from "./messages/MessageTemplateModal";
import { AppContext } from "../context/AppContext";
import { AccountType } from "../model/userModels";

const CLIENTS_ONLY = "clients";
const CAREGIVERS_ONLY = "caregivers";
const ALL_RECIPIENTS = "all";

const adultAndChildRecipientOptions = [
  { key: "undefined", label: "Choose", value: undefined },
  { key: "CLIENTS_ONLY", label: "Children only", value: CLIENTS_ONLY },
  { key: "CAREGIVERS_ONLY", label: "Caregivers only", value: CAREGIVERS_ONLY },
  { key: "ALL_RECIPIENTS", label: "Children and caregivers", value: ALL_RECIPIENTS }
];

const adultRecipientOptions = [
  { key: "undefined", label: "Choose", value: undefined },
  { key: "CAREGIVERS_ONLY", label: "Caregivers only", value: CAREGIVERS_ONLY }
];

export default function BatchMessageModal({ clients, onClose }) {
  const { user } = useContext(AppContext);
  const recipientOptions =
    user.accountType === AccountType.THERAPIST ? adultAndChildRecipientOptions : adultRecipientOptions;
  const [selected, setSelected] = useState(recipientOptions[0].value);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [showTemplateModal, setShowTemplateModal] = useState(false);

  const { displayToast } = useActions();
  const [sendMessageBatchMutation] = useMutation(SEND_MESSAGE_BATCH);
  const { data: therapistChildParentData } = useQuery(GET_ALL_THERAPIST_CHILD_PARENTS);

  const getFamilyMemberIds = client => {
    if (therapistChildParentData?.therapistChildParents?.length) {
      return therapistChildParentData.therapistChildParents
        .filter(tcp => tcp.childId === client.id)
        .map(tcp => tcp.adultId);
    }
    return [];
  };

  const handleSelectChange = useCallback(value => setSelected(value), []);
  const handleSubmit = () => {
    setLoading(true);

    let recipients = [];
    clients.forEach(client => {
      if (selected === CLIENTS_ONLY) {
        recipients.push(client.id);
      } else if (selected === CAREGIVERS_ONLY) {
        recipients.push(...getFamilyMemberIds(client));
      } else if (selected === ALL_RECIPIENTS) {
        recipients.push(client.id);
        recipients.push(...getFamilyMemberIds(client));
      }
    });
    recipients = _.uniq(recipients);
    sendMessageBatchMutation({
      variables: {
        message,
        options: recipients.map(r => {
          return { toUserIds: [r] };
        })
      }
    })
      .then(() => {
        displayToast({ text: "Messages Sent!" });
        onClose();
      })
      .catch(() => {
        setError("Failed to send messages, please try again.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (selected === CAREGIVERS_ONLY) {
      setError("Please note: Standalone accounts will not receive this message");
    } else {
      setError("");
    }
  }, [selected]);

  return (
    <>
      <div className="bulk-message-modal">
        {showTemplateModal && (
          <div className="template-modal-container">
            <MessageTemplateModal
              className="template-modal"
              hidden={!showTemplateModal}
              setHidden={hidden => setShowTemplateModal(!hidden)}
              onSubmit={template => setMessage(message => (message ? message + "\n" + template : template))}
            />
          </div>
        )}
        {!showTemplateModal && (
          <div className="modal-container">
            <div className="modal-close-container">
              <TouchableOpacity style={{ cursor: "pointer" }} onPress={onClose}>
                <Icon source={XIcon} />
              </TouchableOpacity>
            </div>
            <div className="modal-content">
              <div className="modal-title">To</div>
              <div className="modal-select">
                <Select options={recipientOptions} onChange={handleSelectChange} value={selected} />
              </div>
              {error && <div className="modal-error">{error}</div>}
              <div className="modal-input-container">
                <div className="modal-input">
                  <div className="batch-custom-polaris">
                    <TextField
                      id={"batchMessageModalId"}
                      value={message}
                      onChange={setMessage}
                      multiline={12}
                      autoComplete="off"
                      maxLength={3500}
                      maxHeight={250}
                    />
                  </div>
                </div>
                <div className="action-bar">
                  <MessageActionBar
                    textInputId="batchMessageModalId"
                    setMessage={setMessage}
                    message={message}
                    setShowTemplateModal={setShowTemplateModal}
                    style={{ posistion: "relative", right: 60, bottom: 2, width: 20, zIndex: 100 }}
                    pickerStyle={{ posistion: "absolute", bottom: 0, left: 0, width: 500, zIndex: 100 }}
                  />
                </div>
              </div>

              <div className="modal-send-button">
                <Button
                  variant="primary"
                  fullWidth
                  submit
                  disabled={loading || message.length === 0 || selected === recipientOptions[0].value}
                  onClick={handleSubmit}
                >
                  Send
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
