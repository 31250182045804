import React, { useEffect, useState } from "react";
import { TouchableOpacity } from "../react-native";
import { StyleSheet, css } from "aphrodite";
import useHasNarrowScreen from "../../hooks/useHasNarrowScreen";

export function Component({ text, onPress, getIcon = () => {}, getIndicator }) {
  return { text, onPress, getIcon, getIndicator };
}

export function ComponentSwitch({ tabs, defaultIndex, selectedTheme = "manaBlue", large = false }) {
  const [selectedIndex, setSelectedIndex] = useState(defaultIndex);

  useEffect(() => {
    setSelectedIndex(defaultIndex);
  }, [defaultIndex]);

  const hasNarrowScreen = useHasNarrowScreen();

  return (
    <div className={css(styles.header)}>
      {tabs.map((tab, i) => {
        const indicator = tab.getIndicator && tab.getIndicator();
        const selected = selectedIndex === i;
        return (
          <TouchableOpacity
            style={{
              flexGrow: "1"
            }}
            key={i}
            onPress={() => {
              tab.onPress();
              setSelectedIndex(i);
            }}
          >
            <div
              className={css([
                styles.tab,
                hasNarrowScreen ? styles.tabFontSizeNarrow : styles.tabFontSizeWide,
                selected ? styles.activeTab : styles.inactiveTab,
                selected ? styles[selectedTheme] : undefined,
                large ? styles.largePadding : styles.smallPadding
              ])}
            >
              {<div className={css(styles.icon)}>{tab.getIcon(selected)}</div> || <></>}
              <span>{tab.text}</span>
              {indicator > 0 && <p className={css(styles.indicator)}>{indicator}</p>}
            </div>
          </TouchableOpacity>
        );
      })}
    </div>
  );
}

const styles = StyleSheet.create({
  header: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    backgroundColor: "#ECECEC",
    overflow: "hidden"
  },
  activeTab: {
    fontWeight: 700,
    backgroundColor: "#FFFFFF",
    zIndex: "0 !important"
  },
  inactiveTab: {
    fontWeight: 700,
    color: "#C4C4C4",
    backgroundColor: "#FFFFFF",
    borderBottomColor: "#C4C4C4"
  },
  tab: {
    fontFamily: "Helvetica",
    fontStyle: "normal",
    whiteSpace: "normal",
    flexDirection: "row",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",

    borderBottom: "solid",
    borderBottomWidth: "10px"
  },
  tabFontSizeWide: {
    fontSize: "18px"
  },
  tabFontSizeNarrow: {
    fontSize: "13px"
  },
  icon: {
    marginRight: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  indicator: {
    borderRadius: 22,
    height: 22,
    lineHeight: "22px",
    marginLeft: 8,
    paddingLeft: 12,
    paddingRight: 12,
    backgroundColor: "#38D4DE",
    color: "#ffffff",
    fontWeight: 700,
    fontSize: 13
  },
  manaBlue: {
    color: "#2562D8",
    borderBottomColor: "#2562D8"
  },
  purpleCoral: {
    color: "#8d2995",
    borderBottomColor: "#8d2995"
  },
  largePadding: {
    padding: "20px 20px 20px 20px"
  },
  smallPadding: {
    padding: "10px 20px 10px 20px"
  }
});
