import { useContext } from "react";
import { AccountType } from "../../model/userModels";
import Text from "../core/Text";
import { UserContext } from "../../context/UserContext";
import Flex from "../core/Flex";
import React from "react";
import moment from "moment";
import BookedAppointmentImage from "../../assets/booked-appointment.png";

export type Appointment = {
  date: string;
  appointmentType: {
    name: string;
  };
  attendees: {
    id: number;
    firstName: string;
    lastName: string;
    accountType: AccountType;
  }[];
};

type Props = {
  appointment: Appointment;
};

export default function BookedAppointment({ appointment }: Props) {
  const { user } = useContext(UserContext) || {};
  const careTeamMember = appointment.attendees.find(u => AccountType.isCareTeamMember(u.accountType));
  const familyMember = appointment.attendees.find(u => u.id !== careTeamMember?.id);

  const userText = familyMember && user && familyMember.id !== user.id ? `${familyMember.firstName}'s` : "Your";

  return (
    <Flex style={{ padding: 20 }}>
      <Text center theme="manaBlueDark" size={32} weight={700} style={{ paddingBottom: 20 }}>
        Way to go! 👏
      </Text>
      <Flex center style={{ backgroundColor: "#E5EEFF", borderRadius: 12, padding: 28 }}>
        <Text center theme="manaBlue" size={16} weight={700} style={{ marginBottom: 10 }}>
          🗓️ {userText} first session
        </Text>
        {!!appointment?.date && (
          <>
            <Text theme="manaBlueDark" center size={24} weight={700}>
              {moment(appointment.date).format("LL")}
            </Text>
            <Text theme="manaBlueDark" center size={24} weight={700}>
              at {moment(appointment.date).format("LT")}
            </Text>
            {careTeamMember && <Text style={{ paddingTop: 5 }}>with {careTeamMember.firstName}</Text>}
          </>
        )}
      </Flex>
      <Text center size={16} weight={400} color="#212328" style={{ padding: 10 }}>
        Check your inbox for a confirmation and your next steps!
      </Text>
      <img style={{ height: 200, objectFit: "contain" }} src={BookedAppointmentImage} alt="manatee app" />
      <Text center>Want to know more?</Text>
      <Text
        center
        link={{
          onPress: () => {
            window.open("https://www.getmanatee.com/faqs", "_blank");
          }
        }}
      >
        Read our FAQs
      </Text>
    </Flex>
  );
}
