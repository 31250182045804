import React from "react";
import { View } from "./react-native";

export default class ButtonStack extends React.PureComponent {
  render() {
    const children = React.Children.toArray(this.props.children).filter(item => item);
    return (
      <View
        style={[
          { display: "flex" },
          { flexDirection: this.props.horizontal ? "row" : "column" },
          this.props.fullWidth && {
            width: "100%",
            justifyContent: "space-between"
          },
          this.props.bottomSticky && {
            position: "sticky",
            bottom: 0,
            minHeight: "66px",
            backgroundColor: "white",
            borderTop: "1px solid #ececec",
            zIndex: 100,
            padding: "15px"
          }
        ]}
      >
        {children.map((item, i) => (
          <View
            key={i}
            style={[
              this.props.fullWidth && { flex: 1 },
              this.props.horizontal
                ? {
                    marginRight: i !== children.length - 1 ? 10 : 0
                  }
                : {
                    marginBottom: i !== children.length - 1 ? 16 : 0
                  }
            ]}
          >
            {item}
          </View>
        ))}
      </View>
    );
  }
}
