import React from "react";
import useConversations from "../hooks/useConversations";

export const MessagingContext = React.createContext();

export function MessagingContextProvider({ children }) {
  const { unreadCount, conversations, refetch, loading, error } = useConversations();
  return (
    <MessagingContext.Provider value={{ unreadCount, conversations, refetch, loading, error }}>
      {children}
    </MessagingContext.Provider>
  );
}
