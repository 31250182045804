import React, { useMemo } from "react";
import { GET_ASSESSMENT_ASSIGNMENTS_AND_COMPLETIONS } from "../queries";
import { useQuery } from "@apollo/client";
import useFamilyMembers from "../hooks/useFamilyMembers";
import { useAtom } from "tiny-atom/react/hooks";
import moment from "moment";
import { isChartableClinicalAssessment } from "../components/assessments/ClinicalAssessmentChart";
import { useContext } from "react";
import { AppContext } from "./AppContext";
import { AccountType } from "../model/userModels";

export const FamilyAssessmentContext = React.createContext();

const parentColors = ["#0097E9", "#1A2579", "#3BC1D2"];
const clientColor = "#8D2995";

const CARE_PROVIDER_VIEWABLE_ASSESSMENTS = [
  "manatee-parent-survey",
  "bbk-client-survey",
  "gad-7",
  "phq-a-8",
  "phq-a-9",
  "psc"
];

const CARE_COORINDATOR_VIEWABLE_ASSESSMENTS = ["manatee-parent-survey", "bbk-client-survey"];

export const SUPPORTED_ASSIGNABLE_ASSESSMENTS = ["gad-7", "phq-a-8", "psc"];

export function getAssessmentsCtmPermittedToAssign(careTeamMember, client) {
  if (!careTeamMember?.accountType) {
    return [];
  }
  switch (careTeamMember.accountType) {
    case "THERAPIST":
      return SUPPORTED_ASSIGNABLE_ASSESSMENTS;
    case "PARENT_COACH":
      return ["psc"];
    case "CARE_COORDINATOR":
      return ["psc"];
    default:
      return [];
  }
}

export function FamilyAssessmentContextProvider({ children }) {
  const patient = useAtom(state => state.patient);
  const assessments = useAtom(state => state.assessments);

  const { familyMembers, parents } = useFamilyMembers();
  const familyMemberIds = familyMembers.map(u => u.id);

  const { user } = useContext(AppContext);

  const viewableAssessmentIdentifiers = useMemo(
    () =>
      AccountType.isCareProvider(user?.accountType)
        ? CARE_PROVIDER_VIEWABLE_ASSESSMENTS
        : CARE_COORINDATOR_VIEWABLE_ASSESSMENTS,
    [user?.accountType]
  );

  const {
    data: assessmentData,
    loading,
    refetch
  } = useQuery(GET_ASSESSMENT_ASSIGNMENTS_AND_COMPLETIONS, {
    variables: {
      userIds: familyMemberIds,
      assessmentIdentifiers: viewableAssessmentIdentifiers
    },
    fetchPolicy: "cache-and-network"
  });

  const assignments = useMemo(
    () => assessmentData?.assessmentAssignments || [],
    [assessmentData?.assessmentAssignments]
  );

  const assignable = useMemo(
    () => assessmentData?.assessmentsAssignable || {},
    [assessmentData?.assessmentsAssignable]
  );

  const completions = useMemo(() => {
    return (assessmentData?.completedAssessments || [])
      .filter(a => !a.assessmentParameters?.subjectUserId || a.assessmentParameters?.subjectUserId === patient.id)
      .map(a => [a, ...(a.subResponses || [])])
      .flat()
      .sort((a, b) => moment(a.completedAt).diff(b.completedAt));
  }, [assessmentData?.completedAssessments, patient.id]);

  const colorByFmId = useMemo(() => {
    const fmColors = {};
    parents.forEach((m, i) => {
      fmColors[m.id] = parentColors[i % parentColors.length];
    });

    fmColors[patient.id] = clientColor;

    return fmColors;
  }, [parents, patient.id]);

  const chartableAssessments = useMemo(
    () => findFamilyApplicableAssessments(assessments, assignable, completions, viewableAssessmentIdentifiers),
    [assessments, assignable, completions, viewableAssessmentIdentifiers]
  );

  const showSendAssessmentsButton = useMemo(
    () => assignable?.assessmentIdentifiers?.some(identifier => SUPPORTED_ASSIGNABLE_ASSESSMENTS.includes(identifier)),
    [assignable?.assessmentIdentifiers]
  );

  return (
    <FamilyAssessmentContext.Provider
      value={{
        assignable,
        chartableAssessments,
        completions,
        assignments,
        colorByFmId,
        loading,
        refetch,
        showSendAssessmentsButton
      }}
    >
      {children}
    </FamilyAssessmentContext.Provider>
  );
}

function findFamilyApplicableAssessments(assessments, assignable, completions, viewableAssessmentIdentifiers) {
  const applicable = [];

  const allPossibleIdentifiers = new Set();
  if (assignable?.assessmentIdentifiers?.length > 0) {
    assignable.assessmentIdentifiers.forEach(identifier => {
      allPossibleIdentifiers.add(identifier);
    });
  }

  if (completions?.length > 0) {
    completions.forEach(c => {
      allPossibleIdentifiers.add(c.assessmentIdentifier);
    });
  }

  allPossibleIdentifiers.forEach(identifier => {
    const assessment = assessments.find(d => d.identifier === identifier);
    if (
      assessment &&
      isChartableClinicalAssessment(assessment) &&
      viewableAssessmentIdentifiers.includes(assessment.identifier)
    ) {
      applicable.push(assessment);
    }
  });

  return applicable;
}
