import React, { useEffect, useMemo, useRef } from "react";
import { NetworkStatus } from "@apollo/client";
import { Spinner } from "@shopify/polaris";
import Message from "../Message";

export default function ConversationMessages({ conversation, asUserId, data, loading, refetch, networkStatus }) {
  const messageEndRef = useRef();
  const messages = useMemo(() => conversation?.messages || [], [conversation]);

  const scrollToBottom = () => {
    if (messageEndRef && messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div className="conversation-messages">
      {!conversation && loading && !data && networkStatus !== NetworkStatus.poll && (
        <div style={styles.loading}>
          <Spinner />
        </div>
      )}
      <div style={styles.messages}>
        <div ref={messageEndRef} />
        {messages?.length > 0 &&
          messages.map((m, index) => (
            <Message
              message={m}
              conversation={conversation}
              asUserId={asUserId}
              key={index}
              index={index}
              refetch={refetch}
            />
          ))}
      </div>
    </div>
  );
}

const styles = {
  loading: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    height: "100%"
  },
  messages: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "auto",
    overflow: "auto",
    display: "flex",
    flexDirection: "column-reverse",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10
  }
};
