import React from "react";
import { StyleSheet, css } from "aphrodite";
import FamilyImage from "../../assets/consult/consult-booked-illustration.png";
import HeartIcon from "../../assets/consult/heart.png";
import BookIcon from "../../assets/consult/book.png";
import ManateeImage from "../../assets/consult/manatee.png";
import useQueryUser from "../../hooks/useQueryUser";

export default function NoConsultNextSteps({ typeformPath }) {
  const { user } = useQueryUser();
  return (
    <div className={css(styles.container)}>
      <img src={FamilyImage} className={css(styles.image)} />
      <p className={css(styles.manaBlue, styles.textCentered)}>
        <span>{`Way to go${user?.firstName ? ", " + user.firstName : ""}! `}</span>
      </p>
      <div className={css(styles.verticalSpacerSm)} />
      {typeformPath === "lyra" ? (
        <p className={css(styles.gray, styles.textCentered)}>
          We'll be in touch with more details about your upcoming appointment soon.
        </p>
      ) : (
        <p className={css(styles.gray, styles.textCentered)}>We'll be in touch with you soon.</p>
      )}
      <div className={css(styles.verticalSpacerLg)} />
      <p className={css(styles.manaBlue)}>What’s next?</p>
      <p className={css(styles.manaDarkBlue, styles.textCentered)}>Log into your account on the Manatee app</p>
      <div className={css(styles.verticalSpacerSm)} />
      <a className={css(styles.button, styles.downloadButton)} href="https://www.getmanatee.com/get-the-app">
        Download the app
      </a>
      <div className={css(styles.verticalSpacerLg)} />
      <p className={css(styles.gray)}>Use your email and your new password to:</p>
      <div className={css(styles.verticalSpacer)} />
      <div>
        <div className={css(styles.row)}>
          <img src={BookIcon} className={css(styles.icon)} />
          <p className={css(styles.manaBlue, styles.textCentered)}>Start learning with our parenting resources</p>
        </div>
        <div className={css(styles.row)}>
          <img src={HeartIcon} className={css(styles.icon)} />
          <p className={css(styles.manaBlue, styles.textCentered)}>Get a head start on your care journey</p>
        </div>
      </div>
      <div className={css(styles.verticalSpacer)} />
      <img src={ManateeImage} className={css(styles.image)} />
      <p className={css(styles.manaBlue, styles.textCentered)}>Still need some clarification? We’ve got you.</p>
      <a className={css(styles.button)} href="https://www.getmanatee.com/contact-us">
        Contact Manatee Care
      </a>
    </div>
  );
}

const smallScreen = window.innerWidth < 768;

const styles = StyleSheet.create({
  container: {
    marginTop: 50,
    marginBottom: 50,
    padding: "5%",
    paddingTop: 10,
    backgroundColor: "#FFFFFF",
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minWidth: smallScreen ? 200 : 500,
    maxWidth: smallScreen ? 350 : 600
  },
  row: {
    display: "flex",
    flexDirection: smallScreen ? "column" : "row",
    alignItems: "center",
    minWidth: smallScreen ? 200 : 500,
    maxWidth: smallScreen ? 300 : 600
  },
  image: {
    width: 225,
    height: 225,
    objectFit: "contain"
  },
  statsImage: {
    width: smallScreen ? 150 : 100,
    height: smallScreen ? 150 : 100,
    paddingRight: 10,
    objectFit: "contain"
  },
  narrow: {
    width: 500
  },
  appointmentsIcon: {
    transform: "scale(1.25);"
  },
  icon: {
    width: 90,
    height: 70,
    marginRight: smallScreen ? 0 : 20,
    objectFit: "contain"
  },
  manaBlue: {
    color: "#2562D8",
    fontWeight: 500,
    fontSize: 20,
    lineHeight: 1.4,
    paddingBottom: 7
  },
  manaDarkBlue: {
    color: "#1A2579",
    fontWeight: 700,
    fontSize: 26,
    lineHeight: 1
  },
  gray: {
    fontSize: 16,
    lineHeight: 1.5,
    color: "#636C8C",
    fontWeight: 400,
    textAlign: smallScreen ? "center" : "start"
  },
  textCentered: {
    textAlign: "center"
  },
  verticalSpacerSm: {
    height: 5
  },
  verticalSpacer: {
    height: 10
  },
  verticalSpacerLg: {
    height: 22
  },
  button: {
    backgroundColor: "#BBD2FF",
    borderRadius: 15,
    fontSize: 16,
    color: "#2562D8",
    padding: 10,
    textAlign: "center",
    minWidth: 250,
    fontWeight: 600,
    textDecoration: "none"
  },
  downloadButton: {
    marginTop: 10,
    backgroundColor: "#2562D8",
    color: "#FFFFFF"
  },
  hilite: {
    backgroundColor: "#EDD666",
    paddingTop: 0,
    paddingBottom: 0
  }
});
