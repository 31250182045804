import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import "./ActivityFeed.css";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

import graph from "../../../assets/graph.png";
import star from "../../../assets/star.png";
import peopleCyan from "../../../assets/peopleCyan.png";
import personPurple from "../../../assets/personPurple.png";
import personCheckPurple from "../../../assets/personCheckPurple.png";
import peopleCheckCyan from "../../../assets/peopleCheckCyan.png";
import { useLazyQuery } from "@apollo/client";
import { GET_ACTIVITY_FEED } from "../../../queries";
import { useAtom } from "tiny-atom/react/hooks";
import useIsComponentOnScreen from "../../../hooks/useIsComponentOnScreen";

const icons = { graph, starCyan: star, starPurple: star, peopleCyan, personPurple, personCheckPurple, peopleCheckCyan };
const iconBackgroundColors = {
  graph: "#0097E9",
  starCyan: "#8D2995",
  starPurple: "#8D2995",
  peopleCyan: "#F0E9E4",
  personPurple: "#F0E9E4",
  personCheckPurple: "#F0E9E4",
  peopleCheckCyan: "#F0E9E4"
};
const limit = 20;

export default function ActivityFeed() {
  const patient = useAtom(state => state.patient);
  const patientData = useAtom(state => state.patientData);

  const [offset, setOffset] = useState(0);
  const [activityFeed, setActivityFeed] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const loadingMoreRef = useRef();
  const isComponentOnScreen = useIsComponentOnScreen(loadingMoreRef);

  const [getActivityFeed] = useLazyQuery(GET_ACTIVITY_FEED);

  const loadActivityData = useCallback(() => {
    setLoading(true);
    if (!loading && hasMore) {
      getActivityFeed({
        variables: { userIds: [patient.id, ...patientData.childParents.map(p => p.id)], offset, limit }
      })
        .then(result => {
          setOffset(offset + limit);
          setHasMore(result?.data?.activityFeed?.length > 0);
          setActivityFeed([...activityFeed, ...(result?.data?.activityFeed || [])]);
        })
        .finally(() => setLoading(false));
    }
  }, [offset, hasMore, patient, patientData, getActivityFeed, setActivityFeed, activityFeed, loading]);

  useEffect(() => {
    if (isComponentOnScreen) {
      loadActivityData(offset);
    }
  }, [isComponentOnScreen, loadActivityData, offset]);

  let activityItems = [...activityFeed];

  if (!activityItems?.length) {
    return <></>;
  }

  const Image = ({ image }) => {
    const src = `data:image/jpg;base64, ${image}`;
    return <img style={{ marginTop: 10, maxWidth: 268, objectFit: "cover" }} src={src} alt="achievement" />;
  };

  let insertions = [];
  for (let i = 0; i < activityItems.length - 1; i++) {
    let first = activityItems[i];
    let second = activityItems[i + 1];
    if (moment(new Date(first.date)).month() !== moment(new Date(second.date)).month()) {
      insertions.push({
        title: moment(new Date(second.date)).format("MMMM"),
        i: i + 1 + insertions.length,
        isDate: true
      });
    }
  }

  insertions.forEach(insertion => {
    activityItems.splice(insertion.i, 0, insertion);
  });

  return (
    <div className="activity-feed">
      <div className="activity-feed-title">
        {patient.accountType === "STANDALONE_CLIENT" ? "Activity" : "Family Activity"}
      </div>

      <VerticalTimeline animate={false} layout={"1-column-left"} lineColor={"#CCCCCC"}>
        {activityItems?.map((item, i) => (
          <VerticalTimelineElement
            key={i}
            className={`${item.isDate ? "activity-feed-date-container" : "activity-feed-item-container"}`}
            contentStyle={{ boxShadow: "none" }}
            contentArrowStyle={{ display: "none" }}
            iconStyle={{
              display: item.icon ? "flex" : "none",
              backgroundColor: iconBackgroundColors[item.icon] || "#F0E9E4",
              borderWidth: 0,
              boxShadow: "none",
              width: 40,
              height: 40,
              padding: 8
            }}
            icon={<>{item.icon && <img src={icons[item.icon]} className="activity-feed-icon" />}</>}
          >
            <div className={item.isDate ? "" : "activity-feed-item"}>
              <p className={item.isDate ? "activity-feed-date-title" : "activity-feed-item-title"}>{item.title}</p>
              <p className="activity-feed-item-subtitle">{item.subtitle}</p>
              {item.image && <Image image={item.image} />}
            </div>
            {item.date && <p className="activity-feed-item-date">{moment(new Date(item.date)).format("LLLL")}</p>}
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>
      {hasMore && (
        <div className="activity-feed-item-title loading" key={0} ref={loadingMoreRef}>
          Loading...
        </div>
      )}
    </div>
  );
}
