import React, { useEffect, useRef } from "react";
import { Filters as ShopifyFilters } from "@shopify/polaris";
import "./Filters.scss";

export default function Filters({ filters }) {
  const filterRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const clearAll = filterRef.current?.querySelector(".Polaris-Filters__ClearAll");

    if (clearAll) {
      clearAll.innerHTML = "";
    }
  }, []);

  return (
    <div className="filters" ref={filterRef}>
      {filters.length > 0 && (
        <ShopifyFilters
          filters={filters}
          onClearAll={() => filters.filter(f => f.clearAll).forEach(f => f.clearAll())}
          onQueryChange={() => null}
          onQueryClear={() => null}
          hideQueryField
          borderlessQueryField
          appliedFilters={filters}
        />
      )}
    </div>
  );
}
