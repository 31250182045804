import { ReactNode, useContext, useEffect, useState } from "react";
import { RoutingContext } from "../../context/RoutingContext";
import { AccountType } from "../../model/userModels";

export default function useAuthRequiredRouting(routeElements: ReactNode, requiredAccountTypes?: AccountType[]) {
  const { registerRoutes } = useContext(RoutingContext);

  const [localRequiredAccountTypes] = useState<AccountType[]>(requiredAccountTypes || []);

  useEffect(() => {
    const subscription = registerRoutes(routeElements, {
      authRequired: true,
      requiredAccountTypes: localRequiredAccountTypes
    });
    return subscription.remove;
  }, [registerRoutes, localRequiredAccountTypes, routeElements]);
}
