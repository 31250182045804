import React, { useMemo, useEffect } from "react";
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from "@apollo/client";
import { HealthieProvider, Form } from "@healthie/sdk";
import config from "../../config/env";
import "./CompleteEhrForm.css";
import "@healthie/sdk/dist/styles/index.css";

const ehrApiUrl = config.env.REACT_APP_EHR_GRAPHQL;

type Props = {
  ehrApiKey: string;
  ehrUserId: string;
  ehrFormId: string;
  onSubmitted: () => void;
  index: number;
};

declare global {
  interface Window {
    ReactNativeWebView: any;
  }
}

export default function CompleteEhrForm({ ehrApiKey, ehrUserId, ehrFormId, onSubmitted, index }: Props) {
  useEffect(() => {
    const findSubmitButton = setInterval(() => {
      const submitButton = document.querySelector(".submit-button-container");
      if (submitButton) {
        submitButton.addEventListener("click", findErrors);
        clearInterval(findSubmitButton);
      }
    }, 500);

    return () => {
      clearInterval(findSubmitButton);
    };
  }, [index]);

  const findErrors = () => {
    setTimeout(() => {
      const msg: string[] = [];
      const errorDivs = document.querySelectorAll(".form-field-error");
      errorDivs.forEach(node => {
        const parentNode = node.parentNode;
        if (parentNode) {
          const labelDiv = parentNode.querySelector(".label-text");
          const errorDiv = parentNode.querySelector(".form-field-error");
          if (labelDiv && errorDiv) {
            const labelText = labelDiv.textContent;
            const errorText = errorDiv.textContent;
            msg.push(`${labelText} : ${errorText}`);
          }
        }
      });
      if (msg.length > 0 && window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(msg.join("\n"));
      }
    }, 200);
  };

  const client = useMemo(() => {
    const httpLink = new HttpLink({
      uri: ehrApiUrl,
      headers: {
        authorization: `Basic ${ehrApiKey}`,
        authorizationsource: "API"
      }
    });
    return new ApolloClient({
      link: httpLink,
      cache: new InMemoryCache()
    });
  }, [ehrApiKey]);

  return (
    <div className="form-container">
      <ApolloProvider client={client}>
        <HealthieProvider userId={ehrUserId}>
          <Form
            id={ehrFormId}
            onSubmit={() => {
              onSubmitted();
              return true;
            }}
          />
        </HealthieProvider>
      </ApolloProvider>
    </div>
  );
}
