import React, { useState } from "react";
import { BlockStack, TextField, Button } from "@shopify/polaris";
import AuthService from "../services/AuthService";
import { Formik } from "formik";
import { useAtom, useActions } from "tiny-atom/react/hooks";
import Modal from "../components/Modal";
import "./TwoFactorAuthPhone.css";
import FormErrorMessage from "../components/FormErrorMessage";

export default function TwoFactorAuthPhone() {
  const [phoneNumber, setPhoneNumber] = useState();
  const email = useAtom(state => state.email);
  const { navigate, logEvent, setCurrentScreen, showModal, hideModal } = useActions();
  const error = useAtom(state => state.error);
  let loading = useAtom(state => state.loading);
  const isErrorModalVisible = useAtom(state => state.isErrorModalVisible);

  async function handleSubmit() {
    try {
      const result = await AuthService.submitPhone({
        userId: sessionStorage.getItem("userId"),
        email: email,
        phone: phoneNumber
      });
      if (result && !result.success) {
        showModal(result.message);
        logEvent("webTwoFactorAuthPhoneSubmitFailed");
      } else {
        logEvent("twoFactorAuthPhoneSubmitSuccess");
        navigate({ pathname: "/twoFactorAuthSubmitCode" });
      }
    } catch (e) {
      showModal("Error sending phone number. Please contact support@getmanatee.com");
      logEvent("webTwoFactorAuthPhoneSubmitFailed");
    }
  }

  // There are libraries and probably other better ways to do this. It does have a bug when changing characters from the middle but infrequent enough of a case & users can just delete all and start over
  function updatePhoneField(phone) {
    if (phone.charAt(phone.length - 1) === "-" && phone.charAt(phone.length - 2) === "-") {
      setPhoneNumber(phone.substr(0, phone.length - 1));
      return;
    }
    phone = phone.replace(/\D/g, "");
    if (phone.length > 2 && !(phone.length === 3 && phoneNumber.length === 4 && phoneNumber.charAt(3) === "-")) {
      // user is backspacing and deleted the dash
      phone = replace(phone, 3, "-");
    }
    if (phone.length > 6 && !(phone.length === 7 && phoneNumber.length === 8 && phoneNumber.charAt(7) === "-")) {
      phone = replace(phone, 7, "-");
    }
    setPhoneNumber(phone);
  }

  function replace(string, index, insert) {
    return string.substr(0, index) + insert + string.substr(index);
  }

  setCurrentScreen("TwoFactorAuthPhoneScreen");
  return (
    <div
      style={{
        pointerEvents: isErrorModalVisible ? "none" : undefined
      }}
    >
      <Formik
        initialValues={{ phoneNumber: "" }}
        validate={() => {
          let errors = {};
          if (!phoneNumber) {
            errors.phoneNumber = "Required";
          } else if (!/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/i.test(phoneNumber)) {
            errors.phoneNumber = "Invalid phone number format";
          }
          return errors;
        }}
        onSubmit={() => {
          setTimeout(() => {
            handleSubmit();
          }, 400);
        }}
      >
        {({ handleSubmit }) => (
          <div className="TwoFactorAuthPhone">
            <form className="TwoFactorAuthPhone-form" onSubmit={handleSubmit}>
              <div className="TwoFactorAuthPhone-logo" />
              <div className="TwoFactorAuthPhone-text">
                Please enter your mobile phone number for two factor authentication.
              </div>
              <BlockStack gap={"200"}>
                <TextField
                  placeholder="555-555-5555"
                  onChange={updatePhoneField}
                  type="phoneNumber"
                  name="phoneNumber"
                  value={phoneNumber}
                />
                <FormErrorMessage name="phoneNumber" component="div" />
                <Button fullWidth variant="primary" submit disabled={loading} onClick={handleSubmit}>
                  Submit
                </Button>
                <Button fullWidth onClick={() => navigate({ pathname: "/" })}>
                  Cancel
                </Button>
              </BlockStack>
            </form>
          </div>
        )}
      </Formik>
      <Modal
        title={error ? error.message : ""}
        hidden={!isErrorModalVisible}
        onButton1Press={hideModal}
        fullscreen={true}
      />
    </div>
  );
}
